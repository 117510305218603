import { NAME } from "./constants";

export const SET_PAYMENT_LOADING = `${NAME}/SET_PAYMENT_LOADING`;

export const SET_LOOKUP_DATA_LOADING = `${NAME}/SET_LOOKUP_DATA_LOADING`;

export const SET_LOOKUP_DATA = `${NAME}/SET_LOOKUP_DATA`;

export const SET_PAYMENT_RESPONSE = `${NAME}/SET_PAYMENT_RESPONSE`;

export const SET_ALL_COUNTRIES_DATA = `${NAME}/SET_ALL_COUNTRIES_DATA`;

export const SET_ALL_COUNTRIES_LOADING = `${NAME}/SET_ALL_COUNTRIES_LOADING`;