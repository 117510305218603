// matching/actions.js
import { LookUpData } from '../../common/models/input-models';
import { Patient } from '../../common/models/main-models';
import DataSvc from '../../common/services/data-svc';
import * as t from './actionTypes';
import { defaultPatientInfo } from './model';

export const setPatientLoading = (isLoading: boolean) => ({
    type: t.SET_PATIENTS_LOADING,
    payload: isLoading,
});

export const setLookUpData = (data: LookUpData[]) => ({
    type: t.SET_LOOKUP_DATA,
    payload: data
});

export const setPatientInfo = (patient: Patient) => ({
    type: t.SET_PATIENT_INFO,
    payload: patient
});

export const setPatientDataLoading = (isPatientDataLoaded: boolean) => ({
    type: t.SET_PATIENT_DATA_LOADING,
    payload: isPatientDataLoaded
});

export const getLookUpDataAsync = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setPatientLoading(true));
        DataSvc.utilsSvc.getLookUpData().then(
            (result: any) => {
                dispatch(setLookUpData(result));
                dispatch(setPatientLoading(false));
            },
            (error) => { console.error(error); }
        );
    };
};

export const updatePatientAsync = (patient: Patient) => {
    return (dispatch: any, getState: any) => {
        dispatch(setPatientLoading(true));
        DataSvc.patientSvc.updatePatient(getRidOfIncorrectNullValues(patient)).then(
            (result: any) => {
                dispatch(setPatientInfo(result));
                dispatch(setPatientLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}

export const saveNewPatientAsync = (patient: Patient) => {
    return (dispatch: any, getState: any) => {
        dispatch(setPatientLoading(true));
        DataSvc.patientSvc.savePatient(getRidOfIncorrectNullValues(patient)).then(
            () => {
                dispatch(setPatientInfo(defaultPatientInfo));   //  return to default state on submit
                dispatch(setPatientLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}

export const getPatientInfoByIdAsync = (patientId?: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setPatientDataLoading(true));
        if (patientId) {
            DataSvc.patientSvc.getPatientById(patientId).then(
                (result: any) => {
                    dispatch(setPatientInfo(result));
                    dispatch(setPatientDataLoading(false));
                },
                (error) => { console.error(error); }
            )
        }
        else {
            dispatch(setPatientInfo(defaultPatientInfo));
            dispatch(setPatientDataLoading(false));
        }
    };
};

export const getPatientInfoByUserIdAsync = (userId?: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setPatientDataLoading(true));
        if (userId) {
            DataSvc.patientSvc.getPatientByUserId(userId).then(
                (result: any) => {
                    dispatch(setPatientInfo(result));
                    dispatch(setPatientDataLoading(false));
                },
                (error) => { console.error(error); }
            )
        }
    }
}

function getRidOfIncorrectNullValues(object: any) {
    var updatedPatientObject = { ...object };
    if (updatedPatientObject.isAvailableForNoCompensation === 'null') {
        updatedPatientObject.isAvailableForNoCompensation = null;
    }
    if (updatedPatientObject.isEligibleForCompensation === 'null') {
        updatedPatientObject.isEligibleForCompensation = null;
    }
    return updatedPatientObject;
}