import * as React from 'react';
import { State } from '../model';
import CSVReader, { IFileInfo } from 'react-csv-reader';
import '../style.scss';
import EupatiDropdown from '../../../../common/static/dropdown';
import { FormGroup } from 'reactstrap';
import {
  LookUpOptions,
  PatientsImportData,
} from '../../../../common/models/input-models';
import { Rules } from '../../../../common/helpers';
import { Button, FormLabel } from 'react-bootstrap';
import CSVMappingComponent from '../../../../common/static/csv-import/csv-mapping';
import { ImportProperties } from '../model';
import { csvDataToJson } from '../../../../common/static/csv-import/csv-mapping-helper';
import ImportPreviewGrid from '../../../../common/static/import-preview-grid';
import { CSVMappedField } from '../../../../common/static/csv-import/csv-mapping-model';
import EupatiConfirmPage from '../../../../common/static/confirm-page';

type OwnProps = {};

export type DispatchProps = {
  submitPatientsData: (patientsData: PatientsImportData) => void;
  //getLookUpDate: () => void;
};

type Props = State & DispatchProps & OwnProps;

type OwnState = {
  errorMessage: string;
  lookUpValue: any;
  data: any[];
  mapping: CSVMappedField[];
  isMappingMode: boolean;
  isPreviewMode: boolean;
  isOptionSelected: boolean;
  isDataSubmitted: boolean;
};

// ids are based on enum PatientExpertises
const lookUpOptions = [
  { id: 1, name: 'EUPATI Fellow' } as LookUpOptions,
  { id: 3, name: 'EUPATI Open Classroom Learner' } as LookUpOptions,
  { id: 2, name: 'National EUPATI Fellow' } as LookUpOptions,
];
export default class ComponentRoot extends React.Component<Props, OwnState> {
  constructor(props: any) {
    super(props);

    this.state = {
      errorMessage: Rules.selectOptionMessage.required,
      lookUpValue: { expertise: 0 },
      data: [],
      mapping: [],
      isMappingMode: true,
      isPreviewMode: false,
      isOptionSelected: false,
      isDataSubmitted: false,
    };

    this.csvImportTypeHandler = this.csvImportTypeHandler.bind(this);
    this.readCSVData = this.readCSVData.bind(this);
    this.handleReadingError = this.handleReadingError.bind(this);
    this.finishMapping = this.finishMapping.bind(this);
    this.activateMapping = this.activateMapping.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  csvImportTypeHandler(e: any) {
    switch (e.target.value !== '') {
      case true:
        this.setState({
          errorMessage: '',
          lookUpValue: { expertise: parseInt(e.target.value) },
          isOptionSelected: true,
        });
        break;
      case false:
        this.setState({
          errorMessage: Rules.selectOptionMessage.required,
          lookUpValue: { expertise: 0 },
          isOptionSelected: false,
          data: [],
        });
        break;
    }
  }

  readCSVData(data: Array<any>, fileInfo: IFileInfo) {
    this.setState({ data: data });
    console.debug(fileInfo);
  }

  handleReadingError(err: any) {
    console.error(err);
  }

  finishMapping(mapping: any) {
    this.setState({
      mapping: mapping,
      isMappingMode: false,
      isPreviewMode: true,
    });
  }

  activateMapping() {
    this.setState({
      isMappingMode: true,
      isPreviewMode: false,
    });
  }

  submitData() {
    const mappedData = csvDataToJson(this.state.data, this.state.mapping);
    const patientExpertise = lookUpOptions.find(
      (l) => l.id === this.state.lookUpValue.expertise
    );
    mappedData.forEach((md: any) =>
      Object.assign(md, { expertise: patientExpertise })
    );
    this.props.submitPatientsData(mappedData as PatientsImportData);
    this.setState({
      isDataSubmitted: true,
      isMappingMode: false,
      isPreviewMode: false,
      isOptionSelected: false,
    });
  }

  render() {
    //Upload CSV UTF-8 (Comma delimited)
    let uploadCSVComponent: any;
    if (this.state.isOptionSelected) {
      let papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        encoding: 'utf-8',
        transformHeader: (header: any) =>
          header.toLowerCase().replace(`/\W/g`, '_'),
      };
      uploadCSVComponent = (
        <>
          <CSVReader
            cssClass="csv-reader-input"
            label="Select CSV patients data"
            onFileLoaded={(data, fileInfo) => this.readCSVData(data, fileInfo)}
            parserOptions={papaparseOptions}
            onError={this.handleReadingError}
            inputId="patients-csv-import"
            inputName="patients-csv"
            inputStyle={{ color: '#F0B72F', margin: 'auto' }}
          />
          <hr className="solid" />
        </>
      );
    }

    //Column mapping
    let csvMappingComponent: any;
    if (this.state.data.length > 0 && this.state.isMappingMode) {
      let csvMappingProps = {
        csvObject: this.state.data,
        objectProperties: ImportProperties,
        mapping: this.state.mapping,
        finishMapping: (mapping: any) => this.finishMapping(mapping),
        isOptionSelected: this.state.isOptionSelected,
      };
      csvMappingComponent = <CSVMappingComponent {...csvMappingProps} />;
    }

    //Data preview
    let previewComponent: any;
    if (this.state.data.length > 0 && this.state.isPreviewMode) {
      let previewData;
      if (this.state.data.length > 10) {
        previewData = this.state.data.slice(0, 10);
      } else {
        previewData = this.state.data;
      }

      let mappedPreviewData = csvDataToJson(previewData, this.state.mapping);

      previewComponent = (
        <>
          <ImportPreviewGrid importPatients={mappedPreviewData} />
          <div className="d-flex" id="importBtns">
            <Button
              id="mapping-button"
              className="btn-back"
              onClick={this.activateMapping}
            >
              Go To Mapping
            </Button>
            <Button
              id="mapping-button"
              className="btn"
              onClick={this.submitData}
            >
              Upload Data
            </Button>
          </div>
        </>
      );
    }

    //Confirm page
    let confirmPage: any;
    if (this.state.isDataSubmitted) {
      confirmPage = (
        <>
          <EupatiConfirmPage />
        </>
      );
    }

    return (
      <FormGroup>
        {!this.state.isDataSubmitted && (
          <>
            <h4
              style={{
                textAlign: 'center',
                margin: '5rem auto auto auto',
                textDecoration: 'underline',
                fontWeight: 'bold',
              }}
            >
              IMPORT PATIENTS DATA
            </h4>
            <hr className="solid" />
            <FormGroup className="import-pt-dropdown mb-5">
              <FormLabel style={{ alignItems: 'center' }}>
                Select EUPATI Patient type
              </FormLabel>
              <EupatiDropdown
                {...{
                  fieldName: 'csv-type',
                  errorMsg: this.state.errorMessage,
                  values: lookUpOptions,
                  onChange: (e: any) => this.csvImportTypeHandler(e),
                  defaultOptionText: '',
                  width: 'auto',
                }}
              />
            </FormGroup>
          </>
        )}
        {uploadCSVComponent}
        {csvMappingComponent}
        {previewComponent}
        {confirmPage}
      </FormGroup>
    );
  }
}
