import { RouteComponentProps } from "@reach/router";
import React from "react";
import { INVALID_PRICING_DATA } from "../../../common/helpers/messages";
import { State } from "../model";
import { toast } from 'react-toastify';
import { CountriesWithCodeModel, LookUpData, PaymentIntentCreateModel, PaymentIntentResponseModel } from "../../../common/models/input-models";
import EupatiLoader from "../../../common/static/loading-animation/loader";
import '../styles.scss';
import StripeCard from "../../../common/static/stripe-card";

type OwnProps = {
    isPaymentLoading: boolean;
    isLookUpDataLoading: boolean;
    isCountriesLoading: boolean;
    lookUpData: LookUpData[];
    countries: CountriesWithCodeModel[];
    paymentResponse: PaymentIntentResponseModel;
};

export type DispatchProps = {
    getLookUpData: () => void;
    getAllCountries: () => void;
    createPaymentIntent: (data: PaymentIntentCreateModel) => void;
};

type Props = State & DispatchProps & OwnProps & RouteComponentProps<{ location: { state: { price: number, plan: string, package: string, paymentMethod: string, clientSecret?: string } } }>;

type OwnState = {
    clientSecret: string;
};

export default class ComponentRoot extends React.Component<Props, OwnState> {
    constructor(props: any) {
        super(props);

        this.state = {
            clientSecret: ''
        } as OwnState;
    }

    componentDidMount() {
        const historyState = this.props.location?.state;
        if (historyState === undefined || historyState.price <= 0 ||
            historyState.package === '' || historyState.paymentMethod === '') {
            toast.error(INVALID_PRICING_DATA);
            window.location.href = '/pricing';
        }
        else {
            if (historyState.clientSecret === undefined) {
                this.props.createPaymentIntent({ price: historyState.price });
            }
            else {
                this.setState({ clientSecret: historyState.clientSecret });
            }
            this.props.getLookUpData();
            this.props.getAllCountries();
        }
    }

    render() {
        let mainComp = null;
        let loadComp = (
            <div className='loader-wrapper'>
                <EupatiLoader />
            </div>
        );

        if (this.props.isLookUpDataLoading ||
            this.props.isPaymentLoading ||
            this.props.isCountriesLoading
        ) {
            mainComp = loadComp;
        }
        else {
            mainComp = (
                <>
                    <StripeCard
                        {...{
                            lookUpData: this.props.lookUpData,
                            countries: this.props.countries,
                            clientSecret: this.state.clientSecret === '' ? this.props.paymentResponse.clientSecret : this.state.clientSecret,
                            package: this.props.location?.state.package,
                            plan: this.props.location?.state.plan,
                            price: this.props.location?.state.price,
                            paymentMethod: this.props.location?.state.paymentMethod
                        }}
                    />
                </>
            );
        }

        return (
            <React.Fragment>
                {mainComp}
            </React.Fragment>
        )
    }
}