import React from "react";
import { Form, Button } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import EupatiNonCMSToggleBtn from "../../../../common/static/toggle-btn-nonCMS";
import EupatiTextbox from "../../../../common/static/textbox";
import { Rules, Messages } from "../../../../common/helpers";
import { useHistory } from "react-router";
import EupatiTextarea from "../../../../common/static/textarea";
import { Project } from "../../../../common/models/main-models";
import { LookUpOptions } from "../../../../common/models/input-models";
import { toast } from 'react-toastify';

type OwnProps = {
    project: Project,
    isUserReal: boolean
}

type DispatchProps = {
    setProject: (project: Project) => void;
    returnProperDefaultValue: (val: any) => any;
    returnProperLookUpData: (dataType: any) => LookUpOptions[] | null;
    hidePatientsInfoSection: () => void;
    nextPage: () => void;
}

type Props = OwnProps & DispatchProps;

type FieldProps = {
    equipmentRequirements: string,
    timeRequired: string,
    overview: string,
    isThereTravelReimbursement?: number,
    isCompensationProvided?: number,
    compensation: string;
};

export default function ProjectPatientsInfo(props: Props) {

    const history = useHistory();

    const { handleSubmit, control, watch, formState: { errors } } = useForm();

    const fieldsData = {
        equipmentRequirements: watch("equipmentRequirements") as string,
        timeRequired: watch("timeRequired") as string,
        overview: watch("overview") as string,
        isThereTravelReimbursement: watch("isThereTravelReimbursement") as number | undefined,
        isCompensationProvided: watch("isCompensationProvided") as number | undefined,
        compensation: watch("compensation") as string
    } as FieldProps;

    const onSubmit = (data: any) => {
        if (!props.isUserReal) {
            toast.error(Messages.FAIL_AUTH_MSG);
            return history.push('/');
        }

        const updatedProject = Object.assign(props.project, data);
        props.setProject(updatedProject);
        props.nextPage();
    }

    const onBackHandler = () => {
        if (!props.isUserReal) {
            toast.error(Messages.FAIL_AUTH_MSG);
            return history.push('/');
        }

        if (adjustFieldsValues() === 0) {
            const updatedProject = Object.assign(props.project, fieldsData);
            props.setProject(updatedProject);
        }
        props.hidePatientsInfoSection();
    }

    const fieldToggle = () => {
        let val = '';
        if (fieldsData.isCompensationProvided !== undefined && fieldsData.isCompensationProvided !== null) {
            val = fieldsData.isCompensationProvided.toString();
        }
        else if (props.project.isCompensationProvided !== undefined && props.project.isCompensationProvided !== null) {
            val = props.project.isCompensationProvided.toString();
        }

        return val === 'true' ? true : false;
    }

    const adjustFieldsValues = () => {
        if (fieldsData.equipmentRequirements === undefined &&
            fieldsData.timeRequired === undefined &&
            fieldsData.overview === undefined &&
            fieldsData.isThereTravelReimbursement === undefined &&
            fieldsData.isCompensationProvided === undefined &&
            fieldsData.compensation === undefined) {
            return 1;
        }

        if (fieldsData.equipmentRequirements === undefined &&
            props.project.equipmentRequirements !== undefined) {
            fieldsData.equipmentRequirements = props.project.equipmentRequirements;
        }

        if (fieldsData.timeRequired === undefined &&
            props.project.timeRequired !== undefined) {
            fieldsData.timeRequired = props.project.timeRequired;
        }

        if (fieldsData.overview === undefined &&
            props.project.overview !== undefined) {
            fieldsData.overview = props.project.overview;
        }

        if (fieldsData.isThereTravelReimbursement === undefined &&
            props.project.isThereTravelReimbursement !== undefined) {
            fieldsData.isThereTravelReimbursement = props.project.isThereTravelReimbursement;
        }

        if (fieldsData.isCompensationProvided === undefined &&
            props.project.isCompensationProvided !== undefined) {
            fieldsData.isCompensationProvided = props.project.isCompensationProvided;
        }

        if (fieldsData.compensation === undefined &&
            props.project.compensation !== undefined) {
            fieldsData.compensation = props.project.compensation;
        }

        return 0;
    }

    return (
        <Form className='project' onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='project-info'>
                <Form.Group className='rcolumn'>

                    <Form.Group id='p-equptReq' className='project-info-field mb-3'>
                        <Form.Label>Equipment requirements</Form.Label>
                        <Controller
                            name='equipmentRequirements'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.equipmentRequirements)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextarea
                                    {...{
                                        ...field,
                                        errorMsg: errors?.equipmentRequirements?.message
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='p-timeReq' className='project-info-field mb-3'>
                        <Form.Label>Time required</Form.Label>
                        <Controller
                            name='timeRequired'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.timeRequired)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextarea
                                    {...{
                                        ...field,
                                        errorMsg: errors?.timeRequired?.message
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='p-overview' className='project-info-field mb-3'>
                        <Form.Label>Project overview</Form.Label>
                        <Controller
                            name='overview'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.overview)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextarea
                                    {...{
                                        ...field,
                                        errorMsg: errors?.overview?.message,
                                        rowsCount: 8
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='p-trvlReimb' className='project-info-field mb-5'>
                        <Form.Label>Travel reimbursement</Form.Label>
                        <Controller
                            name='isThereTravelReimbursement'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.isThereTravelReimbursement)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiNonCMSToggleBtn
                                    {...{
                                        ...field,
                                        fieldName: 'isThereTravelReimbursement',
                                        errorMsg: errors?.isThereTravelReimbursement?.message,
                                        isMoreThanTwoOptions: false
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='p-compProv' className='project-info-field mb-5'>
                        <Form.Label>Compensation provided</Form.Label>
                        <Controller
                            name='isCompensationProvided'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.isCompensationProvided)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiNonCMSToggleBtn
                                    {...{
                                        ...field,
                                        fieldName: 'isCompensationProvided',
                                        errorMsg: errors?.isCompensationProvided?.message,
                                        isMoreThanTwoOptions: false
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    {fieldToggle() &&
                        <Form.Group id='p-comp' className='project-info-field mb-5'>
                            <Form.Label>Amount</Form.Label>
                            <Controller
                                name='compensation'
                                control={control}
                                defaultValue={props.returnProperDefaultValue(props.project.compensation)}
                                rules={Rules.requiredMessage}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.compensation?.message,
                                            placeholder: 'Please type compensation amount'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    }

                    <Form.Group id='p-btns' className='project-info-field'>
                        <Button className='bck' variant='secondary' onClick={onBackHandler}>Back</Button>
                        <Button className='nxt' variant='primary' type='submit'>Next</Button>
                    </Form.Group>
                </Form.Group>
            </Form.Group>
        </Form>
    )
}