import React, { useState } from "react";
import { LookUpData, LookUpOptions } from "../../../../common/models/input-models";
import { Patient } from "../../../../common/models/main-models";
import PatientsDiseaseExpInfo from "./disease-exp";
import PatientsProjectExpInfo from "./proj-exp";

type OwnProps = {
    patient: Patient,
    lookUpData: LookUpData[],
    showSecondSubComponent: boolean
};

type DispatchProps = {
    setPatientInfo: (patient: Patient) => void;
    nextPage: () => void;
    prevPage: () => void;
    returnProperDefaultValue: (val: any) => any;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
    savePatient: (patient: Patient) => void;
};

type Props = OwnProps & DispatchProps;

export default function PatientExpcInfo(props: Props) {
    const [nextBtnClicked, setNextBtnClicked] = useState<boolean>(props.showSecondSubComponent);

    const toggleSectionVisibility = () => {
        setNextBtnClicked(!nextBtnClicked);
    }

    return (
        <div style={{ margin: '0 0 7.5rem 0' }}>

            {nextBtnClicked &&
                <PatientsProjectExpInfo
                    {...{
                        ...props,
                        showPatientsDiseaseExpInfo: toggleSectionVisibility
                    }}
                />
            }

            {!nextBtnClicked &&
                <PatientsDiseaseExpInfo
                    {...{
                        ...props,
                        hidePatientsDiseaseExpInfo: toggleSectionVisibility
                    }}
                />
            }
        </div>
    );
}