import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as React from 'react';
import { Table } from 'react-bootstrap';
import { LookUpData } from '../models/input-models';
import { Patient } from '../models/main-models';
import EupatiArrowsMenu from './arrows-menu';

type OwnProps = {
    patientInfo: Patient;
    lookUpData: LookUpData[];
    leftArrowText: string;
    rightArrowText?: string;
    isForwardArrowAvail: boolean;
    searchData?: any;
    projectName?: string;
    projectId?: string;
    forwardBtnHandler?: Function;
};

export type DispatchProps = {
    backBtnHandler: () => void;
};

type OwnState = {
    backBtnClicked: boolean;
};

type Props = DispatchProps & OwnProps;

export default class EupatiPatientDetails extends React.Component<Props, OwnState> {


    constructor(props: any) {
        super(props);

        this.state = {
            backBtnClicked: false
        }

        this.returnValueById = this.returnValueById.bind(this);
        this.returnJoinedArrayValues = this.returnJoinedArrayValues.bind(this);
        this.returnProperIconType = this.returnProperIconType.bind(this);
        this.toggleField = this.toggleField.bind(this);
    }

    returnValueById(propName: string, projectPropId: any) {
        let propType = this.props.lookUpData?.find(x => x.name == propName); // projectType

        let propValue = propType?.data.find(x => x.id == projectPropId);

        return propValue?.name;
    }

    returnJoinedArrayValues(fieldProps: any, lookUpDataType: any) {
        let arr = fieldProps?.map((x: any, i: any) => {
            return (
                this.returnValueById(lookUpDataType, x)
            )
        });

        return arr?.join(', ');
    }

    returnProperIconType(icon: any) {
        return icon as IconProp;
    }

    toggleField(val: string) {
        return val !== null && val !== '' ? true : false;
    }

    render() {

        let mainComponent = (
            <>
                <tbody>
                    <tr>
                        <th style={{ textAlign: 'center' }}>Patient information</th>
                    </tr>
                    <tr>
                        <td>First name</td>
                        <td>{this.props.patientInfo.firstName}</td>
                    </tr>
                    <tr>
                        <td>Last name</td>
                        <td>{this.props.patientInfo.lastName}</td>
                    </tr>
                    <tr>
                        <td>Phone number</td>
                        <td>{this.props.patientInfo.phoneNumber}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{this.props.patientInfo.email}</td>
                    </tr>
                    <tr>
                        <td>Birth year</td>
                        <td>{this.props.patientInfo.birthYear}</td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>{this.returnValueById('genderType', this.props.patientInfo.genderTypeId)}</td>
                    </tr>
                    {this.toggleField(this.props?.patientInfo?.genderTypeDescription) &&
                        <tr>
                            <td>Gender description</td>
                            <td>{this.props?.patientInfo?.genderTypeDescription}</td>
                        </tr>
                    }
                    <tr>
                        <td>Country</td>
                        <td>{this.returnValueById('country', this.props.patientInfo.countryId)}</td>
                    </tr>
                    <tr>
                        <td>Languages</td>
                        <td>{this.returnJoinedArrayValues(this.props.patientInfo?.languages, 'language')}</td>
                    </tr>
                    <tr>
                        <td>Countries with experience</td>
                        <td>{this.returnJoinedArrayValues(this.props.patientInfo?.countriesWithExperience, 'countryWithContinents')}</td>
                    </tr>
                    <tr>
                        <td>Is patient org affiliated</td>
                        <td>{this.props.patientInfo.isPOAffiliated ? "Yes" : "No"}</td>
                    </tr>
                    {this.props.patientInfo.isPOAffiliated &&
                        <tr>
                            <td>Patient organisation</td>
                            <td>{this.props.patientInfo.patientOrganisation}</td>
                        </tr>
                    }
                    <tr>
                        <td>Disease experience</td>
                        <td>{this.props.patientInfo.noDiseaseExpertise ? "No specific disease experience" : this.props.patientInfo.diseases.map(d => d.name).join(", ")}</td>
                    </tr>
                    <tr>
                        <td>Patient experience</td>
                        <td>{this.returnValueById('patientExperiense', this.props.patientInfo?.patientExperienceId)}</td>
                    </tr>
                    <tr>
                        <td>Patient expertise</td>
                        <td>{this.returnJoinedArrayValues(this.props.patientInfo?.patientExpertises, 'expertise')}</td>
                    </tr>
                    {this.toggleField(this.props?.patientInfo?.patientExpertiseDescription) &&
                        <tr>
                            <td>Patient expertise description</td>
                            <td>{this.props?.patientInfo?.patientExpertiseDescription}</td>
                        </tr>
                    }
                    <tr>
                        <td>Project type experiences</td>
                        <td>{this.returnJoinedArrayValues(this.props.patientInfo?.projectTypeExperiences, 'projectType')}</td>
                    </tr>
                    {this.toggleField(this.props?.patientInfo?.projectTypeExperiencesDescription) &&
                        <tr>
                            <td>Project type experience description</td>
                            <td>{this.props?.patientInfo?.projectTypeExperiencesDescription}</td>
                        </tr>
                    }
                    <tr>
                        <td>Is eligible for compensations</td>
                        <td>{this.props.patientInfo.isEligibleForCompensation ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                        <td>Is available for voluntary work</td>
                        <td>{this.props.patientInfo.isAvailableForNoCompensation ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                        <td>Project preferences</td>
                        <td>{this.returnJoinedArrayValues(this.props.patientInfo?.projectPreferences, 'projectType')}</td>
                    </tr>
                    {this.toggleField(this.props?.patientInfo?.projectPreferenceDescription) &&
                        <tr>
                            <td>Project preferences description</td>
                            <td>{this.props?.patientInfo?.projectPreferenceDescription}</td>
                        </tr>
                    }
                    <tr>
                        <td>Organisation preferences</td>
                        <td>{this.returnJoinedArrayValues(this.props.patientInfo?.organisationPreferences, 'organisationType')}</td>
                    </tr>
                    {this.toggleField(this.props?.patientInfo?.organisationPreferenceDescription) &&
                        <tr>
                            <td>Organisation preference description</td>
                            <td>{this.props?.patientInfo?.organisationPreferenceDescription}</td>
                        </tr>
                    }
                    <tr>
                        <td>Activity preference</td>
                        <td>{this.returnValueById('activityType', this.props.patientInfo?.activityPreferenceId)}</td>
                    </tr>
                    <tr>
                        <td>Other information</td>
                        <td>{this.props.patientInfo.otherInformation}</td>
                    </tr>
                    {/* {this.props.patientInfo.matchedProjects.length > 0 &&
                        <tr>
                            <td>Matched projects</td>
                            <td>{this.props.patientInfo.matchedProjects.map((x: any) => x.name).join(',')}</td>
                        </tr>
                    } */}
                    <tr>
                        <td>Is banned from search</td>
                        <td>{this.props.patientInfo.isBannedFromSearch ? "Yes" : "No"}</td>
                    </tr>
                </tbody>
            </>
        )

        return (
            <>
                <EupatiArrowsMenu
                    {...{
                        displayStylesProp: '',
                        leftArrowText: this.props.leftArrowText,
                        rightArrowText: this.props.rightArrowText,
                        backBtnHandler: this.props.backBtnHandler,
                        forwardBtnHandler: this.props.forwardBtnHandler,
                        projectId: this.props.projectId,
                        patientId: this.props.patientInfo.id,
                        isForwardArrowAvail: this.props.isForwardArrowAvail
                    }}
                />

                <Table striped bordered hover>
                    {mainComponent}
                </Table>
            </>
        );
    }
}