import React, { useState } from 'react';
import { Table, Button, Form } from 'react-bootstrap';
import EupatiProjectDetails from './project-details';
import { useTable, useGlobalFilter, usePagination, useSortBy } from "react-table";
import { useMemo } from 'react';
import EupatiDropdown from './dropdown';
import { LookUpData, PatientSearchData, ProjectPatientData, RequestData, EmailTemplateData, LookUpOptions } from '../models/input-models';
import { ProjectStatus } from '../helpers';
import GlobalFilter from './global-filter';
import './static.scss';
import { Patient, Project } from '../models/main-models';
import EupatiLoader from './loading-animation/loader';
import { toast } from 'react-toastify';
import { ARCHIVE_PROJ_SUCC_MSG } from '../helpers/messages';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAlphaDown, faSortAlphaUp, faFileExcel } from '@fortawesome/fontawesome-free-solid';
import { faWindowMinimize } from '@fortawesome/fontawesome-free-regular';
import { CSVLink } from "react-csv";
import uniqid from 'uniqid';
import { projectStatus } from '../helpers/status';

type OwnProps = {
    lookUpData: LookUpData[],
    projects: Project[],
    projectsFilter: number,
    matchedPatients: Patient[],
    isMatchedPatientsLoading: boolean,
    isProjectLoading: boolean,
    project: Project
    emailTemplates: EmailTemplateData[];
    singleEmailTemplate: EmailTemplateData;
    emailTemplateLoading: boolean;
};

type DispatchProps = {
    setProjects: (condition: number) => void;
    setProjectsFilter: (filter: number) => void;
    setMatchedPatients: (searchData: PatientSearchData, projectName: string) => void;
    setProjectById: (id: string) => void;
    removePatient: (inputModel: ProjectPatientData) => void;
    addPatient: (inputModel: ProjectPatientData, searchData: PatientSearchData, projectName: string) => void;
    changeProjectStatus: (id: string, filter: number) => void;
    submitRequest: (data: RequestData) => void;
    changePatientStatus: (inputModel: ProjectPatientData) => void;
    getAllEmailTemplates: () => void;
    getEmailTemplateByName: (name: string) => void;
};

type Props = OwnProps & DispatchProps;

const OUTLINE_PADDING_STYLE = {
    paddingLeft: '1.2rem',
    paddingRight: '1.2rem'
};
const PRIMARY_PADDING_STYLE = {
    paddingLeft: '1.7rem',
    paddingRight: '1.7rem'
}
export default function EupatiAllProjectsGrid(props: Props) {

    const [isDetailsBtnClicked, setIsDetailsBtnClicked] = useState<boolean>(false);
    const [clickedProjectId, setClickedProjectId] = useState<string>('');

    const detailsBtnHandler = (id: any) => {
        setClickedProjectId(id);
        props.setProjectById(id);
        setIsDetailsBtnClicked(true);
    }

    const changeProjectStatusHandler = (id: any, filter: number) => {
        toast.success(ARCHIVE_PROJ_SUCC_MSG);
        props.changeProjectStatus(id, filter);
    }

    const backBtnHandler = () => {
        setClickedProjectId('');
        setIsDetailsBtnClicked(false);
    }

    const returnValueById = (propName: string, projectPropId: number) => {
        let propType = props.lookUpData.find((x: any) => x.name == propName); // projectType
        let propValue = propType?.data.find((x: any) => x.id == projectPropId);
        return propValue?.name;
    }

    function findLookUpData(lookUpDataType?: string) {
        if (props.lookUpData &&
            props.lookUpData.length > 0) {
            var data = props.lookUpData?.filter((x: any) => x.name == lookUpDataType)[0]?.data;
            if (lookUpDataType == "projectStatus" && data.findIndex(d => d.id == 0) == -1)//add "All Statuses option in the filter dropdown"
            {
                data.unshift({
                    id: 0, name: "Show All"
                } as LookUpOptions)
            }

            return data;
        }

        return [];
    }

    const projectStatusHandler = (statusId: number) => {
        props.setProjectsFilter(statusId);
        props.setProjects(statusId);
    }

    function convertDate(date: any) {
        if (typeof date === 'undefined') { return }
        function pad(s: any) { return (s < 10) ? '0' + s : s; }                                 //  9 --> 09  ||  10 --> 10
        return [pad(date.getDate()), pad(date.getMonth() + 1), date.getFullYear()].join('/')    //  format date --> 09/12/2021  
    }

    function setValue(val: any) {
        if (isNaN(val)) {
            projectStatusHandler(ProjectStatus.inProgressStatusID);
            return ProjectStatus.inProgressStatusID;
        }

        return val;
    }

    function returnProperIconType(icon: any) {
        return icon as IconProp;
    }

    let projectsDetailsComp: any;
    let loadComp = (
        <div className='loader-wrapper'>
            <EupatiLoader />
        </div>
    )

    if (clickedProjectId === '') {
        projectsDetailsComp = loadComp;
    }
    else {
        projectsDetailsComp = (
            <EupatiProjectDetails
                {...{
                    projectId: clickedProjectId,
                    backToAllProjects: backBtnHandler,
                    lookUpData: props.lookUpData,
                    emailTemplates: props.emailTemplates,
                    singleEmailTemplate: props.singleEmailTemplate,
                    emailTemplateLoading: props.emailTemplateLoading,
                    setMatchedPatients: props.setMatchedPatients,
                    matchedPatients: props.matchedPatients,
                    isMatchedPatientsLoading: props.isMatchedPatientsLoading,
                    setProjectById: props.setProjectById,
                    isProjectLoading: props.isProjectLoading,
                    project: props.project,
                    removePatient: props.removePatient,
                    addPatient: props.addPatient,
                    submitRequest: props.submitRequest,
                    changePatientStatus: props.changePatientStatus,
                    getAllEmailTemplates: props.getAllEmailTemplates,
                    getEmailTemplateByName: props.getEmailTemplateByName
                }}
            />
        )
    }

    const headers = [
        { label: "Name", key: "name" },
        { label: "Type", key: "type" },
        { label: "Start date", key: "date" },
        { label: "Disease(s) experience", key: "diseases" },
        { label: "Chosen plan", key: "plan" },
        { label: "Organisation", key: "org" }
    ];

    const data = useMemo(
        () => props.projects?.map((x: any, i: any) => {
            let statuses = findLookUpData('projectStatus');

            return {
                id: x.id,
                name: x.name,
                type: returnValueById('projectType', x.projectTypeId),
                date: x.createdOn,
                diseases: x.noDiseaseExpertiseRequired ? "No disease experience required" : x.diseases?.map((d: any) => d.name).join(", "),
                plan: x.plan,
                org: x.pocOrganisation,
                status: x.projectStatusId,
                statusText: statuses.find(s => s.id == x.projectStatusId)?.name
            }
        }), [props.projects]
    )

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Type',
                accessor: 'type'
            },
            {
                Header: 'Date',
                accessor: 'date',
                Cell: (props: any) => {
                    if (props.value != undefined && props.value != null) {
                        return props.value.toString() == "0001-01-01T00:00:00" ? "-" : convertDate(new Date(props.value));
                    } else {
                        return '';
                    }
                }
            },
            {
                Header: 'Diseases',
                accessor: 'diseases'
            },
            {
                Header: 'Plan',
                accessor: 'plan'
            },
            {
                Header: 'Organisation',
                accessor: 'org'
            },
            {
                Header: 'Status',
                accessor: 'statusText'
            }
        ], []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups, // columns Header
        rows, // render data based on accessor
        // @ts-ignore
        page,
        // @ts-ignore
        nextPage,
        // @ts-ignore
        previousPage,
        // @ts-ignore
        canNextPage,
        // @ts-ignore
        canPreviousPage,
        prepareRow,
        // @ts-ignore
        visibleColumns,
        // @ts-ignore
        pageOptions,
        // @ts-ignore
        gotoPage,
        // @ts-ignore
        pageCount,
        // @ts-ignore
        setPageSize,
        // @ts-ignore
        state,
        // @ts-ignore
        setGlobalFilter,
    } = useTable({
        // @ts-ignore
        columns,
        data
    },
        useGlobalFilter,
        useSortBy,
        usePagination,
    )
    // @ts-ignore
    const { globalFilter, pageSize, pageIndex } = state;

    let mainComponent = (
        <>
            <Table {...getTableProps()} striped bordered hover>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(col => (
                                //@ts-ignore
                                <th {...col.getHeaderProps(col.getSortByToggleProps())}>
                                    {col.render('Header')}
                                    <span>
                                        {/* @ts-ignore */
                                            col.isSorted ? (col.isSortedDesc ? <FontAwesomeIcon icon={returnProperIconType(faSortAlphaDown)} /> : <FontAwesomeIcon icon={returnProperIconType(faSortAlphaUp)} />) : <FontAwesomeIcon id='line' icon={returnProperIconType(faWindowMinimize)} />
                                        }
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    <tr>
                        <th
                            colSpan={visibleColumns.length}
                            style={{ textAlign: 'left' }}
                        >
                            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        </th>
                    </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row: any) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell: any) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                                <>
                                    <td role='cell'>
                                        <Button
                                            variant="info"
                                            onClick={() => detailsBtnHandler(row.original.id)}
                                        >
                                            DETAILS
                                        </Button>
                                    </td>
                                    {row.original.status === projectStatus.archivedStatusID ? (
                                        <td role='cell'>
                                            <Button
                                                variant='secondary'
                                                onClick={() => changeProjectStatusHandler(row.original.id, projectStatus.inProgressStatusID)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                REVIVE
                                            </Button>
                                        </td>
                                    ) : (
                                        <td role='cell'>
                                            <Button
                                                variant='danger'
                                                onClick={() => changeProjectStatusHandler(row.original.id, projectStatus.archivedStatusID)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                ARCHIVE
                                            </Button>
                                        </td>
                                    )}
                                </>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <Form.Group style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </span>
                <span>
                    Go to page: {' '}
                    <input
                        type='number'
                        defaultValue={pageIndex + 1}
                        min='1'
                        disabled={pageSize >= rows.length}
                        onChange={(e) => {
                            e.preventDefault();
                            const pageNum = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(pageNum);
                        }}
                    />
                </span>
                <select value={pageSize} onChange={e => setPageSize(e.target.value)}>
                    {
                        [10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))
                    }
                </select>
                <Button id='backToFirstPageBtn' onClick={() => gotoPage(0)} disabled={!canPreviousPage} style={canPreviousPage ? { cursor: 'pointer', ...OUTLINE_PADDING_STYLE } : { cursor: 'not-allowed', ...OUTLINE_PADDING_STYLE }} className='btn btn-outline-dark btn-sm' >{'<<'}</Button>
                <Button onClick={() => previousPage()} disabled={!canPreviousPage} style={canPreviousPage ? { cursor: 'pointer' } : { cursor: 'not-allowed' }} variant='secondary' >Previous</Button>
                <Button onClick={() => nextPage()} disabled={!canNextPage} style={canNextPage ? { cursor: 'pointer', ...PRIMARY_PADDING_STYLE } : { cursor: 'not-allowed', ...PRIMARY_PADDING_STYLE }} variant='primary'>Next</Button>
                <Button id='gotoLastPageBtn' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} style={canNextPage ? { cursor: 'pointer', ...OUTLINE_PADDING_STYLE } : { cursor: 'not-allowed', ...OUTLINE_PADDING_STYLE }} className='btn btn-outline-dark btn-sm' >{'>>'}</Button>
            </Form.Group>
        </>
    );

    return (
        <>
            {isDetailsBtnClicked &&
                <>
                    {projectsDetailsComp}
                </>
            }
            {!isDetailsBtnClicked &&
                <>
                    <h4 style={{ textAlign: 'center', textDecoration: 'underline' }}>PROJECTS</h4>
                    <div className='table-btns'>
                        <EupatiDropdown {...{
                            fieldName: 'projectStatusId',
                            errorMsg: '',
                            values: findLookUpData('projectStatus'),
                            onChange: (e: any) => projectStatusHandler(parseInt(e.target.value)),
                            value: setValue(parseInt(props.projectsFilter.toString())),
                            width: 'auto'
                        }} />
                        <span><strong>{props.projects?.length}</strong> projects.</span>
                        <CSVLink
                            data={data}
                            headers={headers}
                            id='csv-download-btn'
                            filename={`${uniqid('projects-table-')}.csv`}
                            className="btn btn-success"
                        >
                            DOWNLOAD <FontAwesomeIcon icon={returnProperIconType(faFileExcel)} />
                        </CSVLink>
                    </div>
                    {mainComponent}
                </>
            }
        </>
    );
}