import React, { useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router';
import AdminRoute from './components/common/static/routes/admin-routes';
import ProtectedRoute from './components/common/static/routes/protected-route';
import Layout from './components/common/static/layout';
import { Root as NewProjectPage } from './components/modules/new-project';
import { Root as PatientInfoPage } from './components/modules/patient-profile';
import { Root as HomePage } from './components/modules/home';
import { Root as PricingPage } from './components/modules/pricing';
import { Root as AdminProjectsPage } from './components/modules/admin/all-projects';
import { Root as AdminImportPatients } from './components/modules/admin/import-patients';
import { Root as AdminPatientsPage } from './components/modules/admin/all-patients';
import { Root as AdminLsPage } from './components/modules/admin/admins-ls';
import { Root as ContentEditor } from './components/modules/admin/content-editor';
import { Root as PriceCatalog } from './components/modules/admin/price-catalog';
import { Root as ContentManagement } from './components/modules/admin/content-management';
import { Root as EmailTemplates } from './components/modules/admin/email-templates';
import { Root as EmailResponsePage } from './components/modules/confirm';
import { Root as PaymentPage } from './components/modules/payment';
//import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import './App.scss';
import { AuthenticationProvider } from './components/common/static/auth-context';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

export default function App() {
    const history = useHistory();

    useEffect(() => {
        const handler = (e: any) => {
            if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                e.stopImmediatePropagation();
            }
        };
        document.addEventListener("focusin", handler);
        return () => document.removeEventListener("focusin", handler);
    }, []);

    // const getPublishableKey = async () => {
    //     const { publishableKey } = await fetch('/api/payments/getstripeconfig').then(res => res.json());
    //     const stripePromise = loadStripe(publishableKey);
    //     return stripePromise;
    // }

    return (
        //<Elements stripe={getPublishableKey()}>
        <AuthenticationProvider>
            <ToastContainer autoClose={4000} transition={Slide} position={'top-center'} style={{ width: '33%' }} hideProgressBar={true} />
            <CookieConsent
                location="bottom"
                style={{ background: "#113D7C" }}
                buttonStyle={{ color: "#secondaryColor", fontSize: "13px" }}
                buttonClasses='cookieConsentBtn'
                expires={150}
            >
                This website uses cookies to enhance the user experience.{" "}
                <span style={{ fontSize: "13px" }}>
                    Please refer to our <a href='/privacy'>Privacy policy</a> page for more details.
                </span>
            </CookieConsent>
            <Layout>
                <Route exact path='/' component={HomePage} />
                <Route exact path='/pricing' component={PricingPage} />
                <Route exact path='/new-project' component={NewProjectPage} />
                {/* <Route exact path='/payment' component={PaymentPage} /> */}
                <Route exact path='/privacy' component={ContentManagement} />
                <Route exact path='/faq' component={ContentManagement} />
                <Route exact path='/terms' component={ContentManagement} />
                <Route path='/letter-of-confirmation' component={EmailResponsePage} />
                <Route path='/letter-of-rejection' component={EmailResponsePage} />
                <ProtectedRoute exact path='/patient' component={PatientInfoPage} />
                <AdminRoute exact path='/admin/projects' component={AdminProjectsPage} />
                <AdminRoute exact path='/admin/import-patients' component={AdminImportPatients} />
                <AdminRoute exact path='/admin/all-patients' component={AdminPatientsPage} />
                <AdminRoute exact path='/admin/all-admins' component={AdminLsPage} />
                <AdminRoute exact path='/admin/content-editor' history={history} component={ContentEditor} />
                <AdminRoute exact path='/admin/prices' component={PriceCatalog} />
                <AdminRoute exact path='/admin/email-templates' component={EmailTemplates} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        </AuthenticationProvider>
        //</Elements>
    )
};
