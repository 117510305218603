import { NAME } from "./constants";

export const SET_PROJECTS_LOADING = `${NAME}/SET_PROJECTS_LOADING`;

export const SET_PROJECT = `${NAME}/SET_PROJECT`;

export const SET_LOOKUP_DATA = `${NAME}/SET_LOOKUP_DATA`;

export const SUBMIT_NEW_PROJECT = `${NAME}/SUBMIT_NEW_PROJECT`;

export const SET_IS_USER_REAL = `${NAME}/SET_IS_USER_REAL`;