export const FAIL_AUTH_MSG = 'We cannot validate, that you are not a robot. Please, refresh the page and try again.';

export const ADMIN_ACCESS_DENIED_MSG = 'You are not a patient.';

export const SUCC_UPDATED_CATALOGS = 'You have successfully updated the catalogues prices.';

export const INVALID_PRICING_DATA = 'You have to select Pricing option, even if a free service applies to you.';

export const INVALID_PAYMENT_METHOD = 'You have to select Payment option, even if a free service applies to you.';

export const EMAILS_COPIED_MSG = 'Emails were copied to the clipboard.';

export const SELECTED_ALL_MSG = 'You have successfully selected all emails.'

export const REMOVE_ADMIN_SUCC_MSG = 'You have successfully removed the admin.';

export const APPROVE_ADMIN_SUCC_MSG = 'You have successfully approved the admin.'

export const REMOVE_PT_SUCC_MSG = 'You have successfully removed the patient.';

export const ADD_PT_TO_PROJ_MSG = 'You have successfully assigned patient to project.';

export const CONFIRM_PATIENT_MSG = 'You have successfully changed patient status to confirmed.';

export const DEL_PT_FROM_PROJ_MSG = 'You have successfully removed patient from project.';

export const ARCHIVE_PROJ_SUCC_MSG = 'You have changed the status of the project.';

export const SAVED_NEW_PRICES_MSG = 'You have successfully updated the prices.';

export const SEND_EMAILS_SUCC_MSG = 'You have successfully sent an email.';

export const INVALID_NUMERIC_INPUT = 'Price field expect a numeric value.';

export const CANCEL_PRICES_UPDATE = 'You have cancelled the prices update.';

export const EMAIL_TEMPLATE_CREATED = 'You have successfully created a new template.';

export const EMAIL_TEMPLATE_UPDATED = 'You have successfully updated the template.';

export const FILL_ALL_FIELD_ERR_MSG = 'Please fill in all the fields.';

export const ANSWER_SUBMIT_SUCC = 'You have successfully submitted your application.';

export const SUCC_ASSIGN_ACTIONS_TO_TEMPLATE = 'You have successfully assigned actions to email template.'