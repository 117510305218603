import * as React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import EupatiLoader from '../../../common/static/loading-animation/loader';
import { State } from '../model';
import '../style.scss';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import BasicPlanComponent from './basic-plan';
import TailorMadeComponent from './tailor-made-plan';
import CustomPlanComponent from './custom-plan';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CATALOG_TYPES, CATEGORY_NAMES, CONVERTED_NAMES, OPTION_NAMES } from '../../../common/helpers/pricing-list';

type OwnProps = {};

export type DispatchProps = {
    setIsUserReal: (token: string) => void;
    setCatalogsPrices: () => void;
};

type Props = State & DispatchProps & OwnProps;

type OwnState = {
    paymentMethods: string[]
};

//const EMPTY_SUBMIT_WARNING = 'You have to select a Pricing from the menu.';
//const PAYMENT_METHOD_WARNING = 'Please select a Payment method';
export default class ComponentRoot extends React.Component<Props, OwnState> {
    constructor(props: any) {
        super(props);

        this.returnIcon = this.returnIcon.bind(this);
        this.convertOptionsNames = this.convertOptionsNames.bind(this);
        this.convertCategoriesNames = this.convertCategoriesNames.bind(this);
        this.returnCategoriesArrayByCatalogType = this.returnCategoriesArrayByCatalogType.bind(this);

        this.state = {
            //paymentMethods: ['Pay via Invoice', 'Pay by Credit Card']//'invoice' and 'card' are keywords
            paymentMethods: ['Pay via Invoice']
        }
    }

    componentDidMount() {
        this.props.setCatalogsPrices();
    }

    convertCategoriesNames(name: string) {
        switch (name) {
            case CATEGORY_NAMES.Researcher:
                return `${CONVERTED_NAMES.ExternalResearcher}`
            case CATEGORY_NAMES.Industry:
                return `${CONVERTED_NAMES.IndustryPartner}`
            case CATEGORY_NAMES.Academia:
                return `${CONVERTED_NAMES.AcademiaPartner}`
            default:
                return ''
        }
    }

    convertOptionsNames(name: string) {
        switch (name) {
            case OPTION_NAMES.Network:
                return `${CONVERTED_NAMES.NetworkOption}`
            case OPTION_NAMES.Mentorship:
                return `${CONVERTED_NAMES.MentorshipOption}`
            default:
                return ''
        }
    }

    returnIcon(icon: any) {
        return icon as IconProp;
    }

    returnCategoriesArrayByCatalogType(catalogType: string) {
        const priceCatalog = this.props.catalogData.find(x => x.type.toLowerCase() === catalogType);
        if (priceCatalog) {
            return priceCatalog.categoryOptionsData;
        }

        return [];
    }

    render() {

        let mainComponent: any;

        let loadingComponent = (
            <div className='loader-wrapper'>
                <EupatiLoader />
            </div>
        );

        if (this.props.isLoading) {
            mainComponent = loadingComponent;
        }
        else {
            const tabs = (
                <div className="tabs-nav-wrapper">
                    <Nav variant="tabs" className="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey='basic'>
                                Basic
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey='tailor'>
                                Tailor-Made
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey='custom'>
                                Custom
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            );

            const tabContents = (
                <>
                    <Tab.Pane eventKey='basic'>
                        <BasicPlanComponent
                            {...{
                                returnIcon: this.returnIcon,
                                paymentMethods: this.state.paymentMethods,
                                basicPlanCategories: this.returnCategoriesArrayByCatalogType(CATALOG_TYPES.Basic.toLowerCase()),
                                convertCategoriesNames: this.convertCategoriesNames
                            }}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey='tailor'>
                        <TailorMadeComponent
                            {...{
                                returnIcon: this.returnIcon,
                                paymentMethods: this.state.paymentMethods,
                                convertOptionsNames: this.convertOptionsNames,
                                convertCategoriesNames: this.convertCategoriesNames,
                                tailorMadePlanCategories: this.returnCategoriesArrayByCatalogType(CATALOG_TYPES.TailorMade.toLowerCase())
                            }}
                        />
                    </Tab.Pane>

                    <Tab.Pane eventKey='custom'>
                        <CustomPlanComponent />
                    </Tab.Pane>
                </>
            );

            mainComponent = (
                <>
                    <GoogleReCaptcha action='homePage' onVerify={token => this.props.setIsUserReal(token)} />

                    <section className='pricing-upper-sec'>
                        <div className='pricing-upper-sec-txt'>
                            <h5 className='pricing-upper-sec-txt-title mb-4'>Connect Now</h5>
                            <h5 className='pricing-upper-sec-txt-sub-title'>Would you like to engage a EUPATI Patient Expert in your patient engagement opportunity?</h5>
                            <p className='pricing-upper-sec-txt-para'>
                                We have created 3 different packages to suit your needs.
                                Select the right one for you and we can begin finding the right patient for your project today.
                            </p>
                        </div>
                    </section>

                    <section className='pricing-lower-sec'>
                        <div id='pricing-tabs'>
                            <Tab.Container
                                id="tabs"
                                defaultActiveKey='basic'
                            >
                                {tabs}
                                <Tab.Content>{tabContents}</Tab.Content>
                            </Tab.Container>
                        </div>
                    </section>
                </>
            );
        }

        return (
            <>
                {mainComponent}
            </>
        );
    }
}