import { ContentData, ContentResponse } from '../../../common/models/input-models';
import DataSvc from '../../../common/services/data-svc';
import * as t from './actionTypes';

export const setContentLoading = (isContentLoading: boolean) => ({
    type: t.SET_CONTENT_LOADING,
    payload: isContentLoading
});

export const setContentResponse = (contentResponse: ContentResponse) => ({
    type: t.SET_CONTENT_RESPONSE,
    payload: { ...contentResponse }
});

export const getContentByNameAsync = (name: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setContentLoading(true));
        DataSvc.utilsSvc.getContent(name).then(
            (result: any) => {
                dispatch(setContentResponse(result));
                dispatch(setContentLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}

export const updateContentAsync = (contentData: ContentData) => {
    return (dispatch: any, getState: any) => {
        try {
            DataSvc.utilsSvc.updateContent(contentData);
        }
        catch (error) {
            console.error(error);
        }
    }
}