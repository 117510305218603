//R.S docs: https://www.npmjs.com/package/react-select
import * as React from 'react';
import { Form } from 'react-bootstrap';
import Async from 'react-select/async';
import { LookUpOptions } from '../models/input-models';
import { DiseaseInfo } from '../models/main-models';
import DataSvc from '../services/data-svc';
import './static.scss';

type OwnProps = {
    value: any[];
    errorMsg: string;
    placeholder?: string;
};

export type DispatchProps = {
    onChange: (e: any) => void;
};

type OwnState = { applyFiltersTimeoutId: any; };

type Props = DispatchProps & OwnProps;

export default class EupatiDiseaseAsyncSelect extends React.Component<Props, OwnState> {
    constructor(props: any) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.mapOptionsToValues = this.mapOptionsToValues.bind(this);
        this.returnComponentStyles = this.returnComponentStyles.bind(this);

        this.state = {
            applyFiltersTimeoutId: undefined
        };
    }

    handleOnChange(event: any) {
        this.props?.onChange(Object.values(event).map((key: any) => ({ name: key.label, treeNumbers: key.value } as DiseaseInfo)));
    }

    mapOptionsToValues = (options: any) => {
        return options?.map((option: any) => ({
            value: option.id,
            label: option.name
        }));
    }

    mapDiseaseOptionsToValues = (options: any) => {
        return options?.map((option: any) => ({
            value: option.treeNumbers,
            label: option.name
        }));
    }

    returnComponentStyles = () => {

        const regularStyles = {
            multiValueLabel: (base: any) => ({ ...base, backgroundColor: '#DAEEF2', color: '#000', padding: '4px 8px', paddingLeft: '8px' }),
            multiValueRemove: (base: any) => ({ ...base, backgroundColor: '#DAEEF2', color: '#808080', cursor: 'pointer', "&:hover": { backgroundColor: '#00A4B6', color: '#fff' } }),
            dropdownIndicator: (base: any) => ({ ...base, cursor: 'pointer' }),
            indicatorsContainer: (base: any) => ({ ...base, cursor: 'pointer', backgroundColor: '#DAEEF2', color: '#808080', "&:hover": { backgroundColor: '#00A4B6', color: '#fff' } }),
            menu: (base: any) => ({ ...base, position: 'relative', backgroundColor: '#fff' }),
            menuList: (base: any) => ({ ...base, position: 'relative', backgroundColor: '#fff' })
        }


        return regularStyles;
    }

    loadAutocompleteOptions = async (inputValue: string) => {
        return new Promise<LookUpOptions[]>((resolve) => {
            if (inputValue && inputValue.length > 2) {
                if (this.state.applyFiltersTimeoutId) {
                    clearTimeout(this.state.applyFiltersTimeoutId);
                }

                var that = this;
                this.setState({
                    applyFiltersTimeoutId: setTimeout(function () {
                        that.setState({ applyFiltersTimeoutId: undefined });
                        DataSvc.utilsSvc.getMeshRecommendations(inputValue).then((result: any) => {
                            const diseaseOptions = result.map((m: any, i: any) => {
                                return { id: m.treeNumbers.join(";"), name: m.originalTerm } as LookUpOptions;
                            });

                            resolve(that.mapOptionsToValues(diseaseOptions));
                        },
                            (error) => { console.error(error); })
                    }, 800),
                });
            } else {
                resolve([]);
            }
        });
    }

    render() {
        return (
            <>
                <Form.Group>
                    <Async
                        isMulti={true}
                        placeholder={this.props.placeholder ? this.props.placeholder : 'Enter a disease to get suggestions'}
                        defaultValue={this.mapDiseaseOptionsToValues(this.props.value)}
                        onChange={e => this.handleOnChange(e)}
                        className='select-dropdown'
                        styles={this.returnComponentStyles()}
                        loadOptions={this.loadAutocompleteOptions}
                    />
                    {!!this.props.errorMsg &&
                        <span className='text-danger'>{this.props.errorMsg}</span>
                    }
                </Form.Group>
            </>
        );
    }
}
