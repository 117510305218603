import { State } from './model';
import * as t from './actionType'
import { CatalogData } from '../../common/models/input-models';

const initialState: State = {
    isLoading: false,
    isLoggedIn: false,
    isUserReal: true,
    catalogData: []
};

export default (state = initialState, action: any): State => {
    switch (action.type) {
        case t.SET_PRICING_LOADING:
            return setPricingLoading(state, action.payload);
        case t.SET_PRICING_LOGGED_IN:
            return setPricingLoggedInStatus(state, action.payload);
        case t.SET_IS_USER_REAL:
            return setIsUserReal(state, action.payload);
        case t.SET_CATALOGS_DATA:
            return setCatalogsData(state, action.payload);
        default:
            return state;
    }
};

const setPricingLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isLoading: val
    } as State;

    return newState;
}

const setPricingLoggedInStatus = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isLoggedIn: val
    } as State;

    return newState;
}

const setIsUserReal = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isUserReal: val
    } as State;

    return newState;
}

const setCatalogsData = (state: State, val: CatalogData[]): State => {
    let newState = {
        ...state,
        catalogData: [...val]
    } as State;

    return newState;
}