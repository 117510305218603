import * as React from 'react';
import { Form } from 'react-bootstrap';

type OwnProps = {
    value: string;
    errorMsg: string;
    rowsCount?: number;
    placeholderMsg?: string;
    isDisabled?: boolean;
};

export type DispatchProps = {
    onChange: (e: any) => void;
};

type OwnState = {};

type Props = DispatchProps & OwnProps;

//const DEFAULT_ELEMENT_HEIGHT = 41;  // rowsCount: 1
export default class EupatiTextarea extends React.Component<Props, OwnState> {
    element: any;

    constructor(props: any) {
        super(props);
        this.element = React.createRef();
    }

    componentDidMount() {
        const height = this.element.current.scrollHeight;
        this.element.current.style.height = `${height}px`;
    }

    // handleKeyDown(e: any) {
    //     e.target.style.height = 'inherit';
    //     e.target.style.height = `${e.target.scrollHeight}px`;
    //     if (e.target.scrollHeight === DEFAULT_ELEMENT_HEIGHT) {
    //         e.target.style.height = 'inherit';
    //     }
    // }

    render() {
        return (
            <>
                <Form.Group>
                    <Form.Control
                        as='textarea'
                        value={this.props.value}
                        className="eupati-textbox"
                        rows={this.props.rowsCount ? this.props.rowsCount : 3}
                        placeholder={this.props.placeholderMsg ? this.props.placeholderMsg : 'Please type in your information...'}
                        disabled={this.props.isDisabled === undefined ? false : this.props.isDisabled}
                        onChange={(e) => this.props.onChange(e)}
                        //onKeyDown={this.handleKeyDown.bind(this)}
                        ref={this.element}
                    />
                    {!!this.props.errorMsg &&
                        <span className='text-danger'>{this.props.errorMsg}</span>
                    }
                </Form.Group>
            </>
        );
    }
}
