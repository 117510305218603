import * as React from 'react';
import { Form } from 'react-bootstrap';
import { EmailTemplateData, LookUpData, PatientSearchData, ProjectPatientData, RequestData } from '../../../../common/models/input-models';
import { Patient, Project } from '../../../../common/models/main-models';
import EupatiLoader from '../../../../common/static/loading-animation/loader';
import EupatiAllProjectsGrid from '../../../../common/static/projects-grid';
import { State } from '../model';
import '../style.scss';

type OwnProps = {
    isLoading: boolean;
    lookUpData: LookUpData[];
    isProjectsLoading: boolean;
    projects: Project[];
    filter: number;
    matchedPatients: Patient[];
    isMatchedPatientsLoading: boolean;
    project: Project;
    isProjectLoading: boolean;
    emailTemplates: EmailTemplateData[];
    singleEmailTemplate: EmailTemplateData;
    emailTemplateLoading: boolean;
};

export type DispatchProps = {
    setLookUpData: () => void;
    setProjects: (condition: number) => void;
    setFilter: (filter: number) => void;
    setMatchedPatients: (searchData: PatientSearchData, projectName: string) => void;
    setProjectById: (id: string) => void;
    removePatient: (inputModel: ProjectPatientData) => void;
    addPatient: (inputModel: ProjectPatientData, searchData: PatientSearchData, projectName: string) => void;
    changeProjectStatus: (id: string, filter: number) => void;
    submitRequest: (data: RequestData) => void;
    changePatientStatus: (inputModel: ProjectPatientData) => void;
    getAllEmailTemplates: () => void;
    getEmailTemplateByName: (name: string) => void;
};

type Props = State & DispatchProps & OwnProps;

type OwnState = {};

//TODO: pass func to grid so it doesnt call services directly
export default class ComponentRoot extends React.Component<Props, OwnState> {

    componentDidMount() {
        this.props.setLookUpData();
        this.props.getAllEmailTemplates();
        this.props.setProjects(this.props.filter);
    }

    render() {

        let mainComponent: any;

        let loadingComponent = (
            <div className='loader-wrapper'>
                <EupatiLoader />
            </div>
        )

        if (this.props.isLoading ||
            this.props.isProjectsLoading) {
            mainComponent = loadingComponent;
        }
        else {
            mainComponent = (
                <Form.Group id='all-projects-tbl' className='all-projects-wrapper mb-5'>
                    <EupatiAllProjectsGrid
                        {...{
                            lookUpData: this.props.lookUpData,
                            projects: this.props.projects,
                            setProjects: this.props.setProjects,
                            setProjectsFilter: this.props.setFilter,
                            projectsFilter: this.props.filter,
                            emailTemplates: this.props.emailTemplates,
                            singleEmailTemplate: this.props.singleEmailTemplate,
                            emailTemplateLoading: this.props.emailTemplateLoading,
                            setMatchedPatients: this.props.setMatchedPatients,
                            matchedPatients: this.props.matchedPatients,
                            isMatchedPatientsLoading: this.props.isMatchedPatientsLoading,
                            setProjectById: this.props.setProjectById,
                            isProjectLoading: this.props.isProjectLoading,
                            project: this.props.project,
                            removePatient: this.props.removePatient,
                            addPatient: this.props.addPatient,
                            changeProjectStatus: this.props.changeProjectStatus,
                            submitRequest: this.props.submitRequest,
                            changePatientStatus: this.props.changePatientStatus,
                            getAllEmailTemplates: this.props.getAllEmailTemplates,
                            getEmailTemplateByName: this.props.getEmailTemplateByName
                        }}
                    />
                </Form.Group>
            )
        }

        return (
            <>
                {mainComponent}
            </>
        );
    }
}