import React from 'react';
import { Button, Form } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import './static.scss';

type OwnProps = {
    target: string;
    params: string;
    targetId: string;
};

type DispatchProps = {
    handleRemove: (id: string, e: any) => void;
};

type Props = OwnProps & DispatchProps;

export default function ConfirmDenyModal(props: Props) {
    return (
        <Popup
            className='modal'
            trigger={
                <Button variant='danger' className='modal-rm'>REMOVE</Button>
            }
            modal
        >
            {(close: any) => (
                <Form.Group>
                    <button className='modal-close' onClick={close}>
                        &times;
                    </button>
                    <Form.Label className='modal-head'>Do you want to permanently delete this?</Form.Label>
                    <Form.Group className='modal-core'>
                        {' '}
                        {`The following action will remove ${props.target.toLocaleUpperCase()} with ${props.params.toLocaleUpperCase()}`}
                    </Form.Group>
                    <Form.Group className='modal-actions'>
                        <Button variant='success' onClick={(e: any) => props.handleRemove(props.targetId, e)} >CONFRIM</Button>
                        <Button variant='secondary' onClick={close} >CANCEL</Button>
                    </Form.Group>
                </Form.Group>
            )
            }
        </Popup >
    )
}