// matching/actions.js

import DataSvc from '../../common/services/data-svc';
import * as t from './actionType';
import { Res } from '../../common/helpers';

export const setHomeLoading = (isLoading: boolean) => ({
    type: t.SET_HOME_LOADING,
    payload: isLoading,
});

export const setHomeLoggedInStatus = (isLoggedIn: boolean) => ({
    type: t.SET_HOME_LOGGED_IN,
    payload: isLoggedIn
});

export const setIsUserReal = (isUserReal: boolean) => ({
    type: t.SET_IS_USER_REAL,
    payload: isUserReal
});

export const verifyUserTokenAsync = (token: string) => {
    return (dispatch: any, getState: any) => {
        DataSvc.utilsSvc.verifyUserByRecaptchaToken({ recaptchaToken: token }).then(
            (result: any) => {
                if (result !== Res.success) {
                    dispatch(setIsUserReal(false));
                }
            },
            (error) => { console.error(error); }
        );
    }
}

export const setUserStatusAsync = (isUserAdmin: boolean) => {
    return (dispatch: any, getState: any) => {
        dispatch(setHomeLoading(true));
        if (isUserAdmin !== undefined) {
            dispatch(setHomeLoggedInStatus(true));
        }
        else {
            dispatch(setHomeLoggedInStatus(false));
        }
        //isUserAdmin !== undefined ? dispatch(setHomeLoggedInStatus(true)) : dispatch(setHomeLoggedInStatus(false));
        dispatch(setHomeLoading(false));
    }
}