import React, { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import AuthenticationContext from "../auth-context";

export default function ProtectedRoute({ component: Component, ...restOfProps }) {

  const contextData = useContext(AuthenticationContext);

  useEffect(() => {
    if (contextData.isUserAdmin === null || contextData.isUserAdmin === undefined) {
      contextData.authenticateUser();
    }
  });

  function isUserLoggedIn() {
    if (contextData.isUserAdmin === null || contextData.isUserAdmin === undefined) {
      return false;
    }

    return true;
  }

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isUserLoggedIn() ? <Component {...props} {...restOfProps} /> : <Redirect to="/" />
      }
    />
  );
}