import React from 'react';
import { Form } from 'react-bootstrap';
import EupatiAllAdminsGrid from '../../../../common/static/admins-grid';
import EupatiLoader from '../../../../common/static/loading-animation/loader';
import { State } from '../model';
import '../style.scss';

type OwnProps = {};

export type DispatchProps = {
  setAdminsData: () => void;
  removeAdminData: (id: string, ownerId: string) => void;
  approveAdmin: (id: string, ownerId: string) => void;
};

type Props = State & DispatchProps & OwnProps;

type OwnState = {};

const HtmlToReactParser = require('html-to-react').Parser;
export default class ComponentRoot extends React.Component<Props, OwnState> {
  componentDidMount() {
    this.props.setAdminsData();
  }

  render() {
    let mainComp: any;

    let loadComp = (
      <div className="loader-wrapper">
        <EupatiLoader />
      </div>
    );

    if (this.props.isAdminsLoading) {
      mainComp = loadComp;
    } else {
      mainComp = (
        <Form.Group id="all-admins-tbl" className="all-admins-wrapper mb-5">
          <EupatiAllAdminsGrid
            {...{
              admins: this.props?.admins,
              removeAdmin: this.props?.removeAdminData,
              approveAdmin: this.props?.approveAdmin,
            }}
          />
        </Form.Group>
      );
    }

    return <>{mainComp}</>;
  }
}
