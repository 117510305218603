import React, { useState, FC, useContext } from 'react';
import authService from '../../api-authorization/AuthorizeService';
import { Roles } from '../helpers';

interface IAuthenticationContext {
    isUserAdmin: boolean;
    userIdentity: string;
    authenticateUser?: () => void;
}

const defaultState = {
    isUserAdmin: false,
    userIdentity: ''
};

const AuthenticationContext = React.createContext<IAuthenticationContext>(defaultState);

export default AuthenticationContext;

export const AuthenticationProvider: FC = ({ children }) => {
    const [isUserAdmin, setIsUserAdmin] = useState<any>(undefined);
    const [userIdentity, setUserIdentity] = useState<string>('');

    const authenticateUser = () => {
        //  res.sub ==> current user id
        authService.getUser().then(res => {
            if (res === undefined || res === null) { return setIsUserAdmin(null); }
            switch (res.role.toUpperCase() === Roles.Admin) {
                case true:
                    setIsUserAdmin(true);
                    setUserIdentity(res.sub);
                    break;
                case false:
                    setIsUserAdmin(false);
                    setUserIdentity(res.sub);
                    break;
            }
        })
    };

    return (
        <AuthenticationContext.Provider
            value={{
                isUserAdmin: isUserAdmin,
                userIdentity: userIdentity,
                authenticateUser: authenticateUser
            }}
        >
            {children}
        </AuthenticationContext.Provider>
    );
};

export const useAuthenticationContext = () => useContext(AuthenticationContext);