import React from "react"
import { ContentData, ContentResponse } from "../../../../common/models/input-models";
import { State } from "../model";
import EupatiContentPage from "./content";
import 'reactjs-popup/dist/index.css';
import '../style.scss';
import AuthenticationContext from "../../../../common/static/auth-context";

type OwnProps = {
    isContentLoading: boolean,
    contentResponse: ContentResponse
};

export type DispatchProps = {
    getContent: (name: string) => void;
    updateContent: (data: ContentData) => void;
};

type Props = State & DispatchProps & OwnProps;

type OwnState = {
    name: string;
};

const CURRENT_PATH = window.location.pathname;  //  (e.g. "/privacy")
export default class ComponentRoot extends React.Component<Props, OwnState> {
    static contextType = AuthenticationContext;
    constructor(props: any) {
        super(props);

        this.state = {
            name: CURRENT_PATH.substring(1)  //  removes the slash from CURRENT_PATH
        }
    }

    componentDidMount() {
        this.props.getContent(this.state.name);
        if (this.context.isUserAdmin === null || this.context.isUserAdmin === undefined) {
            this.context.authenticateUser();
        }
    }

    render() {
        return (
            <>
                <EupatiContentPage
                    {...{
                        content: this.props.contentResponse,
                        isContentLoading: this.props.isContentLoading,
                        contentName: this.state.name,
                        isUserAdmin: this.context.isUserAdmin
                    }}
                />
            </>
        )
    }
}