// model.js
// This is the model of our module state (e.g. return type of the reducer)

import { LookUpData, MeshData } from "../../common/models/input-models";
import { Patient } from "../../common/models/main-models";

export type State = {
    patient: Patient;
    isLoading: boolean;
    lookUpData: LookUpData[];
    isPatientDataLoaded: boolean;
};

export const defaultPatientInfo = {
    id: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    birthYear: undefined,
    genderTypeId: 0,
    countryId: 0,
    languages: [],
    countriesWithExperience: [],
    isPOAffiliated: false,
    patientOrganisation: '',

    diseases: [],
    noDiseaseExpertise: false,
    // diseaseName: '',
    // diseaseMeshId: '',
    // diseaseTAName: '',
    // diseaseTAMeshId: '',
    patientExperienceId: 0,
    patientExpertises: [],
    projectTypeExperiences: [],
    projectTypeExperiencesDescription: '',
    otherInformation: '',
    patientExpertiseDescription: '',
    genderTypeDescription: '',
    organisationPreferenceDescription: '',
    projectPreferenceDescription: '',

    isEligibleForCompensation: false,
    isAvailableForNoCompensation: false,
    projectPreferences: [],
    organisationPreferences: [],
    activityPreferenceId: 0,

    matchedProjects: [],
    confirmedProjects: [],
    isBannedFromSearch: false,
    isGDPRConsentGiven: false,
} as Patient;