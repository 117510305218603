import { LookUpData } from "../../../common/models/input-models";
import { Patient } from "../../../common/models/main-models";
import DataSvc from "../../../common/services/data-svc";
import * as t from './actionTypes';

export const setLoading = (isLoading: boolean) => ({
    type: t.SET_LOADING,
    payload: isLoading
});

export const setLookUpData = (data: LookUpData[]) => ({
    type: t.SET_LOOKUP_DATA,
    payload: data
});

export const setPatients = (patients: Patient[]) => ({
    type: t.SET_PATIENTS,
    payload: [...patients]
});

export const setIsPatientsLoading = (isPatientsLoading: boolean) => ({
    type: t.SET_PATIENTS_LOADING,
    payload: isPatientsLoading
});

export const setPatientInfo = (patient: Patient) => ({
    type: t.SET_PATIENT_INFO,
    payload: patient
});

export const setPatientInfoLoading = (isPatientInfoLoading: boolean) => ({
    type: t.SET_PATIENT_INFO_LOADING,
    payload: isPatientInfoLoading
});

export const getLookUpDataAsync = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        DataSvc.utilsSvc.getLookUpData().then(
            (result: any) => {
                dispatch(setLookUpData(result));
                dispatch(setLoading(false));
            },
            (error) => { console.error(error); }
        );
    };
};

export const getAllPatientsAsync = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setIsPatientsLoading(true));
        DataSvc.patientSvc.getAllPatients().then(
            (result: any) => {
                dispatch(setPatients(result));
                dispatch(setIsPatientsLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}

export const getPatientByIdAsync = (id: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setPatientInfoLoading(true));
        DataSvc.patientSvc.getPatientById(id).then(
            (result: any) => {
                dispatch(setPatientInfo(result));
                dispatch(setPatientInfoLoading(false));
            },
            (error) => { console.log(error); }
        )
    }
}

export const removePatientByIdAsync = (id: string) => {
    return (dispatch: any, getState: any) => {
        DataSvc.patientSvc.getPatientById(id).then(
            (result: any) => {
                DataSvc.patientSvc.removePatient(result).then(
                    () => {
                        dispatch(getAllPatientsAsync());
                    },
                    (error) => { console.log(error); }
                )
            },
            (error) => { console.error(error); }
        )
    }
}