import React from "react";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { Rules } from "../../../../common/helpers";
import EupatiDropdown from "../../../../common/static/dropdown";
import EupatiSelect from "../../../../common/static/select-menu";
import EupatiTextbox from "../../../../common/static/textbox";
import EupatiNonCMSToggleBtn from "../../../../common/static/toggle-btn-nonCMS";
import EupatiToggleBtn from "../../../../common/static/toggle-btn";
import { Patient } from "../../../../common/models/main-models";
import { LookUpOptions } from "../../../../common/models/input-models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/fontawesome-free-solid';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ReactTooltip from 'react-tooltip';

type OwnProps = {
    patient: Patient
};

type DispatchProps = {
    nextPage: () => void;
    setPatientInfo: (patient: Patient) => void;
    hidePatientSpecificInfoSection: () => void;
    returnProperDefaultValue: (val: any) => any;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
};

type Props = OwnProps & DispatchProps;

type FieldProps = {
    countryId?: number,
    languages: string[],
    countriesWithExperience: string[],
    genderTypeId?: number,
    genderTypeDescription: string,
    isPOAffiliated?: boolean,
    patientOrganisation: string
};

export default function PatientSpecificInfo(props: Props) {

    const { handleSubmit, control, watch, formState: { errors } } = useForm();

    const fieldsData = {
        countryId: watch("countryId") as number | undefined,
        languages: watch("languages") as string[],
        countriesWithExperience: watch("countriesWithExperience") as string[],
        genderTypeId: watch("genderTypeId") as number | undefined,
        genderTypeDescription: watch("genderTypeDescription") as string,
        isPOAffiliated: watch("isPOAffiliated") as boolean | undefined,
        patientOrganisation: watch("patientOrganisation") as string
    } as FieldProps;

    const onSubmit = (data: any) => {
        const editedPatientInfo = Object.assign(props.patient, data);
        props.setPatientInfo(editedPatientInfo);
        props.nextPage();
    }

    const onBackHandler = () => {
        if (adjustFieldsValues() === 0) {
            const editedPatientInfo = Object.assign(props.patient, fieldsData);
            props.setPatientInfo(editedPatientInfo);
        }
        props.hidePatientSpecificInfoSection();
    }

    const returnProperIconType = (icon: any) => {
        return icon as IconProp;
    }

    const fieldIsPOAffiliatedToggle = () => {
        if (fieldsData?.isPOAffiliated?.toString() === "true") {
            return true;
        }
        else if (fieldsData?.isPOAffiliated?.toString() === "false") {
            return false;
        }

        if (props.patient.isPOAffiliated !== undefined &&
            props.patient.isPOAffiliated !== null) {
            if (props?.patient?.isPOAffiliated.toString() === "true") {
                return true;
            }
        }

        return false;
    }

    const fieldGenderTypeToggle = () => {
        const otherGenderType = props.findLookUpData("genderType")?.find(gt => gt.name.toLowerCase().includes('other'));
        if (fieldsData.genderTypeId !== undefined && fieldsData.genderTypeId !== null) {
            return otherGenderType?.id === fieldsData.genderTypeId ? true : false;
        }
        if (props.patient.genderTypeId !== undefined && props.patient.genderTypeId !== null) {
            return otherGenderType?.id.toString() === props.patient.genderTypeId.toString() ? true : false;
        }

        return false;
    }

    const checkNullOrUndefined = (val: any) => {
        return typeof val === undefined || val === null || val === 0 ? true : false;
    }

    const adjustFieldsValues = () => {
        if (fieldsData.countryId === undefined &&
            fieldsData.languages === undefined &&
            fieldsData.countriesWithExperience === undefined &&
            fieldsData.genderTypeId === undefined &&
            fieldsData.genderTypeDescription === undefined &&
            fieldsData.isPOAffiliated === undefined &&
            fieldsData.patientOrganisation === undefined) {
            return 1;
        }

        if (fieldsData.countryId === undefined &&
            props.patient.countryId !== undefined) {
            fieldsData.countryId = props.patient.countryId;
        }

        if (fieldsData.languages === undefined &&
            props.patient.languages !== undefined) {
            fieldsData.languages = [...props.patient.languages];
        }

        if (fieldsData.countriesWithExperience === undefined &&
            props.patient.countriesWithExperience !== undefined) {
            fieldsData.countriesWithExperience = [...props.patient.countriesWithExperience];
        }

        if (fieldsData.genderTypeId === undefined &&
            props.patient.genderTypeId !== undefined) {
            fieldsData.genderTypeId = props.patient.genderTypeId;
        }

        if (fieldsData.genderTypeDescription === undefined &&
            props.patient.genderTypeDescription !== undefined) {
            fieldsData.genderTypeDescription = props.patient.genderTypeDescription;
        }

        if (fieldsData.isPOAffiliated === undefined &&
            props.patient.isPOAffiliated !== undefined) {
            fieldsData.isPOAffiliated = props.patient.isPOAffiliated;
        }

        if (fieldsData.patientOrganisation === undefined &&
            props.patient.patientOrganisation !== undefined) {
            fieldsData.patientOrganisation = props.patient.patientOrganisation;
        }

        return 0;
    }

    return (
        <Form className='profile' onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='profile-info'>
                <Form.Group className='rcolumn'>

                    <Form.Group id='pt-ctry' className='profile-info-field mb-4'>
                        <Form.Label>Country of residence</Form.Label>
                        <Controller
                            name='countryId'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.countryId)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiDropdown
                                    {...{
                                        ...field,
                                        errorMsg: errors?.countryId?.message,
                                        values: props.findLookUpData('country'),
                                        fieldName: 'countryId',
                                        defaultOptionText: checkNullOrUndefined(props.patient.countryId) ? 'Please select country...' : undefined
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-lang' className='profile-info-field mb-4'>
                        <Form.Label>Languages</Form.Label>
                        <FontAwesomeIcon data-tip data-for='languagesControlInfo' icon={returnProperIconType(faInfoCircle)} />
                        <ReactTooltip
                            id='languagesControlInfo'
                            multiline={true}>
                            <span>
                                It is possible to select multiple languages.
                                <br />
                                You can achive that by typing their names in the input field.
                                <br />
                                Moreover, you can also pick them from the dropdown menu.
                                <br />
                            </span>
                        </ReactTooltip>
                        <Controller
                            name='languages'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.languages)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiSelect
                                    {...{
                                        ...field,
                                        errorMsg: errors?.languages?.message,
                                        values: props.findLookUpData('language'),
                                        placeholder: 'Select languages...',
                                        isAutocomplete: false
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-otherCtry' className='profile-info-field mb-4'>
                        <Form.Label >Other country(ies) experience</Form.Label>
                        <FontAwesomeIcon data-tip data-for='otherCtryInfo' icon={returnProperIconType(faInfoCircle)} />
                        <ReactTooltip id='otherCtryInfo'>
                            <span>Country(ies) in which you have experience relevant to patient engagement</span>
                        </ReactTooltip>
                        <FontAwesomeIcon data-tip data-for='otherCtryControlInfo' icon={returnProperIconType(faInfoCircle)} />
                        <ReactTooltip
                            id='otherCtryControlInfo'
                            multiline={true}>
                            <span>
                                It is possible to select multiple countries or continents.
                                <br />
                                You can achive that by typing their names in the input field.
                                <br />
                                Moreover, you can also pick them from the dropdown menu.
                                <br />
                            </span>
                        </ReactTooltip>
                        <Controller
                            name='countriesWithExperience'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.countriesWithExperience)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiSelect
                                    {...{
                                        ...field,
                                        errorMsg: errors?.countriesWithExperience?.message,
                                        values: props.findLookUpData('countryWithContinents'),
                                        isAutocomplete: false
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-gender' className='profile-info-field mb-4'>
                        <Form.Label>Gender</Form.Label>
                        <Controller
                            name='genderTypeId'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.genderTypeId)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiToggleBtn
                                    {...{
                                        ...field,
                                        errorMsg: errors?.genderTypeId?.message,
                                        fieldName: 'genderTypeId',
                                        values: props.findLookUpData('genderType')
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    {fieldGenderTypeToggle() &&
                        <Form.Group id='pt-genderDescr' className='profile-info-field mb-4'>
                            <Form.Label>Please specify the gender type</Form.Label>
                            <Controller
                                name='genderTypeDescription'
                                control={control}
                                defaultValue={props.returnProperDefaultValue(props.patient.genderTypeDescription)}
                                rules={Rules.notRequired}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.genderTypeDescription?.message,
                                            placeholder: 'Please specify gender type'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    }

                    <div>
                        <Form.Group id='pt-poa' className='profile-info-field mb-3'>
                            <Form.Label>Affiliation to a patient organisation</Form.Label>
                            <Controller
                                name='isPOAffiliated'
                                control={control}
                                defaultValue={props.returnProperDefaultValue(props.patient.isPOAffiliated)}
                                rules={Rules.selectOptionMessage}
                                render={({ field }) =>
                                    <EupatiNonCMSToggleBtn
                                        {...{
                                            ...field,
                                            errorMsg: errors?.isPOAffiliated?.message,
                                            fieldName: 'isPOAffiliated',
                                            isMoreThanTwoOptions: false
                                        }}
                                    />
                                }
                            />
                        </Form.Group>

                        {fieldIsPOAffiliatedToggle() &&
                            <Form.Group id='pt-org' className='profile-info-field mb-4'>
                                <Form.Label>Patient organisation</Form.Label>
                                <Controller
                                    name='patientOrganisation'
                                    control={control}
                                    defaultValue={props.returnProperDefaultValue(props.patient.patientOrganisation)}
                                    rules={Rules.requiredMessage}
                                    render={({ field }) =>
                                        <EupatiTextbox
                                            {...{
                                                ...field,
                                                errorMsg: errors?.patientOrganisation?.message,
                                                placeholder: 'Please type patient organisation'
                                            }}
                                        />
                                    }
                                />
                            </Form.Group>
                        }
                    </div>

                    <Form.Group id='pt-btns'>
                        <Button className='bck' variant='secondary' type='submit' onClick={onBackHandler}>Back</Button>
                        <Button className='nxt' variant='primary' type='submit'>Next</Button>
                    </Form.Group>

                </Form.Group>
            </Form.Group>
        </Form>
    )
}