import React, { useState } from "react";
import PersonalInfo from "./personal";
import CompanyInfo from "./company";
import { Project } from "../../../../common/models/main-models";
import { LookUpData, LookUpOptions } from "../../../../common/models/input-models";

type OwnProps = {
    project: Project,
    lookUpData: LookUpData[],
    isUserReal: boolean,
    showSecondSubComponent: boolean
};

type DispatchProps = {
    nextPage: () => void;
    setIsUserReal: (token: string) => void;
    setProject: (project: Project) => void;
    returnProperDefaultValue: (val: any) => any;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
};

type Props = OwnProps & DispatchProps;

export default function ResearcherInfo(props: Props) {
    const [nextBtnClicked, setNextBtnClicked] = useState<boolean>(props.showSecondSubComponent);

    const toggleSectionVisibility = () => {
        setNextBtnClicked(!nextBtnClicked);
        //window.scrollTo(0, 0);
    }

    return (
        <div style={{ margin: '0 0 7.5rem 0' }}>

            {nextBtnClicked &&
                <PersonalInfo
                    {...{
                        project: props.project,
                        setProject: props.setProject,
                        returnProperDefaultValue: props.returnProperDefaultValue,
                        findLookUpData: props.findLookUpData,
                        isUserReal: props.isUserReal,
                        showCompanyInfoSection: toggleSectionVisibility
                    }}
                />
            }

            {!nextBtnClicked &&
                <CompanyInfo
                    {...{
                        project: props.project,
                        setProject: props.setProject,
                        returnProperDefaultValue: props.returnProperDefaultValue,
                        findLookUpData: props.findLookUpData,
                        hideCompanyInfoSection: toggleSectionVisibility,
                        isUserReal: props.isUserReal,
                        nextPage: props.nextPage
                    }}
                />
            }
        </div>
    );
}