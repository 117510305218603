// model.js
// This is the model of our module state (e.g. return type of the reducer)

import { ContentResponse } from "../../../common/models/input-models";

export type State = {
    isContentLoading: boolean;
    contentResponse: ContentResponse;
}

export const blankResponse = {
    rawDraftContent: '',
    htmlContent: ''
} as ContentResponse;