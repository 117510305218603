import React, { useEffect, useMemo, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useRowSelect, useTable } from 'react-table';
import { EmailTemplateData, LookUpData, LookUpOptions, PatientSearchData, ProjectPatientData, RequestData } from '../models/input-models';
import { Patient, Project } from '../models/main-models';
import EupatiPatientDetails from './patient-details';
import { toast } from 'react-toastify';
import { ADD_PT_TO_PROJ_MSG, DEL_PT_FROM_PROJ_MSG, CONFIRM_PATIENT_MSG } from '../helpers/messages';
import EmailPopupModal from '../static/popup-modal';
import { Check } from './react-table-checkbox';

type OwnProps = {
    matchedPatients: Patient[],
    lookUpData: LookUpData[],
    isPatientProfileClicked: boolean,
    leftArrowText: string,
    rightArrowText: string
    isForwardArrowAvail: boolean,
    leftBtnText: string,
    rightBtnText: string,
    projectId: string,
    projectName: string,
    isManualSearchBtnClicked: boolean,
    patientSearchData?: any,
    project: Project,
    isConfirmed: boolean,
    emailTemplates: EmailTemplateData[],
    singleEmailTemplate: EmailTemplateData,
    emailTemplateLoading: boolean
};

type DispatchProps = {
    patientProfileBtnHandler: () => void;
    backToProjectProfile: () => void;
    toggleComponents: (val: string) => void;
    removePatient: (inputModel: ProjectPatientData) => void;
    addPatient: (inputModel: ProjectPatientData, searchData: PatientSearchData, projectName: string) => void;
    updateSearchPatients?: (ptSrchData: any) => void;
    submitRequest?: (data: RequestData) => void;
    changePatientStatus: (inputModel: ProjectPatientData) => void;
    getAllEmailTemplates: () => void;
    getEmailTemplateByName: (name: string) => void;
};

type Props = OwnProps & DispatchProps;

export default function EupatiPatientsGrid(props: Props) {

    const [patientInfo, setPatientInfo] = useState<any>({});
    const [allTemplates, setAllTemplates] = useState<EmailTemplateData[]>(props.emailTemplates.length > 0 ? [...props.emailTemplates] : []);
    const [dropdownValues, setDropdownValue] = useState<LookUpOptions[]>([]);

    useEffect(() => {
        if (allTemplates.length > 0) {
            //sort by name alphabetically
            setAllTemplates([...allTemplates.sort((a, b) => a.name.localeCompare(b.name))]);
            //convert templates to dropdown menu options
            templatesToLookUpOptions();
        }
    }, []);

    let mainComponent: any;

    const returnLookUpValueByTypeAndId = (propName: string, projectPropId: number) => {
        const propType = props.lookUpData.find((x: any) => x.name == propName); // projectType
        const propValue = propType?.data.find((x: any) => x.id == projectPropId);
        return propValue?.name;
    }

    function profileBtnHandler(id: any) {
        setPatientInfo(props.matchedPatients.find((x: any) => x.id === id));
        props.patientProfileBtnHandler(); // isPatientProfileClicked:true
        props.toggleComponents('none');
        //window.scrollTo(0, 0);
    }

    function deleteProjectPatient(inputModel: ProjectPatientData) {
        toast.success(DEL_PT_FROM_PROJ_MSG);
        props.removePatient(inputModel);
    }

    function addProjectPatient(inputModel: ProjectPatientData) {
        toast.success(ADD_PT_TO_PROJ_MSG);
        props.addPatient(inputModel, props.patientSearchData, props.projectName);
    }

    function confirmPatient(inputModel: ProjectPatientData) {
        toast.success(CONFIRM_PATIENT_MSG);
        props.changePatientStatus(inputModel);
    }

    function resetTableSelectedState() {
        toggleAllRowsSelected(false);   // reset tbl state
    }

    function rightBtnHandler(patientId: any) {
        let inputModel = {
            projectId: props.projectId,
            patientId: patientId
        } as ProjectPatientData;

        switch (props.rightBtnText.toLowerCase()) {
            case 'remove':
                deleteProjectPatient(inputModel);
                break;
            case 'assign':
                addProjectPatient(inputModel);
                break;
        }
    }

    function backBtnHandler() {
        setPatientInfo({});
        props.backToProjectProfile(); // isPatientProfileClicked:false
        props.toggleComponents('');
        if (typeof props.updateSearchPatients !== 'undefined') {
            props.updateSearchPatients(props.patientSearchData);
        }
    }

    function templatesToLookUpOptions() {
        const arr = allTemplates?.map((x: EmailTemplateData, i: number) => {
            return (
                {
                    id: i,
                    name: x.name
                } as LookUpOptions
            )
        });
        setDropdownValue([...arr]);
    }

    function selectTemplateByIndex(index: number) {
        const selectedTemplate = allTemplates[index];
        if (selectedTemplate !== undefined) {
            props.getEmailTemplateByName(selectedTemplate.name);
        }
        else {
            props.getEmailTemplateByName('empty');
        }
    }

    // function handleCopyOnClick(e: any) {
    //     e.preventDefault();
    //     const emails = selectedFlatRows.map((row: any) => row.original.email).join(';');
    //     navigator.clipboard.writeText(emails.trim());
    //     toast.success(EMAILS_COPIED_MSG);
    // }

    const data = useMemo(
        () => props?.matchedPatients.map((x: any, i: any) => {
            return {
                id: x.id,
                name: x.firstName.concat(' ', x.lastName),
                country: returnLookUpValueByTypeAndId('country', x.countryId),
                diseases: x.noDiseaseExpertise ? "No specific disease experience" : x.diseases?.map((d: any) => d.name).join(", "),
                email: x.email,
                expertises: x.patientExpertises.map((pe: number) => returnLookUpValueByTypeAndId('expertise', pe)).join(", ")
            }
        }), [props.matchedPatients]
    )

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name'
            },
            {
                Header: 'Country',
                accessor: 'country'
            },
            {
                Header: 'Diseases',
                accessor: 'diseases'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Expertises',
                accessor: 'expertises'
            }
        ], []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        //@ts-ignore
        selectedFlatRows,
        //@ts-ignore
        toggleAllRowsSelected
    } = useTable({
        //@ts-ignore
        columns,
        data
    },
        useRowSelect,
        (hooks) => {
            {
                !props.isManualSearchBtnClicked &&
                    hooks.visibleColumns.push((cols) => {
                        return [
                            {
                                id: 'selection',
                                //@ts-ignore
                                Header: (({ getToggleAllRowsSelectedProps }) => (
                                    <Check {...getToggleAllRowsSelectedProps()} />
                                )),
                                //@ts-ignore
                                Cell: ({ row }) => <Check {...row.getToggleRowSelectedProps()} />
                            },
                            ...cols
                        ]
                    })
            }
        }
    )

    mainComponent = (
        <Table {...getTableProps()} striped bordered hover>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(col => (
                            <th {...col.getHeaderProps()}>
                                {col.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                            <>
                                <td>
                                    <Button
                                        variant="info"
                                        onClick={() => profileBtnHandler(row.original.id)}
                                    >
                                        {props.leftBtnText.toUpperCase()}
                                    </Button>
                                </td>
                                {props.isConfirmed === false &&
                                    <td>
                                        <Button
                                            variant="success"
                                            onClick={() => confirmPatient({ patientId: row.original.id, projectId: props.projectId } as ProjectPatientData)}
                                        >
                                            CONFIRM
                                        </Button>
                                    </td>
                                }
                                <td>
                                    <Button
                                        variant='danger'
                                        onClick={() => rightBtnHandler(row.original.id)}
                                    >
                                        {props.rightBtnText.toUpperCase()}
                                    </Button>
                                </td>
                            </>
                        </tr>
                    )
                })}
            </tbody>
            {/* <pre>
                <code>
                    {JSON.stringify(
                        {
                            selectedFlatRows: selectedFlatRows.map((row: any) => row.original.email),
                        },
                        null,
                        2
                    )}
                </code>
            </pre> */}
        </Table >
    )

    return (
        <>
            {props.isPatientProfileClicked &&
                <EupatiPatientDetails
                    {...{
                        patientInfo,
                        lookUpData: props.lookUpData,
                        backBtnHandler,
                        //forwardBtnHandler: props.rightArrowText.includes('remove') ? props.removePatient : props.addPatient,
                        projectName: props.projectName,
                        searchData: props.patientSearchData,
                        leftArrowText: props.leftArrowText,
                        rightArrowText: props.rightArrowText,
                        isForwardArrowAvail: props.isForwardArrowAvail,
                        projectId: props.projectId
                    }}
                />
            }
            {!props.isPatientProfileClicked &&
                <>
                    {!props.isManualSearchBtnClicked &&
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '10px' }}>
                            {/* <Button
                                id='copy-emails-btn'
                                onClick={(e) => { handleCopyOnClick(e) }}
                                disabled={selectedFlatRows.length === 0 ? true : false}
                                style={selectedFlatRows.length === 0 ? { cursor: 'not-allowed', margin: '0 10px 0 0' } : { margin: '0 10px 0 0', cursor: 'pointer' }}
                            >
                                COPY SELECTED EMAILS
                            </Button> */}
                            <EmailPopupModal
                                recipients={selectedFlatRows.map((row: any) => row.original.email).join('\t')}
                                submitRequest={props.submitRequest !== undefined ? props.submitRequest : () => { void 0; }}
                                btnText='Email Patients'
                                resetValues={() => { }}
                                singleEmailTemplate={props.singleEmailTemplate}
                                emailTemplateLoading={props.emailTemplateLoading}
                                project={props.project}
                                returnLookUpValueByTypeAndId={returnLookUpValueByTypeAndId}
                                getAllEmailTemplates={props.getAllEmailTemplates}
                                emailTemplates={props.emailTemplates}
                                selectTemplateByIndex={selectTemplateByIndex}
                                dropdownValues={dropdownValues}
                                matchedPatients={props.matchedPatients}
                            />
                        </div>
                    }
                    {mainComponent}
                </>
            }
        </>
    );
}
