import { State } from "./model";
import * as t from './actionTypes';
import { CatalogData } from "../../../common/models/input-models";

const initialState: State = {
    isCatalogsLoading: false,
    catalogsData: []
}

export default (state = initialState, action: any): State => {
    switch (action.type) {
        case t.SET_IS_CATALOGS_LOADING:
            return setIsCatalogsLoading(state, action.payload);
        case t.SET_CATALOGS_DATA:
            return setCatalogsData(state, action.payload);
        default:
            return state;
    }
}

const setIsCatalogsLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isCatalogsLoading: val
    } as State;

    return newState;
}

const setCatalogsData = (state: State, val: CatalogData[]): State => {
    let newState = {
        ...state,
        catalogsData: [...val]
    } as State;

    return newState;
}