import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import { RouteComponentProps } from '@reach/router';
import { ContentResponse } from '../../../../common/models/input-models';
import ReactDOMServer from 'react-dom/server';
import EupatiLoader from '../../../../common/static/loading-animation/loader';
import { useHistory } from 'react-router';
import '../style.scss';

type OwnProps = {
    content: ContentResponse,
    isContentLoading: boolean,
    contentName: string,
    isUserAdmin: boolean
};

export type DispatchProps = {};

type Props = DispatchProps & OwnProps & RouteComponentProps<{ location: { state: { html: string } } }>;

const HtmlToReactParser = require('html-to-react').Parser;
export default function EupatiPrivacyPage(props: Props) {
    const history = useHistory();

    const htmlToReactParser = new HtmlToReactParser();
    const reactElement = htmlToReactParser.parse(props.content.htmlContent);
    const reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);

    const redirectToEditor = () => {
        history.push({
            pathname: '/admin/content-editor',
            state: { pageName: props.contentName }
        });
    }

    let mainComp: any;
    let loadComp = (
        <div className='loader-wrapper'>
            <EupatiLoader />
        </div>
    )

    if (props.isContentLoading) {
        mainComp = loadComp;
    }
    else {
        mainComp = (
            <div className={`${props.contentName}-content`} dangerouslySetInnerHTML={{ __html: reactHtml }}></div>
        );
    }

    return (
        <>
            <Form.Group id={`${props.contentName}`}>
                {props.isUserAdmin &&
                    <div id={`${props.contentName}-btns`}>
                        <Button variant='primary' onClick={redirectToEditor}>Edit page</Button>
                    </div>
                }
                {mainComp}
            </Form.Group>
        </>
    );
}
