
import React from "react";
import { Button, Form } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import { Rules, Messages } from "../../../../common/helpers";
import { useHistory } from "react-router";
import EupatiSelect from "../../../../common/static/select-menu";
import EupatiDiseaseAsyncSelect from "../../../../common/static/async-select-menu";
import EupatiCheckbox from "../../../../common/static/checkbox-toggle";
import { DiseaseInfo, Project } from "../../../../common/models/main-models";
import { LookUpOptions } from "../../../../common/models/input-models";
import { toast } from 'react-toastify';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/fontawesome-free-solid';
import ReactTooltip from 'react-tooltip';

type OwnProps = {
    project: Project,
    isUserReal: boolean
};

type DispatchProps = {
    setProject: (project: Project) => void;
    returnProperDefaultValue: (val: any) => any;
    showPatientsAdditionalInfoSection: () => void;
    returnProperIconType: (icon: any) => IconProp;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
    prevPage: () => void;
};

type Props = OwnProps & DispatchProps;

type FieldsProps = {
    countries: string[],
    languages: string[],
    diseases: DiseaseInfo[],
    noDiseaseExpertiseRequired: boolean;
    searchDiseaseTherapeuticArea: boolean;
};

const CUSTOM_SPAN_STYLES = ({
    boldFont: {
        fontWeight: 600
    }
});
export default function PatientsDiseaseInfo(props: Props) {

    const history = useHistory();

    const { handleSubmit, control, watch, formState: { errors } } = useForm();

    const fieldsData = {
        countries: watch("countries") as string[],
        languages: watch("languages") as string[],
        diseases: watch("diseases") as DiseaseInfo[],
        noDiseaseExpertiseRequired: watch("noDiseaseExpertiseRequired") as boolean,
        searchDiseaseTherapeuticArea: watch("searchDiseaseTherapeuticArea") as boolean
    } as FieldsProps;

    const onSubmit = (data: any) => {
        if (!props.isUserReal) {
            toast.error(Messages.FAIL_AUTH_MSG);
            return history.push('/');
        }

        const updatedProject = Object.assign(props.project, data);
        props.setProject(updatedProject);
        props.showPatientsAdditionalInfoSection();
    }

    const onBackHandler = () => {
        if (!props.isUserReal) {
            toast.error(Messages.FAIL_AUTH_MSG);
            return history.push('/');
        }

        if (adjustFieldsValues() === 0) {
            const updatedProject = Object.assign(props.project, fieldsData);
            props.setProject(updatedProject);
        }
        props.prevPage();
    }

    const adjustFieldsValues = () => {
        if (fieldsData.countries === undefined &&
            fieldsData.languages === undefined &&
            fieldsData.diseases === undefined &&
            fieldsData.noDiseaseExpertiseRequired === undefined &&
            fieldsData.searchDiseaseTherapeuticArea === undefined) {
            return 1;
        }

        if (fieldsData.countries === undefined &&
            props.project.countries !== undefined) {
            fieldsData.countries = [...props.project.countries];
        }

        if (fieldsData.languages === undefined &&
            props.project.languages !== undefined) {
            fieldsData.languages = [...props.project.languages];
        }

        if (fieldsData.diseases === undefined &&
            props.project.diseases !== undefined) {
            fieldsData.diseases = [...props.project.diseases];
        }

        if (fieldsData.noDiseaseExpertiseRequired === undefined &&
            props.project.noDiseaseExpertiseRequired !== undefined) {
            fieldsData.noDiseaseExpertiseRequired = props.project.noDiseaseExpertiseRequired;
        }

        if (fieldsData.searchDiseaseTherapeuticArea === undefined &&
            props.project.searchDiseaseTherapeuticArea !== undefined) {
            fieldsData.searchDiseaseTherapeuticArea = props.project.searchDiseaseTherapeuticArea;
        }

        return 0;
    }

    const returnProperIconType = (icon: any) => {
        return icon as IconProp;
    }

    const toggleDiseaseClarificationField = () => {
        if (fieldsData.diseases === undefined || fieldsData.diseases === null) {
            return false;
        }
        else if (fieldsData.diseases.length === 0) {
            return false;
        }

        return true;
    }

    return (
        <Form className='pt' onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='pt-info'>
                <Form.Group className='lcolumn'>

                    <Form.Group id='pt-ctrys' className='pt-info-field mb-5'>
                        <Form.Label>Countries</Form.Label>
                        <FontAwesomeIcon data-tip data-for='countriesInfo' icon={returnProperIconType(faInfoCircle)} />
                        <ReactTooltip id='countriesInfo'>
                            <span>Country(ies) where relevant patient engagement experience.</span>
                        </ReactTooltip>
                        <FontAwesomeIcon data-tip data-for='countriesControlInfo' icon={returnProperIconType(faInfoCircle)} />
                        <ReactTooltip
                            id='countriesControlInfo'
                            multiline={true}>
                            <span>
                                It is possible to select multiple countries or continents.
                                <br />
                                You can achive that by typing their names in the input field.
                                <br />
                                Moreover, you can also pick them from the dropdown menu.
                                <br />
                            </span>
                        </ReactTooltip>
                        <Controller
                            name='countries'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.countries)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiSelect
                                    {...{
                                        ...field,
                                        values: props.findLookUpData('countryWithContinents'),
                                        errorMsg: errors?.countries?.message,
                                        isAutocomplete: false
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-langs' className='pt-info-field mb-5'>
                        <Form.Label>Languages</Form.Label>
                        <FontAwesomeIcon data-tip data-for='languagesControlInfo' icon={returnProperIconType(faInfoCircle)} />
                        <ReactTooltip
                            id='languagesControlInfo'
                            multiline={true}>
                            <span>
                                It is possible to select multiple languages.
                                <br />
                                You can achive that by typing their names in the input field.
                                <br />
                                Moreover, you can also pick them from the dropdown menu.
                                <br />
                            </span>
                        </ReactTooltip>
                        <Controller
                            name='languages'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.languages)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiSelect
                                    {...{
                                        ...field,
                                        values: props.findLookUpData('language'),
                                        errorMsg: errors?.languages?.message,
                                        isAutocomplete: false
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-diseaseName' className='pt-info-field mb-5'>
                        <Form.Label style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ ...CUSTOM_SPAN_STYLES.boldFont }}>
                                Therapeutic Areas or Diseases
                                <FontAwesomeIcon data-tip data-for='diseasesControlInfo' icon={returnProperIconType(faInfoCircle)} />
                                <ReactTooltip
                                    id='diseasesControlInfo'
                                    multiline={true}>
                                    <span>
                                        It is possible to select multiple diseases.
                                        <br />
                                        You can achive that by typing their names in the input field.
                                        <br />
                                        Moreover, you can also pick them from the dropdown menu.
                                        <br />
                                    </span>
                                </ReactTooltip>
                            </span>
                            <span>
                                <small style={{ fontSize: '11px' }}>
                                    Diseases are listed according to the US National Library of Medicine.
                                    &nbsp;You can find the controlled vocabulary, Medical Subject Headings (MEsH), <a href='https://meshb.nlm.nih.gov/search' target='_blank' >here.*</a>
                                </small>
                            </span>
                        </Form.Label>

                        <Controller
                            name='diseases'
                            control={control}
                            defaultValue={props.project.diseases}
                            rules={Rules.notRequired}
                            render={({ field }) =>
                                <EupatiDiseaseAsyncSelect
                                    {...{
                                        ...field,
                                        errorMsg: errors?.diseases?.message
                                    }}
                                />
                            }
                        />
                        {toggleDiseaseClarificationField() === false ? (
                            <Controller
                                name='noDiseaseExpertiseRequired'
                                control={control}
                                defaultValue={props.project.noDiseaseExpertiseRequired}
                                rules={Rules.notRequired}
                                render={({ field }) =>
                                    <EupatiCheckbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.noDiseaseExpertise?.message,
                                            fieldName: 'noDiseaseExpertiseRequired',
                                            text: 'No specific disease experience is required'
                                        }}
                                    />
                                }
                            />
                        ) : (
                            <Controller
                                name='searchDiseaseTherapeuticArea'
                                control={control}
                                defaultValue={props.project.searchDiseaseTherapeuticArea}
                                rules={Rules.notRequired}
                                render={({ field }) =>
                                    <EupatiCheckbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.searchDiseaseTherapeuticArea?.message,
                                            fieldName: 'searchDiseaseTherapeuticArea',
                                            text: 'Match patients in the therapeutic area(s) as well(i.e. If Breast Cancer is selected, it would broaden the match to all Oncology experts).'
                                        }}
                                    />
                                }
                            />
                        )}
                    </Form.Group>

                    <Form.Group id='pt-btns' className='pt-info-field'>
                        <Button className='bck' variant='secondary' onClick={onBackHandler} >Back</Button>
                        <Button className='nxt' variant='primary' type='submit'>Next</Button>
                    </Form.Group>
                </Form.Group>
            </Form.Group>
        </Form >
    )
}