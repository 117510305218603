import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { Form, Button } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import EupatiTextbox from './textbox';
import EupatiTextarea from './textarea';
import { Rules } from '../helpers';
//import { EditorState, ContentState } from 'draft-js';
//import { Editor } from 'react-draft-wysiwyg';
import { toast } from 'react-toastify';
import './static.scss';
import 'reactjs-popup/dist/index.css';
//import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faWindowMinimize, faWindowMaximize } from '@fortawesome/fontawesome-free-regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { EmailPatientDetails, LookUpOptions, RequestData } from '../models/input-models';
//import { stateToHTML } from 'draft-js-export-html';
import { SEND_EMAILS_SUCC_MSG } from '../helpers/messages';
//import htmlToDraft from 'html-to-draftjs';
import { Patient, Project } from '../models/main-models';
import { EmailTemplateData } from '../models/input-models';
import EupatiDropdown from './dropdown';
import EupatiLoader from './loading-animation/loader';
import { Editor } from '@tinymce/tinymce-react';
const escape_quotes = require('escape-quotes');

type OwnProps = {
    recipients: string;
    project: Project;
    btnText: string;
    emailTemplates: EmailTemplateData[];
    dropdownValues: LookUpOptions[];
    singleEmailTemplate: EmailTemplateData;
    emailTemplateLoading: boolean;
    matchedPatients: Patient[];
};

type DispatchProps = {
    submitRequest: (reqData: RequestData) => void;
    resetValues: () => void;
    returnLookUpValueByTypeAndId: (propName: string, projectPropId: number) => string | undefined;
    getAllEmailTemplates: () => void;
    selectTemplateByIndex: (index: number) => void;
};

type Props = OwnProps & DispatchProps;

export default function EmailModalPopup(props: Props) {

    const { handleSubmit, reset, control, watch, formState: { errors } } = useForm();

    const [isMaxSize, setIsMaxSize] = useState<boolean>(false);
    const [singleTemplateData, setSingleTemplateData] = useState<EmailTemplateData>({ ...props.singleEmailTemplate });

    const onSubmit = (data: any) => {
        toast.success(SEND_EMAILS_SUCC_MSG);
        const targetedPeople = data.recipients.split('\t');
        props.submitRequest({
            recipients: targetedPeople,
            subject: data.subject,
            content: escape_quotes(returnHtmlContent()),
            projectId: props.project.id,
            patients: props.matchedPatients.filter((x: Patient) => targetedPeople.some((tp: string) => tp === x.email)).map((x: Patient) => { return { id: x.id, email: x.email } as EmailPatientDetails })
        } as RequestData);
    }

    const handleTemplateOnChange = (e: any) => {
        if (e) {
            //e.target.value --> selected menu option index
            const index = parseInt(e.target.value);
            if (index !== undefined && index !== null) {
                props.selectTemplateByIndex(index);
            }
        }
    }

    const returnProperIconType = (icon: any) => {
        return icon as IconProp;
    }

    const returnHtmlContent = () => {
        let htmlContent = '';
        if (singleTemplateData.htmlContent !== '') {
            htmlContent = props.singleEmailTemplate.htmlContent === singleTemplateData.htmlContent ? props.singleEmailTemplate.htmlContent : singleTemplateData.htmlContent;
        }
        else {
            htmlContent = props.singleEmailTemplate.htmlContent;
        }

        return htmlContent;
    }

    // const returnSelectedDropdownOption = () => {
    //     if (templateIndex !== undefined && templateIndex !== null) {
    //         return props.dropdownValues.filter((x: LookUpOptions) => x.id === templateIndex);
    //     }
    // }

    const handleEditorChange = (content: any) => {
        if (content) {
            const updatedHtmlContent = Object.assign(props.singleEmailTemplate, { htmlContent: escape_quotes(content) });
            setSingleTemplateData({ ...updatedHtmlContent });
        }
    }

    return (
        <Popup className={`${!isMaxSize ? 'modal' : 'modal max-size'}`} trigger={<Button className="button"> {props.btnText} </Button>} modal>
            {(close: any) => (
                <Form onSubmit={handleSubmit(onSubmit)} onFocus={(e) => e.stopPropagation()} >
                    <Form.Group className='modal-header'>
                        <span className='modal-header-title'>New Message</span>
                        <div className='modal-header-icons'>
                            <div className='modal-header-icons-ext' onClick={() => { close(); reset(); props.selectTemplateByIndex(-1); }}>
                                <FontAwesomeIcon icon={returnProperIconType(faWindowClose)} />
                            </div>
                            <div className='modal-header-icons-max'>
                                <FontAwesomeIcon icon={returnProperIconType(faWindowMaximize)} onClick={() => setIsMaxSize(!isMaxSize)} />
                            </div>
                            <div className='modal-header-icons-min' onClick={close}>
                                <FontAwesomeIcon icon={returnProperIconType(faWindowMinimize)} />
                            </div>
                        </div>
                    </Form.Group>
                    <div className='modal-content-wrapper'>
                        <Form.Group className='modal-recipients'>
                            <Form.Label className='modal-recipients-title'>Bcc recipients</Form.Label>
                            <Controller
                                name='recipients'
                                control={control}
                                defaultValue={props.recipients}
                                rules={Rules.requiredMessage}
                                render={({ field }) =>
                                    <EupatiTextarea
                                        {...{
                                            ...field,
                                            errorMsg: errors?.recipients?.message,
                                            rowsCount: 1,
                                            placeholderMsg: 'Please select recipient(s)',
                                            isDisabled: true
                                        }}
                                    />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='modal-subject'>
                            <Form.Label className='modal-subject-title'>Subject</Form.Label>
                            <Controller
                                name='subject'
                                control={control}
                                defaultValue=''
                                rules={Rules.requiredMessage}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.subject?.message,
                                            placeholder: 'Please type subject'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='modal-templates'>
                            <Form.Label className='modal-templates-title'>Choose template</Form.Label>
                            <Controller
                                name='templates'
                                control={control}
                                //defaultValue={returnSelectedDropdownOption()}
                                rules={Rules.notRequired}
                                render={({ field }) =>
                                    <EupatiDropdown
                                        {...{
                                            ...field,
                                            onChange: handleTemplateOnChange,
                                            errorMsg: errors?.templates?.message,
                                            fieldName: 'templates',
                                            values: props.dropdownValues,
                                            defaultOptionText: 'Select template...'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>

                        <div style={{ padding: '1rem 1rem' }}>
                            {props.emailTemplateLoading ? (
                                <>
                                    <EupatiLoader />
                                </>
                            ) : (
                                <Form.Group id='content-editor' className='modal-content'>
                                    {/* <Controller
                                        name='content'
                                        control={control}
                                        defaultValue={props.singleEmailTemplate.htmlContent}
                                        rules={Rules.requiredMessage}
                                        render={({ field }) =>
                                            <EupatiContentEditor
                                                {...{
                                                    ...field,
                                                    errorMsg: errors?.content?.message,
                                                }}
                                            />
                                        }
                                    /> */}
                                    <Editor
                                        apiKey="gi5vdpb46pw329w18n94luc6ne2yimgpjks785wd387s2qvu"
                                        value={escape_quotes(props.singleEmailTemplate.htmlContent)}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            convert_urls: true,
                                            relative_urls: false,
                                            remove_script_host: false,
                                            plugins: ['lists'],
                                            toolbar: 'undo redo | ' +
                                                'bold italic | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:18px }'
                                        }}
                                        onEditorChange={handleEditorChange}
                                    />
                                </Form.Group>
                            )}
                        </div>

                    </div>
                    <Form.Group className='modal-btns'>
                        <Button variant='primary' className='modal-btns-save' type='submit'>SEND</Button>
                    </Form.Group>
                </Form>
            )
            }
        </Popup >
    )
};