import { default as RootComponent, DispatchProps } from '../components/component-root';
import * as Constants from '../constants';
import { State } from '../models';
import * as Actions from '../actions';
import { connect } from 'react-redux';
import { ActionsTemplateData, EmailTemplateData } from '../../../../common/models/input-models';

export function mapStateToProps(globalState: any) {
    let name = Constants.NAME;
    let pageState = globalState[name] as State;
    return pageState;
}

export function mapDispatchToProps(dispatch: any) {
    return {
        getAllTemplates: () => dispatch(Actions.setAllEmailTemplatesAsync()),
        getSingleTemplate: (id: string) => dispatch(Actions.setEmailTemplateByIdAsync(id)),
        createEmailTemplate: (data: EmailTemplateData) => dispatch(Actions.createNewEmailTemplateAsync(data)),
        updateEmailTemplate: (data: EmailTemplateData) => dispatch(Actions.updateEmailTemplateDataAsync(data)),
        removeEmailTemplate: (id: string) => dispatch(Actions.removeEmailTemplateDataAsync(id)),
        getModelsPropsName: (id: string) => dispatch(Actions.setModelsPropsNamesAsync(id)),
        getTriggersMenuOptions: () => dispatch(Actions.setTriggersMenuOptionsAsync()),
        assignActionsToEmailTemplate: (data: ActionsTemplateData) => dispatch(Actions.assignActionsToEmailTemplateAsync(data)),
        getReceiversMenuOptions: () => dispatch(Actions.setReceiversMenuOptionsAsync()),
        getEmailTriggerTemplateBeneficiaries: (id: string) => dispatch(Actions.setEmailTriggerTemplateBeneficiariesAsync(id)),
        misallocateActionsFromEmailTemplate: (data: ActionsTemplateData) => dispatch(Actions.misallocateActionsFromEmailTemplateAsync(data))
    } as DispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(RootComponent);