import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "reactstrap";

type OwnProps = {};

type DispatchProps = {};

type Props = {} & DispatchProps & OwnProps;

type OwnState = {
    currentYear: number;
};

export default class Footer extends React.PureComponent<Props, OwnState> {

    constructor(props: any) {
        super(props);

        this.state = {
            currentYear: new Date().getFullYear()
        }
    }

    render() {

        return (
            <div className='footer'>
                <div className='footer-top'>
                    <div className='footer-top-txt'>
                        <a target='_blank' href='https://eupati.eu/contact/'><h5 className='footer-top-txt-title'>Contact Us</h5></a>
                    </div>
                    {/* <Nav className='footer-top-menu'>
                        <li className='nav-item'>
                            <NavLink className='nav-link' target='_blank' href='https://eupati.eu/contact/'>Contact</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' target='_blank' href='https://eupati.eu/donate/'>Donate</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' target='_blank' href='https://collaborate.eupati.eu/'>Collaborate</NavLink>
                        </li>
                    </Nav> */}
                </div>
                <Navbar className='footer-bottom'>
                    <span>{`\u00A9 Copyright EUPATI ${this.state.currentYear}`}</span>
                    <Nav className='footer-bottom-menu'>
                        <li className='nav-item'>
                            <NavLink className='nav-link' href='/terms'>Terms & Conditions</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' href='/privacy'>Privacy</NavLink>
                        </li>
                        <li className='nav-item'>
                            <NavLink className='nav-link' href='/faq'>FAQ</NavLink>
                        </li>
                        {/* <li className='nav-item'>
                            <NavLink className='nav-link' target='_blank' href='https://eupati.eu/cookies/'>Cookies</NavLink>
                        </li> */}
                    </Nav>
                </Navbar>
            </div>
        )
    }
}