import { defaultEmailTemplate, State } from './model';
import * as t from './actionTypes';
import { EmailTemplateData, LookUpData } from '../../../common/models/input-models';
import { Patient, Project } from '../../../common/models/main-models';
import { emptyProject } from '../../new-project/model';

const initialState: State = {
    projects: [],
    lookUpData: [],
    isLoading: false,
    isProjectsLoading: false,
    filter: 1,
    matchedPatients: [],
    isMatchedPatientsLoading: false,
    project: emptyProject,
    isProjectLoading: false,
    emailTemplates: [],
    singleEmailTemplate: defaultEmailTemplate,
    emailTemplateLoading: false
};

export default (state = initialState, action: any): State => {
    switch (action.type) {
        case t.SET_LOADING:
            return setLoading(state, action.payload);
        case t.SET_LOOKUP_DATA:
            return setLookUpData(state, action.payload);
        case t.SET_PROJECTS_LOADING:
            return setProjectsLoading(state, action.payload);
        case t.SET_PROJECTS:
            return setProjects(state, action.payload);
        case t.SET_PROJECTS_FILTER:
            return setProjectsFilter(state, action.payload);
        case t.SET_MATCHED_PATIENTS:
            return setMatchedPatients(state, action.payload);
        case t.SET_MATCHED_PATIENTS_LOADING:
            return setMatchedPatientsLoading(state, action.payload);
        case t.SET_PROJECT_DATA:
            return setProjectData(state, action.payload);
        case t.SET_PROJECTS_LOADING:
            return setProjectLoading(state, action.payload);
        case t.SET_EMAIL_TEMPLATES:
            return setEmailTemplates(state, action.payload);
        case t.SET_SINGLE_EMAIL_TEMPLATE:
            return setSingleEmailTemplate(state, action.payload);
        case t.SET_TEMPLATES_LOADING:
            return setEmailTemplateLoading(state, action.payload);
        default:
            return state;
    }
}

const setLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isLoading: val
    } as State;

    return newState;
}

const setLookUpData = (state: State, val: LookUpData[]): State => {
    let newState = {
        ...state,
        lookUpData: [...val]
    } as State;

    return newState;
}

const setProjectsLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isProjectsLoading: val
    } as State;

    return newState;
}

const setProjects = (state: State, val: Project[]): State => {
    let newState = {
        ...state,
        projects: [...val]
    } as State;

    return newState;
}

const setProjectsFilter = (state: State, val: number): State => {
    let newState = {
        ...state,
        filter: val
    } as State;

    return newState;
}

const setMatchedPatients = (state: State, val: Patient[]): State => {
    let newState = {
        ...state,
        matchedPatients: [...val]
    } as State;

    return newState;
}

const setMatchedPatientsLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isMatchedPatientsLoading: val
    } as State;

    return newState;
}

const setProjectData = (state: State, val: Project): State => {
    let newState = {
        ...state,
        project: { ...val }
    } as State;

    return newState;
}

const setProjectLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isProjectLoading: val
    } as State;

    return newState;
}

const setEmailTemplates = (state: State, val: EmailTemplateData[]): State => {
    let newState = {
        ...state,
        emailTemplates: [...val]
    } as State;

    return newState;
}

const setSingleEmailTemplate = (state: State, val: EmailTemplateData): State => {
    let newState = {
        ...state,
        singleEmailTemplate: { ...val }
    } as State;

    return newState;
}

const setEmailTemplateLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        emailTemplateLoading: val
    } as State;

    return newState;
}