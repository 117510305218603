export const requiredMessage = ({
    required: "This field is required"
});

export const selectOptionMessage = ({
    required: "It is required to select an option"
});

export const notRequired = ({
    required: false
});