import { NAME } from './constants';

export const SET_EMAIL_TEMPLATES_LOADING = `${NAME}/SET_EMAIL_TEMPLATES_LOADING`;

export const SET_EMAIL_TEMPLATE_DATA = `${NAME}/SET_EMAIL_TEMPLATE_DATA`;

export const SET_SINGLE_EMAIL_TEMPLATE_LOADING = `${NAME}/SET_SINGLE_EMAIL_TEMPLATE_LOADING`;

export const SET_SINGLE_EMAIL_TEMPLATE_DATA = `${NAME}/SET_SINGLE_EMAIL_TEMPLATE_DATA`;

export const SET_PROJECT_MODEL_PROPS_NAMES = `${NAME}/SET_PROJECT_MODEL_PROPS_NAMES`;

export const SET_PATIENT_MODEL_PROPS_NAMES = `${NAME}/SET_PATIENT_MODEL_PROPS_NAMES`;

export const SET_TRIGGERS_MENU_OPTIONS = `${NAME}/SET_TRIGGERS_MENU_OPTIONS`;

export const SET_TRIGGERS_MENU_OPTIONS_LOADING = `${NAME}/SET_TRIGGERS_MENU_OPTIONS_LOADING`;

export const SET_RECEIVERS_MENU_OPTIONS = `${NAME}/SET_RECEIVERS_MENU_OPTIONS`;

export const SET_CHOOSEN_RECEIVERS = `${NAME}/SET_CHOOSEN_RECEIVERS`;