import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import EupatiTextbox from '../textbox';
import EupatiPatientsGrid from '../matched-patients-grid';
import EupatiArrowsMenu from '../arrows-menu';
import EupatiLoader from '../loading-animation/loader';
import { LookUpData, PatientSearchData, ProjectPatientData, EmailTemplateData } from '../../models/input-models';
import './manual-search.scss';
import EupatiEmailbox from '../emailbox';
import EupatiDropdown from '../dropdown';
import EupatiDiseaseAsyncSelect from '../async-select-menu';
import EupatiMultiselect from '../multiselect';
import { Patient, Project } from '../../models/main-models';
import { DiseaseInfo } from '../../models/main-models';

type OwnProps = {
    projectId: string,
    displayStylesProp: string,
    isPatientProfileClicked: boolean,
    lookUpData: LookUpData[],
    projectName: string,
    matchedPatients: Patient[],
    isMatchedPatientsLoading: boolean,
    project: Project,
    emailTemplates: EmailTemplateData[],
    singleEmailTemplate: EmailTemplateData;
    emailTemplateLoading: boolean;
};

type DispatchProps = {
    toggleComponents: (val: string) => void;
    patientProfileBtnHandler: () => void;
    backToProjectProfile: () => void;
    setMatchedPatients: (searchData: PatientSearchData, projectName: string) => void;
    removePatient: (inputModel: ProjectPatientData) => void;
    addPatient: (inputModel: ProjectPatientData, searchData: PatientSearchData, projectName: string) => void;
    changePatientStatus: (inputModel: ProjectPatientData) => void;
    getAllEmailTemplates: () => void;
    getEmailTemplateByName: (name: string) => void;
};

type Props = OwnProps & DispatchProps;

type SubmitData = {
    pocFirstName: string,
    pocLastName: string,
    email: string,
    country: number,
    diseases: DiseaseInfo[],
    patientExpertises: number[]
};

export default function EupatiManualMatch(props: Props) {

    const { handleSubmit, control, watch, formState: { errors } } = useForm();

    const [isSubmitBtnClicked, setIsSubmitBtnClicked] = useState<boolean>(false);
    const [isPatientProfileClicked, setIsPatientProfileClicked] = useState<boolean>(false);
    const [ptSearchData, setPtSearchData] = useState({});

    const onSubmit = (data: SubmitData) => {
        setIsSubmitBtnClicked(true);

        const patientSearchData = {
            firstName: data.pocFirstName === undefined ? '' : data.pocFirstName,
            lastName: data.pocLastName === undefined ? '' : data.pocLastName,
            email: data.email === undefined ? '' : data.email,
            countryId: data.country === undefined ? null : data.country,
            diseases: data.diseases === undefined ? [] : data.diseases,
            patientExpertises: data.patientExpertises === undefined ? [] : data.patientExpertises
        } as PatientSearchData;

        setPtSearchData(patientSearchData);
        updateSearchPatients(patientSearchData);
    }

    function updateSearchPatients(patientSearchData: PatientSearchData) {
        props.setMatchedPatients(patientSearchData, props.projectName);
    }

    function findLookUpData(lookUpDataType?: string) {
        if (props.lookUpData &&
            props.lookUpData.length > 0) {
            return props.lookUpData?.filter((x: any) => x.name == lookUpDataType)[0]?.data;
        }

        return [];
    }

    function backToManualSearch() {
        setIsPatientProfileClicked(false);
    }

    function patientProfileBtnHandler() {
        setIsPatientProfileClicked(true);
    }

    let loadingComp = (
        <EupatiLoader />
    )

    let patientsGridComp = (
        <EupatiPatientsGrid
            {...{
                matchedPatients: props.matchedPatients,
                lookUpData: props.lookUpData,
                emailTemplates: props.emailTemplates,
                singleEmailTemplate: props.singleEmailTemplate,
                emailTemplateLoading: props.emailTemplateLoading,
                backToProjectProfile: backToManualSearch,
                isPatientProfileClicked: isPatientProfileClicked,
                patientProfileBtnHandler: patientProfileBtnHandler,
                toggleComponents: props.toggleComponents,
                leftArrowText: 'manual search',
                rightArrowText: 'assign to project',
                isForwardArrowAvail: true,
                leftBtnText: 'profile',
                rightBtnText: 'assign',
                projectId: props.projectId,
                updateSearchPatients,
                patientSearchData: ptSearchData,
                projectName: props.projectName,
                addPatient: props.addPatient,
                removePatient: props.removePatient,
                isManualSearchBtnClicked: true,
                project: props.project,
                changePatientStatus: props.changePatientStatus,
                isConfirmed: true,
                getAllEmailTemplates: props.getAllEmailTemplates,
                getEmailTemplateByName: props.getEmailTemplateByName
            }}
        />
    )

    if (props.isMatchedPatientsLoading) {
        patientsGridComp = loadingComp;
    }

    return (
        <>
            <Form className='manual' onSubmit={handleSubmit(onSubmit)} style={{ display: `${props.displayStylesProp}` }}>
                <EupatiArrowsMenu
                    {...{
                        displayStylesProp: props.displayStylesProp,
                        leftArrowText: 'project details',
                        rightArrowText: props.projectName,
                        backBtnHandler: props.backToProjectProfile,
                        //forwardBtnHandler: props.backToProjectProfile,
                        isForwardArrowAvail: false
                    }}
                />
                <Form.Group style={{ display: 'flex', justifyContent: 'center' }}>
                    <h4 style={{ margin: 'auto auto 50px auto' }} >Current project - {props.projectName.toUpperCase()}</h4>
                </Form.Group>
                <Form.Group className='manual-info'>
                    <Form.Group className='lcolumn'>
                        <Form.Group className='manual-info-field'>
                            <Form.Label>First name</Form.Label>
                            <Controller
                                control={control}
                                name='pocFirstName'
                                render={({ field }) =>
                                    <EupatiTextbox {...{ ...field, errorMsg: errors?.pocFirstName?.message, placeholder: 'Please type first name' }} />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='manual-info-field'>
                            <Form.Label>Surname</Form.Label>
                            <Controller
                                control={control}
                                name='pocLastName'
                                render={({ field }) =>
                                    <EupatiTextbox {...{ ...field, errorMsg: errors?.pocLastName?.message, placeholder: 'Please type last name' }} />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='manual-info-field'>
                            <Form.Label>Email</Form.Label>
                            <Controller
                                control={control}
                                name='email'
                                render={({ field }) =>
                                    <EupatiEmailbox {...{ ...field, errorMsg: errors?.email?.message }} />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='manual-info-field'>
                            <Form.Label>Therapeutic Areas or Diseases</Form.Label>
                            <Controller
                                name='diseases'
                                control={control}
                                render={({ field }) =>
                                    <EupatiDiseaseAsyncSelect
                                        {...{
                                            ...field,
                                            errorMsg: errors?.diseases?.message
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    </Form.Group>

                    <Form.Group className='rcolumn'>

                        <Form.Group className='manual-info-field mb-4'>
                            <Form.Label>Country</Form.Label>
                            <Controller
                                control={control}
                                name='country'
                                render={({ field }) =>
                                    <EupatiDropdown
                                        {...{
                                            ...field,
                                            errorMsg: errors?.country?.message,
                                            fieldName: 'countries',
                                            values: findLookUpData('country'),
                                            defaultOptionText: 'Please select country...'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='manual-info-field'>
                            <Form.Label>Expertises (multiple answers possible)</Form.Label>
                            <Controller
                                control={control}
                                name='patientExpertises'
                                render={({ field }) =>
                                    <EupatiMultiselect
                                        {...{
                                            ...field,
                                            errorMsg: errors?.patientExpertises?.message,
                                            fieldName: 'patientExpertises',
                                            values: findLookUpData('expertise')
                                        }} />
                                }
                            />
                        </Form.Group>

                    </Form.Group>
                </Form.Group>
                <Form.Group id='manual-btn' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button className='sbmt' variant="primary" type='submit'>SEARCH</Button>
                </Form.Group>
            </Form>
            {isSubmitBtnClicked &&
                <>
                    {patientsGridComp}
                </>
            }
        </>
    );
}
