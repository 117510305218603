// matching/actions.js
import { Res } from '../../common/helpers';
import { LookUpData } from '../../common/models/input-models';
import { Project } from '../../common/models/main-models';
import DataSvc from '../../common/services/data-svc';
import * as t from './actionTypes';

export const setProjectLoading = (isLoading: boolean) => ({
    type: t.SET_PROJECTS_LOADING,
    payload: isLoading,
});

export const setProject = (project: Project) => ({
    type: t.SET_PROJECT,
    payload: project
});

export const setLookUpData = (data: LookUpData[]) => ({
    type: t.SET_LOOKUP_DATA,
    payload: data
});

export const setIsUserReal = (isUserReal: boolean) => ({
    type: t.SET_IS_USER_REAL,
    payload: isUserReal
});

export const getLookUpDataAsync = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setProjectLoading(true));
        DataSvc.utilsSvc.getLookUpData().then(
            (result: any) => {
                dispatch(setLookUpData(result));
                dispatch(setProjectLoading(false));
            },
            (error) => { console.error(error); }
        );
    };
};

export const saveNewProjectAsync = (newProject: Project) => {
    return (dispatch: any, getState: any) => {
        dispatch(setProjectLoading(true));
        DataSvc.projectsSvc.saveNewProject(newProject);
        dispatch(setProjectLoading(false));
    };
};

export const verifyUserTokenAsync = (token: string) => {
    return (dispatch: any, getState: any) => {
        DataSvc.utilsSvc.verifyUserByRecaptchaToken({ recaptchaToken: token }).then(
            (result: any) => {
                if (result !== Res.success) {
                    dispatch(setIsUserReal(false));
                }
            },
            (error) => { console.error(error); }
        );
    }
}