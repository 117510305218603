// model.js
// This is the model of our module state (e.g. return type of the reducer)

import { LookUpData, MeshData } from "../../common/models/input-models";
import { Project } from "../../common/models/main-models";

export type State = {
    project: Project;
    isLoading: boolean;
    lookUpData: LookUpData[];
    isUserReal: boolean;
};

export const emptyProject = {
    id: '',
    name: '',
    overview: '',
    projectTypeId: 0,
    projectActivityId: 0,
    projectNumberOfPatientsNeeded: 0,
    interactionTypeId: 0,
    roleAndExpectations: '',
    startDate: undefined,
    endDate: undefined,
    timeRequired: '',
    isCompensationProvided: null as any,
    compensation: '',
    isThereTravelReimbursement: null as any,
    equipmentRequirements: '',
    responseDeadline: undefined,
    diseases: [],
    noDiseaseExpertiseRequired: false,
    searchDiseaseTherapeuticArea: false,
    price: -1,
    plan: '',
    package: '',
    paymentMethod: '',
    // diseaseNames: [],
    // diseasesTreeIds: [],
    // diseaseMeshId: '',
    // diseaseTANames: '',
    //diseaseTAMeshId: '',
    patientExpertises: [],
    patientExpertisesDescription: '',
    countries: [],
    languages: [],
    otherInformation: '',
    pocFirstName: '',
    projectTypeDescription: '',
    pocLastName: '',
    pocOrganisation: '',
    pocOrganisationTypeId: 0,
    pocOrganisationTypeDescription: '',
    pocJobTitle: '',
    pocCountryId: 0,
    pocPhoneNumber: '',
    pocEmail: '',
    isGDPRConsentGiven: false,
    matchedPatients: [],
    confirmedPatients: []
} as Project;