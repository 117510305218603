import * as Actions from './actions';
import * as Components from './components/index';
import * as Containers from './containers/index';
import * as Constants from './constants';
import Reducer from './reducer';

export default {
    actions: Actions,
    components: Components,
    containers: Containers,
    constants: Constants,
    reducer: Reducer
};

export const Root = Containers.ComponentRoot;