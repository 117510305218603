import { ActionsTemplateData, EmailAutomationTriggersMenu, EmailTemplateData, LookUpOptions, ModelPropsNames } from '../../../common/models/input-models';
import DataSvc from '../../../common/services/data-svc';
import * as t from './actionTypes';
import { emptyTemplate } from './models';

export const setIsEmailTemplatesLoading = (isEmailTemplatesLoading: boolean) => ({
    type: t.SET_EMAIL_TEMPLATES_LOADING,
    payload: isEmailTemplatesLoading
});

export const setEmailTemplatesData = (emailTemplatesData: EmailTemplateData[]) => ({
    type: t.SET_EMAIL_TEMPLATE_DATA,
    payload: [...emailTemplatesData]
});

export const setIsSingleEmailTemplateLoading = (isSingleEmailTemplateLoading: boolean) => ({
    type: t.SET_SINGLE_EMAIL_TEMPLATE_LOADING,
    payload: isSingleEmailTemplateLoading
});

export const setSingleEmailTemplateData = (emailTemplateData: EmailTemplateData) => ({
    type: t.SET_SINGLE_EMAIL_TEMPLATE_DATA,
    payload: { ...emailTemplateData }
});

export const setProjectModelPropsNames = (projectModelPropsNames: ModelPropsNames[]) => ({
    type: t.SET_PROJECT_MODEL_PROPS_NAMES,
    payload: [...projectModelPropsNames]
});

export const setPatientModelPropsNames = (patientModelPropsNames: ModelPropsNames[]) => ({
    type: t.SET_PATIENT_MODEL_PROPS_NAMES,
    payload: [...patientModelPropsNames]
});

export const setTriggersMenuOptions = (emailAutomationTriggers: EmailAutomationTriggersMenu[]) => ({
    type: t.SET_TRIGGERS_MENU_OPTIONS,
    payload: [...emailAutomationTriggers]
});

export const setTriggersMenuOptionsLoading = (isTriggersMenuOptionsLoading: boolean) => ({
    type: t.SET_TRIGGERS_MENU_OPTIONS_LOADING,
    payload: isTriggersMenuOptionsLoading
});

export const setReceiversMenuOptions = (receiversMenuOptions: LookUpOptions[]) => ({
    type: t.SET_RECEIVERS_MENU_OPTIONS,
    payload: [...receiversMenuOptions]
});

export const setChoosenReceivers = (choosenReceivers: number[]) => ({
    type: t.SET_CHOOSEN_RECEIVERS,
    payload: [...choosenReceivers]
});

export const setAllEmailTemplatesAsync = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setIsEmailTemplatesLoading(true));
        DataSvc.utilsSvc.getAllEmailTemplates().then(
            (result: any) => {
                dispatch(setEmailTemplatesData(result));
                dispatch(setIsEmailTemplatesLoading(false));
            },
            (error) => { console.error(error); }
        );
    };
};

export const setEmailTemplateByIdAsync = (id?: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setIsSingleEmailTemplateLoading(true));
        switch (id) {
            case undefined:
                dispatch(setSingleEmailTemplateData(emptyTemplate));
                dispatch(setIsSingleEmailTemplateLoading(false));
                break;
            default:
                DataSvc.utilsSvc.getEmailTemplate(id).then(
                    (result: any) => {
                        dispatch(setSingleEmailTemplateData(result));
                        dispatch(setIsSingleEmailTemplateLoading(false));
                        //dispatch(setEmailTriggerTemplateBeneficiariesAsync(id));
                    },
                    (error) => { console.error(error); }
                );
                break;
        }
    };
};

export const createNewEmailTemplateAsync = (emailTemplateData: EmailTemplateData) => {
    return (dispatch: any, getState: any) => {
        dispatch(setIsSingleEmailTemplateLoading(true));
        DataSvc.utilsSvc.addEmailTemplate(emailTemplateData).then(
            (result: any) => {
                dispatch(setIsSingleEmailTemplateLoading(false));
                dispatch(setSingleEmailTemplateData(emptyTemplate));
                dispatch(setAllEmailTemplatesAsync());
            },
            (error) => { console.error(error); }
        )
    };
};

export const updateEmailTemplateDataAsync = (emailTemplateData: EmailTemplateData) => {
    return (dispatch: any, getState: any) => {
        dispatch(setIsSingleEmailTemplateLoading(true));
        DataSvc.utilsSvc.alterEmailTemplateData(emailTemplateData).then(
            (result: any) => {
                dispatch(setIsSingleEmailTemplateLoading(false));
                dispatch(setSingleEmailTemplateData(emptyTemplate));
                dispatch(setAllEmailTemplatesAsync());
            },
            (error) => { console.error(error); }
        )
    };
};

export const removeEmailTemplateDataAsync = (id: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setIsSingleEmailTemplateLoading(true));
        DataSvc.utilsSvc.getEmailTemplate(id).then(
            (result: any) => {
                DataSvc.utilsSvc.removeEmailTemplate(result).then(
                    (result: any) => {
                        dispatch(setAllEmailTemplatesAsync());
                        dispatch(setIsSingleEmailTemplateLoading(false));
                    },
                    (error) => { console.error(error); }
                )
            },
            (error) => { console.error(error); }
        )
    };
};

export const setModelsPropsNamesAsync = (id: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setIsSingleEmailTemplateLoading(true));
        DataSvc.utilsSvc.getClassPropertiesNames({ id: id }).then(
            (result: any) => {
                const projectProps = result.find((x: any) => x.name === 'project');
                dispatch(setProjectModelPropsNames(projectProps.items));
                const patientsProps = result.find((x: any) => x.name === 'patient');
                dispatch(setPatientModelPropsNames(patientsProps.items));
                dispatch(setIsSingleEmailTemplateLoading(false));
            },
            (error) => { console.error(error); }
        )
    };
};

export const setTriggersMenuOptionsAsync = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setTriggersMenuOptionsLoading(true));
        DataSvc.utilsSvc.getEmailAutomationTriggers().then(
            (result: any) => {
                dispatch(setTriggersMenuOptions(result));
                dispatch(setTriggersMenuOptionsLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}

export const assignActionsToEmailTemplateAsync = (data: ActionsTemplateData) => {
    return (dispatch: any, getState: any) => {
        DataSvc.utilsSvc.assignActionsToEmailTemplate(data).then(
            () => {
                dispatch(setAllEmailTemplatesAsync())
            },
            (error) => { console.error(error); }
        )
    }
}

export const misallocateActionsFromEmailTemplateAsync = (data: ActionsTemplateData) => {
    return (dispatch: any, getState: any) => {
        dispatch(setIsSingleEmailTemplateLoading(true));
        DataSvc.utilsSvc.misallocateActionsFromEmailTemplate(data).then(
            (result: any) => {
                dispatch(setEmailTemplateByIdAsync(data.emailTemplateId));
            },
            (error) => { console.error(error); }
        )
    }
}

export const setReceiversMenuOptionsAsync = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setTriggersMenuOptionsLoading(true));
        DataSvc.utilsSvc.getLookUpData().then(
            (result: any) => {
                dispatch(setReceiversMenuOptions(result.find((x: any) => x.name === "receiverType").data));
                dispatch(setTriggersMenuOptionsLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}

export const setEmailTriggerTemplateBeneficiariesAsync = (id: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setIsSingleEmailTemplateLoading(true));
        DataSvc.utilsSvc.getEmailTriggerTemplateBeneficiaries(id).then(
            (result: any) => {
                dispatch(setChoosenReceivers(result));
                dispatch(setIsSingleEmailTemplateLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}