import { default as RootComponent, DispatchProps } from '../components/component-root';
import * as Actions from '../actions';
import * as Constants from '../constants';
import { State } from '../model';
import { connect } from 'react-redux';
import { Project } from '../../../common/models/main-models';

export function mapStateToProps(globalState: any) {
    let name = Constants.NAME;
    let pageState = globalState[name] as State;
    return pageState;
}

export function mapDispatchToProps(dispatch: any) {
    return {
        setProject: (project: Project) => dispatch(Actions.setProject(project)),
        setLookUpData: () => dispatch(Actions.getLookUpDataAsync()),
        submitNewProject: (project: Project) => dispatch(Actions.saveNewProjectAsync(project)),
        setIsUserReal: (token: string) => dispatch(Actions.verifyUserTokenAsync(token))
    } as DispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(RootComponent);