import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import './static.scss';
import { ProjectPatientData } from '../models/input-models';
import DataSvc from '../services/data-svc';
import { toast } from 'react-toastify';
import { DEL_PT_FROM_PROJ_MSG, ADD_PT_TO_PROJ_MSG } from '../helpers/messages';

type OwnProps = {
    leftArrowText: string,
    displayStylesProp: string,
    isForwardArrowAvail: boolean
    patientId?: string,
    projectId?: string,
    rightArrowText?: string,
    forwardBtnHandler?: Function,
}

type DispatchProps = {
    backBtnHandler: () => void;
}

type Props = OwnProps & DispatchProps;

export default function EupatiArrowsMenu(props: Props) {

    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
    const [btnCursorProps, setBtnCursorProps] = useState<string>('');

    function returnProperIconType(icon: any) {
        return icon as IconProp;
    }

    function removePatientFromProject(inputModel: any) {
        DataSvc.projectsSvc.removePatientFromProject(inputModel).then(
            (result: any) => {
                setIsBtnDisabled(true);
                setBtnCursorProps('not-allowed');
                toast.success(DEL_PT_FROM_PROJ_MSG);
            },
            (error) => { console.error(error); }
        )
    }

    function assignPatientFromProject(inputModel: any) {
        DataSvc.projectsSvc.assignPatientToProject(inputModel).then(
            (result: any) => {
                setIsBtnDisabled(true);
                setBtnCursorProps('not-allowed');
                toast.success(ADD_PT_TO_PROJ_MSG);
            },
            (error) => { console.error(error); }
        )
    }

    function rightArrowBtn() {
        let inputModel = {
            projectId: props.projectId,
            patientId: props.patientId
        } as ProjectPatientData;

        if (typeof inputModel.projectId === 'undefined' &&
            typeof inputModel.patientId === 'undefined') {
            if (props.forwardBtnHandler) { props.forwardBtnHandler(); } // redirect to manual search
        }
        else {
            if (props?.rightArrowText?.toLowerCase().includes('remove')) {
                removePatientFromProject(inputModel);
            } else if (props?.rightArrowText?.toLowerCase().includes('assign')) {
                assignPatientFromProject(inputModel);
            } else if (props?.rightArrowText?.toLowerCase().includes('edit')) {
                //TODO: edit patient info...
                if (props.forwardBtnHandler) { props?.forwardBtnHandler(); }
            }
        }
    }

    return (
        <Form.Group className='arrows-menu' style={{ display: `${props.displayStylesProp}` }}>
            <Form.Group className='left-arrow-container' onClick={props.backBtnHandler}>
                <FontAwesomeIcon
                    icon={returnProperIconType(faArrowLeft)}
                    className='left-arrow-container-icon'
                />
                <span className='left-arrow-container-text'>{props.leftArrowText.toUpperCase()}</span>
            </Form.Group>
            <Form.Group className='right-arrow-container' style={!props.isForwardArrowAvail ? { visibility: 'hidden' } : { visibility: 'visible' }} onClick={rightArrowBtn}>
                <Button style={{ cursor: btnCursorProps }} disabled={isBtnDisabled} variant='warning' className='right-arrow-container-btn'>{props?.rightArrowText?.toUpperCase()}</Button>
            </Form.Group>
        </Form.Group>
    );
}
