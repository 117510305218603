import React from "react";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import EupatiTextbox from "../../../../common/static/textbox";
import EupatiNumberbox from "../../../../common/static/numberbox";
import EupatiDropdown from "../../../../common/static/dropdown";
import EupatiTextarea from "../../../../common/static/textarea";
import { Rules } from "../../../../common/helpers";
import { Messages } from "../../../../common/helpers";
import { useHistory } from "react-router";
import EupatiToggleBtn from "../../../../common/static/toggle-btn";
import EupatiDateMenu from "../../../../common/static/date-menu";
import { Project } from "../../../../common/models/main-models";
import { LookUpData, LookUpOptions } from "../../../../common/models/input-models";
import { toast } from 'react-toastify';

type OwnProps = {
    project: Project,
    isUserReal: boolean
};

type DispatchProps = {
    prevPage: () => void;
    setProject: (project: Project) => void;
    showPatientsInfoSection: () => void;
    returnProperDefaultValue: (val: any) => any;
    returnProperLookUpData: (dataType: any) => LookUpOptions[] | null;
};

type Props = OwnProps & DispatchProps;

type FieldProps = {
    name: string,
    projectTypeId: number,
    projectTypeDescription: string,
    roleAndExpectations: string,
    projectActivityId: number,
    projectNumberOfPatientsNeeded: number,
    interactionTypeId: number,
    startDate?: Date,
    endDate?: Date,
    responseDeadline?: Date
};

export default function ProjectGeneralInfo(props: Props) {

    const history = useHistory();

    const { handleSubmit, control, watch, reset, formState: { errors } } = useForm();

    const fieldsData = {
        name: watch("name") as string,
        projectTypeId: watch("projectTypeId") as number,
        projectTypeDescription: watch("projectTypeDescription") as string,
        roleAndExpectations: watch("roleAndExpectations") as string,
        projectActivityId: watch("projectActivityId") as number,
        projectNumberOfPatientsNeeded: watch("projectActivityId") as number,
        interactionTypeId: watch("interactionTypeId") as number,
        startDate: watch("startDate") as Date | undefined,
        endDate: watch("endDate") as Date | undefined,
        responseDeadline: watch("responseDeadline") as Date | undefined
    } as FieldProps;

    const onSubmit = (data: any) => {
        if (!props.isUserReal) {
            toast.error(Messages.FAIL_AUTH_MSG);
            return history.push('/');
        }

        const updatedProject = Object.assign(props.project, data);
        props.setProject(updatedProject);
        props.showPatientsInfoSection();
    }

    const onBackHandler = () => {
        if (!props.isUserReal) {
            toast.error(Messages.FAIL_AUTH_MSG);
            return history.push('/');
        }

        if (adjustFieldsValues() === 0) {
            const updatedProject = Object.assign(props.project, fieldsData);
            props.setProject(updatedProject);
        }
        props.prevPage();
    }

    const toggleField = () => {
        let projectId = 0;
        if (fieldsData.projectTypeId !== undefined && fieldsData.projectTypeId !== null) {
            projectId = fieldsData.projectTypeId;
        }
        else if (props.project.projectTypeId !== undefined && props.project.projectTypeId !== null) {
            projectId = props.project.projectTypeId;
        }

        const projectTypes = props.returnProperLookUpData('projectType');
        return projectTypes?.find(x => x.id.toString() === projectId.toString())?.name.toLowerCase().includes('other');
    }

    const adjustFieldsValues = () => {
        if (fieldsData.name === undefined &&
            fieldsData.projectTypeId === undefined &&
            fieldsData.projectTypeDescription === undefined &&
            fieldsData.roleAndExpectations === undefined &&
            fieldsData.projectActivityId === undefined &&
            fieldsData.interactionTypeId === undefined &&
            fieldsData.startDate === undefined &&
            fieldsData.endDate === undefined &&
            fieldsData.responseDeadline === undefined) {
            return 1;
        }

        if (fieldsData.name === undefined &&
            props.project.name !== undefined) {
            fieldsData.name = props.project.name;
        }

        if (fieldsData.projectTypeId === undefined &&
            props.project.projectTypeId !== undefined) {
            fieldsData.projectTypeId = props.project.projectTypeId;
        }

        if (fieldsData.projectTypeDescription === undefined &&
            props.project.projectTypeDescription !== undefined) {
            fieldsData.projectTypeDescription = props.project.projectTypeDescription;
        }

        if (fieldsData.roleAndExpectations === undefined &&
            props.project.roleAndExpectations !== undefined) {
            fieldsData.roleAndExpectations = props.project.roleAndExpectations;
        }

        if (fieldsData.projectActivityId === undefined &&
            props.project.projectActivityId !== undefined) {
            fieldsData.projectActivityId = props.project.projectActivityId;
        }

        if (fieldsData.projectNumberOfPatientsNeeded === undefined &&
            props.project.projectNumberOfPatientsNeeded !== undefined) {
            fieldsData.projectNumberOfPatientsNeeded = props.project.projectNumberOfPatientsNeeded;
        }

        if (fieldsData.interactionTypeId === undefined &&
            props.project.interactionTypeId !== undefined) {
            fieldsData.interactionTypeId = props.project.interactionTypeId;
        }

        if (fieldsData.startDate === undefined &&
            props.project.startDate !== undefined) {
            fieldsData.startDate = props.project.startDate;
        }

        if (fieldsData.endDate === undefined &&
            props.project.endDate !== undefined) {
            fieldsData.endDate = props.project.endDate;
        }

        if (fieldsData.responseDeadline === undefined &&
            props.project.responseDeadline !== undefined) {
            fieldsData.responseDeadline = props.project.responseDeadline;
        }

        return 0;
    }

    return (
        <Form className='project' onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='project-info'>
                <Form.Group className='lcolumn'>

                    <Form.Group id='p-name' className='project-info-field mb-3'>
                        <Form.Label>Project name</Form.Label>
                        <Controller
                            name='name'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.name)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.name?.message,
                                        placeholder: 'Please type project name'
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='p-projTp' className='project-info-field mb-3'>
                        <Form.Label>Project type</Form.Label>
                        <Controller
                            name='projectTypeId'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.projectTypeId)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiDropdown
                                    {...{
                                        ...field,
                                        errorMsg: errors?.projectTypeId?.message,
                                        fieldName: 'projectTypeId',
                                        values: props.returnProperLookUpData('projectType'),
                                        defaultOptionText: ''
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    {toggleField() &&
                        <Form.Group id='p-projTpDescr' className='project-info-field mb-3'>
                            <Form.Label>Other project type description</Form.Label>
                            <Controller
                                name='projectTypeDescription'
                                control={control}
                                defaultValue={props.returnProperDefaultValue(props.project.projectTypeDescription)}
                                rules={Rules.notRequired}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.projectTypeDescription?.message,
                                            placeholder: 'Please type project type'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    }

                    <Form.Group id='p-roleAndExpctons' className='project-info-field mb-3'>
                        <Form.Label>Patients role and expectations</Form.Label>
                        <Controller
                            name='roleAndExpectations'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.roleAndExpectations)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextarea
                                    {...{
                                        ...field,
                                        errorMsg: errors?.roleAndExpectations?.message
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='p-projAct' className='project-info-field mb-4'>
                        <Form.Label>Activity</Form.Label>
                        <Controller
                            name='projectActivityId'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.projectActivityId)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiToggleBtn
                                    {...{
                                        ...field,
                                        fieldName: 'projectActivityId',
                                        values: props.returnProperLookUpData('activityType'),
                                        errorMsg: errors?.projectActivityId?.message
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='p-intractnTp' className='project-info-field mb-4'>
                        <Form.Label>Interaction type</Form.Label>
                        <Controller
                            name='interactionTypeId'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.interactionTypeId)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiToggleBtn
                                    {...{
                                        ...field,
                                        fieldName: 'interactionTypeId',
                                        values: props.returnProperLookUpData('interactionType'),
                                        errorMsg: errors?.interactionTypeId?.message
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='p-projHowManyPatients' className='project-info-field mb-12'>
                        <Form.Label>How many patients are you looking for?</Form.Label>
                        <Controller
                            name='projectNumberOfPatientsNeeded'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.projectNumberOfPatientsNeeded)}
                            rules={Rules.notRequired}
                            render={({ field }) =>
                                <EupatiNumberbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.projectTypeDescription?.message,
                                        placeholder: 'Please enter how many patients you are looking for'
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='p-startDate' className='project-info-field mb-3'>
                        <Form.Label>Start date</Form.Label>
                        <Controller
                            name='startDate'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.startDate)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiDateMenu
                                    {...{
                                        ...field,
                                        errorMsg: errors?.startDate?.message
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='p-endDate' className='project-info-field mb-3'>
                        <Form.Label>End date</Form.Label>
                        <Controller
                            name='endDate'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.endDate)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiDateMenu
                                    {...{
                                        ...field,
                                        errorMsg: errors?.endDate?.message
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='p-resDedln' className='project-info-field mb-3'>
                        <Form.Label>Response deadline</Form.Label>
                        <Controller
                            name='responseDeadline'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.responseDeadline)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiDateMenu
                                    {...{
                                        ...field,
                                        errorMsg: errors?.responseDeadline?.message
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='p-btns' className='project-info-field'>
                        <Button className='bck' variant='secondary' onClick={onBackHandler}>Back</Button>
                        <Button className='nxt' variant='primary' type='submit'>Next</Button>
                    </Form.Group>
                </Form.Group>
            </Form.Group>
        </Form>
    )
}