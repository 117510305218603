// model.js
// This is the model of our module state (e.g. return type of the reducer)

import { LookUpData } from "../../../common/models/input-models";

export type State = {
    isDataSubmitted: boolean;
    lookUpData: LookUpData[];
};

export const ImportProperties = [
    'firstName',
    'lastName',
    'phoneNumber',
    'email',
    'country',
    //'countryCode',
    'condition',
    'gender'
];