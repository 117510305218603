import * as React from 'react';
import EupatiLoader from '../../../../common/static/loading-animation/loader';
import { State } from '../models';
import EupatiRichTextEditor from '../../../../common/static/text-editor';
import { ContentData, ContentResponse } from '../../../../common/models/input-models';
import { RouteComponentProps } from '@reach/router';
import { History } from 'history';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TinyMCEditor from '../../../../common/static/tinymce-editor';

type OwnProps = {
    isContentLoading: boolean,
    contentResponse: ContentResponse,
    history: History
};

export type DispatchProps = {
    getContent: (name: string) => void;
    updateContent: (data: ContentData) => void;
};

type Props = State & DispatchProps & OwnProps & RouteComponentProps<{ location: { state: { pageName: string } } }>;

type OwnState = {};

//raw draft content:
//`{"blocks": [{"key": "fl66k","text": "asdasdasdasd","type": "unstyled","depth": 0,"inlineStyleRanges": [],"entityRanges": [],"data": {}}],"entityMap": {}}`,
export default class ComponentRoot extends React.Component<Props, OwnState> {

    componentDidMount() {
        if (this.props.location?.state.pageName !== undefined) {
            this.props.getContent(this.props.location?.state.pageName);
        }
    }

    render() {

        let mainComp: any;

        let loadComp = (
            <div className='loader-wrapper'>
                <EupatiLoader />
            </div>
        )

        if (this.props.isContentLoading ||
            this.props.location?.state.pageName === undefined) {
            mainComp = loadComp;
        }
        else {
            mainComp = (
                // <EupatiRichTextEditor
                //     {...{
                //         pageName: this.props?.location?.state.pageName !== undefined ? this.props.location?.state.pageName : '',
                //         content: this.props?.contentResponse?.rawDraftContent !== undefined ? this.props.contentResponse.rawDraftContent : '',
                //         updateContent: this.props?.updateContent,
                //         history: this.props.history
                //     }}
                // />
                <TinyMCEditor
                    {...{
                        pageName: this.props?.location?.state.pageName !== undefined ? this.props.location?.state.pageName : '',
                        content: this.props?.contentResponse?.htmlContent !== undefined ? this.props.contentResponse.htmlContent : '',
                        updateContent: this.props?.updateContent
                    }}
                />
            )
        }

        return (
            <>
                {mainComp}
            </>
        )
    }
}