import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Form } from 'react-bootstrap';
import { Rules } from "../../../../common/helpers";
import EupatiNonCMSToggleBtn from "../../../../common/static/toggle-btn-nonCMS";
import EupatiToggleBtn from "../../../../common/static/toggle-btn";
import EupatiGDPRBtn from "../../../../common/static/GDPR-toggle";
import { Patient } from "../../../../common/models/main-models";
import { LookUpOptions } from "../../../../common/models/input-models";

type OwnProps = {
    patient: Patient,
    isNewPatient: boolean
};

type DispatchProps = {
    nextPage: () => void;
    prevPage: () => void;
    savePatient: (patient: Patient) => void;
    hidePatientCompensationReqInfo: () => void;
    setPatientInfo: (patient: Patient) => void;
    returnProperDefaultValue: (val: any) => any;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
    submitNewPatient: (patient: Patient) => void;
};


type Props = OwnProps & DispatchProps;

type FieldProps = {
    isEligibleForCompensation?: boolean,
    isAvailableForNoCompensation?: boolean,
    activityPreferenceId?: number,
    isGDPRConsentGiven: boolean
};

export default function PatientCompensationReqInfo(props: Props) {

    const { handleSubmit, control, watch, formState: { errors } } = useForm();

    const fieldsData = {
        isEligibleForCompensation: watch("isEligibleForCompensation") as boolean | undefined,
        isAvailableForNoCompensation: watch("isAvailableForNoCompensation") as boolean | undefined,
        activityPreferenceId: watch("activityPreferenceId") as number | undefined,
        isGDPRConsentGiven: watch("isGDPRConsentGiven") as boolean,
    } as FieldProps;

    const onSubmit = (data: any) => {
        const editedPatientInfo = Object.assign(props.patient, data);
        switch (props.isNewPatient) {
            case true:
                props.submitNewPatient(editedPatientInfo);
                break;
            case false:
                props.savePatient(editedPatientInfo);
                break;
        }
        props.nextPage();
    }

    function onBackHandler() {
        if (adjustFieldsValues() === 0) {
            const editedPatientInfo = Object.assign(props.patient, fieldsData);
            props.setPatientInfo(editedPatientInfo);
        }
        props.hidePatientCompensationReqInfo();
    }

    const adjustFieldsValues = () => {
        if (fieldsData.isEligibleForCompensation === undefined &&
            fieldsData.isAvailableForNoCompensation === undefined &&
            fieldsData.activityPreferenceId === undefined &&
            fieldsData.isGDPRConsentGiven === undefined) {
            return 1;   // if user hasn't changed anything
        }

        if (fieldsData.isEligibleForCompensation === undefined &&
            props.patient.isEligibleForCompensation !== undefined) {
            fieldsData.isEligibleForCompensation = props.patient.isEligibleForCompensation;
        }

        if (fieldsData.isAvailableForNoCompensation === undefined &&
            props.patient.isAvailableForNoCompensation !== undefined) {
            fieldsData.isAvailableForNoCompensation = props.patient.isAvailableForNoCompensation;
        }

        if (fieldsData.activityPreferenceId === undefined &&
            props.patient.activityPreferenceId !== undefined) {
            fieldsData.activityPreferenceId = props.patient.activityPreferenceId;
        }

        if (fieldsData.isGDPRConsentGiven === undefined &&
            props.patient.isGDPRConsentGiven !== undefined) {
            fieldsData.isGDPRConsentGiven = props.patient.isGDPRConsentGiven;
        }

        return 0;
    }

    return (
        <Form className='requirements' onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='requirements-info'>
                <Form.Group className='rcolumn'>
                    <Form.Group id='pt-compEligbl' className='requirements-info-field mb-3'>
                        <Form.Label>Are you eligible to receive compensation?</Form.Label>
                        <Controller
                            name='isEligibleForCompensation'
                            control={control}
                            defaultValue={props.patient.isEligibleForCompensation !== undefined ? `${props.patient.isEligibleForCompensation}` : ''}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiNonCMSToggleBtn
                                    {...{
                                        ...field,
                                        errorMsg: errors?.isEligibleForCompensation?.message,
                                        fieldName: 'isEligibleForCompensation',
                                        isMoreThanTwoOptions: true
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-noCompAvlbl' className='requirements-info-field mb-3'>
                        <Form.Label>Are you available for voluntary work?</Form.Label>
                        <Controller
                            name='isAvailableForNoCompensation'
                            control={control}
                            defaultValue={props.patient.isAvailableForNoCompensation !== undefined ? `${props.patient.isAvailableForNoCompensation}` : ''}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiNonCMSToggleBtn
                                    {...{
                                        ...field,
                                        errorMsg: errors?.isAvailableForNoCompensation?.message,
                                        fieldName: 'isAvailableForNoCompensation',
                                        isMoreThanTwoOptions: true
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-expcProj' className='requirements-info-field mb-3'>
                        <Form.Label>Preference for an individual or group projects</Form.Label>
                        <Controller
                            name='activityPreferenceId'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.activityPreferenceId)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiToggleBtn
                                    {...{
                                        ...field,
                                        errorMsg: errors?.activityPreferenceId?.message,
                                        fieldName: 'activityPreferenceId',
                                        values: props.findLookUpData('activityType')
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-gdpr' className='requirements-info-field mb-4'>
                        <Controller
                            name='isGDPRConsentGiven'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.isGDPRConsentGiven)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiGDPRBtn
                                    {...{
                                        ...field,
                                        fieldName: 'isGDPRConsentGiven',
                                        errorMsg: errors?.isGDPRConsentGiven?.message
                                    }}
                                />
                            }
                        />
                        <small className='google-message'>
                            This site is protected by reCAPTCHA and the Google
                            <a className='google-message-privacy' href="https://policies.google.com/privacy" target="_blank"> Privacy Policy</a> and
                            <a className='google-message-tos' href="https://policies.google.com/terms" target="_blank"> Terms of Service</a> apply.
                        </small>
                    </Form.Group>

                    <Form.Group id='pt-btns'>
                        <Button className='bck' variant='secondary' type='submit' onClick={onBackHandler}>Back</Button>
                        <Button className='nxt' variant='primary' type='submit'>Submit</Button>
                    </Form.Group>
                </Form.Group>
            </Form.Group>
        </Form>
    )
}