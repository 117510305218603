import { User } from '../models/main-models';
import HttpRequester from './http-requester';

//declare var baseUrl: string;
var baseUrl = '/users';

export default class UsersSvc {
  httpRequester: HttpRequester;

  constructor() {
    this.httpRequester = new HttpRequester('/api');
  }

  getAllAdmins() {
    return this.httpRequester.httpGet(`${baseUrl}/getalladmins`);
  }

  removeAdmin(admin: User) {
    return this.httpRequester.httpPost(`${baseUrl}/removeadmin`, admin);
  }

  approveAdmin(admin: User) {
    return this.httpRequester.httpPost(`${baseUrl}/approveadmin`, admin);
  }

  getAdminById(id: string) {
    return this.httpRequester.httpGet(`${baseUrl}/getadminbyid/?id=${id}`);
  }

  getEupatiUserById(id: string) {
    return this.httpRequester.httpGet(`${baseUrl}/geteupatiuserbyid/?id=${id}`);
  }
}
