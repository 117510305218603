import React from "react";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { Rules } from "../../../../common/helpers";
import EupatiTextbox from "../../../../common/static/textbox";
import EupatiEmailbox from "../../../../common/static/emailbox";
import { Patient } from "../../../../common/models/main-models";

type OwnProps = {
    patient: Patient
};

type DispatchProps = {
    setPatientInfo: (patient: Patient) => void;
    showPatientSpecificInfoSection: () => void;
    returnProperDefaultValue: (val: any) => any;
};

type Props = OwnProps & DispatchProps;

export default function PatientGenericInfo(props: Props) {

    const { handleSubmit, control, watch, formState: { errors } } = useForm();

    const onSubmit = (data: any) => {
        const editedPatientInfo = Object.assign(props.patient, data);
        props.setPatientInfo(editedPatientInfo);
        props.showPatientSpecificInfoSection();
    }

    return (
        <Form className='profile' onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='profile-info'>
                <Form.Group className='lcolumn'>

                    <Form.Group id='pt-fname' className='profile-info-field mb-3'>
                        <Form.Label>First name</Form.Label>
                        <Controller
                            name='firstName'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.firstName)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.firstName?.message,
                                        placeholder: 'Please type first name'
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-lname' className='profile-info-field mb-3'>
                        <Form.Label>Surname</Form.Label>
                        <Controller
                            name='lastName'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.lastName)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.lastName?.message,
                                        placeholder: 'Please type last name'
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-tel' className='profile-info-field mb-3'>
                        <Form.Label>Phone</Form.Label>
                        <Controller
                            name='phoneNumber'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.phoneNumber)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.phoneNumber?.message,
                                        placeholder: 'Please type phone number'
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-year' className='profile-info-field mb-3'>
                        <Form.Label>Year of birth</Form.Label>
                        <Controller
                            name='birthYear'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.birthYear)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.birthYear?.message,
                                        fieldType: 'number',
                                        placeholder: 'Please type year of birth'
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-email' className='profile-info-field mb-3'>
                        <Form.Label>Email</Form.Label>
                        <Controller
                            name='email'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.email)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiEmailbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.email?.message
                                    }}
                                />
                            }
                        />
                    </Form.Group>


                    <Form.Group id='pt-btns'>
                        <Button className='bck' variant='secondary' type='submit' style={{ visibility: 'hidden' }}>Back</Button>
                        <Button className='nxt' variant='primary' type='submit'>Next</Button>
                    </Form.Group>

                </Form.Group>
            </Form.Group>
        </Form>
    )
}