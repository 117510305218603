import { Messages } from '../../../common/helpers';
import { CatalogData } from '../../../common/models/input-models';
import DataSvc from '../../../common/services/data-svc';
import * as t from './actionTypes';

export const setIsCatalogsLoading = (isCatalogsLoading: boolean) => ({
    type: t.SET_IS_CATALOGS_LOADING,
    payload: isCatalogsLoading
});

export const setCatalogsData = (catalogsData: CatalogData[]) => ({
    type: t.SET_CATALOGS_DATA,
    payload: catalogsData
});

export const getCatalogsPricesAsync = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setIsCatalogsLoading(true));
        DataSvc.utilsSvc.getPriceCatalogs().then(
            (result: any) => {
                dispatch(setCatalogsData(result));
                dispatch(setIsCatalogsLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}

export const updateCatalogsPricesAsync = (data: CatalogData[]) => {
    return (dispatch: any, getState: any) => {
        dispatch(setIsCatalogsLoading(true));
        DataSvc.utilsSvc.updatePriceCatalogs(data).then(
            (result: any) => {
                dispatch(getCatalogsPricesAsync());
            },
            (error) => { console.error(error); }
        )
    }
}