import * as React from 'react';
import { Button, Table } from 'react-bootstrap';
import { CatalogData, CategoryData, OptionData } from '../models/input-models';
import { CATALOG_TYPES, CATEGORY_NAMES, CONVERTED_NAMES, OPTION_NAMES } from '../../common/helpers/pricing-list';
import { toast } from 'react-toastify';
import { SAVED_NEW_PRICES_MSG, INVALID_NUMERIC_INPUT, CANCEL_PRICES_UPDATE } from '../helpers/messages';

type OwnProps = {
    catalogsData: CatalogData[]
};

export type DispatchProps = {
    setCatalogsPrices: () => void
    updateCatalogsPrices: (data: CatalogData[]) => void;
};

type Props = DispatchProps & OwnProps;

export default function EupatiPricesGrid(props: Props) {

    const [isEditBtnClicked, setIsEditBtnClicked] = React.useState<boolean>(false);
    const [tempData, setTempData] = React.useState<CatalogData[]>([...props.catalogsData]);

    function onEditRowHandler(e: any) {
        e.preventDefault();
        setIsEditBtnClicked(true);
    }

    function onCancelOperationHandler(e: any) {
        e.preventDefault();
        toast.info(CANCEL_PRICES_UPDATE);
        setTimeout(() => {
            setIsEditBtnClicked(false);
            props.setCatalogsPrices();
        }, 100);
    }

    function onChangeBasePriceHandler(e: any, val: any, categoryIndex: number, catalogIndex: number) {
        e.preventDefault();
        if (isNaN(val.basePrice)) { return toast.error(INVALID_NUMERIC_INPUT); }
        const updatedData = Object.assign(tempData[catalogIndex].categoryOptionsData[categoryIndex], val);
        Object.assign(tempData[catalogIndex].categoryOptionsData[categoryIndex], updatedData);
        setTempData([...tempData]);
    }

    function onChangeOptionsPriceHandler(e: any, val: any, catalogIndex: number, categoryIndex: number, optionIndex: number) {
        e.preventDefault();
        if (isNaN(val.price)) { return toast.error(INVALID_NUMERIC_INPUT); }
        const updatedData = Object.assign(tempData[catalogIndex].categoryOptionsData[categoryIndex].additionalOptionsData[optionIndex], val);
        Object.assign(tempData[catalogIndex].categoryOptionsData[categoryIndex].additionalOptionsData[optionIndex], updatedData);
        setTempData([...tempData]);
    }

    function onSaveStateHandler(e: any) {
        e.preventDefault();
        toast.success(SAVED_NEW_PRICES_MSG);
        props.updateCatalogsPrices(tempData);
        setIsEditBtnClicked(false);
    }

    function formatNumber(num: number) {
        return (Math.round(num * 100) / 100).toFixed(2);
    }

    function convertCategoriesNames(name: string) {
        switch (name) {
            case CATEGORY_NAMES.Academia:
                return `${CONVERTED_NAMES.AcademiaPartner}`
            case CATEGORY_NAMES.Industry:
                return `${CONVERTED_NAMES.IndustryPartner}`
            case CATEGORY_NAMES.Researcher:
                return `${CONVERTED_NAMES.ExternalResearcher}`
            default:
                return ''
        }
    }

    function convertOptionsNames(category: string, name: string) {
        switch (name) {
            case OPTION_NAMES.Network:
                return `${category.concat(` with ${CONVERTED_NAMES.NetworkOption}`)}`
            case OPTION_NAMES.Mentorship:
                return `${category.concat(` with ${CONVERTED_NAMES.MentorshipOption}`)}`
            default:
                return ''
        }
    }

    function convertCatalogTypesNames(name: string) {
        switch (name) {
            case CATALOG_TYPES.Basic:
                return `${CATALOG_TYPES.Basic}`
            case CATALOG_TYPES.TailorMade:
                return `${CONVERTED_NAMES.TailorMadeType}`
            default:
                return ''
        }
    }

    return (
        <>
            <h4 style={{ textAlign: 'center', textDecoration: 'underline', margin: '5rem auto auto auto' }} >PRICING DASHBOARD</h4>
            <div style={isEditBtnClicked === false ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : { display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                {isEditBtnClicked === true ? (
                    <Button onClick={onCancelOperationHandler}>Exit</Button>
                ) : (
                    <Button onClick={onEditRowHandler}>Edit</Button>
                )}
                <Button onClick={onSaveStateHandler} style={isEditBtnClicked === false ? { display: 'none' } : { display: 'block' }} >Save</Button>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Category</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {tempData.map((data: CatalogData, catalogIndex: number) => {
                        return (
                            <React.Fragment key={catalogIndex + 'a'}>
                                <tr style={{ fontWeight: 'bolder', textDecoration: 'underline', backgroundColor: '#113D7C', color: '#fff' }}>
                                    <td>
                                        {`${convertCatalogTypesNames(data.type)}`}
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <React.Fragment key={catalogIndex + 'b'}>
                                    {data.categoryOptionsData.map((category: CategoryData, categoryIndex: number) => {
                                        return (
                                            <React.Fragment key={categoryIndex + 'c'}>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        {`${convertCategoriesNames(category.name)}`}
                                                    </td>
                                                    {isEditBtnClicked &&
                                                        <td>
                                                            <input type='text' value={category.basePrice} onChange={(e: any) => onChangeBasePriceHandler(e, { basePrice: parseFloat(e.target.value) }, categoryIndex, catalogIndex)} />
                                                        </td>
                                                    }
                                                    {!isEditBtnClicked &&
                                                        <td>
                                                            {formatNumber(category.basePrice)}
                                                        </td>
                                                    }
                                                </tr>
                                                {
                                                    category.additionalOptionsData.map((option: OptionData, optionIndex: number) => {
                                                        return (
                                                            <React.Fragment key={optionIndex + 'd'}>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>
                                                                        {convertOptionsNames(convertCategoriesNames(category.name), option.name)}
                                                                    </td>
                                                                    {isEditBtnClicked &&
                                                                        <td>
                                                                            <input type='text' value={option.price} onChange={(e: any) => onChangeOptionsPriceHandler(e, { price: parseFloat(e.target.value) }, catalogIndex, categoryIndex, optionIndex)} />
                                                                        </td>
                                                                    }
                                                                    {!isEditBtnClicked &&
                                                                        <td>
                                                                            {formatNumber(option.price)}
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                                {category.additionalOptionsData.length > 0 &&
                                                    <tr style={{ fontWeight: 'bold', backgroundColor: '#F0B72F' }}>
                                                        <td></td>
                                                        <td>
                                                            {`${convertCategoriesNames(category.name)} ${(category.additionalOptionsData.map(x => convertOptionsNames('', x.name)).join(' and ')).trim()}`}
                                                        </td>
                                                        <td>
                                                            {formatNumber(category.totalPrice)}
                                                        </td>
                                                    </tr>
                                                }
                                            </React.Fragment>
                                        )
                                    })}
                                </React.Fragment>
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </Table>
        </>
    );
}
