import { default as RootComponent, DispatchProps } from '../components/component-root';
import * as Constants from '../constants';
import * as Actions from '../actions';
import { State } from '../model';
import { connect } from 'react-redux';
import { ChangePatientStatusData, ProjectFeedbackData, ProjectStatusData, SurveyAnswerData } from '../../../common/models/input-models';

export function mapStateToProps(globalState: any) {
    let name = Constants.NAME;
    let pageState = globalState[name] as State;
    return pageState;
}

export function mapDispatchToProps(dispatch: any) {
    return {
        changePatientStatus: (data: ChangePatientStatusData) => dispatch(Actions.changePatientStatusAsync(data)),
        addSurveyAnswer: (data: SurveyAnswerData) => dispatch(Actions.addSurveyAnswerAsync(data)),
        changeProjectStatus: (data: ProjectStatusData) => dispatch(Actions.changeProjectStatusAsync(data)),
        addProjectFeedback: (data: ProjectFeedbackData) => dispatch(Actions.addProjectFeedbackAsync(data))
    } as DispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(RootComponent);