import { AdminVerifyModel, ActionsTemplateData, CatalogData, ChangePatientStatusData, ContentData, EmailTemplateData, RequestData, SurveyAnswerData } from '../models/input-models';
import HttpRequester from './http-requester';

//declare var baseUrl: string;
var baseUrl = '/utils';

export default class UtilsSvc {
	httpRequester: HttpRequester;

	constructor() {
		this.httpRequester = new HttpRequester('/api');
	}

	getLookUpData() {
		return this.httpRequester.httpGet(
			`${baseUrl}/getlookupdata`
		);
	}

	getMeshRecommendations(condition: string) {
		return this.httpRequester.httpGet(
			`${baseUrl}/getmeshrecommendations?condition=${condition}`
		)
	}

	getMeshRecordParents(uid: string) {
		return this.httpRequester.httpGet(
			`${baseUrl}/getmeshrecordparents?uid=${uid}`
		)
	}

	verifyUserByRecaptchaToken(tokenObj: any) {
		return this.httpRequester.httpPost(
			`${baseUrl}/verifyuserbyrecaptchatoken`,
			tokenObj
		)
	}

	getContent(name: string) {
		return this.httpRequester.httpGet(
			`${baseUrl}/getcontent?name=${name}`
		)
	}

	updateContent(contentData: ContentData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/updatecontent`,
			contentData
		)
	}

	getPriceCatalogs() {
		return this.httpRequester.httpGet(
			`${baseUrl}/getpricecatalogs`
		)
	}

	updatePriceCatalogs(data: CatalogData[]) {
		return this.httpRequester.httpPost(
			`${baseUrl}/updatepricecatalogs`,
			data
		)
	}

	sendEmail(data: RequestData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/sendemail`,
			data
		)
	}

	getAllEmailTemplates() {
		return this.httpRequester.httpGet(
			`${baseUrl}/getallemailtemplates`
		)
	}

	getEmailTemplateByName(name: string) {
		return this.httpRequester.httpGet(
			`${baseUrl}/getemailtemplatebyname?name=${name}`
		)
	}

	getEmailTemplate(id: string) {
		return this.httpRequester.httpGet(
			`${baseUrl}/getemailtemplate?id=${id}`
		)
	}

	addEmailTemplate(data: EmailTemplateData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/addemailtemplate`,
			data
		)
	}

	alterEmailTemplateData(data: EmailTemplateData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/alteremailtemplatedata`,
			data
		)
	}

	removeEmailTemplate(data: EmailTemplateData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/removeemailtemplate`,
			data
		)
	}

	getClassPropertiesNames(id: AdminVerifyModel) {
		return this.httpRequester.httpPost(
			`${baseUrl}/getclasspropertiesnames`,
			id
		)
	}

	getEmailAutomationTriggers() {
		return this.httpRequester.httpGet(
			`${baseUrl}/getemailautomationtriggers`
		)
	}

	assignActionsToEmailTemplate(data: ActionsTemplateData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/assignactionstoemailtemplate`,
			data
		)
	}

	misallocateActionsFromEmailTemplate(data: ActionsTemplateData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/misallocateactionsfromemailtemplate`,
			data
		)
	}

	getEmailTriggerTemplateBeneficiaries(id: string) {
		return this.httpRequester.httpGet(
			`${baseUrl}/getemailtriggertemplatebeneficiaries?id=${id}`
		)
	}

	getAllCountries() {
		return this.httpRequester.httpGet(
			`${baseUrl}/getallcountries`
		)
	}
}
