import * as React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { LookUpOptions } from '../models/input-models';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/fontawesome-free-regular';

type OwnProps = {
    fieldName: string;
    values: LookUpOptions[] | null;
    value: any[];
    errorMsg: string;
};

type DispatchProps = {
    onChange: (e: any) => void;
};

type OwnState = {
    vals: any[];
};

type Props = OwnProps & DispatchProps;

const ALL_ELEMENTS_OPTION = { id: 1312, name: "All" };
export default class EupatiMultiselect extends React.Component<Props, OwnState> {

    constructor(props: any) {
        super(props);

        this.state = {
            vals: []
        };

        this.returnProperIconType = this.returnProperIconType.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.compare = this.compare.bind(this);
    }

    componentDidMount() {
        if (this.props.values !== null) {
            this.setState({ vals: [...this.props.values, ALL_ELEMENTS_OPTION] });
        }
    }

    returnProperIconType(icon: any) {
        return icon as IconProp;
    }

    compare(a: any, b: any) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    handleOnChange(e: any) {
        if (e.includes(ALL_ELEMENTS_OPTION.id)) {
            const ids: any[] = [];
            this.state.vals.forEach(x => { if (x.id !== ALL_ELEMENTS_OPTION.id) { ids.push(x.id) } });
            this.props.onChange(ids);
        }
        else {
            this.props.onChange(e);
        }
    }

    render() {
        let valuesComponent: any;

        let multiSelectComponent: any;

        if (this.props.values) {

            if (this.props.values.length > 3) {
                valuesComponent = this.state.vals.sort(this.compare).map((x, i) => {
                    return (
                        <ToggleButton
                            key={i}
                            value={x.id}
                            type='checkbox'
                            className={this.props?.value?.length === this.props?.values?.length ? 'active' : ''}
                            name={this.props.fieldName}
                            style={{ border: '0' }}
                        >
                            <div className='toggle-btn'>
                                <FontAwesomeIcon
                                    icon={this.returnProperIconType(faCheckSquare)}
                                    className='toggle-btn-circle'
                                />
                            </div>
                            <div className='check-btn-text'>{x.name}</div>
                        </ToggleButton>
                    )
                });
            } else {
                valuesComponent = this.state.vals.map((x, i) => {
                    if (x.id !== 1312) {
                        return (
                            <ToggleButton
                                key={i}
                                value={x.id}
                                type='checkbox'
                                name={this.props.fieldName}
                            >
                                <div className='toggle-btn'>
                                    <FontAwesomeIcon
                                        icon={this.returnProperIconType(faCheckSquare)}
                                        className='toggle-btn-circle'
                                    />
                                </div>
                                <div className='check-btn-text'>{x.name}</div>
                            </ToggleButton>
                        )
                    }
                });
            }
        }

        multiSelectComponent = (
            <ToggleButtonGroup
                id={this.props.fieldName}
                className={this.props.fieldName}
                value={this.props.value}
                type='checkbox'
                onChange={(e) => this.handleOnChange(e)}
            >
                {valuesComponent}
            </ToggleButtonGroup>
        )

        return (
            <>
                {multiSelectComponent}
                {!!this.props.errorMsg &&
                    <span className='text-danger'>{this.props.errorMsg}</span>
                }
            </>
        )
    }
}