import * as React from 'react';
import { Image } from 'react-bootstrap';
import { withRouter, NavLink } from 'react-router-dom';
import logo from '../../../images/logo.jpg';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { LoginMenu } from '../../api-authorization/LoginMenu';
import { faHome } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type OwnProps = {
    isUserAdmin: boolean;
    authenticateUser: () => void;
}

export default class NavBar extends React.PureComponent<OwnProps, {}> {

    componentDidMount() {
        if (this.props.isUserAdmin === null || this.props.isUserAdmin === undefined) {
            this.props.authenticateUser();
            this.forceUpdate();
        }
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.isUserAdmin === null || prevProps.isUserAdmin === undefined) {
            this.props.authenticateUser();
        }
    }

    returnProperIconType(icon: any) {
        return icon as IconProp;
    }

    public render() {

        const ImgCard = withRouter(({ history }) => (
            <Image
                src={logo}
                rounded
                className='navbar-top-brand-logo'
                onClick={() => history.push('/')}
            >
            </Image>
        ));

        return (
            <div className='navigation-bar'>
                <Navbar className='navbar-top'>
                    <Navbar.Brand className='navbar-top-brand' style={{ cursor: 'pointer' }}>
                        <ImgCard />
                    </Navbar.Brand>
                    <div className='navbar-top-btns'>
                        <Nav>
                            <Nav.Link id='homeLink' href='/' className='navbar-top-button'><FontAwesomeIcon className='navbar-top-home' icon={this.returnProperIconType(faHome)} />Home</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link id='eupatiLink' href='https://eupati.eu/' className='navbar-top-button' style={{ marginLeft: '1.5rem' }}>Back To EUPATI</Nav.Link>
                        </Nav>
                    </div>
                </Navbar>
                <Navbar className='navbar-bottom' expand='lg' collapseOnSelect style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Navbar.Toggle />
                    <Navbar.Collapse className='navbar-bottom-menu'>
                        <Nav className='navbar-bottom-menu-list' style={!this.props.isUserAdmin ? { justifyContent: 'flex-end' } : {}}>
                            {this.props.isUserAdmin &&
                                <div className='admin-nav-items'>
                                    <li className='nav-item'>
                                        <NavLink className='nav-link' to='/admin/prices' style={{ textDecoration: 'none', color: '#fff' }}>Pricing</NavLink>
                                    </li>
                                    <li className='nav-item'>
                                        <NavLink className='nav-link' to='/admin/all-admins' style={{ textDecoration: 'none', color: '#fff' }}>Admins</NavLink>
                                    </li>
                                    <li className='nav-item'>
                                        <NavLink className='nav-link' to='/admin/all-patients' style={{ textDecoration: 'none', color: '#fff' }}>Patients</NavLink>
                                    </li>

                                    <li className='nav-item'>
                                        <NavLink className='nav-link' to='/admin/projects' style={{ textDecoration: 'none', color: '#fff' }}>Projects</NavLink>
                                    </li>

                                    <li className='nav-item'>
                                        <NavLink className='nav-link' to='/admin/import-patients' style={{ textDecoration: 'none', color: '#fff' }}>Import</NavLink>
                                    </li>

                                    <li className='nav-item'>
                                        <NavLink className='nav-link' to='/admin/email-templates' style={{ textDecoration: 'none', color: '#fff' }}>Templates</NavLink>
                                    </li>
                                </div>
                            }
                        </Nav>
                        <Nav className='navbar-bottom-menu-list' style={!this.props.isUserAdmin ? { justifyContent: 'flex-end' } : {}}>
                            <div className='auth-nav-items'>
                                <LoginMenu />
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <hr className='ribbon'></hr>
            </div>
        )
    }
}
