import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { ContentData } from '../models/input-models';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';

type OwnProps = {
    content: string;    // default state
    pageName: string;  //  privacy or terms
}

export type DispatchProps = {
    updateContent: (data: ContentData) => void;
}

type Props = DispatchProps & OwnProps;

export default function TinyMCEditor(props: Props) {
    const history = useHistory();
    const editorRef = useRef(null);
    const submit = () => {
        if (editorRef.current) {
            props.updateContent({
                name: props.pageName,
                rawDraftContent: '',
                //@ts-ignore
                htmlContent: editorRef.current.getContent()
            } as ContentData);
            toast.success(`You have successfully updated the ${props.pageName.toUpperCase()} content.`);
            setTimeout(() => {
                history.push(`/${props.pageName.toLowerCase()}`);
            }, 200);
        }
    };
    return (
        <>
            <Editor
                //@ts-ignore
                onInit={(evt, editor) => (editorRef.current) = editor}
                initialValue={props.content}
                apiKey="gi5vdpb46pw329w18n94luc6ne2yimgpjks785wd387s2qvu"
                init={{
                    height: 500,
                    convert_urls: true,
                    relative_urls: false,
                    remove_script_host: false,
                    menubar: 'file edit view insert format tools table',
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | accordion |' +
                        'removeformat',
                    setup: function (editor) {
                        editor.ui.registry.addButton('accordion', {
                            text: 'Collapsible',
                            onAction: function (_) {
                                editor.insertContent('&nbsp;<details> <summary>CHANGE THE TITLE</summary> <p>CHANGE THE CONTENT</p></details>&nbsp;');
                            }
                        });
                    },
                    content_style: 'body {font-family:Helvetica,Arial,sans-serif; font-size:18px }'
                }}
            />
            <div id='editor-btns'>
                <Button variant='secondary' onClick={() => history.push(`/${props.pageName.toLowerCase()}`)} >{`BACK TO ${props.pageName.toUpperCase()}`}</Button>
                <Button variant='primary' onClick={submit}>SAVE</Button>
            </div>
        </>
    );
}
