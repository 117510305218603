// model.js
// This is the model of our module state (e.g. return type of the reducer)

import { EmailTemplateData, LookUpData } from "../../../common/models/input-models";
import { Patient, Project } from "../../../common/models/main-models";

export type State = {
    isLoading: boolean;
    lookUpData: LookUpData[];
    isProjectsLoading: boolean;
    projects: Project[];
    filter: number;
    matchedPatients: Patient[];
    isMatchedPatientsLoading: boolean;
    project: Project;
    isProjectLoading: boolean;
    emailTemplates: EmailTemplateData[];
    singleEmailTemplate: EmailTemplateData;
    emailTemplateLoading: boolean;
};

export const defaultEmailTemplate = {
    id: '',
    name: '',
    subject: '',
    actions: [],
    htmlContent: ''
} as EmailTemplateData;