import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { useState } from 'react';
import { LookUpOptions } from '../../../../common/models/input-models';
import { Project } from '../../../../common/models/main-models';
import PatientsAdditionalInfo from './additional';
import PatientsDiseaseInfo from './disease';

type OwnProps = {
    project: Project,
    isUserReal: boolean,
    showSecondSubComponent: boolean
};

type DispatchProps = {
    prevPage: () => void;
    nextPage: () => void;
    setProject: (project: Project) => void;
    setIsUserReal: (token: string) => void;
    submitProject: (project: Project) => void;
    returnProperDefaultValue: (val: any) => any;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
};

type Props = OwnProps & DispatchProps;

export default function PatientInfo(props: Props) {
    const [nextBtnClicked, setNextBtnClicked] = useState<boolean>(props.showSecondSubComponent);

    const toggleSectionVisibility = () => {
        setNextBtnClicked(!nextBtnClicked);
        //window.scrollTo(0, 0);
    }

    function returnProperIconType(icon: any) {
        return icon as IconProp;
    }

    return (
        <div style={{ margin: '0 0 7.5rem 0' }}>

            {nextBtnClicked &&
                <PatientsDiseaseInfo
                    {...{
                        project: props.project,
                        setProject: props.setProject,
                        returnProperDefaultValue: props.returnProperDefaultValue,
                        showPatientsAdditionalInfoSection: toggleSectionVisibility,
                        isUserReal: props.isUserReal,
                        returnProperIconType: returnProperIconType,
                        findLookUpData: props.findLookUpData,
                        prevPage: props.prevPage
                    }}
                />
            }

            {!nextBtnClicked &&
                <PatientsAdditionalInfo
                    {...{
                        project: props.project,
                        submitProject: props.submitProject,
                        nextPage: props.nextPage,
                        setProject: props.setProject,
                        hidePatientsAdditionalInfoSection: toggleSectionVisibility,
                        returnProperDefaultValue: props.returnProperDefaultValue,
                        isUserReal: props.isUserReal,
                        findLookUpData: props.findLookUpData
                    }}
                />
            }
        </div>
    )
}