import * as React from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { withRouter } from 'react-router';
import EupatiLoader from '../../../common/static/loading-animation/loader';
import { State } from '../model';
import '../style.scss';
import researcher from '../../../../images/researcher.png';
import patient from '../../../../images/patient.png';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FAIL_AUTH_MSG, ADMIN_ACCESS_DENIED_MSG } from '../../../common/helpers/messages';
import imgRoadmap from '../../../../images/patient-engagement-tools/roadmap.png';
import imgGuidances from '../../../../images/patient-engagement-tools/guidances.png';
import imgParadigmTools from '../../../../images/patient-engagement-tools/paradigm_tools.png';
import imgEngagementSeparator from '../../../../images/patient-engagement-tools/engagement_separator.png';
import imgFaqSeparator from '../../../../images/faq/faq_separator.png';
import imgFaqHowDoesWork from '../../../../images/faq/how_does_work.png';
import imgFaqWhatIsEupati from '../../../../images/faq/what_is_eupati.png';
import imgNetBall from '../../../../images/net_ball.png';
import imgNetBallHalf from '../../../../images/net_ball_half.png';
import imgNetBallLight from '../../../../images/net_ball_light.png';
import imgNetBallPartial from '../../../../images/net_ball_partial.png';
import Testimonials from './testimonials';
import imgWhy1 from '../../../../images/why-eupati/why1.png';
import imgWhy2 from '../../../../images/why-eupati/why2.png';
import imgWhy3 from '../../../../images/why-eupati/why3.png';
import imgWhy4 from '../../../../images/why-eupati/why4.png';
import imgWhy5 from '../../../../images/why-eupati/why5.png';
import imgWhy6 from '../../../../images/why-eupati/why6.png';
import imgWhy7 from '../../../../images/why-eupati/why7.png';
import imgWhy8 from '../../../../images/why-eupati/why8.png';
import imgWhy9 from '../../../../images/why-eupati/why9.png';
import { toast } from 'react-toastify';
import AuthenticationContext from '../../../common/static/auth-context';
import { faCheck } from '@fortawesome/fontawesome-free-solid';
import TinyMCEditor from '../../../common/static/tinymce-editor';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type OwnProps = {};

export type DispatchProps = {
    setIsUserReal: (token: string) => void;
    setUserStatus: (isAdmin: boolean) => void;
};

type Props = State & DispatchProps & OwnProps;

type OwnState = {};

export default class ComponentRoot extends React.Component<Props, OwnState> {
    static contextType = AuthenticationContext;
    componentDidMount() {
        if (this.context.isUserAdmin === null || this.context.isUserAdmin === undefined) {
            this.context.authenticateUser();
        }
        this.props.setUserStatus(this.context.isUserAdmin);
    }

    returnProperIconType(icon: any) {
        return icon as IconProp;
    }

    render() {

        let mainComponent: any;
        let whyComponent: any;
        let noteComponent: any;
        let statsComponent: any;
        let patientEngagementComponent: any;
        let testimonialComponent: any;
        let faqComponent: any;

        let loadingComponent = (
            <div className='loader-wrapper'>
                <EupatiLoader />
            </div>
        );

        let PatientBtn = withRouter(({ history }) => (
            <Button
                variant='primary'
                type='submit'
                className='card-p-btn'
                id='pt-home-btn'
                onClick={() => {
                    if (!this.props.isUserReal) {
                        toast.error(FAIL_AUTH_MSG);
                        return history.push('/');
                    }

                    switch (this.context.isUserAdmin) {
                        case true:
                            toast.error(ADMIN_ACCESS_DENIED_MSG);
                            return history.push('/');
                        case false:
                            return history.push({ pathname: '/patient', state: { isUserPatient: (this.context.isUserAdmin !== undefined && this.context.isUserAdmin !== null), userId: this.context.userIdentity } });
                        default:
                            window.location.href = "/validate";
                            break;
                    }
                }}
            >
                View Your Profile
            </Button>
        ));

        let ResearcherBtn = withRouter(({ history }) => (
            <Button
                variant='primary'
                type='submit'
                className='card-r-btn'
                id='res-home-btn'
                onClick={() => {
                    if (!this.props.isUserReal) {
                        toast.error(FAIL_AUTH_MSG);
                        return history.push('/');
                    }

                    history.push('/pricing');
                }}
            >
                Connect Now
            </Button>
        ));

        if (this.props.isLoading) {
            mainComponent = loadingComponent;
            whyComponent = null;
            noteComponent = null;
            statsComponent = null;
            patientEngagementComponent = null;
            testimonialComponent = null;
        }
        else {
            mainComponent = (
                <>
                    <GoogleReCaptcha action='homePage' onVerify={token => this.props.setIsUserReal(token)} />

                    <section className='upper-sec'>
                        {/* <img className='upper-sec-img' src={upperSectionBg} /> */}
                        <div className='upper-sec-txt'>
                            <h5 className='upper-sec-txt-title mb-4'>EUPATIConnect</h5>
                            <p className='upper-sec-txt-para'>
                                Bringing EUPATI Patient Experts and researchers together
                            </p>
                        </div>

                    </section>
                    <section className='mid-sec'></section>
                    <section className='lower-sec'>
                        <div id='home-cards-container' className='row g-3 justify-content-center'>
                            <Form.Group className='col-xs-auto mb-3'>
                                <Card style={{ width: '20rem', boxShadow: 'rgb(0 0 0 / 20%) -4px 4px 8px 0px' }} className='left'>
                                    <div className='card-img'>
                                        <Card.Img variant="top" src={researcher} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title style={{ textAlign: 'center' }}>Researcher</Card.Title>
                                        <Card.Text className='mb-4'>
                                            Would you like to involve a EUPATI Patient Expert in your patient engagement initiative or opportunity?
                                        </Card.Text>
                                        <Form.Group style={{ textAlign: 'center', margin: '0' }}>
                                            <ResearcherBtn />
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Form.Group>

                            <Form.Group className='col-xs-auto mb-3'>
                                <Card style={{ width: '20rem', boxShadow: 'rgb(0 0 0 / 20%) -4px 4px 8px 0px' }}>
                                    <div className='card-img'>
                                        <Card.Img variant="top" src={patient} />
                                    </div>
                                    <Card.Body>
                                        <Card.Title style={{ textAlign: 'center' }}>EUPATI Patient Expert</Card.Title>
                                        <Card.Text className='mb-4'>
                                            Update your profile to share your knowledge and participate in patient engagement opportunities today.
                                        </Card.Text>
                                        <Form.Group style={{ textAlign: 'center', margin: '0 0 2px 0' }}>
                                            <PatientBtn />
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Form.Group>
                        </div>
                    </section>

                </>
            )
        }

        if (!this.props.isLoading) {
            whyComponent = (
                <>
                    <section className='why-title-sec'>
                        <div className='why-title-sec-txt'>
                            <h5 className='why-title-sec-txt-title mb-4'>Why EUPATIConnect?</h5>
                            <p className='why-title-sec-txt-para'>
                                EUPATIConnect is a place where both EUPATI Patient Experts and researchers can connect, to create mutually beneficial opportunities and to enhance the future of patient engagement.
                            </p>
                        </div>
                    </section>
                    <section className='why-content-sec'>
                        <div className='why-content-sec-panel panel-right'>
                            <div className='why-content-sec-panel-image'>
                                <img className='why-image' src={imgWhy1} />
                            </div>
                            <div className='why-content-sec-panel-content'>
                                <div className='why-content-sec-panel-content-title'>
                                    Large database of EUPATI Fellows
                                </div>
                                <div className='why-content-sec-panel-content-description'>
                                    We can connect you with 200+ EUPATI Patient Experts from over 100+ disease areas. We also have over 300+ EUPATI Open Classroom learners who have expertise in 1 or more EUPATI learning modules.
                                </div>
                            </div>
                        </div>
                        <div id='small-panel' className='why-content-sec-panel panel-left'>
                            <div className='why-content-sec-panel-content'>
                                <div className='why-content-sec-panel-content-title'>
                                    International Network <br />of EUPATI Fellows
                                </div>
                                <div className='why-content-sec-panel-content-description'>
                                    Our Patient Experts come from over <br />39 number of countries.
                                </div>
                            </div>
                            <div className='why-content-sec-panel-image'>
                                <img className='why-image' src={imgWhy2} />
                            </div>
                        </div>
                        <div className='why-content-sec-panel panel-right'>
                            <div className='why-content-sec-panel-image'>
                                <img className='why-image' src={imgWhy3} />
                            </div>
                            <div className='why-content-sec-panel-content'>
                                <div className='why-content-sec-panel-content-title'>
                                    Proficiency of Language
                                </div>
                                <div className='why-content-sec-panel-content-description'>
                                    Fluency in English is a requirement of the EUPATI training programme therefore our patients have a high degree of fluency in English. However, as our Patient Experts hail from all over the world many also have proficiency in a second or third language.
                                </div>
                            </div>
                        </div>
                        <div className='why-content-sec-panel panel-left'>
                            <div className='why-content-sec-panel-content'>
                                <div className='why-content-sec-panel-content-title'>
                                    Scientific and Health Literacy
                                </div>
                                <div className='why-content-sec-panel-content-description'>
                                    High level of knowledge of the medicines R&D process coupled with a high level of literacy in health and scientific terms. Our modules include Non-Clinical and Clinical Development, Health Technology Assessment, Regulatory Affairs.
                                </div>
                            </div>
                            <div className='why-content-sec-panel-image'>
                                <img className='why-image' src={imgWhy4} />
                            </div>
                        </div>
                        <div className='why-content-sec-panel panel-right'>
                            <div className='why-content-sec-panel-image'>
                                <img className='why-image' src={imgWhy5} />
                            </div>
                            <div className='why-content-sec-panel-content'>
                                <div className='why-content-sec-panel-content-title'>
                                    Strongly Motivated
                                </div>
                                <div className='why-content-sec-panel-content-description'>
                                    Patients Experts come to EUPATI driven internally by their desire to learn and change the future of patient engagement. As a result, they are strongly motivated to partner with researchers and to provide input, advice and feedback on all aspects of the medicines R&D process.
                                </div>
                            </div>
                        </div>
                        <div className='why-content-sec-panel panel-left'>
                            <div className='why-content-sec-panel-content'>
                                <div className='why-content-sec-panel-content-title'>
                                    Changing The Future Landscape of Patient Engagement
                                </div>
                                <div className='why-content-sec-panel-content-description'>
                                    Our partners and Patient Experts regularly share their experience of working together on research projects. In turn, these experiences often become case studies within our Patient Expert toolbox, thereby enhancing learning for future generations of Patient Experts to come.
                                </div>
                            </div>
                            <div className='why-content-sec-panel-image'>
                                <img className='why-image' src={imgWhy6} />
                            </div>
                        </div>
                        <div className='why-content-sec-panel panel-right'>
                            <div className='why-content-sec-panel-image'>
                                <img className='why-image' src={imgWhy7} />
                            </div>
                            <div className='why-content-sec-panel-content'>
                                <div className='why-content-sec-panel-content-title'>
                                    Quality of Training
                                </div>
                                <div className='why-content-sec-panel-content-description'>
                                    We pride ourselves on the thorough and rigorous training of the EUPATI Programme. All content has been created via a rigorous multistakeholder (patient, academic and industry) process to ensure the highest standard of patient engagement education.
                                </div>
                            </div>
                        </div>
                        <div className='why-content-sec-panel panel-left'>
                            <div className='why-content-sec-panel-content'>
                                <div className='why-content-sec-panel-content-title'>
                                    Opportunity to Share Knowledge
                                </div>
                                <div className='why-content-sec-panel-content-description'>
                                    EUPATIConnect provides a unique opportunity for EUPATI Fellows to share their knowledge, advance the patient engagement agenda and demonstrate their availability to be involved in future research opportunities and projects.
                                </div>
                            </div>
                            <div className='why-content-sec-panel-image'>
                                <img className='why-image' src={imgWhy8} />
                            </div>
                        </div>
                        <div className='why-content-sec-panel panel-right'>
                            <div className='why-content-sec-panel-image'>
                                <img className='why-image' src={imgWhy9} />
                            </div>
                            <div className='why-content-sec-panel-content'>
                                <div className='why-content-sec-panel-content-title'>
                                    Building New Connections
                                </div>
                                <div className='why-content-sec-panel-content-description'>
                                    EUPATIConnect provides EUPATI Fellows and Open Classroom learners the opportunity to build new connections as part of the large EUPATI Network.
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            );

            noteComponent = (
                <>
                    <section className='note-sec'>
                        <div className='note-sec-txt'>
                            <h5 className='note-sec-txt-title mb-4'>EUPATI National Platforms with Local Connections</h5>
                            <p className='note-sec-txt-para'>
                                EUPATI has National Platforms in over 23 countries a number of which are currently running their own local versions of the course (Ireland, Italy & The Netherlands). Therefore, if you require specific local expertise, we can also connect you with our local ENPs.
                            </p>
                        </div>
                        <img src={imgNetBallPartial} className='net-ball-partial' />
                    </section>
                </>
            );

            statsComponent = (
                <>
                    <section className='stats-sec'>
                        <div className='stats-sec-txt'>
                            <h5 className='stats-sec-txt-title mb-5'>Statistics</h5>
                        </div>
                        <div className='stats-sec-content'>
                            <div className='stats-sec-content-stat'>
                                <div className='stats-sec-content-stat-title'>300</div>
                                <div className='stats-sec-content-stat-content'>hours of online training</div>
                            </div>
                            <div className='stats-sec-content-stat'>
                                <div className='stats-sec-content-stat-title'>8 days</div>
                                <div className='stats-sec-content-stat-content'>of intensive classroom training</div>
                            </div>
                            <div className='stats-sec-content-stat'>
                                <div className='stats-sec-content-stat-title'>200+</div>
                                <div className='stats-sec-content-stat-content'>EUPATI Fellows</div>
                            </div>
                            <div className='stats-sec-content-stat'>
                                <div className='stats-sec-content-stat-title'>300+</div>
                                <div className='stats-sec-content-stat-content'>EUPATI Open Classroom Learners</div>
                            </div>
                            <div className='stats-sec-content-stat'>
                                <div className='stats-sec-content-stat-title'>100+</div>
                                <div className='stats-sec-content-stat-content'>disease areas</div>
                            </div>
                            <div className='stats-sec-content-stat'>
                                <div className='stats-sec-content-stat-title'>39</div>
                                <div className='stats-sec-content-stat-content'>number of countries</div>
                            </div>
                            <div className='stats-sec-content-stat'>
                                <div className='stats-sec-content-stat-title'>35</div>
                                <div className='stats-sec-content-stat-content'>of languages spoken</div>
                            </div>
                        </div>
                        <img src={imgNetBallHalf} className='net-ball-stats-right' />
                    </section>
                </>
            );

            patientEngagementComponent = (
                <>
                    <section className='engagement-sec'>
                        <div className='engagement-sec-separator'>
                            <img src={imgEngagementSeparator} />
                        </div>
                        <div className='engagement-sec-txt'>
                            <h5 className='engagement-sec-txt-title mb-4'>Patient Engagement Tools</h5>
                            <p className='engagement-sec-txt-para'>
                                Looking for further information on how to engage patients in your project? Check out our patient engagement tools below
                            </p>
                        </div>
                        <div className='engagement-sec-content'>
                            <a href='https://toolbox.eupati.eu/resources-guidance/patient-engagement-roadmap/' target='_blank'>
                                <div className='engagement-sec-content-card'>
                                    <img src={imgRoadmap} />
                                    <div className='engagement-sec-content-card-name'>Patient Engagement Roadmap</div>
                                </div>
                            </a>
                            {/* <a href='https://toolbox.eupati.eu/guidance/' target='_blank'> */}
                            <div className='engagement-sec-content-double-card'>
                                <div className='engagement-sec-content-double-card-title'><img src={imgGuidances} /> Guidances</div>
                                <ul>
                                    <li><FontAwesomeIcon icon={this.returnProperIconType(faCheck)} /><div className='engagement-sec-content-double-card-item'><a href='https://toolbox.eupati.eu/guidance/' target='_blank'>EUPATI Patient Engagement Guidances</a></div></li>
                                    <li><FontAwesomeIcon icon={this.returnProperIconType(faCheck)} /><div className='engagement-sec-content-double-card-item'><a href='https://imi-paradigm.eu/petoolbox/' target='_blank'>PARADIGM Tools</a></div></li>
                                    <li><FontAwesomeIcon icon={this.returnProperIconType(faCheck)} /><div className='engagement-sec-content-double-card-item'><a href='https://pemsuite.org/legal-and-contractual-tools/' target='_blank'>PFMD Legal and Contracting Tools</a></div></li>
                                    <li><FontAwesomeIcon icon={this.returnProperIconType(faCheck)} /><div className='engagement-sec-content-double-card-item'><a href='https://pemsuite.org/fmv/' target='_blank'>PFMD Remuneration and Fair Market Value tools</a></div></li>
                                </ul>
                            </div>
                            {/* </a> */}
                            {/* <a href='https://imi-paradigm.eu/' target='_blank'>
                                <div className='engagement-sec-content-card'>
                                    <img src={imgParadigmTools} />
                                    <div className='engagement-sec-content-card-name'>Paradigm Tools</div>
                                </div>
                            </a> */}
                        </div>
                        <img src={imgNetBallLight} className='net-ball-stats-left' />
                    </section>
                </>
            );

            testimonialComponent = <Testimonials />;

            faqComponent = (
                <>
                    <section className='faq-sec'>
                        <div className='faq-sec-separator'>
                            <img src={imgFaqSeparator} />
                        </div>
                        <div className='faq-sec-txt'>
                            <h5 className='faq-sec-txt-title mb-4'>FAQ</h5>
                            {/* <p className='faq-sec-txt-para'>
                                Looking for further information on how to engage patients in your project? Check out our patient engagement tools below
                            </p> */}
                        </div>
                        <div className='faq-sec-content'>
                            {/* <a href='/faq'>
                                <div className='faq-sec-content-card'>
                                    <img src={imgFaqWhatIsEupati} />
                                    <div className='faq-sec-content-card-name'>What is EUPATIConnect?</div>
                                </div>
                            </a> */}
                            <a href='/faq'>
                                <div className='faq-sec-content-card'>
                                    <img src={imgFaqHowDoesWork} />
                                    <div className='faq-sec-content-card-name'>
                                        <span className='faq-sec-content-card-text'>What is EUPATIConnect?<br />How does it work?</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className='faq-sec-txt'>
                            <p className='faq-sec-txt-link'>
                                <a href='/faq'>Check our FAQ page</a>
                            </p>
                        </div>
                    </section >
                </>
            );
        }

        return (
            <>
                {mainComponent}
                {whyComponent}
                {noteComponent}
                {statsComponent}
                {patientEngagementComponent}
                {testimonialComponent}
                {faqComponent}
            </>
        );
    }
}