import newProject from '../components/modules/new-project';
import patientProfile from '../components/modules/patient-profile';
import homePage from '../components/modules/home';
import allProjects from '../components/modules/admin/all-projects';
import importPatients from '../components/modules/admin/import-patients';
import allPatients from '../components/modules/admin/all-patients';
import adminsLs from '../components/modules/admin/admins-ls';
import contentEditor from '../components/modules/admin/content-editor';
import priceCatalog from '../components/modules/admin/price-catalog';
import pricing from '../components/modules/pricing';
import contentManagement from '../components/modules/admin/content-management';
import emailTemplates from '../components/modules/admin/email-templates';
import emailResponse from '../components/modules/confirm';
import payment from '../components/modules/payment';

// The top-level state object
export interface ApplicationState {

}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    // guest
    contentManagement: contentManagement.reducer,
    homePage: homePage.reducer,
    newProject: newProject.reducer,
    paymentPage: payment.reducer,
    // users
    patientProfile: patientProfile.reducer,
    emailResponse: emailResponse.reducer,
    // admins
    allAdmins: adminsLs.reducer,
    allPatients: allPatients.reducer,
    allProjects: allProjects.reducer,
    contentEditor: contentEditor.reducer,
    emailTemplates: emailTemplates.reducer,
    importPatients: importPatients.reducer,
    priceCatalog: priceCatalog.reducer,
    pricingPage: pricing.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
