import * as React from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/fontawesome-free-solid';
import { Link, NavLink } from 'react-router-dom';

type OwnProps = {
    fieldName: string;
    value: any;
    errorMsg: string;
};

type DispatchProps = {
    onChange: (e: any) => void;
};

type OwnState = {};

type Props = OwnProps & DispatchProps;

export default class EupatiGDPRBtn extends React.Component<Props, OwnState> {

    constructor(props: any) {
        super(props);

        this.returnProperIconType = this.returnProperIconType.bind(this);
    }

    returnProperIconType(icon: any) {
        return icon as IconProp;
    }

    render() {
        let valuesComponent: any;

        let gdprComponent: any;

        let values = [
            {
                id: true,
                name: 'I agree to '
            }
        ];

        valuesComponent = values.map((x, i) => {
            return (
                <ToggleButton
                    key={i}
                    value={x.id.toString()}
                    type='radio'
                    name={this.props.fieldName}
                >
                    <div className='toggle-btn'>
                        <FontAwesomeIcon
                            icon={this.returnProperIconType(faCheckSquare)}
                            className='toggle-btn-circle'
                        />
                    </div>
                    <div className='check-btn-text'>{x.name}<a href='https://toolbox.eupati.eu/glossary/gdpr/' target="_blank" >terms and conditions</a></div>
                </ToggleButton>
            )
        });

        gdprComponent = (
            <ToggleButtonGroup
                id={this.props.fieldName}
                className={this.props.fieldName}
                value={this.props.value.toString()}
                name={this.props.fieldName}
                type='radio'
                onChange={(e) => this.props.onChange(e)}
            >
                {valuesComponent}
            </ToggleButtonGroup>
        )

        return (
            <>
                {gdprComponent}
                {!!this.props.errorMsg &&
                    <span className='text-danger'>{this.props.errorMsg}</span>
                }
            </>
        )
    }
}