import {
  default as RootComponent,
  DispatchProps,
} from '../components/component-root';
import * as Constants from '../constants';
import * as Actions from '../actions';
import { State } from '../model';
import { connect } from 'react-redux';

export function mapStateToProps(globalState: any) {
  let name = Constants.NAME;
  let pageState = globalState[name] as State;
  return pageState;
}

export function mapDispatchToProps(dispatch: any) {
  return {
    setAdminsData: () => dispatch(Actions.getAllAdminsAsync()),
    removeAdminData: (id: string, ownerId: string) =>
      dispatch(Actions.removeAdminAsync(id, ownerId)),
    approveAdmin: (id: string, ownerId: string) =>
      dispatch(Actions.approveAdminAsync(id, ownerId)),
  } as DispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(RootComponent);
