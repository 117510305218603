//R.S docs: https://www.npmjs.com/package/react-select
import * as React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { LookUpOptions } from '../models/input-models';
import './static.scss';

type OwnProps = {
    value: any[];
    errorMsg: string;
    values: LookUpOptions[] | null;
    placeholder?: string;
};

export type DispatchProps = {
    onChange: (e: any) => void;
};

type OwnState = {};

type Props = DispatchProps & OwnProps;

const CONTINENTS_LIST = ['Worldwide', 'Asia', 'Africa', 'North America', 'South America', 'Antarctica', 'Europe'];
export default class EupatiSelect extends React.Component<Props, OwnState> {

    constructor(props: any) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.mapOptionsToValues = this.mapOptionsToValues.bind(this);
        this.mapArrayToValues = this.mapArrayToValues.bind(this);
        this.returnComponentStyles = this.returnComponentStyles.bind(this);
        this.langCompare = this.langCompare.bind(this);
    }

    handleOnChange(event: any) {
        this.props.onChange(Object.values(event).map((key: any) => key.value));
    }

    mapOptionsToValues = (options: any) => {
        const isCountry = (item: any) => item.name.toLowerCase() === 'worldwide';
        if (options.length) {
            if (options.some(isCountry)) {
                return options.map((option: any) => ({
                    value: option.id,
                    label: option.name
                }));
            }

            return options.sort(this.langCompare).map((option: any) => ({
                value: option.id,
                label: option.name
            }));
        }
    }

    mapArrayToValues = (arr: any) => {
        if (arr.length) {
            return arr.map((element: any) => {
                let newElement = this.props?.values?.find((x) => x.id === element);
                return { value: newElement?.id, label: newElement?.name };
            });
        }
    }

    langCompare = (a: any, b: any) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    returnComponentStyles = () => {

        const continentsBoldStyles = {
            multiValueLabel: (base: any) => ({ ...base, backgroundColor: '#DAEEF2', color: '#000', padding: '4px 8px', paddingLeft: '8px' }),
            multiValueRemove: (base: any) => ({ ...base, backgroundColor: '#DAEEF2', color: '#808080', cursor: 'pointer', "&:hover": { backgroundColor: '#00A4B6', color: '#fff' } }),
            dropdownIndicator: (base: any) => ({ ...base, cursor: 'pointer' }),
            indicatorsContainer: (base: any) => ({ ...base, cursor: 'pointer', backgroundColor: '#DAEEF2', color: '#808080', "&:hover": { backgroundColor: '#00A4B6', color: '#fff' } }),
            option: (base: any) => (
                {
                    ...base,
                    "&:nth-of-type(1)": { fontWeight: 'bold' },
                    "&:nth-of-type(2)": { fontWeight: 'bold' },
                    "&:nth-of-type(3)": { fontWeight: 'bold' },
                    "&:nth-of-type(4)": { fontWeight: 'bold' },
                    "&:nth-of-type(5)": { fontWeight: 'bold' },
                    "&:nth-of-type(6)": { fontWeight: 'bold' },
                    "&:nth-of-type(7)": { fontWeight: 'bold' },
                    "&:nth-of-type(8)": { fontWeight: 'bold' }
                }
            ),
            menu: (base: any) => ({ ...base, position: 'relative', backgroundColor: '#fff' }),
            menuList: (base: any) => ({ ...base, position: 'relative', backgroundColor: '#fff' })
        }

        const regularStyles = {
            multiValueLabel: (base: any) => ({ ...base, backgroundColor: '#DAEEF2', color: '#000', padding: '4px 8px', paddingLeft: '8px' }),
            multiValueRemove: (base: any) => ({ ...base, backgroundColor: '#DAEEF2', color: '#808080', cursor: 'pointer', "&:hover": { backgroundColor: '#00A4B6', color: '#fff' } }),
            dropdownIndicator: (base: any) => ({ ...base, cursor: 'pointer' }),
            indicatorsContainer: (base: any) => ({ ...base, cursor: 'pointer', backgroundColor: '#DAEEF2', color: '#808080', "&:hover": { backgroundColor: '#00A4B6', color: '#fff' } }),
            menu: (base: any) => ({ ...base, position: 'relative', backgroundColor: '#fff' }),
            menuList: (base: any) => ({ ...base, position: 'relative', backgroundColor: '#fff' })
        }


        if (this.props?.values !== null) {
            return this.props?.values[0]?.name?.toLowerCase() === 'worldwide' ? continentsBoldStyles : regularStyles;
        }

        return regularStyles;
    }

    render() {
        return (
            <>
                <Form.Group>
                    <Select
                        isMulti={true}
                        placeholder={this.props.placeholder ? this.props.placeholder : 'Please select an option...'}
                        options={this.mapOptionsToValues(this.props.values)}
                        defaultValue={this.mapArrayToValues(this.props.value)}
                        onChange={e => this.handleOnChange(e)}
                        className='select-dropdown'
                        styles={this.returnComponentStyles()}
                    />
                    {!!this.props.errorMsg &&
                        <span className='text-danger'>{this.props.errorMsg}</span>
                    }
                </Form.Group>
            </>
        );
    }
}
