import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import EupatiDiseaseAsyncSelect from "../../../../common/static/async-select-menu";
import { Rules } from "../../../../common/helpers";
import EupatiToggleBtn from "../../../../common/static/toggle-btn";
import EupatiTextarea from "../../../../common/static/textarea";
import { DiseaseInfo, Patient } from "../../../../common/models/main-models";
import { LookUpOptions } from "../../../../common/models/input-models";
import EupatiCheckbox from "../../../../common/static/checkbox-toggle";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/fontawesome-free-solid';
import ReactTooltip from 'react-tooltip';

type OwnProps = {
    patient: Patient
};

type DispatchProps = {
    nextPage: () => void;
    setPatientInfo: (patient: Patient) => void;
    hidePatientsDiseaseExpInfo: () => void;
    returnProperDefaultValue: (val: any) => any;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
};

type Props = OwnProps & DispatchProps;

type FieldProps = {
    diseases: DiseaseInfo[],
    noDiseaseExpertise: boolean,
    patientExperienceId: number,
    otherInformation: string
};

const CUSTOM_SPAN_STYLES = ({
    boldFont: {
        fontWeight: 600
    }
});
export default function PatientsDiseaseExpInfo(props: Props) {
    const { handleSubmit, control, watch, formState: { errors } } = useForm();

    const fieldsData = {
        diseases: watch("diseases") as DiseaseInfo[],
        noDiseaseExpertise: watch("noDiseaseExpertise") as boolean,
        patientExperienceId: watch("patientExperienceId") as number,
        otherInformation: watch("otherInformation") as string
    } as FieldProps;

    const onSubmit = (data: any) => {
        const editedPatientInfo = Object.assign(props.patient, data);
        props.setPatientInfo(editedPatientInfo);
        props.nextPage();
    }

    function onBackHandler() {
        if (adjustFieldsValues() === 0) {
            const editedPatientInfo = Object.assign(props.patient, fieldsData);
            props.setPatientInfo(editedPatientInfo);
        }
        props.hidePatientsDiseaseExpInfo();
    }

    const adjustFieldsValues = () => {
        if (fieldsData.diseases === undefined &&
            fieldsData.noDiseaseExpertise === undefined &&
            fieldsData.patientExperienceId === undefined &&
            fieldsData.otherInformation === undefined) {
            return 1;   // if user hasn't changed anything
        }

        if (fieldsData.diseases === undefined &&
            props.patient.diseases.length >= 0) {
            fieldsData.diseases = [...props.patient.diseases];
        }

        if (fieldsData.noDiseaseExpertise === undefined &&
            props.patient.noDiseaseExpertise !== undefined) {
            fieldsData.noDiseaseExpertise = props.patient.noDiseaseExpertise;
        }

        if (fieldsData.otherInformation === undefined &&
            props.patient.otherInformation !== undefined) {
            fieldsData.otherInformation = props.patient.otherInformation;
        }

        if (fieldsData.patientExperienceId === undefined &&
            props.patient.patientExperienceId !== undefined) {
            fieldsData.patientExperienceId = props.patient.patientExperienceId;
        }

        return 0;
    }

    const returnProperIconType = (icon: any) => {
        return icon as IconProp;
    }

    return (
        <Form className='experience' onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='experience-info'>
                <Form.Group className='rcolumn'>
                    <Form.Group id='pt-disease-name' className='experience-info-field mb-3'>
                        <Form.Label style={{ display: 'flex', flexDirection: 'column' }}>
                            <span style={{ ...CUSTOM_SPAN_STYLES.boldFont }}>
                                Disease experience
                                <FontAwesomeIcon data-tip data-for='expDiseasesControlInfo' icon={returnProperIconType(faInfoCircle)} />
                                <ReactTooltip
                                    id='expDiseasesControlInfo'
                                    multiline={true}
                                >
                                    <span>
                                        It is possible to select multiple diseases.
                                        <br />
                                        You can achive that by typing their names in the input field.
                                        <br />
                                        Moreover, you can also pick them from the dropdown menu.
                                        <br />
                                    </span>
                                </ReactTooltip>
                            </span>
                            <span>
                                <small style={{ fontSize: '11px' }}>
                                    Diseases are listed according to the US National Library of Medicine.
                                    &nbsp;You can find the controlled vocabulary, Medical Subject Headings (MEsH), <a href='https://meshb.nlm.nih.gov/search' target='_blank' >here.*</a>
                                </small>
                            </span>
                        </Form.Label>
                        <Controller
                            name='diseases'
                            control={control}
                            defaultValue={props?.patient?.diseases}
                            rules={Rules.notRequired}
                            render={({ field }) =>
                                <EupatiDiseaseAsyncSelect
                                    {...{
                                        ...field,
                                        errorMsg: errors?.diseases?.message
                                    }}
                                />
                            }
                        />
                        <Controller
                            name='noDiseaseExpertise'
                            control={control}
                            defaultValue={props.patient.noDiseaseExpertise}
                            rules={Rules.notRequired}
                            render={({ field }) =>
                                <EupatiCheckbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.noDiseaseExpertise?.message,
                                        fieldName: 'noDiseaseExpertise',
                                        text: 'I have no specific disease experience'
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-expc' className='experience-info-field mb-3'>
                        <Form.Label>Level of experience with disease</Form.Label>
                        <Controller
                            name='patientExperienceId'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.patientExperienceId)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiToggleBtn
                                    {...{
                                        ...field,
                                        errorMsg: errors?.patientExperienceId?.message,
                                        fieldName: 'patientExperienceId',
                                        values: props.findLookUpData('patientExperiense')
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-otherInfo' className='experience-info-field mb-3'>
                        <Form.Label>Other relevant information</Form.Label>
                        <Controller
                            name='otherInformation'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.otherInformation)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextarea
                                    {...{
                                        ...field,
                                        errorMsg: errors?.otherInformation?.message,
                                        rowsCount: 8
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-btns'>
                        <Button className='bck' variant='secondary' type='submit' onClick={onBackHandler}>Back</Button>
                        <Button className='nxt' variant='primary' type='submit'>Next</Button>
                    </Form.Group>
                </Form.Group>
            </Form.Group>
        </Form >
    )
}