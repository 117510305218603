import * as React from 'react';
import { Table, Form, Button } from 'react-bootstrap';
import { LookUpData, PatientSearchData, ProjectPatientData, RequestData, EmailTemplateData, LookUpOptions } from '../models/input-models';
import EupatiLoader from './loading-animation/loader';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import EupatiManualMatch from './manual-search/index';
import EupatiPatientsGrid from './matched-patients-grid';
import './static.scss';
import EupatiArrowsMenu from './arrows-menu';
import { Patient, Project } from '../models/main-models';
import EmailModalPopup from './popup-modal';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

type OwnProps = {
    projectId: string;
    lookUpData: LookUpData[];
    patientSearchData?: any;
    matchedPatients: Patient[];
    isMatchedPatientsLoading: boolean;
    isProjectLoading: boolean;
    project: Project;
    emailTemplates: EmailTemplateData[];
    singleEmailTemplate: EmailTemplateData;
    emailTemplateLoading: boolean;
};

type OwnState = {
    isManualSearchBtnClicked: boolean;
    isPatientProfileClicked: boolean;
    displayStylesProp: string;
    allTemplates: EmailTemplateData[];
};

type DispatchProps = {
    updateSearchPatients?: (ptSrchData: any) => void;
    backToAllProjects: () => void;
    setMatchedPatients: (searchData: PatientSearchData, projectName: string) => void;
    setProjectById: (id: string) => void;
    removePatient: (inputModel: ProjectPatientData) => void;
    addPatient: (inputModel: ProjectPatientData, searchData: PatientSearchData, projectName: string) => void;
    submitRequest: (data: RequestData) => void;
    changePatientStatus: (inputModel: ProjectPatientData) => void;
    getAllEmailTemplates: () => void;
    getEmailTemplateByName: (name: string) => void;
};

type Props = OwnProps & DispatchProps;

export default class EupatiProjectDetails extends React.Component<Props, OwnState> {

    constructor(props: any) {
        super(props);

        this.state = {
            isManualSearchBtnClicked: false,
            isPatientProfileClicked: false,
            displayStylesProp: '',
            allTemplates: this.props.emailTemplates.length > 0 ? [...this.props.emailTemplates] : [],
        }

        this.returnValueById = this.returnValueById.bind(this);
        this.returnProperIconType = this.returnProperIconType.bind(this);
        this.returnJoinedArrayValues = this.returnJoinedArrayValues.bind(this);
        this.manualSearchBtnHandler = this.manualSearchBtnHandler.bind(this);
        this.backToProjectProfile = this.backToProjectProfile.bind(this);
        this.patientProfileBtnHandler = this.patientProfileBtnHandler.bind(this);
        this.toggleComponents = this.toggleComponents.bind(this);
        this.convertDate = this.convertDate.bind(this);
        this.toggleField = this.toggleField.bind(this);
        this.returnLookUpValueByTypeAndId = this.returnLookUpValueByTypeAndId.bind(this);
        this.templatesToLookUpOptions = this.templatesToLookUpOptions.bind(this);
        this.selectTemplateByIndex = this.selectTemplateByIndex.bind(this);
    }

    componentDidMount() {
        this.props.setProjectById(this.props?.projectId);
        if (this.state.allTemplates.length > 0) {
            //sort by name alphabetically
            this.setState(prevState => ({
                allTemplates: [...prevState.allTemplates.sort((a, b) => a.name.localeCompare(b.name))]
            }));
        }
    }

    returnValueById(propName: string, projectPropId: number) {
        let propType = this.props.lookUpData.find(x => x.name === propName); // projectType
        let propValue = propType?.data.find(x => x.id === projectPropId);
        return propValue?.name;
    }

    returnJoinedArrayValues(fieldProps: any, lookUpDataType: any) {
        let arr = fieldProps?.map((x: any, i: any) => {
            return (
                this.returnValueById(lookUpDataType, x)
            )
        });

        return arr?.join(', ');
    }

    templatesToLookUpOptions() {
        const arr = this.state.allTemplates?.map((x: EmailTemplateData, i: number) => {
            return (
                {
                    id: i,
                    name: x.name
                } as LookUpOptions
            )
        });

        return arr;
    }

    selectTemplateByIndex(index: number) {
        const selectedTemplate = this.state.allTemplates[index];
        if (selectedTemplate !== undefined) {
            this.props.getEmailTemplateByName(selectedTemplate.name);
        }
        else {
            this.props.getEmailTemplateByName('empty');
        }
    }

    returnProperIconType(icon: any) {
        return icon as IconProp;
    }

    manualSearchBtnHandler() {
        this.setState({
            isManualSearchBtnClicked: true
        });
    }

    backToProjectProfile() {
        this.props.setProjectById(this.props?.projectId);
        this.setState({
            isManualSearchBtnClicked: false,
            isPatientProfileClicked: false
        })
    }

    patientProfileBtnHandler() {
        this.setState({
            isPatientProfileClicked: true
        })
    }

    toggleComponents(val: string) {
        this.setState({
            displayStylesProp: val
        })
    }

    convertDate(date: any) {
        if (typeof date === 'undefined') { return }
        date = new Date(date);
        function pad(s: any) { return (s < 10) ? '0' + s : s; }
        return [pad(date.getDate()), pad(date.getMonth() + 1), date.getFullYear()].join('/')
    }

    toggleField(val: string) {
        return val !== null && val !== '' ? true : false;
    }

    returnLookUpValueByTypeAndId = (propName: string, projectPropId: number) => {
        const propType = this.props.lookUpData.find((x: any) => x.name == propName); // projectType
        const propValue = propType?.data.find((x: any) => x.id == projectPropId);
        return propValue?.name;
    }

    downloadPDF() {
        let page = document.getElementById('all-projects-tbl');
        if (page) {
            html2canvas(page)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF();
                    pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width / 12, canvas.height / 14);
                    window.open(pdf.output('bloburl'))
                    //pdf.save("EUPATIProject.pdf");
                })
        }
    }

    render() {

        let loadingComponent = (
            <div className='loader-wrapper'>
                <EupatiLoader />
            </div>
        );

        let mainComponent: any;

        if (this.props.project.name === '') {
            mainComponent = loadingComponent;
        } else {
            mainComponent = (
                <>
                    <EupatiArrowsMenu
                        {...{
                            displayStylesProp: this.state.displayStylesProp,
                            leftArrowText: 'all projects',
                            rightArrowText: 'manual match',
                            backBtnHandler: this.props.backToAllProjects,
                            forwardBtnHandler: this.manualSearchBtnHandler,
                            isForwardArrowAvail: true,
                        }}
                    />
                    <Form.Group style={{ display: this.state.displayStylesProp === '' ? 'flex' : this.state.displayStylesProp, justifyContent: 'center' }}>
                        <h5 style={{ color: '#F0B72F', fontWeight: 'bold', textDecoration: 'underline' }}>Projects information</h5>
                    </Form.Group>
                    <Table striped bordered hover style={{ display: `${this.state.displayStylesProp}` }}>
                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td>{this.props?.project?.name}</td>
                            </tr>
                            <tr>
                                <td>Overview</td>
                                <td>{this.props?.project?.overview}</td>
                            </tr>
                            <tr>
                                <td>Type</td>
                                <td>{this.returnValueById('projectType', this.props?.project?.projectTypeId)}</td>
                            </tr>
                            {this.toggleField(this.props?.project?.projectTypeDescription) &&
                                <tr>
                                    <td>Type description</td>
                                    <td>{this.props?.project?.projectTypeDescription}</td>
                                </tr>
                            }
                            <tr>
                                <td>Interaction</td>
                                <td>{this.returnValueById('interactionType', this.props?.project?.interactionTypeId)}</td>
                            </tr>
                            <tr>
                                <td>Activity</td>
                                <td>{this.returnValueById('activityType', this.props?.project?.projectActivityId)}</td>
                            </tr>
                            <tr>
                                <td>How many patients are you looking for?</td>
                                <td>{(this.props?.project?.projectNumberOfPatientsNeeded == 0 || this.props?.project?.projectNumberOfPatientsNeeded == undefined) ? '' : this.props?.project?.projectNumberOfPatientsNeeded}</td>
                            </tr>
                            <tr>
                                <td>Role and expectations</td>
                                <td>{this.props?.project?.roleAndExpectations}</td>
                            </tr>
                            <tr>
                                <td>Start date</td>
                                <td>{this.convertDate(this.props?.project?.startDate)}</td>
                            </tr>
                            <tr>
                                <td>End date</td>
                                <td>{this.convertDate(this.props?.project?.endDate)}</td>
                            </tr>
                            <tr>
                                <td>Time required</td>
                                <td>{this.props?.project?.timeRequired}</td>
                            </tr>
                            <tr>
                                <td>Is compensation provided </td>
                                <td>{this.props?.project?.isCompensationProvided ? "Yes" : "No"}</td>
                            </tr>
                            {this.props?.project?.isCompensationProvided &&
                                <tr>
                                    <td>Compensation amout</td>
                                    <td>{this.props?.project?.compensation}</td>
                                </tr>
                            }
                            <tr>
                                <td>Is ThereTravel Reimbursement</td>
                                <td>{this.props?.project?.isThereTravelReimbursement ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                                <td>Equipment requirements</td>
                                <td>{this.props?.project?.equipmentRequirements}</td>
                            </tr>
                            <tr>
                                <td>Response deadline</td>
                                <td>{this.convertDate(this.props?.project?.responseDeadline)}</td>
                            </tr>
                            <tr>
                                <td>Price</td>
                                <td>{this.props.project.price}</td>
                            </tr>
                            <tr>
                                <td>Plan</td>
                                <td>{this.props.project.plan}</td>
                            </tr>
                            <tr>
                                <td>Package</td>
                                <td>{this.props.project.package}</td>
                            </tr>
                            <tr>
                                <td>Payment method</td>
                                <td>{this.props.project.paymentMethod}</td>
                            </tr>
                            <tr>
                                <td>Disease Experience</td>
                                <td>{this.props?.project?.noDiseaseExpertiseRequired ? "No specific disease experience is required" : this.props?.project?.diseases?.map((d: any) => d.name).join(", ")}</td>
                            </tr>
                            <tr>
                                <td>Disease Preference</td>
                                <td>{this.props?.project?.searchDiseaseTherapeuticArea ? "Match patients in the therapeutic area(s) as well" : "Match only from the selected disease(s)"}</td>
                            </tr>
                            {/* <tr>
                                <td>Disease area</td>
                                <td>{this.props?.project?.diseaseTAName}</td>
                            </tr> */}
                            <tr>
                                <td>Patient expertises</td>
                                <td>{this.returnJoinedArrayValues(this.props?.project?.patientExpertises, 'expertise')}</td>
                            </tr>
                            {this.toggleField(this.props?.project?.patientExpertisesDescription) &&
                                <tr>
                                    <td>Patient expertises description</td>
                                    <td>{this.props?.project?.patientExpertisesDescription}</td>
                                </tr>
                            }
                            <tr>
                                <td>Countries</td>
                                <td>{this.returnJoinedArrayValues(this.props?.project?.countries, 'countryWithContinents')}</td>
                            </tr>
                            <tr>
                                <td>Languages</td>
                                <td>{this.returnJoinedArrayValues(this.props?.project?.languages, 'language')}</td>
                            </tr>
                            <tr>
                                <td>Other information</td>
                                <td>{this.props?.project?.otherInformation}</td>
                            </tr>
                        </tbody>
                    </Table>

                    <Form.Group style={{ display: this.state.displayStylesProp === '' ? 'flex' : this.state.displayStylesProp, justifyContent: 'center' }}>
                        <h5 style={{ color: '#F0B72F', fontWeight: 'bold', textDecoration: 'underline' }}>Person of contact</h5>
                    </Form.Group>
                    <Form.Group style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <EmailModalPopup
                            recipients={this.props?.project?.pocEmail}
                            project={this.props?.project}
                            btnText='Email Researcher'
                            submitRequest={this.props.submitRequest}
                            resetValues={() => { }}
                            singleEmailTemplate={this.props.singleEmailTemplate}
                            emailTemplateLoading={this.props.emailTemplateLoading}
                            returnLookUpValueByTypeAndId={this.returnLookUpValueByTypeAndId}
                            getAllEmailTemplates={this.props.getAllEmailTemplates}
                            emailTemplates={this.state.allTemplates}
                            selectTemplateByIndex={this.selectTemplateByIndex}
                            dropdownValues={this.templatesToLookUpOptions()}
                            matchedPatients={this.props.matchedPatients}
                        />
                    </Form.Group>
                    <Table striped bordered hover style={{ display: `${this.state.displayStylesProp}` }}>
                        <tbody>
                            <tr>
                                <td>First name</td>
                                <td>{this.props?.project?.pocFirstName}</td>
                            </tr>
                            <tr>
                                <td>Last name</td>
                                <td>{this.props?.project?.pocLastName}</td>
                            </tr>
                            <tr>
                                <td>Organisation</td>
                                <td>{this.props?.project?.pocOrganisation}</td>
                            </tr>
                            <tr>
                                <td>Organisation type</td>
                                <td>{this.returnValueById('organisationType', this.props?.project?.pocOrganisationTypeId)}</td>
                            </tr>
                            {this.toggleField(this.props?.project?.pocOrganisationTypeDescription) &&
                                <tr>
                                    <td>Organisation type description</td>
                                    <td>{this.props?.project?.pocOrganisationTypeDescription}</td>
                                </tr>
                            }
                            <tr>
                                <td>Job title</td>
                                <td>{this.props?.project?.pocJobTitle}</td>
                            </tr>
                            <tr>
                                <td>Country of origin</td>
                                <td>{this.returnValueById('country', this.props?.project?.pocCountryId)}</td>
                            </tr>
                            <tr>
                                <td>Phone number</td>
                                <td>{this.props?.project?.pocPhoneNumber}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>{this.props?.project?.pocEmail}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Form.Group onClick={this.downloadPDF}>
                        <Button className='right-arrow-container-btn'>Download Project Information as PDF</Button>
                    </Form.Group>
                </>
            );
        }

        return (
            <>
                {!this.state.isManualSearchBtnClicked ? (
                    <>
                        <>
                            {mainComponent}
                        </>
                        {this.props?.project?.matchedPatients?.length > 0 &&
                            <>
                                <Form.Group style={{ display: this.state.displayStylesProp === '' ? 'flex' : this.state.displayStylesProp, justifyContent: 'center', margin: '0', padding: '0' }}>
                                    <h5 style={{ color: '#F0B72F', fontWeight: 'bold', textDecoration: 'underline' }}>Matched patients</h5>
                                </Form.Group>
                                <EupatiPatientsGrid
                                    {...{
                                        matchedPatients: [...this.props?.project?.matchedPatients],
                                        lookUpData: this.props.lookUpData,
                                        emailTemplates: this.props.emailTemplates,
                                        singleEmailTemplate: this.props.singleEmailTemplate,
                                        emailTemplateLoading: this.props.emailTemplateLoading,
                                        patientProfileBtnHandler: this.patientProfileBtnHandler,
                                        backToProjectProfile: this.backToProjectProfile,
                                        isPatientProfileClicked: this.state.isPatientProfileClicked,
                                        toggleComponents: this.toggleComponents,
                                        leftArrowText: 'projects details',
                                        rightArrowText: 'remove from project',
                                        isForwardArrowAvail: true,
                                        leftBtnText: 'profile',
                                        rightBtnText: 'remove',
                                        projectId: this.props?.projectId,
                                        updateSearchPatients: this.props.updateSearchPatients,
                                        patientSearchData: this.props.patientSearchData,
                                        projectName: this.props?.project?.name,
                                        addPatient: this.props.addPatient,
                                        removePatient: this.props.removePatient,
                                        isManualSearchBtnClicked: this.state.isManualSearchBtnClicked,
                                        submitRequest: this.props.submitRequest,
                                        project: this.props.project,
                                        changePatientStatus: this.props.changePatientStatus,
                                        isConfirmed: false,
                                        getAllEmailTemplates: this.props.getAllEmailTemplates,
                                        getEmailTemplateByName: this.props.getEmailTemplateByName
                                    }}
                                />
                            </>
                        }
                        {this.props?.project?.confirmedPatients?.length > 0 &&
                            <>
                                <Form.Group style={{ display: this.state.displayStylesProp === '' ? 'flex' : this.state.displayStylesProp, justifyContent: 'center', margin: '0', padding: '0' }}>
                                    <h5 style={{ color: '#F0B72F', fontWeight: 'bold', textDecoration: 'underline' }}>Confirmed patients</h5>
                                </Form.Group>
                                <EupatiPatientsGrid
                                    {...{
                                        matchedPatients: [...this.props?.project?.confirmedPatients],
                                        lookUpData: this.props.lookUpData,
                                        emailTemplates: this.props.emailTemplates,
                                        singleEmailTemplate: this.props.singleEmailTemplate,
                                        emailTemplateLoading: this.props.emailTemplateLoading,
                                        patientProfileBtnHandler: this.patientProfileBtnHandler,
                                        backToProjectProfile: this.backToProjectProfile,
                                        isPatientProfileClicked: this.state.isPatientProfileClicked,
                                        toggleComponents: this.toggleComponents,
                                        leftArrowText: 'projects details',
                                        rightArrowText: 'remove from project',
                                        isForwardArrowAvail: true,
                                        leftBtnText: 'profile',
                                        rightBtnText: 'remove',
                                        projectId: this.props?.projectId,
                                        updateSearchPatients: this.props.updateSearchPatients,
                                        patientSearchData: this.props.patientSearchData,
                                        projectName: this.props?.project?.name,
                                        addPatient: this.props.addPatient,
                                        removePatient: this.props.removePatient,
                                        isManualSearchBtnClicked: this.state.isManualSearchBtnClicked,
                                        submitRequest: this.props.submitRequest,
                                        project: this.props.project,
                                        changePatientStatus: this.props.changePatientStatus,
                                        isConfirmed: true,
                                        getAllEmailTemplates: this.props.getAllEmailTemplates,
                                        getEmailTemplateByName: this.props.getEmailTemplateByName
                                    }}
                                />
                            </>
                        }
                    </>
                ) : (
                    <>
                        <EupatiManualMatch
                            {...{
                                //matchedPatients: [...this.props?.project?.matchedPatients],
                                projectName: this.props?.project?.name,
                                backToProjectProfile: this.backToProjectProfile,//
                                lookUpData: this.props.lookUpData,
                                emailTemplates: this.props.emailTemplates,
                                singleEmailTemplate: this.props.singleEmailTemplate,
                                emailTemplateLoading: this.props.emailTemplateLoading,
                                isPatientProfileClicked: this.state.isPatientProfileClicked,
                                patientProfileBtnHandler: this.patientProfileBtnHandler,
                                toggleComponents: this.toggleComponents,
                                displayStylesProp: this.state.displayStylesProp,
                                projectId: this.props?.projectId,
                                setMatchedPatients: this.props.setMatchedPatients,
                                matchedPatients: this.props.matchedPatients,
                                isMatchedPatientsLoading: this.props.isMatchedPatientsLoading,
                                addPatient: this.props.addPatient,
                                removePatient: this.props.removePatient,
                                project: this.props.project,
                                changePatientStatus: this.props.changePatientStatus,
                                getAllEmailTemplates: this.props.getAllEmailTemplates,
                                getEmailTemplateByName: this.props.getEmailTemplateByName
                            }}
                        />
                    </>
                )}
            </>
        );
    }
}