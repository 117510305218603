import * as React from 'react';
import { Form } from 'react-bootstrap';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type OwnProps = {
    fieldName: string;
    text: string;
    value: boolean;
    errorMsg: string;
};

type OwnState = {};

type DispatchProps = {
    onChange: (e: any) => void;
};

type Props = OwnProps & DispatchProps;

class EupatiCheckbox extends React.Component<Props, OwnState> {

    constructor(props: any) {
        super(props);

        this.returnProperIconType = this.returnProperIconType.bind(this);
    }

    returnProperIconType(icon: any) {
        return icon as IconProp;
    }

    render() {

        let btnComponent: any;

        btnComponent = (
            <Form.Group>
                <label className="form-check-container">{this.props.text}
                    <input
                        id={this.props.fieldName}
                        className='form-check-input'
                        type="checkbox"
                        checked={this.props.value}
                        onChange={(e: any) => this.props.onChange(e)}
                    />
                    <span className="form-check-checkmark"></span>
                </label>

            </Form.Group>
        );

        return (
            <>
                {btnComponent}
                {!!this.props.errorMsg &&
                    <span className='text-danger'>{this.props.errorMsg}</span>
                }
            </>
        );
    }
}

export default EupatiCheckbox;