//based on Models.Enums.Status
export const projectStatus = ({
    inProgressStatusID: 1,
    archivedStatusID: 2,
    pendingAcceptanceStatusID: 3,
    declinedStatusID: 4
});

//based on Models.Enums.StatusType
export const patientStatus = ({
    matchedStatusID: 1,
    confirmedStatusID: 2,
    notInterestedStatusID: 3
});