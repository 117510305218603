import { LookUpData, MeshData } from '../../common/models/input-models';
import { Project } from '../../common/models/main-models';
import * as t from './actionTypes';
import { emptyProject, State } from './model';

const initialState: State = {
    project: emptyProject,
    isLoading: false,
    lookUpData: [],
    isUserReal: true
};

export default (state = initialState, action: any): State => {
    switch (action.type) {
        case t.SET_PROJECTS_LOADING:
            return setProjectsLoading(state, action.payload);
        case t.SET_PROJECT:
            return setProject(state, action.payload);
        case t.SET_LOOKUP_DATA:
            return setLookUpData(state, action.payload);
        case t.SET_IS_USER_REAL:
            return setIsUserReal(state, action.payload);
        default:
            return state;
    }
};

const setIsUserReal = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        setIsUserReal: val
    } as State;

    return newState;
}

const setProjectsLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isLoading: val
    } as State;

    return newState;
}

const setProject = (state: State, val: Project): State => {
    let newState = {
        ...state,
        project: { ...val }
    } as State;

    return newState;
}

const setLookUpData = (state: State, val: LookUpData[]): State => {
    let newState = {
        ...state,
        lookUpData: [...val]
    } as State;

    return newState;
}
