import { ProjectFeedbackData, ProjectPatientData, ProjectStatusData } from '../models/input-models';
import { Project } from '../models/main-models';
import HttpRequester from './http-requester';

//declare var baseUrl: string;
var baseUrl = "/projects";

export default class ProjectsSvc {
	httpRequester: HttpRequester;

	constructor() {
		this.httpRequester = new HttpRequester('/api');
	}

	getAllProjects() {
		return this.httpRequester.httpGet(
			`${baseUrl}/all`
		);
	}

	saveNewProject(project: Project) {
		return this.httpRequester.httpPost(
			`${baseUrl}/savenewproject`,
			project
		);
	}

	getProjectById(id: string) {
		return this.httpRequester.httpGet(
			`${baseUrl}/getproject?id=${id}`
		);
	}

	changeProjectStatus(inputModel: ProjectStatusData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/changestatus`,
			inputModel
		);
	}

	assignPatientToProject(inputModel: ProjectPatientData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/assignpatient`,
			inputModel
		);
	}

	removePatientFromProject(inputModel: ProjectPatientData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/removepatient`,
			inputModel
		);
	}

	changePatientStatusToConfirm(inputModel: ProjectPatientData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/confirmpatient`,
			inputModel
		)
	}

	addFeedback(inputModel: ProjectFeedbackData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/addfeedback`,
			inputModel
		)
	}
}
