import React, { useEffect, useState } from "react";
import { ChangePatientStatusData, ProjectFeedbackData, ProjectStatusData, SurveyAnswerData } from "../../../common/models/input-models";
import { State } from "../model";
import confrimImg from '../../../../images/confirm-img.png';
import declineImg from '../../../../images/decline-img.png';
import { Button } from "react-bootstrap";
import '../style.scss';
import { useHistory } from "react-router";
import EupatiLoader from "../../../common/static/loading-animation/loader";
import { toast } from "react-toastify";
import { ANSWER_SUBMIT_SUCC } from "../../../common/helpers/messages";
import { projectStatus, patientStatus } from '../../../common/helpers/status';

type OwnProps = {
    isLoading: boolean;
};

export type DispatchProps = {
    changePatientStatus: (data: ChangePatientStatusData) => void;
    addSurveyAnswer: (data: SurveyAnswerData) => void;
    changeProjectStatus: (data: ProjectStatusData) => void;
    addProjectFeedback: (data: ProjectFeedbackData) => void;
};

type Props = State & DispatchProps & OwnProps;

const CONFRIM_PATH = 'letter-of-confirmation';
const REJECT_PATH = 'letter-of-rejection';
const url = window.location.href;
const regex = /(\?|\&)(uid|pid)\=([a-zA-Z0-9-]+)/gm;
const DEFAULT_HTML = '<p>The page does not exist!</p>';
export default function EmailResponsePage(props: Props) {
    const history = useHistory();

    const [html, setHtml] = useState<string>(DEFAULT_HTML);
    const [text, setText] = useState<string>('');
    const [uid, setUID] = useState<string>('');
    const [pid, setPID] = useState<string>('');
    const [matches, setMatches] = useState<number>(0);

    useEffect(() => {
        //match 1:    ?uid=e9d034f0-410d-4b52-b6df-d754c942c0ee    match 2:    &pid=df915d9a-06c8-4af3-981a-b668e2c11db7
        //group 1:    ?                                            group 1:    &
        //group 2:    uid                                          group 2:    pid
        //group 3:    e9d034f0-410d-4b52-b6df-d754c942c0ee         group 3:    df915d9a-06c8-4af3-981a-b668e2c11db7
        const match = [...url.matchAll(regex)];
        setMatches(match.length);
        if (match !== null && match.length === 2) {
            try {
                const patientId = match[0][3];
                const projectId = match[1][3];
                setUID(patientId); setPID(projectId);
                if (url.includes(REJECT_PATH)) {
                    const data = {
                        uid: patientId,
                        pid: projectId,
                        sid: patientStatus.notInterestedStatusID
                    } as ChangePatientStatusData;
                    props.changePatientStatus(data);
                    setHtml('<p>Thank you for the response.</br>We understand you are busy right now.</br>We will keep you informed on further opportunities.</p>');
                }
                else if (url.includes(CONFRIM_PATH)) {
                    const data = {
                        uid: patientId,
                        pid: projectId,
                        sid: patientStatus.confirmedStatusID
                    } as ChangePatientStatusData;
                    props.changePatientStatus(data);
                    setHtml('<p>Thank you for confirming.</br>We will contact you shortly.</p>');
                }
            } catch (error) {
                console.error(error);
            }
        }
        else if (match !== null && match.length === 1) {
            try {
                if (url.includes(CONFRIM_PATH)) {
                    const projectId = match[0][3];
                    setPID(projectId);
                    const data = {
                        projectId: projectId,
                        statusId: projectStatus.inProgressStatusID
                    } as ProjectStatusData;
                    props.changeProjectStatus(data);
                    setHtml('<p>Thank you for confirming.</br>We will contact you shortly.</p>');
                }
                else if (url.includes(REJECT_PATH)) {
                    const projectId = match[0][3];
                    setPID(projectId);
                    const data = {
                        projectId: projectId,
                        statusId: projectStatus.declinedStatusID
                    } as ProjectStatusData;
                    props.changeProjectStatus(data);
                    setHtml('<p>Thank you for the response.</br>Please do not hesitate to contact us, if you need further assistance.</p>');
                }
            }
            catch (error) {
                console.error(error);
            }
        }
    }, []);
    //patient response
    function addPatientSurveyAnswerHandler() {
        props.addSurveyAnswer({
            uid: uid,
            pid: pid,
            txt: text
        } as SurveyAnswerData);
        toast.success(ANSWER_SUBMIT_SUCC);
        history.push('/');
    }
    //researcher response
    function addProjectFeedbackHandler() {
        props.addProjectFeedback({
            projectId: pid,
            feedback: text
        } as ProjectFeedbackData);
        toast.success(ANSWER_SUBMIT_SUCC);
        history.push('/');
    }

    let mainComp = null;
    let loadingComp = (
        <div className='loader-wrapper'>
            <EupatiLoader />
        </div>
    );

    if (props.isLoading) {
        mainComp = loadingComp;
    }
    else {
        mainComp = (
            <div className='mail-response'>
                {/* <img src={html === DEFAULT_HTML ? declineImg : confrimImg} alt='confrim' className="mail-response-img" /> */}
                <div
                    className="mail-response-text"
                    dangerouslySetInnerHTML={{ __html: html }}
                    style={{ paddingTop: '40px', paddingBottom: '40px' }}
                >
                </div>
                {html !== DEFAULT_HTML &&
                    <>
                        <div>
                            <textarea
                                rows={10}
                                cols={50}
                                placeholder={html.includes('confirming') ?
                                    "Please tell us why you are fit for this project..." : "To help us improve, please give us your feedback..."
                                }
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                            />
                        </div>
                        <div style={{ width: '100%' }}>
                            {matches === 2 && (
                                <Button
                                    className='mail-response-btn'
                                    onClick={addPatientSurveyAnswerHandler}
                                >
                                    Submit
                                </Button>
                            )}
                            {matches === 1 && (
                                <Button
                                    className='mail-response-btn'
                                    onClick={addProjectFeedbackHandler}
                                >
                                    Submit
                                </Button>
                            )}
                        </div>
                    </>
                }
            </div>
        )
    }

    return (
        <>
            {mainComp}
        </>
    )
}