import { User } from '../../../common/models/main-models';
import DataSvc from '../../../common/services/data-svc';
import * as t from './actionTypes';

export const setIsAdminsLoading = (isAdminsLoading: boolean) => ({
  type: t.SET_ADMINS_LOADING,
  payload: isAdminsLoading,
});

export const setAdmins = (admins: User[]) => ({
  type: t.SET_ADMINS,
  payload: [...admins],
});

export const getAllAdminsAsync = () => {
  return (dispatch: any, getState: any) => {
    dispatch(setIsAdminsLoading(true));
    DataSvc.usersSvc.getAllAdmins().then(
      (result: any) => {
        dispatch(setAdmins(result));
        dispatch(setIsAdminsLoading(false));
      },
      (error) => {
        console.error(error);
      }
    );
  };
};

export const removeAdminAsync = (id: string, ownerId: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(setIsAdminsLoading(true));
    DataSvc.usersSvc.getEupatiUserById(ownerId).then(
      (result: any) => {
        DataSvc.usersSvc.removeAdmin({ id: id, email: '' } as User).then(
          () => {
            dispatch(getAllAdminsAsync());
            dispatch(setIsAdminsLoading(false));
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (error) => {
        console.error(error);
      }
    );
  };
};

export const approveAdminAsync = (id: string, ownerId: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(setIsAdminsLoading(true));
    DataSvc.usersSvc.getAdminById(ownerId).then(
      (result: any) => {
        DataSvc.usersSvc.approveAdmin({ id: id, email: '' } as User).then(
          () => {
            dispatch(getAllAdminsAsync());
            dispatch(setIsAdminsLoading(false));
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (error) => {
        console.error(error);
      }
    );
  };
};
