import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { SUCC_ASSIGN_ACTIONS_TO_TEMPLATE } from "../helpers/messages";
import { ActionsTemplateData, EmailAutomationTriggersMenu, EmailTemplateData, LookUpOptions } from "../models/input-models";
import EupatiDropdown from "./dropdown";

type OwnProps = {
    emailTemplate: EmailTemplateData;
    triggersMenuOptions: EmailAutomationTriggersMenu[];
    filteredTriggersMenuOptions: EmailAutomationTriggersMenu[];
    receiversMenuOptions: LookUpOptions[];
    choosenReceivers: number[];
};

export type DispatchProps = {
    onBackToAllHandler: () => void;
    assignActionsToTemplate: (data: ActionsTemplateData) => void;
    compare: (a: any, b: any) => number;
    misallocateActionsFromEmailTemplate: (data: ActionsTemplateData) => void;
};

type Props = DispatchProps & OwnProps;

export default function ActionsTemplateMenu(props: Props) {

    const [templateTriggers, setTemplateTriggers] = useState<string[]>(props.emailTemplate?.actions?.length > 0 ? props.emailTemplate?.actions?.map((eta) => eta.name) : []);
    //const [currentTrigger, setCurrentTrigger] = useState<EmailAutomationTriggersMenu | undefined>(props.triggersMenuOptions.sort(props.compare).find((o: any) => templateTriggers.some(tt => tt === o.name)))

    const onSubmit = (e: any) => {
        e.preventDefault();
        toast.success(SUCC_ASSIGN_ACTIONS_TO_TEMPLATE);
        props.assignActionsToTemplate({
            emailTemplateId: props.emailTemplate.id,
            actions: templateTriggers,
            receivers: []
        } as ActionsTemplateData);
        props.onBackToAllHandler();
    }

    const onTemplateTriggersChangeHandler = (e: any) => {
        //if empty string, remove assigned actions
        const val = props.triggersMenuOptions[e.target.value].name;
        switch (val) {
            case undefined:
                setTemplateTriggers([]);
                break;
            default:
                setTemplateTriggers([val]);
                break;
        }
    }

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group id='template-triggers-btns' className="mb-3">
                <Button
                    variant='secondary'
                    id='back-btn'
                    onClick={() => props.onBackToAllHandler()}
                >
                    BACK
                </Button>
                <Button
                    variant='primary'
                    id='save-btn'
                    type="submit"
                >
                    SAVE
                </Button>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Email Template Name</Form.Label>
                <Form.Control type="text" value={props.emailTemplate.name} placeholder="Enter template name..." disabled={true} />
            </Form.Group>
            <Form.Group className="mb-3" >
                <Form.Label>Available Triggers</Form.Label>
                <EupatiDropdown
                    fieldName="actions"
                    values={props.triggersMenuOptions.sort(props.compare).map((x: EmailAutomationTriggersMenu, i: number) => { return { id: i, name: x.name } })}
                    value={props.triggersMenuOptions.sort(props.compare).findIndex((o: any) => templateTriggers.some(tt => tt === o.name))}
                    errorMsg=""
                    defaultOptionText="No action assigned..."
                    onChange={onTemplateTriggersChangeHandler}
                />
            </Form.Group>
            {/* <Form.Group className="mb-3">
                <Form.Label>Choosen Receivers</Form.Label>
                <EupatiMultiselect
                    fieldName="receivers"
                    values={props.receiversMenuOptions}
                    value={receivers}
                    errorMsg=''
                    onChange={(e) => onReceiversChangeHandler(e)}
                />
            </Form.Group> */}
        </Form>
    )
}