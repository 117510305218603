import * as React from 'react';
import { Container } from 'react-bootstrap';
import NavBar from './navbar';
import Footer from './footer';
import AuthenticationContext from './auth-context';

export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    static contextType = AuthenticationContext;
    public render() {
        return (
            <React.Fragment>
                <NavBar {...this.context} />
                <Container>
                    {this.props.children}
                </Container>
                <Footer />
            </React.Fragment>
        );
    }
}