import { PaymentIntentCreateModel } from "../models/input-models";
import HttpRequester from "./http-requester";

//declare var baseUrl: string;
var baseUrl = '/payments';

export default class PaymentSvc {
    httpRequester: HttpRequester;

    constructor() {
        this.httpRequester = new HttpRequester('/api');
    }

    getStripeConfig() {
        return this.httpRequester.httpGet(
            `${baseUrl}/getstripeconfig`
        );
    }

    createPaymentIntent(data: PaymentIntentCreateModel) {
        return this.httpRequester.httpPost(
            `${baseUrl}/createpaymentintent`,
            data
        );
    }
}