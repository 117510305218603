import React, { useState } from "react";
import { LookUpOptions } from "../../../../common/models/input-models";
import { Patient } from "../../../../common/models/main-models";
import PatientProjectReqInfo from "./comp-req";
import PatientCompensationReqInfo from "./proj-req";

type OwnProps = {
    patient: Patient,
    isNewPatient: boolean,
    showSecondSubComponent: boolean
};

type DispatchProps = {
    nextPage: () => void;
    prevPage: () => void;
    savePatient: (project: Patient) => void;
    setPatientInfo: (project: Patient) => void;
    returnProperDefaultValue: (val: any) => any;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
    submitNewPatient: (patient: Patient) => void;
};

type Props = OwnProps & DispatchProps;

export default function RequirementsInfo(props: Props) {

    const [nextBtnClicked, setNextBtnClicked] = useState<boolean>(props.showSecondSubComponent);

    const toggleSectionVisibility = () => {
        setNextBtnClicked(!nextBtnClicked);
    }

    return (
        <div style={{ margin: '0 0 7.5rem 0' }}>

            {nextBtnClicked &&
                <PatientProjectReqInfo
                    {...{
                        ...props,
                        showPatientCompensationReqInfo: toggleSectionVisibility
                    }}
                />
            }

            {!nextBtnClicked &&
                // GDPR here...
                <PatientCompensationReqInfo
                    {...{
                        ...props,
                        hidePatientCompensationReqInfo: toggleSectionVisibility
                    }}
                />
            }


        </div>
    );
}