import * as React from 'react';
import { Form } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

type OwnProps = {};

export type DispatchProps = {};

type Props = {} & DispatchProps & OwnProps;

type OwnState = {};

export default class EupatiConfirmPage extends React.Component<Props, OwnState> {
    render() {
        return (
            <div style={{ marginTop: '5rem' }}>
                <Form.Group className='confirm-page-wrapper'>
                    <h2>Thanks for your submission!</h2>
                    <h4>We will contact you soon.</h4>
                    <li className='nav-item'>
                        <NavLink className='confirm-page-btn nav-link' to='/' style={{ color: '#fff' }}>Back To EUPATI</NavLink>
                    </li>
                </Form.Group>
            </div>
        );
    }
}