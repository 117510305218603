import { State } from "./model";
import * as t from './actionTypes';

const initialState: State = {
    isLoading: false
};

export default (state = initialState, action: any): State => {
    switch (action.type) {
        case t.SET_LOADING:
            return setLoading(state, action.payload);
        default:
            return state;
    }
};

const setLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isLoading: val
    } as State;

    return newState;
}