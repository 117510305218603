import { emptyTemplate, State } from "./models";
import * as t from './actionTypes';
import { EmailAutomationTriggersMenu, EmailTemplateData, LookUpOptions, ModelPropsNames } from "../../../common/models/input-models";

const initialState: State = {
    isEmailTemplatesLoading: false,
    isSingleEmailTemplateLoading: false,
    emailTemplatesData: [],
    singleEmailTemplateData: emptyTemplate,
    projectPropsNames: [],
    patientPropsNames: [],
    triggersMenuOptions: [],
    isTriggersMenuOptionsLoading: false,
    receiversMenuOptions: [],
    choosenReceivers: []
};

export default (state = initialState, action: any): State => {
    switch (action.type) {
        case t.SET_EMAIL_TEMPLATES_LOADING:
            return setEmailTemplatesLoading(state, action.payload);
        case t.SET_EMAIL_TEMPLATE_DATA:
            return setEmailTemplatesData(state, action.payload);
        case t.SET_SINGLE_EMAIL_TEMPLATE_LOADING:
            return setSingleEmailTemplateLoading(state, action.payload);
        case t.SET_SINGLE_EMAIL_TEMPLATE_DATA:
            return setSingleEmailTemplateData(state, action.payload);
        case t.SET_PROJECT_MODEL_PROPS_NAMES:
            return setProjectModelPropsNames(state, action.payload);
        case t.SET_PATIENT_MODEL_PROPS_NAMES:
            return setPatientModelPropsNames(state, action.payload);
        case t.SET_TRIGGERS_MENU_OPTIONS:
            return setTriggersMenuOptions(state, action.payload);
        case t.SET_TRIGGERS_MENU_OPTIONS_LOADING:
            return setTriggersMenuOptionsLoading(state, action.payload);
        case t.SET_RECEIVERS_MENU_OPTIONS:
            return setReceiversMenuOptions(state, action.payload);
        case t.SET_CHOOSEN_RECEIVERS:
            return setChoosenReceivers(state, action.payload);
        default:
            return state;
    }
};

const setEmailTemplatesLoading = (state: State, val: boolean): State => {
    const newState = {
        ...state,
        isEmailTemplatesLoading: val
    } as State;

    return newState;
};

const setSingleEmailTemplateLoading = (state: State, val: boolean): State => {
    const newState = {
        ...state,
        isSingleEmailTemplateLoading: val
    } as State;

    return newState;
};

const setEmailTemplatesData = (state: State, val: EmailTemplateData[]): State => {
    const newState = {
        ...state,
        emailTemplatesData: [...val]
    } as State;

    return newState;
};

const setSingleEmailTemplateData = (state: State, val: EmailTemplateData): State => {
    const newState = {
        ...state,
        singleEmailTemplateData: { ...val }
    } as State;

    return newState;
};

const setProjectModelPropsNames = (state: State, val: ModelPropsNames[]): State => {
    const newState = {
        ...state,
        projectPropsNames: [...val]
    } as State;

    return newState;
};

const setPatientModelPropsNames = (state: State, val: ModelPropsNames[]): State => {
    const newState = {
        ...state,
        patientPropsNames: [...val]
    } as State;

    return newState;
};

const setTriggersMenuOptions = (state: State, val: EmailAutomationTriggersMenu[]): State => {
    const newState = {
        ...state,
        triggersMenuOptions: [...val]
    } as State;

    return newState;
}

const setTriggersMenuOptionsLoading = (state: State, val: boolean): State => {
    const newState = {
        ...state,
        isTriggersMenuOptionsLoading: val
    } as State;

    return newState;
}

const setReceiversMenuOptions = (state: State, val: LookUpOptions[]): State => {
    const newState = {
        ...state,
        receiversMenuOptions: [...val]
    } as State;

    return newState;
}

const setChoosenReceivers = (state: State, val: number[]): State => {
    const newState = {
        ...state,
        choosenReceivers: [...val]
    } as State;

    return newState;
}