import * as React from 'react';
import { Form } from 'react-bootstrap';
import { LookUpOptions } from '../models/input-models';

type OwnProps = {
    fieldName: string;
    values: LookUpOptions[] | null;
    errorMsg: string;
    value?: number;
    defaultOptionText?: string;
    width?: string;
};

export type DispatchProps = {
    onChange: (e: any) => void;
};

type OwnState = {
    vals: LookUpOptions[] | null;
}

type Props = DispatchProps & OwnProps;

class EupatiDropdown extends React.Component<Props, OwnState> {

    constructor(props: any) {
        super(props);

        this.state = {
            vals: [],
        };

        this.compare = this.compare.bind(this);
    }

    componentDidMount() {
        if (this.props.values !== null) {
            if (this.props.fieldName.toLowerCase().includes('country')) {
                this.setState({ vals: [...this.props.values].sort(this.compare) });
            }
            else {
                this.setState({ vals: [...this.props.values] });
            }
        }
    }

    compare(a: any, b: any) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    render() {

        let optionsComponent: any;

        let dropdownComponent: any;

        if (this.props.values &&
            this.props.values.length > 0) {

            optionsComponent = this.state.vals?.map((x, i) => {
                if (i === 0) {
                    return (
                        <React.Fragment key={i + 'a'} >
                            {typeof this.props.defaultOptionText !== 'undefined' &&
                                <option
                                    key={'default-option'}
                                    className='option-item'
                                    value=''
                                >
                                    {this.props.defaultOptionText ? this.props.defaultOptionText : "Please select..."}
                                </option>
                            }

                            <option
                                key={i}
                                className='option-item'
                                value={x.id}
                            >
                                {x.name}
                            </option>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <option
                            key={i}
                            className='option-item'
                            value={x.id}
                        >
                            {x.name}
                        </option>
                    );
                }
            });
        }
        else {
            optionsComponent = (
                <option
                    key='option-disabled'
                    className='option-item'
                    disabled={true}
                >
                    No options available...
                </option>
            );
        }

        dropdownComponent = (
            <Form.Control
                as='select'
                className="eupati-dropdown"
                value={this.props.value}
                onChange={(e) => this.props.onChange(e)}
                style={{ width: this.props.width ? this.props.width : '100%' }}
            >
                {optionsComponent}
            </Form.Control>
        );

        return (
            <>
                {dropdownComponent}
                {!!this.props.errorMsg &&
                    <span className='text-danger'>{this.props.errorMsg}</span>
                }
            </>
        );
    }
}

export default EupatiDropdown;
