import { LookUpData, PatientsImportData } from "../../../common/models/input-models";
import DataSvc from "../../../common/services/data-svc";
import * as t from './actionTypes';

export const setIsDataSubmitted = (isDataSubmitted: boolean) => ({
    type: t.SET_IS_DATA_SUBMITTED,
    payload: isDataSubmitted,
});

export const setLookUpData = (lookUpData: LookUpData[]) => ({
    type: t.SET_LOOK_UP_DATA,
    payload: lookUpData
});

export const submitMatchedDataAsync = (patientsData: PatientsImportData) => {
    return (dispatch: any, getState: any) => {
        dispatch(setIsDataSubmitted(false));
        DataSvc.patientSvc.importPatients(patientsData).then(
            (result: any) => {
                dispatch(setIsDataSubmitted(true));
            },
            (error) => {
                console.error(error);
            }
        )
    }
}

export const getLookUpDataAsync = () => {
    return (dispatch: any, getState: any) => {
        DataSvc.utilsSvc.getLookUpData().then(
            (result: any) => {
                dispatch(setLookUpData(result));
            },
            (error) => { console.error(error); }
        )
    }
}