import { State } from './model';
import * as t from './actionType'

const initialState: State = {
    isLoading: false,
    isLoggedIn: false,
    isUserReal: true
};

export default (state = initialState, action: any): State => {
    switch (action.type) {
        case t.SET_HOME_LOADING:
            return setHomeLoading(state, action.payload);
        case t.SET_HOME_LOGGED_IN:
            return setHomeLoggedInStatus(state, action.payload);
        case t.SET_IS_USER_REAL:
            return setIsUserReal(state, action.payload);
        default:
            return state;
    }
};

const setHomeLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isLoading: val
    } as State;

    return newState;
}

const setHomeLoggedInStatus = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isLoggedIn: val
    } as State;

    return newState;
}

const setIsUserReal = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isUserReal: val
    } as State;

    return newState;
}