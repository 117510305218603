import DataSvc from '../../common/services/data-svc';
import { ChangePatientStatusData, ProjectFeedbackData, ProjectStatusData, SurveyAnswerData } from '../../common/models/input-models';
import * as t from './actionTypes';

export const setLoading = (isLoading: boolean) => ({
    type: t.SET_LOADING,
    payload: isLoading
});

export const changePatientStatusAsync = (data: ChangePatientStatusData) => {
    return (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        DataSvc.patientSvc.changePatientStatus(data).then(
            (result: any) => {
                if (result === 'You got me on that one...') {
                    dispatch(setLoading(false));
                }
            },
            (error) => { console.error(error); }
        )
    }
}

export const addSurveyAnswerAsync = (data: SurveyAnswerData) => {
    return (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        DataSvc.patientSvc.addSurveyAnswer(data).then(
            (result: any) => {
                if (result === 'You are a wizard, Harry...') {
                    dispatch(setLoading(false));
                }
            },
            (error) => { console.error(error); }
        )
    }
}

export const changeProjectStatusAsync = (data: ProjectStatusData) => {
    return (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        DataSvc.projectsSvc.changeProjectStatus(data).then(
            (result: any) => {
                dispatch(setLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}

export const addProjectFeedbackAsync = (data: ProjectFeedbackData) => {
    return (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        DataSvc.projectsSvc.addFeedback(data).then(
            (result: any) => {
                dispatch(setLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}