import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Form } from 'react-bootstrap';
import astericsImg from '../../../../images/pricing/asterics.png';
import { CategoryData } from '../../../common/models/input-models';
import { faChevronDown, faChevronUp, faCheck } from '@fortawesome/fontawesome-free-solid';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import { CATALOG_TYPES } from '../../../common/helpers/pricing-list';
import { INVALID_PRICING_DATA, INVALID_PAYMENT_METHOD } from '../../../common/helpers/messages';
import { toast } from 'react-toastify';

type OwnProps = {
    basicPlanCategories: CategoryData[],
    paymentMethods: string[]
};

export type DispatchProps = {
    returnIcon: (icon: any) => IconProp;
    convertCategoriesNames: (name: string) => string;
};

type Props = DispatchProps & OwnProps;

type SelectedOptionData = {
    price: number,
    plan: string,
    text: string,
    index: number,
    bgColor: string
}

const DEFAULT_COLOR_SELECTED = '#00A4B6';
export default function BasicPlanComponent(props: Props) {
    const history = useHistory();

    const [selectedOption, setSelectedOption] = useState<SelectedOptionData>({ price: -1, plan: CATALOG_TYPES.Basic, text: '', index: -1, bgColor: DEFAULT_COLOR_SELECTED });
    const [icon, setIcon] = useState<IconProp>(faChevronDown as IconProp);
    const [paymentIcon, setPaymentIcon] = useState<IconProp>(faChevronUp as IconProp);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('');
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<string>('');

    useEffect(() => {
        setDefaultPaymentMethod(props.paymentMethods.find(pm => pm.toLowerCase().includes('invoice'))!);//component-root state
    }, []);

    function priceChanged(price: number, categoryName: string, categoryIndex: number) {
        const updatedState = Object.assign(selectedOption, { price: price, text: categoryName, index: categoryIndex });
        setSelectedOption({ ...updatedState });
    }

    function toggleIcons() {
        if (icon === props.returnIcon(faChevronDown)) {
            setIcon(props.returnIcon(faChevronUp));
            setPaymentIcon(props.returnIcon(faChevronDown));
        }
        else {
            setIcon(props.returnIcon(faChevronDown));
            setPaymentIcon(props.returnIcon(faChevronDown));
        }
    }

    function updatePaymentMethodOnClick(method: string) {
        if (selectedOption.price === -1) {
            toast.error(INVALID_PRICING_DATA);
            return;
        }
        setSelectedPaymentMethod(method);
    }

    function reverseToggleIcon() {
        if (paymentIcon === props.returnIcon(faChevronUp)) {
            setPaymentIcon(props.returnIcon(faChevronDown));
            setIcon(props.returnIcon(faChevronDown));
        }
        else {
            setPaymentIcon(props.returnIcon(faChevronUp));
            setIcon(props.returnIcon(faChevronDown));
        }
    }

    const selectedOptionStyle = {
        backgroundColor: selectedOption.bgColor,
        color: '#ffffff'
    };

    const submitBtn = (
        <div className='pricing-plan-card-submit-button'>
            <Button
                variant='primary'
                type='submit'
                className='submit-btn'
                onClick={() => {
                    if (selectedOption.price < 0 || selectedOption.text === '') {
                        return toast.error(INVALID_PRICING_DATA);
                    }
                    else if (selectedPaymentMethod === '') {
                        return toast.error(INVALID_PAYMENT_METHOD);
                    }
                    history.push({
                        pathname: '/new-project',
                        state: {
                            price: selectedOption.price,
                            plan: selectedOption.plan,
                            package: props.convertCategoriesNames(selectedOption.text),
                            paymentMethod: selectedPaymentMethod
                        }
                    });
                    //if they wanna pay via invoice
                    //redirect directly to new project page
                    //cos our stripe implementation works only with cards
                    // switch (selectedPaymentMethod === defaultPaymentMethod) {
                    //     case true:
                    //         history.push({
                    //             pathname: '/new-project',
                    //             state: {
                    //                 price: selectedOption.price,
                    //                 plan: selectedOption.plan,
                    //                 package: props.convertCategoriesNames(selectedOption.text),
                    //                 paymentMethod: selectedPaymentMethod
                    //             }
                    //         });
                    //         break;
                    //     default:
                    //         history.push({
                    //             pathname: '/payment',
                    //             state: {
                    //                 price: selectedOption.price,
                    //                 plan: selectedOption.plan,
                    //                 package: props.convertCategoriesNames(selectedOption.text),
                    //                 paymentMethod: selectedPaymentMethod
                    //             }
                    //         });
                    //         break;
                    // }
                }}
            >
                Submit Your Request
            </Button>
        </div >
    );

    const pricingCard = (
        <Card>
            <Card.Header style={{ marginBottom: '0.5rem', borderRadius: '4px' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={toggleIcons}>
                    Pricing
                    <FontAwesomeIcon icon={props.returnIcon(icon)} />
                </Accordion.Toggle>

            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    {props?.basicPlanCategories?.map((category: CategoryData, categoryIndex: number) => {
                        return (
                            <Form.Group
                                key={categoryIndex + 'a'}
                                style={selectedOption.index === categoryIndex ? { ...selectedOptionStyle } : {}}
                                onClick={() => priceChanged(category.basePrice, category.name, categoryIndex)}
                            >
                                <Form.Label id='cat-price'>{`€	${category.basePrice}`}</Form.Label>
                                <Form.Label id='cat-name' >{props.convertCategoriesNames(category.name)}</Form.Label>
                            </Form.Group>
                        )
                    })}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );

    const paymentOptions = (
        <>
            {selectedOption.price === 0 ? (
                <Form.Group
                    key={69420 + 'b'}
                >
                    <div
                        className='payments-wrapper'
                        style={selectedPaymentMethod === defaultPaymentMethod ? { ...selectedOptionStyle } : {}}
                        onClick={() => updatePaymentMethodOnClick(defaultPaymentMethod)}
                    >
                        <div className='payment-check'>
                            <FontAwesomeIcon icon={props.returnIcon(faCheck)} style={selectedPaymentMethod === defaultPaymentMethod ? { position: 'relative', top: '0', left: '0', color: '#fff' } : { position: 'relative', top: '0', left: '0' }} />
                        </div>
                        <Form.Label id='cat-name'>{defaultPaymentMethod}</Form.Label>
                    </div>
                </Form.Group>
            ) : (
                <>
                    {props.paymentMethods.map((method: string, methodIndex: number) => {
                        return (
                            <Form.Group
                                key={methodIndex + 'b'}
                            //id={`${method.toLowerCase().includes('card') ? 'disabled-option-cursor' : ''}`}
                            >
                                <div
                                    className='payments-wrapper'
                                    //id={`${method.toLowerCase().includes('card') ? 'disabled-option-events' : ''}`}
                                    style={selectedPaymentMethod === method ? { ...selectedOptionStyle } : {}}
                                    onClick={() => updatePaymentMethodOnClick(method)}
                                >
                                    <div className='payment-check'>
                                        <FontAwesomeIcon icon={props.returnIcon(faCheck)} style={selectedPaymentMethod === method ? { position: 'relative', top: '0', left: '0', color: '#fff' } : { position: 'relative', top: '0', left: '0' }} />
                                    </div>
                                    <Form.Label id='cat-name'>{method}</Form.Label>
                                </div>
                            </Form.Group>
                        )
                    })}
                </>
            )}
        </>
    )

    const paymentsCard = (
        <Card>
            <Card.Header id='payment-card-header' style={{ marginBottom: '0.5rem', borderRadius: '4px' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={reverseToggleIcon}>
                    Payment method
                    <FontAwesomeIcon icon={props.returnIcon(paymentIcon)} />
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
                <Card.Body style={{ border: '0' }}>
                    {paymentOptions}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );

    const submitBasic = (
        <>
            <Accordion defaultActiveKey="0">
                {pricingCard}
                {paymentsCard}
            </Accordion>
            <div className='pricing-plan-card-submit'>
                {submitBtn}
                <div className='pricing-plan-card-submit-note'>
                    <img src={astericsImg} className='pricing-plan-card-submit-note-asterics' />
                    <strong>Over 90% of our requests have been successfully matched.</strong> However, please note that EUPATI cannot guarantee a successful matching result.
                </div>
            </div>
        </>
    );

    return <div className='pricing-plan'>
        <div className='pricing-plan-card pricing-plan-basic' style={{ paddingTop: '15px' }}>
            {selectedOption.text !== '' &&
                <div className='d-flex' style={{ minHeight: '1px' }}>
                    <div className='pricing-plan-card-pricebox'>{'€ ' + selectedOption.price}</div>
                    <div className='pricing-plan-card-pricetitle'>{props.convertCategoriesNames(selectedOption.text)}</div>
                </div>
            }
            {selectedOption.text === '' &&
                <div className='d-flex' style={{ minHeight: '1px' }}>
                    <div className='pricing-plan-card-plantitle'>Basic Plan</div>
                </div>
            }
            <div className='pricing-plan-card-benefit'>
                <div className='pricing-plan-card-benefit-description'>
                    Are you are looking for a EUPATI Patient Expert and happy to manage the process of selecting the right patient for your project among a pool of matched individuals? Select the basic plan - we can connect you with patient experts corresponding to the requested profile and you can take it from there.
                </div>
            </div>
            <div className='pricing-plan-card-benefit'>
                <div className='pricing-plan-card-benefit-title'>
                    Search
                </div>
                <div className='pricing-plan-card-benefit-description'>
                    We will search over 200+ EUPATI Fellows along with our EUPATI Open Classroom Learners to find the right Patient Expert for you.
                </div>
            </div>
            <div className='pricing-plan-card-benefit'>
                <div className='pricing-plan-card-benefit-title'>
                    Match
                </div>
                <div className='pricing-plan-card-benefit-description'>
                    We will share your request with our matched Patient Experts.
                </div>
            </div>
            <div className='pricing-plan-card-benefit'>
                <div className='pricing-plan-card-benefit-title'>
                    Connect
                </div>
                <div className='pricing-plan-card-benefit-description'>
                    Our interested Patient Experts will contact you directly.
                </div>
            </div>
            <div className='pricing-plan-card-benefit'>
                <div className='pricing-plan-card-benefit-title'>
                    Co-Create!
                </div>
                <div className='pricing-plan-card-benefit-description'>
                    You and your matched Patients Experts can start working together straight away.
                </div>
            </div>
        </div>
        <div className='pricing-plan-card pricing-plan-basic pricing-plan-card-2'>
            {submitBasic}
        </div>
    </div>;
}