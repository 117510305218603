import React, { useState } from "react";
import { LookUpData, LookUpOptions } from "../../../../common/models/input-models";
import { Patient } from "../../../../common/models/main-models";
import PatientGenericInfo from "./generic";
import PatientSpecificInfo from "./specific";

type OwnProps = {
    patient: Patient,
    lookUpData: LookUpData[],
    showSecondSubComponent: boolean
};

type DispatchProps = {
    nextPage: () => void;
    prevPage: () => void;
    savePatient: (project: Patient) => void;
    setPatientInfo: (project: Patient) => void;
    returnProperDefaultValue: (val: any) => any;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
};

type Props = OwnProps & DispatchProps;

export default function PatientGeneralInfo(props: Props) {
    const [nextBtnClicked, setNextBtnClicked] = useState<boolean>(props.showSecondSubComponent);

    const toggleSectionVisibility = () => {
        setNextBtnClicked(!nextBtnClicked);
    }

    return (
        <div style={{ margin: '0 0 7.5rem 0' }}>

            {nextBtnClicked &&
                <PatientGenericInfo
                    {...{
                        ...props,
                        showPatientSpecificInfoSection: toggleSectionVisibility
                    }}
                />
            }

            {!nextBtnClicked &&
                <PatientSpecificInfo
                    {...{
                        ...props,
                        hidePatientSpecificInfoSection: toggleSectionVisibility
                    }}
                />
            }

        </div>
    );
}