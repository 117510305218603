// matching/actions.js

import { CountriesWithCodeModel, LookUpData, PaymentIntentCreateModel, PaymentIntentResponseModel } from '../../common/models/input-models.js';
import DataSvc from '../../common/services/data-svc';
import * as t from './actionType.js';

export const setPaymentLoading = (isLoading: boolean) => ({
    type: t.SET_PAYMENT_LOADING,
    payload: isLoading,
});

export const setLookUpDataLoading = (isLoading: boolean) => ({
    type: t.SET_LOOKUP_DATA_LOADING,
    payload: isLoading
});

export const setLookUpData = (data: LookUpData[]) => ({
    type: t.SET_LOOKUP_DATA,
    payload: [...data]
});

export const setPaymentResponse = (model: PaymentIntentResponseModel) => ({
    type: t.SET_PAYMENT_RESPONSE,
    payload: { ...model }
});

export const setAllCountriesData = (model: CountriesWithCodeModel[]) => ({
    type: t.SET_ALL_COUNTRIES_DATA,
    payload: [...model]
});

export const setAllCountriesLoading = (isLoading: boolean) => ({
    type: t.SET_ALL_COUNTRIES_LOADING,
    payload: isLoading
});

export const getLookUpDataAsync = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setLookUpDataLoading(true));
        DataSvc.utilsSvc.getLookUpData().then(
            (result: any) => {
                dispatch(setLookUpData(result));
                dispatch(setLookUpDataLoading(false));
            },
            (error) => { console.error(error); }
        );
    };
};

export const getAllCountriesAsync = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setAllCountriesLoading(true));
        DataSvc.utilsSvc.getAllCountries().then(
            (result: any) => {
                dispatch(setAllCountriesData(result));
                dispatch(setAllCountriesLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}

export const createPaymentIntentAsync = (data: PaymentIntentCreateModel) => {
    return (dispatch: any, getState: any) => {
        dispatch(setPaymentLoading(true));
        DataSvc.paymentSvc.createPaymentIntent(data).then(
            (result: any) => {
                //TODO: add error handler;
                //just to log the error is not enough in this case;
                //because we are chargin real money;
                //and if it fails for some reason;
                //we should be able to display the message to the user;
                //endless loading screen after you click "Pay" is a big no no imo.
                dispatch(setPaymentResponse(result));//clientSecret
                dispatch(setPaymentLoading(false));
            },
            (error) => { console.error(error); }
        );
    };
};