import * as React from 'react';
import './loader.scss';

type OwnProps = { 
    text?: string;
 };

type OwnState = {};

type DispatchProps = {};

type Props = OwnProps & DispatchProps;

let defaultLoadingText = 'Loading...';

class EupatiLoader extends React.Component<Props, OwnState> {
    render() {
        return (
            <div className='eupati-loader'>
                <div className='eupati-loader-circle'></div>
                <span className='eupati-loader-text'>{this.props.text ? this.props.text : defaultLoadingText}</span>
            </div>
        )
    }
}

export default EupatiLoader;