import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { EmailTemplateData, ModelPropsNames, OptionsData } from "../models/input-models";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { FILL_ALL_FIELD_ERR_MSG } from "../helpers/messages";

type OwnProps = {
    emailTemplateData: EmailTemplateData;
    projectPropsNames: ModelPropsNames[];
    patientPropsNames: ModelPropsNames[];
    options: OptionsData[];
}

export type DispatchProps = {
    onBackToAllHandler: () => void;
    onSaveTemplateChangesHandler: (templateData: EmailTemplateData) => void;
}

type Props = DispatchProps & OwnProps;

const randomstring = require("randomstring");
export default function EmailTemplateEditor(props: Props) {
    const editorRef = useRef(null);
    const eupatiURL = "https://connect.eupati.eu/";

    const [emailTemplateName, setEmailTemplateName] = useState<string>(props.emailTemplateData.name);
    const [emailTemplateSubject, setEmailTemplateSubject] = useState<string>(props.emailTemplateData.subject);

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (editorRef.current) {
            const updatedEmailTemplateData = Object.assign(props.emailTemplateData, {
                name: emailTemplateName, actions: [],
                subject: emailTemplateSubject,
                //@ts-ignore
                htmlContent: editorRef.current.getContent()
            });
            if (updatedEmailTemplateData.name === '' ||
                updatedEmailTemplateData.subject === '' ||
                updatedEmailTemplateData.htmlContent === '') {
                return toast.error(FILL_ALL_FIELD_ERR_MSG);
            }
            props.onSaveTemplateChangesHandler(updatedEmailTemplateData);
        }
    }

    const onEmailTemplateNameChangeHandler = (e: any) => {
        setEmailTemplateName(e.target.value);
    }

    const onEmailTemplateSubjectChangeHandler = (e: any) => {
        setEmailTemplateSubject(e.target.value);
    }

    return (
        <Form onSubmit={onSubmit} >
            <Form.Group id='email-template-editor-btns' className="mb-3">
                <Button
                    variant='secondary'
                    id='back-btn'
                    onClick={() => props.onBackToAllHandler()}
                >
                    BACK
                </Button>
                <Button
                    variant='primary'
                    id='save-btn'
                    type="submit"
                >
                    SAVE
                </Button>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" value={emailTemplateName} placeholder="Enter template name..." onChange={onEmailTemplateNameChangeHandler} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Subject</Form.Label>
                <Form.Control type="text" value={emailTemplateSubject} placeholder="Enter template subject..." onChange={onEmailTemplateSubjectChangeHandler} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Content</Form.Label>
                <Editor
                    //@ts-ignore
                    onInit={(evt, editor) => (editorRef.current) = editor}
                    initialValue={props.emailTemplateData.htmlContent}
                    apiKey="gi5vdpb46pw329w18n94luc6ne2yimgpjks785wd387s2qvu"
                    init={{
                        height: 500,
                        convert_urls: true,
                        relative_urls: false,
                        remove_script_host: false,
                        menubar: 'file edit view insert format tools table properties',
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent |' +
                            'removeformat | ptconfirmation | ptrejection | projconfirmation | projrejection',
                        menu: {
                            properties: { title: 'Fields', items: 'project patient' }
                        },
                        setup: function (editor) {
                            //toolbar
                            editor.ui.registry.addButton('ptconfirmation', {
                                text: 'Patient Confirm',
                                onAction: function () {
                                    const url = `${eupatiURL}letter-of-confirmation/?uid=##uid##+${randomstring.generate(128)}&pid=##pid##`;
                                    editor.insertContent(`<a href='${url}' target='_blank' style="color:#fff; border-radius:41px; border-color:transparent; background-color:#00749F; font-size: 18px; padding:12px 24px; cursor:pointer; margin-top:30px; text-decoration: none;">Confirm</a>`);
                                }
                            });
                            editor.ui.registry.addButton('ptrejection', {
                                text: 'Patient Reject',
                                onAction: function () {
                                    const url = `${eupatiURL}letter-of-rejection/?uid=##uid##+${randomstring.generate(128)}&pid=##pid##`;
                                    editor.insertContent(`<a href='${url}' target='_blank' style="color:#fff; border-radius:41px; border-color:transparent; background-color:#A7171A; font-size: 18px; padding:12px 24px; cursor:pointer; margin-top:30px; text-decoration: none;">Not Interested</a>`);
                                }
                            });
                            editor.ui.registry.addButton('projconfirmation', {
                                text: 'Project Confirm',
                                onAction: function () {
                                    const url = `${eupatiURL}letter-of-confirmation/?pid=##pid##+${randomstring.generate(128)}`;
                                    editor.insertContent(`<a href='${url}' target='_blank' style="color:#fff; border-radius:41px; border-color:transparent; background-color:#00749F; font-size: 18px; padding:12px 24px; cursor:pointer; margin-top:30px; text-decoration: none;">Confirm</a>`);
                                }
                            });
                            editor.ui.registry.addButton('projrejection', {
                                text: 'Project Reject',
                                onAction: function () {
                                    const url = `${eupatiURL}letter-of-rejection/?pid=##pid##+${randomstring.generate(128)}`;
                                    editor.insertContent(`<a href='${url}' target='_blank' style="color:#fff; border-radius:41px; border-color:transparent; background-color:#A7171A; font-size: 18px; padding:12px 24px; cursor:pointer; margin-top:30px; text-decoration: none;">Not Interested</a>`);
                                }
                            });
                            //menu
                            editor.ui.registry.addNestedMenuItem('project', {
                                text: 'Project',
                                getSubmenuItems: () => {
                                    return props.projectPropsNames.map(x => {
                                        return {
                                            type: x.type,
                                            text: x.text,
                                            onAction: () => {
                                                editor.insertContent(`${x.value}`);
                                            }
                                        }
                                    }) as any;
                                }
                            });
                            editor.ui.registry.addNestedMenuItem('patient', {
                                text: 'Patient',
                                getSubmenuItems: () => {
                                    return props.patientPropsNames.map(x => {
                                        return {
                                            type: x.type,
                                            text: x.text,
                                            onAction: () => {
                                                editor.insertContent(`${x.value}`);
                                            }
                                        }
                                    }) as any;
                                }
                            });
                        },
                        content_style: 'body {font - family:Helvetica,Arial,sans-serif; font-size:18px }'
                    }}
                />
            </Form.Group>
        </Form>
    )
}