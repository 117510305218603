import React from 'react';

export default function GlobalFilter(props: any) {
    return (
        <span>
            Search:{' '}
            <input
                value={props.filter || ""}
                onChange={e => props.setFilter(e.target.value)}
                placeholder='Filter the records...'
                style={{ fontSize: '1.1rem', border: '0' }}
            />
        </span>
    )
}