import { State } from './model';
import * as t from './actionTypes';
import { LookUpData } from '../../../common/models/input-models';

const initialState: State = {
    isDataSubmitted: false,
    lookUpData: []
};

export default (state = initialState, action: any): State => {
    switch (action.type) {
        case t.SET_IS_DATA_SUBMITTED:
            return setIsDataSubmitted(state, action.payload);
        case t.SET_LOOK_UP_DATA:
            return setLookUpData(state, action.payload);
        default:
            return state;
    }
};

const setIsDataSubmitted = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isDataSubmitted: val
    } as State;

    return newState;
}

const setLookUpData = (state: State, val: LookUpData[]): State => {
    let newState = {
        ...state,
        lookUpData: [...val]
    } as State;

    return newState;
}