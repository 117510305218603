import * as React from 'react';
import { emptyProject, State } from '../model';
import '../style.scss';
import { Project } from '../../../common/models/main-models';
import EupatiLoader from '../../../common/static/loading-animation/loader';
import ProjectInfo from './project-info';
import ResearcherInfo from './researcher-info';
import PatientInfo from './patient-info';
import EupatiConfirmPage from '../../../common/static/confirm-page';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Form } from 'react-bootstrap';
import { RouteComponentProps } from '@reach/router';
import { INVALID_PRICING_DATA } from '../../../common/helpers/messages';
import { toast } from 'react-toastify';

type OwnProps = {};

export type DispatchProps = {
	setProject: (project: Project) => void;
	setLookUpData: () => void;
	submitNewProject: (project: Project) => void;
	setIsUserReal: (token: string) => void;
};

type Props = State & DispatchProps & OwnProps & RouteComponentProps<{ location: { state: { price: number, plan: string, package: string, paymentMethod: string } } }>;

type OwnState = {
	showComponentByIndex: number;
	showSecondSubComponent: boolean;
};

class ComponentRoot extends React.Component<Props, OwnState> {

	constructor(props: any) {
		super(props);

		this.state = {
			showComponentByIndex: 0,
			showSecondSubComponent: true	// when this is "true" on render it will show the first subcomponent (ResearcherInfo --> PersonalInfo).
		}

		this.submitProject = this.submitProject.bind(this);
		this.nextPageHandler = this.nextPageHandler.bind(this);
		this.previousPageHandler = this.previousPageHandler.bind(this);
		this.findLookUpData = this.findLookUpData.bind(this);
		this.returnProperDefaultValue = this.returnProperDefaultValue.bind(this);
		this.returnProperLookUpData = this.returnProperLookUpData.bind(this);
	}

	componentDidMount() {
		const historyState = this.props.location?.state;
		if (historyState === undefined || historyState.price < 0 ||
			historyState.package === '' || historyState.paymentMethod === '') {
			toast.error(INVALID_PRICING_DATA);
			window.location.href = '/pricing';
		}
		const initialProjectData = Object.assign(emptyProject, { ...historyState });
		this.props.setLookUpData();
		this.props.setProject(initialProjectData);
	}

	submitProject() {
		this.props.submitNewProject(this.props.project);
	}

	nextPageHandler() {
		if (this.state.showComponentByIndex !== 3) {
			this.setState(prevState => ({
				showComponentByIndex: prevState.showComponentByIndex + 1,
				showSecondSubComponent: true
			}));
		}
		//window.scrollTo(0, 0);
	}

	previousPageHandler() {
		if (this.state.showComponentByIndex !== 0) {
			this.setState(prevState => ({
				showComponentByIndex: prevState.showComponentByIndex - 1,
				showSecondSubComponent: false
			}));
		}
		//window.scrollTo(0, 0);
	}

	findLookUpData(lookUpDataType?: string) {
		if (this.props.lookUpData &&
			this.props.lookUpData.length > 0) {
			return this.props.lookUpData?.filter((x: any) => x.name == lookUpDataType)[0]?.data;
		}

		return [];
	}

	returnProperDefaultValue(val: any) {
		return val ? val : '';
	}

	returnProperLookUpData(dataType: any) {
		return typeof dataType !== 'undefined' ? this.findLookUpData(dataType) : null
	}

	render() {

		let mainComponent: any;

		let loadingComponent = (
			<div className='loader-wrapper'>
				<EupatiLoader />
			</div>
		);

		const props = {
			project: this.props.project,
			lookUpData: this.props.lookUpData,
			isUserReal: this.props.isUserReal,
			showSecondSubComponent: this.state.showSecondSubComponent,
			setProject: this.props.setProject,
			nextPage: this.nextPageHandler,
			prevPage: this.previousPageHandler,
			submitProject: this.props.submitNewProject,
			findLookUpData: this.findLookUpData,
			returnProperDefaultValue: this.returnProperDefaultValue,
			setIsUserReal: this.props.setIsUserReal,
			returnProperLookUpData: this.returnProperLookUpData
		};

		const spanStyles = {
			fontWeight: 'bolder',
			//textDecoration: 'underline',
			backgroundColor: '#00749F',
			borderRadius: '30px',
			color: '#fff'
		} as any;

		if (this.props.isLoading) {
			mainComponent = loadingComponent;
		}
		else {
			mainComponent = (
				<>
					<GoogleReCaptcha action='newProjectPage' onVerify={token => this.props.setIsUserReal(token)} />
					<h2 className='heading' style={this.state.showComponentByIndex === 3 ? { display: 'none' } : {}}>Project Request</h2>
					{this.state.showComponentByIndex !== 3 &&
						<Form.Group className='titles-wrapper'>
							<div className='titles'>
								<div className='titles-title' style={this.state.showComponentByIndex === 0 ? { ...spanStyles } : {}}>
									<span >1. Personal info</span>
								</div>
								<div className='titles-title' style={this.state.showComponentByIndex === 1 ? { ...spanStyles } : {}}>
									<span >2. Project data</span>
								</div>
								<div className='titles-title' style={this.state.showComponentByIndex === 2 ? { ...spanStyles } : {}}>
									<span >3. EUPATI Experts</span>
								</div>
							</div>
						</Form.Group>
					}
					{this.state.showComponentByIndex === 0 && <ResearcherInfo {...props} />}
					{this.state.showComponentByIndex === 1 && <ProjectInfo {...props} />}
					{this.state.showComponentByIndex === 2 && <PatientInfo {...props} />}
					{this.state.showComponentByIndex === 3 && <EupatiConfirmPage />}
				</>
			)
		}

		return (
			<>
				{mainComponent}
			</>
		);
	}
}

export default ComponentRoot;