import * as React from "react";
import { Button } from "react-bootstrap";
import { FormGroup } from "reactstrap";
import { CSVMappedField } from "./csv-mapping-model";
import { Rules } from '../../helpers';
import { toast } from 'react-toastify';

type OwnProps = {
    csvObject: any;
    objectProperties: string[];
    mapping: CSVMappedField[];
    isOptionSelected: boolean;
}

export type DispatchProps = {
    finishMapping: (mapping: any) => void;
}

type Props = DispatchProps & OwnProps;

type OwnState = {
    mapping: CSVMappedField[];
}

export default class CSVMappingComponent extends React.Component<Props, OwnState> {
    constructor(props: any) {
        super(props);

        this.state = {
            mapping: []
        };

        this.selectMatchingCsvColumn = this.selectMatchingCsvColumn.bind(this);
        this.finishMapping = this.finishMapping.bind(this);
    }

    componentDidMount() {
        this.setState({ mapping: this.props.mapping });
    }

    selectMatchingCsvColumn(value: any, key: any) {
        let mapping = this.state.mapping;

        let mapItem = mapping.filter((m) => m.objectProperty === key)[0];
        if (mapItem) {
            mapItem.csvColumnName = value;
        } else {
            mapping.push({
                objectProperty: key,
                csvColumnName: value,
            } as CSVMappedField);
        }

        this.setState({ mapping: mapping });
    }

    finishMapping() {
        if (!this.props.isOptionSelected) {
            toast.error(Rules.selectOptionMessage.required);
        }
        else {
            this.props.finishMapping(this.state.mapping);
        }
    }

    render() {

        let objPropsOptions = Object.keys(this.props.csvObject[0]).map((k, i) => {
            return (
                <option key={i} value={k}>
                    {k.toLowerCase()}
                </option>
            );
        });

        let mappingComponent = this.props.objectProperties.map((p, i) => {
            let mapItem = this.state.mapping.filter(
                (m) => m.objectProperty === p
            )[0];

            if (!mapItem) {
                mapItem = {
                    objectProperty: '',
                    csvColumnName: ''
                };
            }

            return (
                <FormGroup key={i} className="mapping-item-wrapper">
                    <FormGroup className="mapping-item-object-property">{p.toUpperCase()}</FormGroup>
                    <FormGroup className="mapping-item-csv-column">
                        <select
                            onChange={(event) =>
                                this.selectMatchingCsvColumn(
                                    event.target.value,
                                    p
                                )
                            }
                            className="blue-dropdown"
                            value={mapItem.csvColumnName}
                        >
                            <option value="">-</option>
                            {objPropsOptions}
                        </select>
                    </FormGroup>
                </FormGroup>
            );
        });

        return (
            <FormGroup id="mapping-wrapper">
                {mappingComponent}
                <FormGroup className='mapping-item-wrapper'>
                    <Button
                        id="mapping-button"
                        className="btn-fmp"
                        onClick={this.finishMapping}
                    >
                        Finish Mapping
                    </Button>
                </FormGroup>
            </FormGroup>
        )
    }
}