import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import astericsImg from '../../../../images/pricing/asterics.png';

type OwnProps = {};

export type DispatchProps = {};

type Props = DispatchProps & OwnProps;

type OwnState = {};

export default class CustomPlanComponent extends Component<Props, OwnState> {

    constructor(props: any) {
        super(props);
    }

    render() {

        const contactUsBtn = (
            <div className='pricing-plan-card-submit-button'>
                <Button
                    variant='primary'
                    type='submit'
                    className='submit-btn'
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = 'https://eupati.eu/contact/';
                    }}
                >
                    Contact Us
                </Button>
            </div>
        );

        return (
            <div className='pricing-plan' style={{ justifyContent: 'center', alignItems: 'center' }} >
                <div className='pricing-plan-card pricing-plan-custom' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div className='d-flex' style={{ minHeight: '1px' }}>
                        <div className='pricing-plan-card-pricebox' style={{ flexDirection: 'column', paddingBottom: '10px' }}>20% <span className='discount'>discount</span></div>
                        <div className='pricing-plan-card-pricetitle'>Based on the number of requests</div>
                    </div>
                    <div className='pricing-plan-card-benefit'>
                        <div className='pricing-plan-card-benefit-title'>
                            Customised Annual Subscription Plan
                        </div>
                        <div className='pricing-plan-card-benefit-description' style={{ lineHeight: '250%' }}>
                            Would you like to sign up for an annual subscription plan for a certain number of matchmaking requests per year to streamline your internal approval and payments processes? Contact us and we will customise the right matchmaking plan for you.
                        </div>
                    </div>
                    <div className='pricing-plan-card-submit' style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0px', height: 'auto' }}>
                        {contactUsBtn}
                        <div className='pricing-plan-card-submit-note' style={{ display: 'none' }}>
                            <img src={astericsImg} className='pricing-plan-card-submit-note-asterics' />
                            <strong>Over 90% of our requests have been successfully matched.</strong> However, please note that EUPATI cannot guarantee a successful matching result. In the case of an unsuccessful match only the basic fee will be charged.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}