import React, { useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import AuthenticationContext from "../auth-context";

export default function AdminRoute({ component: Component, ...restOfProps }) {

  const contextData = useContext(AuthenticationContext);

  useEffect(() => {
    if (contextData.isUserAdmin === null || contextData.isUserAdmin === undefined) {
      contextData.authenticateUser();
    }
  });

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        contextData.isUserAdmin ? <Component {...props} {...restOfProps} /> : <Redirect to="/" />
      }
    />
  );
}