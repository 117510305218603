import { default as RootComponent, DispatchProps } from '../components/component-root';
import * as Constants from '../constants';
import { State } from '../model';
import * as Actions from '../actions';
import { ContentData } from '../../../../common/models/input-models';
import { connect } from 'react-redux';

export function mapStateToProps(globalState: any) {
    let name = Constants.NAME;
    let pageState = globalState[name] as State;
    return pageState;
}

export function mapDispatchToProps(dispatch: any) {
    return {
        getContent: (name: string) => dispatch(Actions.getContentByNameAsync(name)),
        updateContent: (data: ContentData) => dispatch(Actions.updateContentAsync(data))
    } as DispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(RootComponent);