import * as React from 'react';
import { ToggleButton, ToggleButtonGroup, ToggleButtonProps } from 'react-bootstrap';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/fontawesome-free-regular';

type OwnProps = {
    fieldName: string;
    value: number;
    errorMsg: string;
    isMoreThanTwoOptions: boolean;
};

type OwnState = {};

type DispatchProps = {
    onChange: (e: any) => void;
};

type Props = OwnProps & DispatchProps;

class EupatiNonCMSToggleBtn extends React.Component<Props, OwnState> {

    constructor(props: any) {
        super(props);

        this.returnProperIconType = this.returnProperIconType.bind(this);
    }

    returnProperIconType(icon: any) {
        return icon as IconProp;
    }

    render() {

        let values = [
            {
                id: true,
                name: 'Yes'
            },
            {
                id: false,
                name: 'No'
            },
            {
                id: null,
                name: 'Maybe'
            }
        ];

        let valuesComponent: any;

        let btnComponent: any;

        if (!this.props.isMoreThanTwoOptions) {
            if (values.length > 2) {
                values.pop();
            }
        }

        valuesComponent = values?.map((x, i) => {
            return (
                <ToggleButton
                    key={i}
                    name={this.props.fieldName}
                    className='radio-item'
                    value={`${x.id}`}
                    type='radio'
                >
                    <div className='toggle-btn'>
                        <FontAwesomeIcon
                            icon={this.returnProperIconType(faDotCircle)}
                            className='toggle-btn-circle'
                        />
                    </div>
                    <div className='check-btn-text'>{x.name}</div>
                </ToggleButton>
            )
        });

        btnComponent = (
            <React.Fragment key={'abcde'}>
                <ToggleButtonGroup
                    id={this.props.fieldName}
                    className='radio-items'
                    onChange={(e: any) => this.props.onChange(e)}
                    value={`${this.props.value}`}
                    name={this.props.fieldName}
                    type='radio'
                >
                    {valuesComponent}
                </ToggleButtonGroup>
                {!!this.props.errorMsg &&
                    <span className='text-danger'>{this.props.errorMsg}</span>
                }
            </React.Fragment>
        );

        return (
            <>
                {btnComponent}
            </>
        );
    }
}

export default EupatiNonCMSToggleBtn;