import React, { useMemo, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { Table } from 'reactstrap';
import { User } from '../models/main-models';
import GlobalFilter from './global-filter';
import { toast } from 'react-toastify';
import {
  REMOVE_ADMIN_SUCC_MSG,
  APPROVE_ADMIN_SUCC_MSG,
} from '../../common/helpers/messages';
import AuthenticationContext from '../../common/static/auth-context';
import './static.scss';
import { Roles } from '../helpers';
import ConfirmDenyModal from './confirm-deny-modal';

type OwnProps = {
  admins: User[];
};

type DispatchProps = {
  removeAdmin: (id: string, ownerId: string) => void;
  approveAdmin: (id: string, ownerId: string) => void;
};

type Props = OwnProps & DispatchProps;

export default function EupatiAllAdminsGrid(props: Props) {
  const contextData = useContext(AuthenticationContext);

  const handleOnRemove = (id: string, event: any) => {
    event.preventDefault();
    props.removeAdmin(id, contextData.userIdentity);
    toast.success(REMOVE_ADMIN_SUCC_MSG);
  };

  const handleOnApprove = (id: string, event: any) => {
    event.preventDefault();
    props.approveAdmin(id, contextData.userIdentity);
    toast.success(APPROVE_ADMIN_SUCC_MSG);
  };

  const data = useMemo(
    () =>
      props?.admins?.map((x: any, i: any) => {
        return {
          id: x.id,
          email: x.email,
          role: x.role,
        };
      }),
    [props?.admins]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups, // columns Header
    rows, // render data based on accessor
    // @ts-ignore
    page,
    prepareRow,
    // @ts-ignore
    visibleColumns,
    // @ts-ignore
    state,
    // @ts-ignore
    setGlobalFilter,
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );
  // @ts-ignore
  const { globalFilter, pageSize, pageIndex } = state;

  return (
    <>
      <h4
        style={{
          textAlign: 'center',
          textDecoration: 'underline',
          margin: '5rem auto 2.5rem auto',
        }}
      >
        ADMINS
      </h4>
      <Table {...getTableProps()} striped bordered hover>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((col) => (
                <th {...col.getHeaderProps()}>{col.render('Header')}</th>
              ))}
            </tr>
          ))}
          <tr>
            <th colSpan={visibleColumns.length} style={{ textAlign: 'left' }}>
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </th>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
                <>
                  {props.admins
                    .find((a) => a.id === row.original.id)
                    ?.role.toUpperCase() === Roles.Member && (
                    <td>
                      <Button
                        variant="success"
                        onClick={(e: any) =>
                          handleOnApprove(row.original.id, e)
                        }
                      >
                        APPROVE
                      </Button>
                    </td>
                  )}
                  <td>
                    <ConfirmDenyModal
                      {...{
                        targetId: row.original.id,
                        target: 'admin',
                        params: `email - ${
                          props.admins.find(
                            (a: User) => a.id === row.original.id
                          )?.email
                        }`,
                        handleRemove: handleOnRemove,
                      }}
                    />
                    {/* <Button
                                            variant="danger"
                                            onClick={(e: any) => handleOnRemove(row.original.id, e)}
                                        >
                                            REMOVE
                                        </Button> */}
                  </td>
                </>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
