import React from "react";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import EupatiMultiselect from "../../../../common/static/multiselect";
import { Rules } from "../../../../common/helpers";
import { Patient } from "../../../../common/models/main-models";
import { LookUpOptions } from "../../../../common/models/input-models";
import EupatiTextbox from "../../../../common/static/textbox";

type OwnProps = {
    patient: Patient
};

type DispatchProps = {
    prevPage: () => void;
    showPatientsDiseaseExpInfo: () => void;
    setPatientInfo: (patient: Patient) => void;
    returnProperDefaultValue: (val: any) => any;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
};

type Props = OwnProps & DispatchProps;

type FieldProps = {
    patientExpertises: number[],
    patientExpertiseDescription: string,
    projectTypeExperiences: number[],
    projectTypeExperiencesDescription: string
};

export default function PatientsProjectExpInfo(props: Props) {
    const { handleSubmit, control, watch, formState: { errors } } = useForm();

    const fieldsData = {
        patientExpertises: watch("patientExpertises") as number[],
        projectTypeExperiences: watch("projectTypeExperiences") as number[],
        patientExpertiseDescription: watch("patientExpertiseDescription") as string,
        projectTypeExperiencesDescription: watch("projectTypeExperiencesDescription") as string
    } as FieldProps;

    const onSubmit = (data: any) => {
        const editedPatientInfo = Object.assign(props.patient, Object.assign(data));
        props.setPatientInfo(editedPatientInfo);
        props.showPatientsDiseaseExpInfo();
    }

    const onBackHandler = () => {
        if (adjustFieldsValues() === 0) {
            const editedPatientInfo = Object.assign(props.patient, Object.assign(fieldsData));
            props.setPatientInfo(editedPatientInfo);
        }
        props.prevPage();
    }

    const adjustFieldsValues = () => {
        if (fieldsData.patientExpertises === undefined &&
            fieldsData.projectTypeExperiences === undefined &&
            fieldsData.patientExpertiseDescription === undefined &&
            fieldsData.projectTypeExperiencesDescription === undefined) {
            return 1;   // if user hasn't changed anything
        }

        if (fieldsData.patientExpertises === undefined &&
            props.patient.patientExpertises !== undefined) {
            fieldsData.patientExpertises = props.patient.patientExpertises;
        }

        if (fieldsData.projectTypeExperiences === undefined &&
            props.patient.projectTypeExperiences !== undefined) {
            fieldsData.projectTypeExperiences = [...props.patient.projectTypeExperiences];
        }

        if (fieldsData.projectTypeExperiencesDescription === undefined &&
            props.patient.projectTypeExperiencesDescription !== undefined) {
            fieldsData.projectTypeExperiencesDescription = props.patient.projectTypeExperiencesDescription;
        }

        if (fieldsData.patientExpertiseDescription === undefined &&
            props.patient.patientExpertiseDescription !== undefined) {
            fieldsData.patientExpertiseDescription = props.patient.patientExpertiseDescription;
        }

        return 0;
    }

    const toggleProjTypeExpField = () => {
        let projTypeExp: number[] = [];
        if (fieldsData.projectTypeExperiences !== undefined && fieldsData.projectTypeExperiences !== null) {
            projTypeExp = [...fieldsData.projectTypeExperiences];
        }
        else if (props.patient.projectTypeExperiences !== undefined && props.patient.projectTypeExperiences !== null) {
            projTypeExp = [...props.patient.projectTypeExperiences];
        }

        //if (orgTypes.length > 1) { return false; }
        let otherType = props.findLookUpData('projectType')?.find(ot => ot.name.toLowerCase().includes('other')); // gets { id: 3, name: 'Other' }
        return projTypeExp?.some((x) => x === otherType?.id) ? true : false;
    }

    const togglePtExpertiseField = () => {
        let ptExpertises: number[] = [];
        if (fieldsData.patientExpertises !== undefined && fieldsData.patientExpertises !== null) {
            ptExpertises = [...fieldsData.patientExpertises];
        }
        else if (props.patient.patientExpertises !== undefined && props.patient.patientExpertises !== null) {
            ptExpertises = [...props.patient.patientExpertises];
        }

        //if (orgTypes.length > 1) { return false; }
        let otherType = props.findLookUpData('expertise')?.find(ot => ot.name.toLowerCase().includes('other')); // gets { id: 3, name: 'Other' }
        return ptExpertises?.some((x) => x === otherType?.id) ? true : false;
    }

    return (
        <Form className='experience' onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='experience-info'>
                <Form.Group className='lcolumn'>

                    <Form.Group id='pt-expertise' className='experience-info-field mb-4'>
                        <Form.Label>Level of expertise (multiple answers possible)</Form.Label>
                        <Controller
                            name='patientExpertises'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.patientExpertises)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiMultiselect
                                    {...{
                                        ...field,
                                        errorMsg: errors?.message,
                                        fieldName: 'patientExpertises',
                                        values: props.findLookUpData('expertise')
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    {togglePtExpertiseField() &&
                        <Form.Group id='pt-expertiseDescr' className='experience-info-field mb-4'>
                            <Form.Label>Please specify level of expertise</Form.Label>
                            <Controller
                                name='patientExpertiseDescription'
                                control={control}
                                defaultValue={props.returnProperDefaultValue(props.patient.patientExpertiseDescription)}
                                rules={Rules.notRequired}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.patientExpertiseDescription?.message,
                                            placeholder: 'Please type level of expertise'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    }

                    <Form.Group id='pt-expcProj' className='experience-info-field mb-4'>
                        <Form.Label>Type of project experience (multiple answers possible)</Form.Label>
                        <Controller
                            name='projectTypeExperiences'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.projectTypeExperiences)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiMultiselect
                                    {...{
                                        ...field,
                                        errorMsg: errors?.projectTypeExperiences?.message,
                                        fieldName: 'projectTypeExperiences',
                                        values: props.findLookUpData('projectType')
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    {toggleProjTypeExpField() &&
                        <Form.Group id='pt-expcProjDescr' className='experience-info-field mb-4' >
                            <Form.Label>Please specify the type of project</Form.Label>
                            <Controller
                                name='projectTypeExperiencesDescription'
                                control={control}
                                defaultValue={props.returnProperDefaultValue(props.patient.projectTypeExperiencesDescription)}
                                rules={Rules.notRequired}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.projectTypeExperiencesDescription?.message,
                                            placeholder: 'Please specify project type'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    }

                    <Form.Group id='pt-btns'>
                        <Button className='bck' variant='secondary' type='submit' onClick={onBackHandler}>Back</Button>
                        <Button className='nxt' variant='primary' type='submit'>Next</Button>
                    </Form.Group>
                </Form.Group>
            </Form.Group>
        </Form>
    )
}