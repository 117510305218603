import { NAME } from "./constants";

export const SET_LOOKUP_DATA = `${NAME}/SET_LOOKUP_DATA`;

export const SET_LOADING = `${NAME}/SET_LOADING`;

export const SET_PROJECTS = `${NAME}/SET_PROJECTS`;

export const SET_PROJECTS_LOADING = `${NAME}/SET_PROJECTS_LOADING`;

export const SET_PROJECTS_FILTER = `${NAME}/SET_PROJECTS_FILTER`;

export const SET_MATCHED_PATIENTS = `${NAME}/SET_MATCHED_PATIENTS`;

export const SET_MATCHED_PATIENTS_LOADING = `${NAME}/SET_MATCHED_PATIENTS_LOADING`;

export const SET_PROJECT_DATA = `${NAME}/SET_PROJECT_DATA`;

export const SET_PROJECT_LOADING = `${NAME}/SET_PROJECT_LOADING`;

export const SET_TEMPLATES_LOADING = `${NAME}/SET_TEMPLATES_LOADING`;

export const SET_EMAIL_TEMPLATES = `${NAME}/SET_EMAIL_TEMPLATES`;

export const SET_SINGLE_EMAIL_TEMPLATE = `${NAME}/SET_SINGLE_EMAIL_TEMPLATE`;