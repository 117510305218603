import React, { useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { useTable } from 'react-table';

type OwnProps = {
    importPatients: any[]
};

type DispatchProps = {

};

type Props = OwnProps & DispatchProps;

export default function ImportPreviewGrid(props: Props) {
    const data = useMemo(
        () => props?.importPatients.map((x: any, i: any) => {
            return x
        }), [props?.importPatients]
    )

    const columns = useMemo(
        () => [
            {
                Header: 'First name',
                accessor: 'firstName'
            },
            {
                Header: 'Surname',
                accessor: 'lastName'
            },
            {
                Header: 'Phone number',
                accessor: 'phoneNumber'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Country',
                accessor: 'country'
            },
            // {
            //     Header: 'Country code',
            //     accessor: 'countryCode'
            // },
            {
                Header: 'Condition',
                accessor: 'condition'
            },
            {
                Header: 'Gender',
                accessor: 'gender'
            }
        ], []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data })

    return (
        <Table {...getTableProps()} striped bordered hover>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(col => (
                            <th {...col.getHeaderProps()}>
                                {col.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
}
