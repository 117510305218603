import React, { useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import EupatiTextbox from './textbox';
import EupatiDropdown from './dropdown';
import { CountriesWithCodeModel, LookUpData } from '../models/input-models';
import { Rules } from '../helpers';
import CheckoutResult from './checkout-outcome';
import EupatiLoader from './loading-animation/loader';
import './static.scss';

type Props = {
    lookUpData: LookUpData[],
    clientSecret: string,
    countries: CountriesWithCodeModel[];
    price?: number;
    plan?: string;
    package?: string;
    paymentMethod?: string;
};

type SubmitFormData = {
    name: string;
    phone: string;
    email: string;
    city: string;
    country: string;
    line1: string;
    line2: string;
    zip: string;//postal_code
    state: string;
};

export default function StripeCard(props: Props) {
    const { handleSubmit, control, watch, formState: { errors } } = useForm();

    const elements = useElements();
    const stripe = useStripe();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [shouldRenderSubComp, setShouldRenderSubComp] = useState<boolean>(false);
    const [isPaymentFailed, setIsPaymentFailed] = useState<boolean>(false);

    const fieldsData = {
        name: watch("name", '') as string,
        phone: watch("phone", '') as string,
        email: watch("email", '') as string,
        city: watch("city", '') as string,
        country: watch("country", '') as string,
        line1: watch("line1", '') as string,
        line2: watch("line2", '') as string,
        zip: watch("zip", '') as string,
        state: watch("state", '') as string,
    } as SubmitFormData;

    const onSubmit = async (data: SubmitFormData) => {
        if (!stripe || !elements) {
            return;
        }

        // confirm payment
        // TODO: add checkout outcome pages (success and fail)
        const cardElement = elements.getElement(CardElement);
        if (cardElement) {
            const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
                //props.clientSecret, {
                "pi_3Ktr2NAfbriVg9as0afScgCw_secret_RHZRBa3UvEqVqYrWXWVILMSj9", {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        address: {
                            city: data.city,
                            country: returnCountryCode(parseInt(data.country)),
                            line1: data.line1,
                            line2: data.line2,
                            postal_code: data.zip,
                            state: data.state
                        },
                        email: data.email,
                        name: data.name,
                        phone: data.phone
                    }
                }
            });
            setIsLoading(true);
            if (stripeError) {
                //console.error(stripeError);
                setIsPaymentFailed(true);
                const message = stripeError.message;
                if (message) {
                    setMessage(message);
                }
                else {
                    setMessage('Something went wrong...');
                }
                swapComponents(true);
                return;
            }

            //console.log(paymentIntent);
            setIsPaymentFailed(false);
            setMessage('you can now create a project');
            swapComponents(true);
        }
    }

    function findLookUpData(lookUpDataType?: string) {
        if (props.lookUpData &&
            props.lookUpData.length > 0) {
            return props.lookUpData?.filter((x: any) => x.name == lookUpDataType)[0]?.data;
        }

        return [];
    }

    const returnCountryCode = (countryId: number) => {
        const country = props.countries.find(c => c.id === countryId);
        if (country) {
            return country.code;
        }

        return '';
    }

    const swapComponents = (shouldSwap: boolean) => {
        setIsLoading(true);
        setShouldRenderSubComp(shouldSwap);
        setTimeout(() => {
            setIsLoading(false);
        }, 300);
    }

    const cardElementOptions = {
        // a way to inject styles into stripe iframe
        hidePostalCode: true,
    }

    let loadComp = (
        <div className='loader-wrapper'>
            <EupatiLoader />
        </div>
    );

    let mainComp = null;
    let subComp = null;
    if (isLoading) {
        mainComp = loadComp;
        subComp = loadComp;
    }
    else {
        //#region MainComponent
        mainComp = (
            <div id='stripe-payment'>
                <h4>Checkout</h4>
                <Form id='stripe-payment-form' onSubmit={handleSubmit(onSubmit)}>
                    {/* <Form.Group className='billing-info'>
                        <h6 className='billing-info-text'>Billing details</h6>
                    </Form.Group> */}

                    <Form.Group id='row-wrapper'>
                        <Form.Group className='stripe-payment-field' id='row'>
                            <Form.Label>Name</Form.Label>
                            <Controller
                                control={control}
                                name='name'
                                rules={Rules.requiredMessage}
                                defaultValue={fieldsData.name}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.name?.message,
                                            placeholder: 'John Doe'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='stripe-payment-field' id='row'>
                            <Form.Label>Phone</Form.Label>
                            <Controller
                                control={control}
                                name='phone'
                                rules={Rules.requiredMessage}
                                defaultValue={fieldsData.phone}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.phone?.message,
                                            placeholder: '+447975777666'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='stripe-payment-field' id='row'>
                            <Form.Label>Email</Form.Label>
                            <Controller
                                control={control}
                                name='email'
                                rules={Rules.requiredMessage}
                                defaultValue={fieldsData.email}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.email?.message,
                                            placeholder: 'john.doe@example.com'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='stripe-payment-field' id='row'>
                            <Form.Label>City</Form.Label>
                            <Controller
                                control={control}
                                name='city'
                                rules={Rules.requiredMessage}
                                defaultValue={fieldsData.city}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.city?.message,
                                            placeholder: 'Essex'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='stripe-payment-field' id='row'>
                            <Form.Label>Аddress Line 1</Form.Label>
                            <Controller
                                control={control}
                                name='line1'
                                rules={Rules.requiredMessage}
                                defaultValue={fieldsData.line1}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.line1?.message,
                                            placeholder: 'Street address'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='stripe-payment-field' id='row'>
                            <Form.Label>Address Line 2</Form.Label>
                            <Controller
                                control={control}
                                name='line2'
                                rules={Rules.notRequired}
                                defaultValue={fieldsData.line2}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.line2?.message,
                                            placeholder: 'Apartment, building, floor'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='stripe-payment-field' id='row'>
                            <Form.Label>Postal code</Form.Label>
                            <Controller
                                control={control}
                                name='zip'
                                rules={Rules.requiredMessage}
                                defaultValue={fieldsData.zip}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.zip?.message,
                                            placeholder: 'CB1 6NU'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='stripe-payment-field' id='row'>
                            <Form.Label>Province</Form.Label>
                            <Controller
                                control={control}
                                name='state'
                                rules={Rules.requiredMessage}
                                defaultValue={fieldsData.state}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.state?.message,
                                            placeholder: 'Greater London'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    </Form.Group>

                    <Form.Group id='row-wrapper-2'>
                        <Form.Group className='stripe-payment-field mb-4' id='row-2'>
                            <Form.Label>Country</Form.Label>
                            <Controller
                                control={control}
                                name='country'
                                rules={Rules.selectOptionMessage}
                                defaultValue={fieldsData.country}
                                render={({ field }) =>
                                    <EupatiDropdown
                                        {...{
                                            ...field,
                                            errorMsg: errors?.country?.message,
                                            fieldName: 'countries',
                                            values: findLookUpData('country'),
                                            defaultOptionText: 'Please select country...'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>

                        <Form.Group className='card-info' id='row-2'>
                            <Form.Label htmlFor='stripe-card-element'>Card information</Form.Label>
                            <Form.Group className="form-control" style={{ height: '2.4rem', paddingTop: '.7em' }} >
                                <CardElement
                                    id='stripe-card-element'
                                    options={cardElementOptions}
                                />
                            </Form.Group>
                        </Form.Group>
                    </Form.Group>
                    <Button type='submit'>Pay</Button>
                </Form>
            </div>
        );
        //#endregion

        //#region SubComponent
        subComp = (
            <>
                <CheckoutResult
                    {...{
                        isFail: isPaymentFailed,
                        message: message,
                        plan: props.plan,
                        package: props.package,
                        paymentMethod: props.paymentMethod,
                        price: props.price,
                        clientSecret: props.clientSecret,
                        swapComponents: swapComponents,
                        startLoading: () => setIsLoading(true)
                    }}
                />
            </>
        );
        //#endregion
    }

    return (
        <>
            {shouldRenderSubComp ? (
                <>
                    {subComp}
                </>
            ) : (
                <>
                    {mainComp}
                </>
            )}
        </>
    )
}