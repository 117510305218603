export const CATALOG_TYPES = {
    Basic: 'Basic',
    TailorMade: 'TailorMade'
};

export const CATEGORY_NAMES = {
    Researcher: 'ExternalResearcher',
    Industry: 'IndustryPartner',
    Academia: 'AcademiaPartner'
};

export const OPTION_NAMES = {
    Network: 'NetworkOption',
    Mentorship: 'MentorshipOption'
};

export const CONVERTED_NAMES = {
    //  based on pricing excel spreadsheet
    AcademiaPartner: 'EUPATI Partner - Academic',
    IndustryPartner: 'EUPATI Partner - Industry',
    ExternalResearcher: 'External Researcher',
    NetworkOption: 'External Networks Option',
    MentorshipOption: 'Mentorship Option',
    TailorMadeType: 'Tailor-Made'
}