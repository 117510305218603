import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Form } from 'react-bootstrap';
import { Rules } from "../../../../common/helpers";
import EupatiMultiselect from "../../../../common/static/multiselect";
import { Patient } from "../../../../common/models/main-models";
import { LookUpOptions } from "../../../../common/models/input-models";
import EupatiTextbox from "../../../../common/static/textbox";

type OwnProps = {
    patient: Patient
};

type DispatchProps = {
    prevPage: () => void;
    setPatientInfo: (patient: Patient) => void;
    showPatientCompensationReqInfo: () => void;
    returnProperDefaultValue: (val: any) => any;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
};

type Props = OwnProps & DispatchProps;

type FieldsProps = {
    projectPreferences: number[],
    projectPreferenceDescription: string,
    organisationPreferences: number[],
    organisationPreferenceDescription: string
};

export default function PatientProjectReqInfo(props: Props) {

    const { handleSubmit, control, watch, formState: { errors } } = useForm();

    const fieldsData = {
        projectPreferences: watch("projectPreferences") as number[],
        projectPreferenceDescription: watch("projectPreferenceDescription") as string,
        organisationPreferences: watch("organisationPreferences") as number[],
        organisationPreferenceDescription: watch("organisationPreferenceDescription") as string
    } as FieldsProps;

    const onSubmit = (data: any) => {
        const editedPatientInfo = Object.assign(props.patient, data);
        props.setPatientInfo(editedPatientInfo);
        props.showPatientCompensationReqInfo();
    }

    function onBackHandler() {
        if (adjustFieldsValues() === 0) {
            const editedPatientInfo = Object.assign(props.patient, fieldsData);
            props.setPatientInfo(editedPatientInfo);
        }
        props.prevPage();
    }

    const orderLookUpOptionsByNameLength = (options: LookUpOptions[] | null) => {
        if (options !== null) {
            return options.sort((a, b) => a.name.length - b.name.length);
        }

        return null;
    }

    const toggleOrgPrefField = () => {
        let orgTypes: number[] = [];
        if (fieldsData.organisationPreferences !== undefined && fieldsData.organisationPreferences !== null) {
            orgTypes = [...fieldsData.organisationPreferences];
        }
        else if (props.patient.organisationPreferences !== undefined && props.patient.organisationPreferences !== null) {
            orgTypes = [...props.patient.organisationPreferences];
        }

        //if (orgTypes.length > 1) { return false; }
        let otherType = props.findLookUpData('organisationType')?.find(ot => ot.name.toLowerCase().includes('other')); // gets { id: 3, name: 'Other' }
        return orgTypes?.some((x) => x === otherType?.id) ? true : false;
    }

    const toggleProjPrefField = () => {
        let projPrefs: number[] = [];
        if (fieldsData.projectPreferences !== undefined && fieldsData.projectPreferences !== null) {
            projPrefs = [...fieldsData.projectPreferences];
        }
        else if (props.patient.projectPreferences !== undefined && props.patient.projectPreferences !== null) {
            projPrefs = [...props.patient.projectPreferences];
        }

        //if (orgTypes.length > 1) { return false; }
        let otherType = props.findLookUpData('projectType')?.find(ot => ot.name.toLowerCase().includes('other')); // gets { id: 3, name: 'Other' }
        return projPrefs?.some((x) => x === otherType?.id) ? true : false;
    }

    const adjustFieldsValues = () => {
        if (fieldsData.projectPreferences === undefined &&
            fieldsData.organisationPreferences === undefined &&
            fieldsData.projectPreferenceDescription === undefined &&
            fieldsData.organisationPreferenceDescription === undefined) {
            return 1;   // if user hasn't changed anything
        }

        if (fieldsData.projectPreferences === undefined &&
            props.patient.projectPreferences !== undefined) {
            fieldsData.projectPreferences = [...props.patient.projectPreferences];
        }

        if (fieldsData.organisationPreferences === undefined &&
            props.patient.organisationPreferences !== undefined) {
            fieldsData.organisationPreferences = [...props.patient.organisationPreferences];
        }

        if (fieldsData.projectPreferenceDescription === undefined &&
            props.patient.projectPreferenceDescription !== undefined) {
            fieldsData.projectPreferenceDescription = props.patient.projectPreferenceDescription;
        }

        if (fieldsData.organisationPreferenceDescription === undefined &&
            props.patient.organisationPreferenceDescription !== undefined) {
            fieldsData.organisationPreferenceDescription = props.patient.organisationPreferenceDescription;
        }

        return 0;
    }

    return (
        <Form className='requirements' onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='requirements-info'>
                <Form.Group className='lcolumn'>

                    <Form.Group id='pt-tpProj' className='requirements-info-field mb-3'>
                        <Form.Label>Preference on types of projects (multiple answers possible)</Form.Label>
                        <Controller
                            name='projectPreferences'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.projectPreferences)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiMultiselect
                                    {...{
                                        ...field,
                                        errorMsg: errors?.projectPreferences?.message,
                                        fieldName: 'projectPreferences',
                                        values: props.findLookUpData('projectType')
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    {toggleProjPrefField() &&
                        <Form.Group id='pt-projPrefDescr' className='requirements-info-field mb-3'>
                            <Form.Label>Please specify project preference</Form.Label>
                            <Controller
                                name='projectPreferenceDescription'
                                control={control}
                                defaultValue={props.returnProperDefaultValue(props.patient.projectPreferenceDescription)}
                                rules={Rules.notRequired}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.projectPreferenceDescription?.message,
                                            placeholder: 'Please type project preference'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    }

                    <Form.Group id='pt-orgTp' className='requirements-info-field mb-3'>
                        <Form.Label>Preference on types of organisation (multiple answers possible)</Form.Label>
                        <Controller
                            name='organisationPreferences'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.patient.organisationPreferences)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiMultiselect
                                    {...{
                                        ...field,
                                        errorMsg: errors?.projectPreferences?.message,
                                        fieldName: 'organisationPreferences',
                                        values: orderLookUpOptionsByNameLength(props.findLookUpData('organisationType'))
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    {toggleOrgPrefField() &&
                        <Form.Group id='pt-orgTpDescr' className='requirements-info-field mb-3'>
                            <Form.Label>Please specify organisation preference</Form.Label>
                            <Controller
                                name='organisationPreferenceDescription'
                                control={control}
                                defaultValue={props.returnProperDefaultValue(props.patient.organisationPreferenceDescription)}
                                rules={Rules.notRequired}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.organisationPreferenceDescription?.message,
                                            placeholder: 'Please type organisation preference'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    }

                    <Form.Group id='pt-btns'>
                        <Button className='bck' variant='secondary' type='submit' onClick={onBackHandler}>Back</Button>
                        <Button className='nxt' variant='primary' type='submit'>Next</Button>
                    </Form.Group>
                </Form.Group>
            </Form.Group>
        </Form>
    )
}