import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { Rules, Messages } from '../../../../common/helpers';
import EupatiMultiselect from '../../../../common/static/multiselect';
import EupatiGDPRBtn from '../../../../common/static/GDPR-toggle';
import { useHistory } from 'react-router';
import EupatiTextarea from '../../../../common/static/textarea';
import { Project } from '../../../../common/models/main-models';
import { LookUpOptions } from '../../../../common/models/input-models';
import EupatiTextbox from '../../../../common/static/textbox';
import { toast } from 'react-toastify';

type OwnProps = {
    project: Project,
    isUserReal: boolean,
};

type DispatchProps = {
    setProject: (project: Project) => void;
    submitProject: (project: Project) => void;
    returnProperDefaultValue: (val: any) => any;
    hidePatientsAdditionalInfoSection: () => void;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
    nextPage: () => void;
};

type Props = OwnProps & DispatchProps;

type FieldsProps = {
    patientExpertises: number[],
    patientExpertisesDescription: string,
    otherInformation: string,
    isGDPRConsentGiven: boolean
};

export default function PatientsAdditionalInfo(props: Props) {

    const history = useHistory();

    const { handleSubmit, control, watch, formState: { errors } } = useForm();

    const fieldsData = {
        patientExpertises: watch("patientExpertises") as number[],
        patientExpertisesDescription: watch("patientExpertisesDescription") as string,
        otherInformation: watch("otherInformation") as string,
        isGDPRConsentGiven: watch("isGDPRConsentGiven") as boolean
    } as FieldsProps;

    const onSubmit = (data: any) => {
        if (!props.isUserReal) {
            toast.error(Messages.FAIL_AUTH_MSG);
            return history.push('/');
        }

        const updatedProject = Object.assign(props.project, Object.assign(data));
        props.submitProject(updatedProject);
        props.nextPage();
    }

    const onBackHandler = () => {
        if (!props.isUserReal) {
            toast.error(Messages.FAIL_AUTH_MSG);
            return history.push('/');
        }

        if (adjustFieldsValues() === 0) {
            const updatedProject = Object.assign(props.project, Object.assign(fieldsData));
            props.setProject(updatedProject);
        }
        props.hidePatientsAdditionalInfoSection();
    }

    const adjustFieldsValues = () => {
        if (fieldsData.patientExpertises === undefined &&
            fieldsData.patientExpertisesDescription === undefined &&
            fieldsData.otherInformation === undefined &&
            fieldsData.isGDPRConsentGiven === undefined) {
            return 1;   // if user hasn't changed anything
        }

        if (fieldsData.patientExpertises === undefined &&
            props.project.patientExpertises !== undefined) {
            fieldsData.patientExpertises = [...props.project.patientExpertises];
        }

        if (fieldsData.patientExpertisesDescription === undefined &&
            props.project.patientExpertisesDescription !== undefined) {
            fieldsData.patientExpertisesDescription = props.project.patientExpertisesDescription;
        }

        if (fieldsData.otherInformation === undefined &&
            props.project.otherInformation !== undefined) {
            fieldsData.otherInformation = props.project.otherInformation;
        }

        if (fieldsData.isGDPRConsentGiven === undefined &&
            props.project.isGDPRConsentGiven !== undefined) {
            fieldsData.isGDPRConsentGiven = props.project.isGDPRConsentGiven;
        }

        return 0;
    }

    const toggleField = () => {
        let ptExpertises: number[] = [];
        if (fieldsData.patientExpertises !== undefined && fieldsData.patientExpertises !== null) {
            ptExpertises = [...fieldsData.patientExpertises];
        }
        else if (props.project.patientExpertises !== undefined && props.project.patientExpertises !== null) {
            ptExpertises = [...props.project.patientExpertises];
        }

        let otherType = props.findLookUpData('expertise')?.find(ot => ot.name.toLowerCase().includes('other'));
        return ptExpertises?.some((x) => x === otherType?.id) ? true : false;
    }

    return (
        <Form className='pt' onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='pt-info'>
                <Form.Group className='rcolumn'>

                    <Form.Group id='pt-lvlExprt' className='pt-info-field mb-4'>
                        <Form.Label>Level of expertise (multiple answers possible)</Form.Label>
                        <Controller
                            name='patientExpertises'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.patientExpertises)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiMultiselect
                                    {...{
                                        ...field,
                                        fieldName: 'patientExpertises',
                                        values: props.findLookUpData('expertise'),
                                        errorMsg: errors?.patientExpertises?.message
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    {toggleField() &&
                        <Form.Group id='pt-lvlExprtDescr' className='pt-info-field mb-4'>
                            <Form.Label>Please specify your level of expertise</Form.Label>
                            <Controller
                                name='patientExpertisesDescription'
                                control={control}
                                defaultValue={props.returnProperDefaultValue(props.project.patientExpertisesDescription)}
                                rules={Rules.notRequired}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.patientExpertisesDescription?.message,
                                            placeholder: 'Please type level of expertise'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    }

                    <Form.Group id='pt-otherInfo' className='pt-info-field mb-3'>
                        <Form.Label>Additional patient information</Form.Label>
                        <Controller
                            name='otherInformation'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.otherInformation)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextarea
                                    {...{
                                        ...field,
                                        errorMsg: errors?.otherInformation?.message,
                                        rowsCount: 8
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='pt-gdpr' className='pt-info-field mb-4'>
                        <Controller
                            name='isGDPRConsentGiven'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.isGDPRConsentGiven)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiGDPRBtn
                                    {...{
                                        ...field,
                                        fieldName: 'isGDPRConsentGiven',
                                        errorMsg: errors?.isGDPRConsentGiven?.message
                                    }}
                                />
                            }
                        />
                        <small className='google-message'>
                            This site is protected by reCAPTCHA and the Google
                            <a className='google-message-privacy' href="https://policies.google.com/privacy" target="_blank"> Privacy Policy</a> and
                            <a className='google-message-tos' href="https://policies.google.com/terms" target="_blank"> Terms of Service</a> apply.
                        </small>
                    </Form.Group>

                    <Form.Group id='pt-btns' className='pt-info-field'>
                        <Button className='bck' variant='secondary' onClick={onBackHandler} >Back</Button>
                        <Button className='nxt' variant='primary' type='submit'>Submit</Button>
                    </Form.Group>
                </Form.Group>
            </Form.Group>
        </Form>
    )
}