import { default as RootComponent, DispatchProps } from '../components/component-root';
import * as Constants from '../constants';
import * as Actions from '../actions';
import { State } from '../model';
import { connect } from 'react-redux';
import { PatientSearchData, ProjectPatientData, RequestData } from '../../../../common/models/input-models';

export function mapStateToProps(globalState: any) {
    let name = Constants.NAME;
    let pageState = globalState[name] as State;
    return pageState;
}

export function mapDispatchToProps(dispatch: any) {
    return {
        setLookUpData: () => dispatch(Actions.getLookUpDataAsync()),
        setProjects: (condition: number) => dispatch(Actions.getAllFilteredProjectsAsync(condition)),
        setFilter: (filter: number) => dispatch(Actions.setProjectsFilter(filter)),
        setMatchedPatients: (searchData: PatientSearchData, projectName: string) => dispatch(Actions.updateSearchPatients(searchData, projectName)),
        setProjectById: (id: string) => dispatch(Actions.getProjectDataByIdAsync(id)),
        removePatient: (inputModel: ProjectPatientData) => dispatch(Actions.removePatientFromProjectAsync(inputModel)),
        addPatient: (inputModel: ProjectPatientData, searchData: PatientSearchData, projectName: string) => dispatch(Actions.addPatientToProjectAsync(inputModel, searchData, projectName)),
        changeProjectStatus: (id: string, filter: number) => dispatch(Actions.changeProjectStatusAsync(id, filter)),
        submitRequest: (data: RequestData) => dispatch(Actions.submitEmailDataAsync(data)),
        changePatientStatus: (inputModel: ProjectPatientData) => dispatch(Actions.changePatientStatusToConfirmAsync(inputModel)),
        getAllEmailTemplates: () => dispatch(Actions.getAllEmailTemplatesAsync()),
        getEmailTemplateByName: (name: string) => dispatch(Actions.getEmailTemplateByNameAsync(name))
    } as DispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(RootComponent);