import * as React from 'react';
import { ToggleButton, ToggleButtonGroup, ToggleButtonProps } from 'react-bootstrap';
import { LookUpOptions } from '../models/input-models';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/fontawesome-free-regular';

type OwnProps = {
    fieldName: string;
    values: LookUpOptions[] | null;
    value: number;
    errorMsg: string;
};

type OwnState = {};

type DispatchProps = {
    onChange: (e: any) => void;
};

type Props = OwnProps & DispatchProps;

class EupatiToggleBtn extends React.Component<Props, OwnState> {

    constructor(props: any) {
        super(props);

        this.returnProperIconType = this.returnProperIconType.bind(this);
    }

    returnProperIconType(icon: any) {
        return icon as IconProp;
    }

    render() {

        let valuesComponent: any;

        let btnComponent: any;

        valuesComponent = this.props.values?.map((x, i) => {
            return (
                <ToggleButton
                    key={i}
                    type='radio'
                    value={x.id}
                    name={this.props.fieldName}
                >
                    <div className='toggle-btn'>
                        <FontAwesomeIcon                             
                            icon={this.returnProperIconType(faDotCircle)}
                            className='toggle-btn-circle'
                        />
                    </div>
                    <div className='check-btn-text'><span style={{ clear: 'both', display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap' }}>{x.name.trim()}</span></div>
                </ToggleButton>
            )
        });

        btnComponent = (
            <ToggleButtonGroup
                id={this.props.fieldName}
                className={this.props.fieldName}
                value={this.props.value}
                name={this.props.fieldName}
                onChange={(e: any) => this.props.onChange(e)}
            >
                {valuesComponent}
            </ToggleButtonGroup>
        );

        return (
            <>
                {btnComponent}
                {!!this.props.errorMsg &&
                    <span className='text-danger'>{this.props.errorMsg}</span>
                }
            </>
        );
    }
}

export default EupatiToggleBtn;