import ProjectsSvc from './projects-svc';
import PatientSvc from './patients-svc';
import UtilsSvc from './utils-svc';
import UsersSvc from './users-svc';
import PaymentSvc from './payment-svc';

export default class DataSvc {
	public static projectsSvc: ProjectsSvc = new ProjectsSvc();
	public static patientSvc: PatientSvc = new PatientSvc();
	public static utilsSvc: UtilsSvc = new UtilsSvc();
	public static usersSvc: UsersSvc = new UsersSvc();
	public static paymentSvc: PaymentSvc = new PaymentSvc();
}