import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Form } from 'react-bootstrap';
import { CategoryData, OptionData } from '../../../common/models/input-models';
import astericsImg from '../../../../images/pricing/asterics.png';
import { faCheck, faChevronDown, faChevronUp, faSquare } from '@fortawesome/fontawesome-free-solid';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CATALOG_TYPES, CATEGORY_NAMES } from '../../../common/helpers/pricing-list';
import { INVALID_PRICING_DATA, INVALID_PAYMENT_METHOD } from '../../../common/helpers/messages';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

type OwnProps = {
    tailorMadePlanCategories: CategoryData[],
    paymentMethods: string[]
};

export type DispatchProps = {
    returnIcon: (icon: any) => IconProp;
    convertCategoriesNames: (name: string) => string;
    convertOptionsNames: (name: string) => string;
};

type Props = DispatchProps & OwnProps;

type SelectedOptionData = {
    price: number,
    text: string[],
    plan: string,
    index: number,
    bgColor: string,
    additionals: AdditionalsData[]
}

type AdditionalsData = {
    name: string,
    price: number
}

const DEFAULT_COLOR_SELECTED = '#00A4B6';
const DEFAULT_OPTIONS_MENU = ['Mentorship Option', 'External Networks Option']; //  if these ever change blame Tech0
const SELECT_PRICING_WARNING = 'Please first select pricing option';
export default function TailorMadeComponent(props: Props) {
    const history = useHistory();

    const [selectedOption, setSelectedOption] = useState<SelectedOptionData>({ price: -1, text: [], plan: CATALOG_TYPES.TailorMade, index: -1, bgColor: DEFAULT_COLOR_SELECTED, additionals: [] });
    const [icon, setIcon] = useState<IconProp>(faChevronDown as IconProp);
    const [paymentIcon, setPaymentIcon] = useState<IconProp>(faChevronUp as IconProp);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('');
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<string>('');

    useEffect(() => {
        setDefaultPaymentMethod(props.paymentMethods.find(pm => pm.toLowerCase().includes('invoice'))!);//component-root state
    }, []);

    function updateStateOnClick(price: number, categoryName: string, categoryIndex: number) {
        //  calls only when selecting category (ExternalResearcher, IndustryPartner, AcademiaPartner)
        const updatedText = [categoryName];
        setSelectedOption({ price: price, text: updatedText, plan: CATALOG_TYPES.TailorMade, index: categoryIndex, additionals: [], bgColor: DEFAULT_COLOR_SELECTED });
    }

    function removeItemFromAdditionals(option: AdditionalsData) {
        const updatedAdditionals = selectedOption.additionals.filter(x => x.name !== option.name);
        const updatedText = selectedOption.text.filter(x => x !== option.name);
        setSelectedOption({
            price: selectedOption.price -= option.price,
            text: updatedText,
            plan: CATALOG_TYPES.TailorMade,
            index: selectedOption.index,
            bgColor: selectedOption.bgColor,
            additionals: updatedAdditionals
        });
    }

    function addItemToAdditionals(option: AdditionalsData) {
        const updatedAdditionals = selectedOption.additionals.concat(option);
        const updatedText = selectedOption.text.concat(option.name);
        setSelectedOption({
            price: selectedOption.price += option.price,
            text: updatedText,
            plan: CATALOG_TYPES.TailorMade,
            index: selectedOption.index,
            bgColor: selectedOption.bgColor,
            additionals: updatedAdditionals
        });
    }

    function updateAdditionalOptionsOnClick(option: AdditionalsData) {
        selectedOption.additionals.some(x => x.name === option.name) ? removeItemFromAdditionals(option) : addItemToAdditionals(option);
    }

    function updatePaymentMethodOnClick(method: string) {
        if (selectedOption.price === -1) {
            toast.error(INVALID_PRICING_DATA);
            return;
        }
        setSelectedPaymentMethod(method);
    }

    function toggleIcons() {
        /*
            down --> open
            up   --> close
        */
        if (icon === props.returnIcon(faChevronDown)) {
            setIcon(props.returnIcon(faChevronUp));
            setPaymentIcon(props.returnIcon(faChevronDown));
        }
        else {
            setIcon(props.returnIcon(faChevronDown));
            setPaymentIcon(props.returnIcon(faChevronDown));
        }
    }

    function reverseToggleIcon() {
        if (paymentIcon === props.returnIcon(faChevronUp)) {
            setPaymentIcon(props.returnIcon(faChevronDown));
            setIcon(props.returnIcon(faChevronDown));
        }
        else {
            setPaymentIcon(props.returnIcon(faChevronUp));
            setIcon(props.returnIcon(faChevronDown));
        }
    }

    function displayCategoryNameWithOptions() {
        let result = '';
        const categoryName = selectedOption.text.find(x => Object.values(CATEGORY_NAMES).some(name => name.toLowerCase() === x.toLowerCase()));
        if (categoryName) {
            //  "NetworkOption"--> "External Networks Option" --> "External Networks"   (convert names based on xlsx and extract 'Option' due to repetitiveness)
            const optionNames = selectedOption.text.filter(x => x !== categoryName).map(x => props.convertOptionsNames(x)).map(x => x.replace('Option', '').trim());
            //  "External Researcher" --> "External Researcher with"   (depends on whether optionNames.length > 0)
            result = optionNames.length > 0 ? `${props.convertCategoriesNames(categoryName)} with ` : `${props.convertCategoriesNames(categoryName)}`;
            //  "Mentorship" --> "Mentorship and External Networks"   (depends on whether optionNames.length > 1)
            optionNames.length > 1 ? result += `${optionNames.join(' and ')}` : optionNames.forEach(o => result += `${o}`);
        }

        return result.trim();
    }

    const selectedOptionStyle = {
        backgroundColor: selectedOption.bgColor,
        color: '#ffffff'
    };

    const submitBtn = (
        <div className='pricing-plan-card-submit-button'>
            <Button
                variant='primary'
                type='submit'
                className='submit-btn'
                onClick={() => {
                    if (selectedOption.price < 0 || selectedOption.text.length === 0) {
                        return toast.error(INVALID_PRICING_DATA);
                    }
                    else if (selectedPaymentMethod === '') {
                        return toast.error(INVALID_PAYMENT_METHOD);
                    }
                    history.push({
                        pathname: '/new-project',
                        state: {
                            price: selectedOption.price,
                            plan: selectedOption.plan,
                            package: displayCategoryNameWithOptions(),
                            paymentMethod: selectedPaymentMethod
                        }
                    });
                    //if they wanna pay via invoice
                    //redirect directly to new project page
                    //cos our stripe implementation works only with cards
                    // switch (selectedPaymentMethod === defaultPaymentMethod) {
                    //     case true:
                    //         history.push({
                    //             pathname: '/new-project',
                    //             state: {
                    //                 price: selectedOption.price,
                    //                 plan: selectedOption.plan,
                    //                 package: displayCategoryNameWithOptions(),
                    //                 paymentMethod: selectedPaymentMethod
                    //             }
                    //         });
                    //         break;
                    //     default:
                    //         history.push({
                    //             pathname: '/payment',
                    //             state: {
                    //                 price: selectedOption.price,
                    //                 plan: selectedOption.plan,
                    //                 package: displayCategoryNameWithOptions(),
                    //                 paymentMethod: selectedPaymentMethod
                    //             }
                    //         });
                    //         break;
                    // }
                }}
            >
                Submit Your Request
            </Button>
        </div>
    );

    const categoriesCard = (
        <Card style={{ padding: '34px 26px 0px 26px' }}>
            <Card.Header style={{ marginBottom: '0.5rem', borderRadius: '4px' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={toggleIcons}>
                    Pricing
                    <FontAwesomeIcon icon={props.returnIcon(icon)} />
                </Accordion.Toggle>

            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    {props?.tailorMadePlanCategories?.map((category: CategoryData, index: number) => {
                        return (
                            <Form.Group
                                key={index + 'ge'}
                                style={selectedOption.index === index ? { ...selectedOptionStyle } : {}}
                                onClick={() => updateStateOnClick(category.basePrice, category.name, index)}
                            >
                                <Form.Label id='cat-price'>{`€	${category.basePrice}`}</Form.Label>
                                <Form.Label id='cat-name' >{props.convertCategoriesNames(category.name)}</Form.Label>
                            </Form.Group>
                        )
                    })}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );

    // const optionsCard = (
    //     <Card>
    //         <Card.Header style={{ marginBottom: '0.5rem', borderRadius: '4px' }}>
    //             <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={toggleIcons}>
    //                 Options
    //                 <FontAwesomeIcon icon={props.returnIcon(icon)} />
    //             </Accordion.Toggle>
    //         </Card.Header>
    //         <Accordion.Collapse eventKey="0">
    //             <Card.Body>
    //                 {props.tailorMadePlanCategories[selectedOption.index]?.additionalOptionsData.map((option: OptionData, optionIndex: number) => {
    //                     return (
    //                         <Form.Group
    //                             style={selectedOption.additionals.some(x => x.name === option.name) ? { ...selectedOptionStyle } : {}}
    //                             onClick={() => updateAdditionalOptionsOnClick({ name: option.name, price: option.price } as AdditionalsData)}
    //                         >
    //                             <Form.Label id='cat-price'>{`€	${option.price}`}</Form.Label>
    //                             <Form.Label id='cat-name' >{props.convertOptionsNames(option.name)}</Form.Label>
    //                         </Form.Group>
    //                     )
    //                 })}
    //             </Card.Body>
    //         </Accordion.Collapse>
    //     </Card>
    // );

    const optionsBlock = (
        <section className='options-section'>
            <div className='options-section-title'>
                <label>ADDITIONAL OPTIONS:</label>
            </div>
            <div className='options-section-menu'>
                {props.tailorMadePlanCategories[selectedOption.index] === undefined ? (
                    <React.Fragment key={selectedOption.index + 'a'}>
                        {DEFAULT_OPTIONS_MENU.map((optionName: string, index: number) => {
                            return (
                                <div
                                    key={index + 'ce'}
                                    className='options-section-menu-item'
                                    onClick={() => toast.error(SELECT_PRICING_WARNING)}>
                                    <FontAwesomeIcon icon={props.returnIcon(faSquare)} />
                                    <label>{optionName}</label>
                                </div>
                            )
                        })}
                    </React.Fragment>
                ) : (
                    <React.Fragment key={selectedOption.index + 'be'}>
                        {props.tailorMadePlanCategories[selectedOption.index]?.additionalOptionsData.map((option: OptionData, optionIndex: number) => {
                            return (
                                <div
                                    key={optionIndex + 'ef'}
                                    className='options-section-menu-item'
                                    onClick={() => updateAdditionalOptionsOnClick({ name: option.name, price: option.price } as AdditionalsData)}
                                >
                                    <FontAwesomeIcon icon={props.returnIcon(faSquare)} style={selectedOption.additionals.some(x => x.name === option.name) ? { color: 'rgb(0, 164, 182)', backgroundColor: '#fff' } : {}} />
                                    <label>{`${props.convertOptionsNames(option.name)}  -   €	${option.price}`}</label>
                                </div>
                            )
                        })}
                    </React.Fragment>
                )}
            </div>
        </section>
    );

    const paymentOptions = (
        <>
            {selectedOption.price === 0 ? (
                <Form.Group
                    key={69420 + 'b'}
                >
                    <div
                        className='payments-wrapper'
                        style={selectedPaymentMethod === defaultPaymentMethod ? { ...selectedOptionStyle } : {}}
                        onClick={() => updatePaymentMethodOnClick(defaultPaymentMethod)}
                    >
                        <div className='payment-check'>
                            <FontAwesomeIcon icon={props.returnIcon(faCheck)} style={selectedPaymentMethod === defaultPaymentMethod ? { position: 'relative', top: '0', left: '0', color: '#fff' } : { position: 'relative', top: '0', left: '0' }} />
                        </div>
                        <Form.Label id='cat-name'>{defaultPaymentMethod}</Form.Label>
                    </div>
                </Form.Group>
            ) : (
                <>
                    {props.paymentMethods.map((method: string, methodIndex: number) => {
                        return (
                            <Form.Group
                                key={methodIndex + 'de'}
                            //id={`${method.toLowerCase().includes('card') ? 'disabled-option-cursor' : ''}`}
                            >
                                <div
                                    className='payments-wrapper'
                                    //id={`${method.toLowerCase().includes('card') ? 'disabled-option-events' : ''}`}
                                    style={selectedPaymentMethod === method ? { ...selectedOptionStyle } : {}}
                                    onClick={() => updatePaymentMethodOnClick(method)}
                                >
                                    <div className='payment-check'>
                                        <FontAwesomeIcon icon={props.returnIcon(faCheck)} style={selectedPaymentMethod === method ? { position: 'relative', top: '0', left: '0', color: '#fff' } : { position: 'relative', top: '0', left: '0' }} />
                                    </div>
                                    <Form.Label id='cat-name' >{method}</Form.Label>
                                </div>
                            </Form.Group>
                        )
                    })}
                </>
            )}
        </>
    )

    const paymentsCard = (
        <Card style={{ padding: '16px 26px 34px 26px' }}>
            <Card.Header id='payment-card-header' style={{ marginBottom: '0.5rem', borderRadius: '4px' }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={reverseToggleIcon}>
                    Payment method
                    <FontAwesomeIcon icon={props.returnIcon(paymentIcon)} />
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
                <Card.Body style={{ border: '0' }}>
                    {paymentOptions}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )

    const submitTailorMade = (
        <>
            <Accordion id='tailor-made-accordion' defaultActiveKey="0" style={{ height: '100%' }}>
                <section>
                    {categoriesCard}
                    {paymentsCard}
                </section>
                {optionsBlock}
            </Accordion>
            <div className='pricing-plan-card-submit' style={{ margin: '0.1px' }}>
                {submitBtn}
                <div className='pricing-plan-card-submit-note'>
                    <img src={astericsImg} className='pricing-plan-card-submit-note-asterics' />
                    <strong>Over 90% of our requests have been successfully matched.</strong> However, please note that EUPATI cannot guarantee a successful matching result. In the case of an unsuccessful match only the basic fee will be charged.
                </div>
            </div>
        </>
    );

    return (
        <div className='pricing-plan' key={selectedOption.text.length + 'eh'}>
            <div className='pricing-plan-card pricing-plan-tailor-made' style={{ paddingTop: '27px' }}>
                {selectedOption.text.length > 0 &&
                    <div className='d-flex' style={{ minHeight: '1px' }}>
                        <div className='pricing-plan-card-pricebox'>{'€ ' + selectedOption.price}</div>
                        <div className='pricing-plan-card-pricetitle'>{displayCategoryNameWithOptions()}</div>
                    </div>
                }
                {selectedOption.text.length === 0 &&
                    <div className='d-flex' style={{ minHeight: '1px' }}>
                        <div className='pricing-plan-card-plantitle'>Tailor-Made Plan</div>
                    </div>
                }
                <div className='pricing-plan-card-benefit'>
                    <div className='pricing-plan-card-benefit-description'>
                        Need some extra help? Let us take the hassle out of finding the right patient for you! If you would like some additional help in finding the right Patient Expert for your project, select the Tailor-Made plan. Under this plan, we will use our expert knowledge of our patient database to find the most suitable Patient Expert for you, connecting you solely with the patients who are interested in your project. We can also reduce the burden on you by liaising on any follow up actions with our EUPATI Patient Experts where necessary.
                    </div>
                </div>
                <div className='pricing-plan-card-benefit'>
                    <div className='pricing-plan-card-benefit-title'>
                        Search
                    </div>
                    <div className='pricing-plan-card-benefit-description'>
                        We will search over 200+ EUPATI Fellows along with our EUPATI Open Classroom Learners to find the right Patient Expert for you.
                    </div>
                </div>
                <div className='pricing-plan-card-benefit'>
                    <div className='pricing-plan-card-benefit-title'>
                        Match
                    </div>
                    <div className='pricing-plan-card-benefit-description'>
                        We will share your request with our matched Patient Experts.
                    </div>
                </div>
                <div className='pricing-plan-card-benefit'>
                    <div className='pricing-plan-card-benefit-title'>
                        Share & Connect
                    </div>
                    <div className='pricing-plan-card-benefit-description'>
                        We will share the matched and interested Patient Experts with you.
                    </div>
                </div>
                <div className='pricing-plan-card-benefit'>
                    <div className='pricing-plan-card-benefit-title'>
                        Co-Create!
                    </div>
                    <div className='pricing-plan-card-benefit-description'>
                        You and your matched Patients Experts can start working together straight away.
                    </div>
                </div>
                <div className='pricing-plan-card-benefit'>
                    <div className='pricing-plan-card-benefit-title'>
                        Follow Up
                    </div>
                    <div className='pricing-plan-card-benefit-description'>
                        We liaise with you on any follow up where necessary.
                    </div>
                </div>
            </div>
            <div className='pricing-plan-card pricing-plan-tailor-made pricing-plan-card-2' style={{ padding: '0px' }}>
                {submitTailorMade}
            </div>
            <div className='pricing-plan-card pricing-plan-tailor-made-options'>
                <div id='options-icon-plus'>+</div>
                <div id='options-title-box'>Additional Options:</div>
                <div className='pricing-plan-card-benefit'>
                    <div className='pricing-plan-card-benefit-title'>
                        Mentorship
                    </div>
                    <div className='pricing-plan-card-benefit-description'>
                        If you desire, we can provide additional mentorship to your organisation or the Patient Expert where either party has minimal expertise in previous patient engagement projects.
                    </div>
                </div>
                <div className='pricing-plan-card-benefit'>
                    <div className='pricing-plan-card-benefit-title'>
                        External Networks
                    </div>
                    <div className='pricing-plan-card-benefit-description'>
                        Are you looking to reach beyond EUPATI Expert Patients? If necessary, we can reach out to our external networks, outside the EUPATI Patient Expert Pool, to find the right patient for you.
                    </div>
                </div>
            </div>
        </div>
    )
}