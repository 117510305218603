import React, { useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from 'react-table';
import { Button, Form } from 'react-bootstrap';
import GlobalFilter from './global-filter';
import EupatiPatientDetails from './patient-details';
import './static.scss';
import EupatiLoader from './loading-animation/loader';
import { DiseaseInfo, Patient } from '../models/main-models';
import { LookUpData } from '../models/input-models';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { REMOVE_PT_SUCC_MSG } from '../../common/helpers/messages';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAlphaDown,
  faSortAlphaUp,
  faFileExcel,
} from '@fortawesome/fontawesome-free-solid';
import { faWindowMinimize } from '@fortawesome/fontawesome-free-regular';
import { CSVLink } from 'react-csv';
import uniqid from 'uniqid';
import ConfirmDenyModal from './confirm-deny-modal';

type OwnProps = {
  lookUpData: LookUpData[];
  patients: Patient[];
  patientDetails: Patient;
};

type DispatchProps = {
  removePatientById: (patientId: string) => void;
  showPatientDetails: (patientId: string) => void;
};

type Props = OwnProps & DispatchProps;

const OUTLINE_PADDING_STYLE = {
  paddingLeft: '1.2rem',
  paddingRight: '1.2rem',
};
const PRIMARY_PADDING_STYLE = {
  paddingLeft: '1.7rem',
  paddingRight: '1.7rem',
};
export default function EupatiAllPatients(props: Props) {
  const history = useHistory();

  const [isDetailsBtnClicked, setIsDetailsBtnClicked] =
    useState<boolean>(false);

  const handleRemovePatient = (id: string, e: any) => {
    e.preventDefault();
    props.removePatientById(id);
    toast.success(REMOVE_PT_SUCC_MSG);
  };

  const handlePatientDetails = (id: string, e: any) => {
    e.preventDefault();
    setIsDetailsBtnClicked(true);
    props.showPatientDetails(id);
  };

  const backBtnHandler = () => {
    setIsDetailsBtnClicked(false);
  };

  const addBtnHandler = () => {
    history.push({
      pathname: '/patient',
      state: { isNewPatient: true },
    });
  };

  const returnValueById = (propName: string, projectPropId: number) => {
    let propType = props.lookUpData.find((x: any) => x.name == propName); // projectType
    let propValue = propType?.data.find((x: any) => x.id == projectPropId);
    return propValue?.name;
  };

  const headers = [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Country', key: 'country' },
    { label: 'Diseases', key: 'diseases' },
    { label: 'Email', key: 'email' },
    { label: 'Expertises', key: 'expertises' },
    { label: 'Phone number', key: 'phone' },
    { label: 'Other Information', key: 'otherInformation' },
    { label: 'Gender', key: 'gender' },
    { label: 'Patient Experience', key: 'patientExperience' },
    { label: 'Activity Preference', key: 'activityPreference' },
    { label: 'Is Affiliated With Patient Org', key: 'isPOAffiliated' },
    { label: 'Is Eligible For Compensation', key: 'isEligibleForCompensation' },
    {
      label: 'Is Available Without Compensation',
      key: 'isAvailableForNoCompensation',
    },
    { label: 'Languages', key: 'languages' },
    { label: 'Project Preferences', key: 'projectPreferences' },
    { label: 'Project Experiences', key: 'projectTypeExperiences' },
    { label: 'Organisation Preferences', key: 'organisationPreferences' },
    { label: 'Countries With Experience', key: 'countriesWithExperience' },
  ];

  const data = useMemo(
    () =>
      props.patients?.map((x: any, i: any) => {
        return {
          //default grid cols
          id: x.id,
          firstName: x.firstName,
          lastName: x.lastName,
          country: returnValueById('country', x.countryId),
          diseases: x.noDiseaseExpertise
            ? 'No specific disease experience'
            : x.diseases?.map((d: any) => d.name).join(', '),
          email: x.email,
          expertises: x.patientExpertises
            .map((pe: number) => returnValueById('expertise', pe))
            .join(', '),
          //additional csv cols
          //single fields
          phone: `${x.phoneNumber}`,
          otherInformation: x.otherInformation?.replace(/\r?\n/g, ''),
          gender: returnValueById('genderType', x.genderTypeId),
          patientExperience: returnValueById(
            'patientExperiense',
            x.patientExperienceId
          ),
          activityPreference: returnValueById(
            'activityType',
            x.activityPreferenceId
          ),
          isPOAffiliated: x.isPOAffiliated ? 'Yes' : 'No',
          isEligibleForCompensation: x.isEligibleForCompensation ? 'Yes' : 'No',
          isAvailableForNoCompensation: x.isAvailableForNoCompensation
            ? 'Yes'
            : 'No',
          //collections
          projectPreferences: x.projectPreferences
            .map((pp: number) => returnValueById('projectType', pp))
            .join(', '),
          projectTypeExperiences: x.projectTypeExperiences
            .map((pte: number) => returnValueById('projectType', pte))
            .join(', '),
          organisationPreferences: x.organisationPreferences
            .map((op: number) => returnValueById('organisationType', op))
            .join(', '),
          countriesWithExperience: x.countriesWithExperience
            .map((c: string) => returnValueById('country', parseInt(c)))
            .join(', '),
          languages: x.languages
            .map((l: string) => returnValueById('language', parseInt(l)))
            .join(', '),
          matchedProjects: x.matchedProjects.length,
          confirmedProjects: x.confirmedProjects.length,
        };
      }),
    [props.patients]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: 'Country',
        accessor: 'country',
      },
      {
        Header: 'Disease',
        accessor: 'diseases',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Expertises',
        accessor: 'expertises',
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    page,
    rows,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    canPreviousPage,
    prepareRow,
    visibleColumns,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    setPageSize,
    state,
    // @ts-ignore
    setGlobalFilter,
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
      // @ts-ignore
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  // @ts-ignore
  const { globalFilter, pageSize, pageIndex } = state;

  let patientsDetailsComp: any;
  let loadComp = (
    <div className="loader-wrapper">
      <EupatiLoader />
    </div>
  );

  function redirectToPatientsPage() {
    history.push({
      pathname: '/patient',
      state: {
        patientId: props.patientDetails.id,
        isNewPatient: false,
      },
    });
  }

  function returnProperIconType(icon: any) {
    return icon as IconProp;
  }

  if (props.patientDetails.id === '') {
    patientsDetailsComp = loadComp;
  } else {
    patientsDetailsComp = (
      <EupatiPatientDetails
        {...{
          patientInfo: props.patientDetails,
          lookUpData: props.lookUpData,
          backBtnHandler: backBtnHandler,
          leftArrowText: 'all patients',
          rightArrowText: 'edit patient',
          forwardBtnHandler: redirectToPatientsPage,
          isForwardArrowAvail: true,
        }}
      />
    );
  }

  return (
    <>
      {isDetailsBtnClicked && <>{patientsDetailsComp}</>}
      {!isDetailsBtnClicked && (
        <>
          <h4 style={{ textAlign: 'center', textDecoration: 'underline' }}>
            PATIENTS
          </h4>
          <div className="table-btns">
            <Button id="add-pt-btn" onClick={addBtnHandler}>
              ADD PATIENT
            </Button>
            <span>
              <strong>{props.patients?.length}</strong> patients in total.
            </span>
            <CSVLink
              data={data}
              headers={headers}
              id="csv-download-btn"
              filename={`${uniqid('patients-table-')}.csv`}
              className="btn btn-success"
            >
              DOWNLOAD{' '}
              <FontAwesomeIcon icon={returnProperIconType(faFileExcel)} />
            </CSVLink>
          </div>
          <Table {...getTableProps()} striped bordered hover>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((col) => (
                    //@ts-ignore
                    <th {...col.getHeaderProps(col.getSortByToggleProps())}>
                      {col.render('Header')}
                      <span>
                        {
                          /* @ts-ignore */
                          col.isSorted ? (
                            /* @ts-ignore */
                            col.isSortedDesc ? (
                              <FontAwesomeIcon
                                icon={returnProperIconType(faSortAlphaDown)}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={returnProperIconType(faSortAlphaUp)}
                              />
                            )
                          ) : (
                            <FontAwesomeIcon
                              id="line"
                              icon={returnProperIconType(faWindowMinimize)}
                            />
                          )
                        }
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
              <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{ textAlign: 'left' }}
                >
                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                  />
                </th>
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                    <>
                      <td>
                        <Button
                          variant="info"
                          onClick={(e: any) =>
                            handlePatientDetails(row.original.id, e)
                          }
                        >
                          DETAILS
                        </Button>
                      </td>
                      <td>
                        <ConfirmDenyModal
                          {...{
                            targetId: row.original.id,
                            target: 'patient',
                            params: `email address - ${
                              props.patients.find(
                                (pt: Patient) => pt.id === row.original.id
                              )?.email
                            }`,
                            handleRemove: handleRemovePatient,
                          }}
                        />
                        {/* <Button
                                                    variant='danger'
                                                    onClick={(e: any) => handleRemovePatient(row.original.id, e)}
                                                >
                                                    REMOVE
                                                </Button> */}
                      </td>
                    </>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Form.Group
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <span>
              Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                min="1"
                disabled={pageSize >= rows.length}
                onChange={(e) => {
                  e.preventDefault();
                  const pageNum = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNum);
                }}
              />
            </span>
            <select
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value)}
            >
              {[10, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <Button
              id="backToFirstPageBtn"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              style={
                canPreviousPage
                  ? { cursor: 'pointer', ...OUTLINE_PADDING_STYLE }
                  : { cursor: 'not-allowed', ...OUTLINE_PADDING_STYLE }
              }
              className="btn btn-outline-dark btn-sm"
            >
              {'<<'}
            </Button>
            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              style={
                canPreviousPage
                  ? { cursor: 'pointer' }
                  : { cursor: 'not-allowed' }
              }
              variant="secondary"
            >
              Previous
            </Button>
            <Button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              style={
                canNextPage
                  ? { cursor: 'pointer', ...PRIMARY_PADDING_STYLE }
                  : { cursor: 'not-allowed', ...PRIMARY_PADDING_STYLE }
              }
              variant="primary"
            >
              Next
            </Button>
            <Button
              id="gotoLastPageBtn"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              style={
                canNextPage
                  ? { cursor: 'pointer', ...OUTLINE_PADDING_STYLE }
                  : { cursor: 'not-allowed', ...OUTLINE_PADDING_STYLE }
              }
              className="btn btn-outline-dark btn-sm"
            >
              {'>>'}
            </Button>
          </Form.Group>
        </>
      )}
    </>
  );
}
