import React from "react";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { Rules } from "../../../../common/helpers";
import EupatiTextbox from "../../../../common/static/textbox";
import EupatiEmailbox from "../../../../common/static/emailbox";
import { useHistory } from "react-router";
import { Messages } from "../../../../common/helpers";
import { Project } from "../../../../common/models/main-models";
import { LookUpOptions } from '../../../../common/models/input-models';
import { toast } from 'react-toastify';

type OwnProps = {
    project: Project,
    isUserReal: boolean
};

type DispatchProps = {
    showCompanyInfoSection: () => void;
    setProject: (project: Project) => void;
    returnProperDefaultValue: (val: any) => any;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
}

type Props = OwnProps & DispatchProps;

export default function PersonalInfo(props: Props) {

    const history = useHistory();

    const { handleSubmit, control, reset, formState: { errors } } = useForm();

    const onSubmit = (data: any) => {
        if (!props.isUserReal) {
            toast.error(Messages.FAIL_AUTH_MSG);
            return history.push('/');
        }

        const updatedProject = Object.assign(props.project, data);
        props.setProject(updatedProject);
        props.showCompanyInfoSection();
    }

    return (
        <Form className='researcher' onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='researcher-info'>
                <Form.Group className="lcolumn">

                    <Form.Group id='r-fname' className='researcher-info-field mb-3'>
                        <Form.Label>First name</Form.Label>
                        <Controller
                            name='pocFirstName'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.pocFirstName)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.pocFirstName?.message,
                                        placeholder: 'Please type first name'
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='r-lname' className='researcher-info-field mb-3'>
                        <Form.Label>Surname</Form.Label>
                        <Controller
                            name='pocLastName'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.pocLastName)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.pocLastName?.message,
                                        placeholder: 'Please type last name'
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='r-tel' className='researcher-info-field mb-3'>
                        <Form.Label>Phone</Form.Label>
                        <Controller
                            name='pocPhoneNumber'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.pocPhoneNumber)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.pocPhoneNumber?.message,
                                        placeholder: 'Please type phone number'
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='r-email' className='researcher-info-field mb-3'>
                        <Form.Label>Email</Form.Label>
                        <Controller
                            name='pocEmail'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.pocEmail)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiEmailbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.pocEmail?.message
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='r-btns' className='researcher-info-field'>
                        <Button className='bck' style={{ visibility: 'hidden' }} variant='secondary' disabled>Back</Button>
                        <Button className='nxt' variant='primary' type='submit'>Next</Button>
                    </Form.Group>
                </Form.Group>
            </Form.Group>
        </Form>
    )
}