import * as React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "../../../../styles/carousel.css";

type OwnProps = {};

export type DispatchProps = {};

type OwnState = {};

type Props = DispatchProps & OwnProps;

export default class Testimonials extends React.Component<Props, OwnState> {

    render() {
        const carouselProps = {
            autoPlay: true,
            interval: 5000,
            centerMode: true,
            centerSlidePercentage: 100,
            infiniteLoop: true,
            showStatus: false,
            showThumbs: false
        }
        return (
            <>
                <section className='testimonials-sec'>
                    <div className='testimonials-sec-txt'>
                        <h5 className='testimonials-sec-txt-title mb-4'>What People are Saying</h5>
                    </div>
                    <div className='testimonials-sec-content'>
                        <Carousel {...carouselProps}>
                            <div className='testimonials-sec-content-card'>
                                <div className='testimonials-sec-content-card-quotes'></div>
                                <div className='testimonials-sec-content-card-text'>There's a certain responsibility that comes with being a EUPATI fellow. We need to use what we have learned to make a real difference! EUPATIConnect means that I am working with trusted partners on topics that matter to patients. We learn from each other and make meaningful solutions together.</div>
                                <div className='testimonials-sec-content-card-profile-image image-joana' />
                                <div className='testimonials-sec-content-card-profile-name'>Joan Jordan</div>
                                <div className='testimonials-sec-content-card-profile-title'>EUPATI Patient Expert</div>
                            </div>
                            <div className='testimonials-sec-content-card'>
                                <div className='testimonials-sec-content-card-quotes'></div>
                                <div className='testimonials-sec-content-card-text'>I had the opportunity to be involved as Patient Advisor in several clinical research projects, especially working on the study protocols and the development of patient relevant material. The involvement of a EUPATI Patient Expert means that any clinical research becomes more focused on the patients’ real needs as well as having the voice of the patient included in all steps.</div>
                                <div className='testimonials-sec-content-card-profile-image image-paola' />
                                <div className='testimonials-sec-content-card-profile-name'>Paola Kruger</div>
                                <div className='testimonials-sec-content-card-profile-title'>EUPATI Patient Expert</div>
                            </div>
                            <div className='testimonials-sec-content-card'>
                                <div className='testimonials-sec-content-card-quotes'></div>
                                <div className='testimonials-sec-content-card-text'>We received some great insights in interviews with EUPATI fellows. Withing 24 hours of placing a request, we were connected to three patients. All interviews were completed in 2 days and gave us a great understanding of the patient perspective.</div>
                                <div className='testimonials-sec-content-card-profile-image image-ankita' />
                                <div className='testimonials-sec-content-card-profile-name'>Dr Ankita Batla</div>
                                <div className='testimonials-sec-content-card-profile-title'>VMLY&Rx | Chief Medical Office Lead-Health Insights and Patient Partnerships</div>
                            </div>
                            <div className='testimonials-sec-content-card'>
                                <div className='testimonials-sec-content-card-quotes'></div>
                                <div className='testimonials-sec-content-card-text'>We had a fantastic response following our request for patient review of our informed consent (ICF) template.  EUPATI fellows had some hugely valuable feedback which will be used to inform our future ICF template to make it clearer and more user friendly.</div>
                                <div className='testimonials-sec-content-card-profile-image image-kay' />
                                <div className='testimonials-sec-content-card-profile-name'>Kay Warner</div>
                                <div className='testimonials-sec-content-card-profile-title'>GSK</div>
                            </div>
                        </Carousel>
                    </div>
                </section>
            </>
        );
    }
}
