import { NAME } from "./constants";

export const SET_PRICING_LOADING = `${NAME}/SET_PRICING_LOADING`;

export const SET_PRICING_LOGGED_IN = `${NAME}/SET_PRICING_LOGGED_IN`;

export const SET_IS_USER_REAL = `${NAME}/SET_IS_USER_REAL`;

export const SET_IS_USER_ADMIN = `${NAME}/SET_IS_USER_ADMIN`;

export const SET_CATALOGS_DATA = `${NAME}/SET_CATALOGS_DATA`;