import { default as RootComponent, DispatchProps } from '../components/component-root'
import * as Constants from '../constants';
import * as Actions from '../actions';
import { State } from '../model';
import { connect } from 'react-redux';
import { Patient } from '../../../common/models/main-models';

export function mapStateToProps(globalState: any) {
    let name = Constants.NAME;
    let pageState = globalState[name] as State;
    return pageState;
}

export function mapDispatchToProps(dispatch: any) {
    return {
        setLookUpData: () => dispatch(Actions.getLookUpDataAsync()),
        setPatientInfo: (patient: Patient) => dispatch(Actions.setPatientInfo(patient)),
        savePatient: (patient: Patient) => dispatch(Actions.updatePatientAsync(patient)),
        setPatientData: (patientId?: string) => dispatch(Actions.getPatientInfoByIdAsync(patientId)),
        setUserPatientData: (userId?: string) => dispatch(Actions.getPatientInfoByUserIdAsync(userId)),
        submitNewPatient: (patient: Patient) => dispatch(Actions.saveNewPatientAsync(patient)),
    } as DispatchProps;
}

export default connect(mapStateToProps, mapDispatchToProps)(RootComponent);