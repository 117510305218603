import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useTable } from "react-table";
import { toast } from "react-toastify";
import { EMAIL_TEMPLATE_CREATED, EMAIL_TEMPLATE_UPDATED } from "../../../../common/helpers/messages";
import { ActionsTemplateData, EmailAutomationTriggerData, EmailAutomationTriggersMenu, EmailTemplateData, LookUpOptions, ModelPropsNames, OptionsData } from "../../../../common/models/input-models";
import AuthenticationContext from "../../../../common/static/auth-context";
import EupatiLoader from "../../../../common/static/loading-animation/loader";
import EmailTemplateEditor from "../../../../common/static/template-editor";
import ActionsTemplateMenu from '../../../../common/static/actions-template-menu';
import { State } from "../models";
import '../style.scss';
import ConfirmDenyModal from "../../../../common/static/confirm-deny-modal";

type OwnProps = {
    isEmailTemplatesLoading: boolean;
    isSingleEmailTemplateLoading: boolean;
    emailTemplatesData: EmailTemplateData[];
    singleEmailTemplateData: EmailTemplateData;
    projectPropsNames: ModelPropsNames[];
    patientPropsNames: ModelPropsNames[];
    triggersMenuOptions: EmailAutomationTriggersMenu[];
    isTriggersMenuOptionsLoading: boolean;
    receiversMenuOptions: LookUpOptions[];
    choosenReceivers: number[];
};

export type DispatchProps = {
    getAllTemplates: () => void;
    getSingleTemplate: (id?: string) => void;
    createEmailTemplate: (data: EmailTemplateData) => void;
    updateEmailTemplate: (data: EmailTemplateData) => void
    removeEmailTemplate: (id: string) => void;
    getModelsPropsName: (id: string) => void;
    getTriggersMenuOptions: () => void;
    assignActionsToEmailTemplate: (data: ActionsTemplateData) => void;
    getReceiversMenuOptions: () => void;
    getEmailTriggerTemplateBeneficiaries: (id: string) => void;
    misallocateActionsFromEmailTemplate: (data: ActionsTemplateData) => void;
};

type Props = State & DispatchProps & OwnProps;

const options = [
    { value: 'toResearcher', label: "Send to researcher" },
    { value: 'toPatient', label: "Send to patients" }
] as OptionsData[];

export default function EmailTemplates(props: Props) {
    const contextData = useContext(AuthenticationContext);

    const [isCreateTemplateClicked, setIsCreateTemplateClicked] = useState<boolean>(false);
    const [isEditTemplateClicked, setIsEditTemplateClicked] = useState<boolean>(false);
    const [isActionsBtnClicked, setIsActionsBtnClicked] = useState<boolean>(false);

    useEffect(() => {
        if (contextData.isUserAdmin === true) {
            props.getAllTemplates();
            props.getTriggersMenuOptions();
            props.getReceiversMenuOptions();
            props.getModelsPropsName(contextData.userIdentity);
        }
    }, []);

    const onCreateNewHandler = () => {
        props.getSingleTemplate();//reset to emptyTemplate
        setIsCreateTemplateClicked(true);
    }

    const onEditTemplateHandler = (id: string) => {
        setIsEditTemplateClicked(true);
        props.getSingleTemplate(id);
    }

    const onBackToAllHandler = () => {
        props.getSingleTemplate();//reset to emptyTemplate
        setIsCreateTemplateClicked(false);
        setIsEditTemplateClicked(false);
        setIsActionsBtnClicked(false);
    }

    const onAssignActionsHandler = (id: string) => {
        props.getSingleTemplate(id);
        setIsActionsBtnClicked(true);
    }

    const onRemoveTemplateHandler = (id: string) => {
        props.removeEmailTemplate(id);
    }

    const onSaveTemplateChangesHandler = (emailTemplateData: EmailTemplateData) => {
        switch (emailTemplateData.id !== '') {
            case true:
                toast.success(EMAIL_TEMPLATE_UPDATED);
                props.updateEmailTemplate(emailTemplateData);
                break;
            default:
                toast.success(EMAIL_TEMPLATE_CREATED);
                props.createEmailTemplate(emailTemplateData);
                break;
        }
        setIsCreateTemplateClicked(false);
        setIsEditTemplateClicked(false);
    }

    const compare = (a: any, b: any) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    const data = useMemo(
        () => props.emailTemplatesData?.sort(compare).map((x: any, i: any) => {
            return {
                id: x.id,
                index: i + 1,
                name: x.name,
                //actions: options.filter((o: OptionsData) => x.actions.split(';').some((a: any) => a === o.value)).map((el: OptionsData) => el.label).join(', '),
                content: x.htmlContent,
            }
        }), [props.emailTemplatesData]
    )

    const columns = useMemo(
        () => [
            {
                Header: '#',
                accessor: 'index'
            },
            {
                Header: 'Name',
                accessor: 'name'
            },
            // {
            //     Header: 'Actions',
            //     accessor: 'actions'
            // }
        ], []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        // @ts-ignore
        columns,
        data,
    });

    const loadComp = (
        <div className='loader-wrapper'>
            <EupatiLoader />
        </div>
    );

    const emailTemplatesComp = (
        <>
            <h4 style={{ textAlign: 'center', textDecoration: 'underline' }}>EMAIL TEMPLATES</h4>
            <Button variant='primary' id='create-btn' onClick={onCreateNewHandler} >CREATE NEW</Button>
            <Table {...getTableProps()} striped bordered hover>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(col => (
                                <th {...col.getHeaderProps()}>
                                    {col.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row: any) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell: any) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                                <>
                                    <td>
                                        <Button
                                            variant='info'
                                            onClick={(e: any) => onAssignActionsHandler(row.original.id)}
                                        >
                                            ACTIONS
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            variant='warning'
                                            onClick={(e: any) => onEditTemplateHandler(row.original.id)}
                                        >
                                            EDIT
                                        </Button>
                                    </td>
                                    <td>
                                        <ConfirmDenyModal
                                            {...{
                                                targetId: row.original.id,
                                                target: 'email template',
                                                params: `name - ${props.emailTemplatesData.find((etd: EmailTemplateData) => etd.id === row.original.id)?.name}`,
                                                handleRemove: onRemoveTemplateHandler
                                            }}
                                        />
                                        {/* <Button
                                            variant='danger'
                                            onClick={(e: any) => onRemoveTemplateHandler(row.original.id)}
                                        >
                                            REMOVE
                                        </Button> */}
                                    </td>
                                </>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </>
    )

    const emailTemplateEditor = (
        <>
            <h4 style={{ textAlign: 'center', textDecoration: 'underline' }}>EMAIL TEMPLATE EDITOR</h4>
            <EmailTemplateEditor
                {...{
                    emailTemplateData: props.singleEmailTemplateData,
                    onBackToAllHandler: onBackToAllHandler,
                    onSaveTemplateChangesHandler: onSaveTemplateChangesHandler,
                    projectPropsNames: props.projectPropsNames,
                    patientPropsNames: props.patientPropsNames,
                    options: options
                }}
            />
        </>
    )

    const emailTemplatesActionsComp = (
        <>
            <h4 style={{ textAlign: 'center', textDecoration: 'underline' }}>ASSIGN TRIGGERS TO TEMPLATE</h4>
            <ActionsTemplateMenu
                {...{
                    emailTemplate: props.singleEmailTemplateData,
                    triggersMenuOptions: props.triggersMenuOptions,
                    filteredTriggersMenuOptions: props.triggersMenuOptions.filter((tmo: EmailAutomationTriggersMenu) => !props.emailTemplatesData.map((etd: EmailTemplateData) => etd.actions).map((etda: EmailAutomationTriggerData[]) => etda.map((x: EmailAutomationTriggerData) => x.name)[0]).some((val: string) => val === tmo.name)),
                    onBackToAllHandler: onBackToAllHandler,
                    assignActionsToTemplate: props.assignActionsToEmailTemplate,
                    receiversMenuOptions: props.receiversMenuOptions,
                    choosenReceivers: props.choosenReceivers,
                    compare: compare,
                    misallocateActionsFromEmailTemplate: props.misallocateActionsFromEmailTemplate
                }}
            />
        </>
    )

    const pageSwitcher = () => {
        if (props.isEmailTemplatesLoading || props.isSingleEmailTemplateLoading || props.isTriggersMenuOptionsLoading) {
            return loadComp;
        }
        else if (!isCreateTemplateClicked && !isEditTemplateClicked && !isActionsBtnClicked) {
            return emailTemplatesComp//grid
        }
        else if (isActionsBtnClicked) {
            return emailTemplatesActionsComp
        }
        else {
            return emailTemplateEditor
        }
    }

    return (
        <Form.Group className='email-templates-wrapper'>
            {pageSwitcher()}
        </Form.Group>
    )
}