import { LookUpData, MeshData } from '../../common/models/input-models';
import { Patient } from '../../common/models/main-models';
import * as t from './actionTypes';
import { defaultPatientInfo, State } from './model';

const initialState: State = {
    patient: defaultPatientInfo,
    isLoading: true,
    lookUpData: [],
    isPatientDataLoaded: true
};

export default (state = initialState, action: any): State => {
    switch (action.type) {
        case t.SET_LOOKUP_DATA:
            return setLookUpData(state, action.payload);
        case t.SET_PATIENT_INFO:
            return setPatientInfo(state, action.payload);
        case t.SET_PATIENTS_LOADING:
            return setPatientLoading(state, action.payload);
        case t.SET_PATIENT_DATA_LOADING:
            return setPatientDataLoading(state, action.payload);
        default:
            return state;
    }
};

const setLookUpData = (state: State, val: LookUpData[]): State => {
    let newState = {
        ...state,
        lookUpData: [...val]
    } as State;

    return newState;
};

const setPatientInfo = (state: State, val: Patient): State => {
    let newState = {
        ...state,
        patient: {...val}
    } as State;

    return newState;
}

const setPatientLoading = (state: State, val: boolean): State => {
    let newState = {
		...state,
        isLoading: val
	} as State;

	return newState;
}

const setPatientDataLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isPatientDataLoaded: val
    } as State;

    return newState;
}