import { ChangePatientStatusData, PatientSearchData, PatientsImportData, SurveyAnswerData } from '../models/input-models';
import { Patient } from '../models/main-models';
import HttpRequester from './http-requester';

//declare var baseUrl: string;
var baseUrl = '/patients';

export default class PatientSvc {
	httpRequester: HttpRequester;

	constructor() {
		this.httpRequester = new HttpRequester('/api');
	}

	savePatient(patient: Patient) {
		return this.httpRequester.httpPost(
			`${baseUrl}/savepatient`,
			patient
		);
	}

	getPatientByUserId(id: string) {
		return this.httpRequester.httpGet(
			`${baseUrl}/getpatientbyuserid?userId=${id}`
		);
	}

	getPatientById(id: string) {
		return this.httpRequester.httpGet(
			`${baseUrl}/getpatientbyid?patientId=${id}`
		)
	}

	searchPatients(patientData?: PatientSearchData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/searchpatients`,
			patientData
		);
	}

	importPatients(patientsData: PatientsImportData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/importpatients`,
			patientsData
		)
	}

	getAllPatients() {
		return this.httpRequester.httpGet(
			`${baseUrl}/all`
		)
	}

	updatePatient(patient: Patient) {
		return this.httpRequester.httpPost(
			`${baseUrl}/updatepatient`,
			patient
		)
	}

	removePatient(patient: Patient) {
		return this.httpRequester.httpPost(
			`${baseUrl}/removepatient`,
			patient
		)
	}

	changePatientStatus(data: ChangePatientStatusData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/changepatientstatus`,
			data
		)
	}

	addSurveyAnswer(data: SurveyAnswerData) {
		return this.httpRequester.httpPost(
			`${baseUrl}/addsurveyanswer`,
			data
		)
	}
}