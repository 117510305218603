// request docs here: https://www.npmjs.com/package/request-promise
//import * as request from "request-promise-native";
const axios = require('axios');

export default class HttpRequester {
	url: string;

	constructor(rootUrl = '/') {
		this.url = rootUrl;
	}

	handleError(error: any) {
		console.error(JSON.stringify(error)); //this does not output the complete error?!?!?
		let msg = error.message;		
	
		//see if the back end dev has put in some nice description
		if (typeof (error.response) != 'undefined' && typeof (error.response.data) != 'undefined') {			
			msg = error.response.data;
		}
		
		if (error.status === 403 && msg === '') {
			msg = "You don't have sufficient rights to perform this action. Contact us through the live chat.";
		}

		return msg;
	}

	httpGet(path: string) {
		var that = this;
		var promise = new Promise(function(resolve, reject) {
			let fullPath = that.url + path;

			axios.get(fullPath).then(
				function(result: any) {
					resolve(result.data);
				},
				function(error: any) {
					let msg = that.handleError(error);
					reject(msg);
				}
			);
		});

		return promise;
	}

	httpPost(path: string, content: any) {
		var that = this;
		
		var promise = new Promise(function (resolve, reject) {
			let fullPath = that.url + path;
			
			axios.post(fullPath, content, { headers: { 'Content-Type': 'application/json' } }).then(
				function (result: any) {
					resolve(result.data);
				},
				function (error: any) {
					let msg = that.handleError(error);
					reject(msg);
				}
			);
		});

		return promise;
	}
}
