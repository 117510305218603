import { User } from "../../../common/models/main-models";
import { State } from "./model";
import * as t from './actionTypes';

const initialState: State = {
    admins: [],
    isAdminsLoading: false
}

export default (state = initialState, action: any): State => {
    switch (action.type) {
        case t.SET_ADMINS_LOADING:
            return setAdminsLoading(state, action.payload);
        case t.SET_ADMINS:
            return setAdmins(state, action.payload);
        default:
            return state;
    }
}

const setAdminsLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isAdminsLoading: val
    } as State;

    return newState;
}

const setAdmins = (state: State, val: User[]): State => {
    let newState = {
        ...state,
        admins: [...val]
    } as State;

    return newState;
}