import React, { useState } from 'react';
import { LookUpOptions } from '../../../../common/models/input-models';
import { Project } from '../../../../common/models/main-models';
import ProjectGeneralInfo from './general';
import ProjectPatientsInfo from './patients';

type OwnProps = {
    project: Project,
    isUserReal: boolean,
    showSecondSubComponent: boolean
};

type DispatchProps = {
    setProject: (project: Project) => void;
    nextPage: () => void;
    prevPage: () => void;
    returnProperDefaultValue: (val: any) => any;
    setIsUserReal: (token: string) => void;
    returnProperLookUpData: (dataType: any) => LookUpOptions[] | null;
};

type Props = OwnProps & DispatchProps;

export default function ProjectInfo(props: Props) {
    const [nextBtnClicked, setNextBtnClicked] = useState<boolean>(props.showSecondSubComponent);

    const toggleSectionVisibility = () => {
        setNextBtnClicked(!nextBtnClicked);
        //window.scrollTo(0, 0);
    }

    return (
        <div style={{ margin: '0 0 7.5rem 0' }}>

            {nextBtnClicked &&
                <ProjectGeneralInfo
                    {...{
                        project: props.project,
                        setProject: props.setProject,
                        returnProperDefaultValue: props.returnProperDefaultValue,
                        returnProperLookUpData: props.returnProperLookUpData,
                        showPatientsInfoSection: toggleSectionVisibility,
                        isUserReal: props.isUserReal,
                        prevPage: props.prevPage
                    }}
                />
            }

            {!nextBtnClicked &&
                <ProjectPatientsInfo
                    {...{
                        project: props.project,
                        setProject: props.setProject,
                        returnProperDefaultValue: props.returnProperDefaultValue,
                        returnProperLookUpData: props.returnProperLookUpData,
                        hidePatientsInfoSection: toggleSectionVisibility,
                        isUserReal: props.isUserReal,
                        nextPage: props.nextPage
                    }}
                />
            }

        </div>
    )
}