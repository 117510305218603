// matching/actions.js

import DataSvc from '../../common/services/data-svc';
import * as t from './actionType';
import { Res } from '../../common/helpers';
import { CatalogData } from '../../common/models/input-models';

export const setPricingLoading = (isLoading: boolean) => ({
    type: t.SET_PRICING_LOADING,
    payload: isLoading,
});

export const setPricingLoggedInStatus = (isLoggedIn: boolean) => ({
    type: t.SET_PRICING_LOGGED_IN,
    payload: isLoggedIn
});

export const setIsUserReal = (isUserReal: boolean) => ({
    type: t.SET_IS_USER_REAL,
    payload: isUserReal
});

export const setIsUserAdmin = (isUserAdmin: boolean) => ({
    type: t.SET_IS_USER_ADMIN,
    payload: isUserAdmin
});

export const setCatalogsData = (data: CatalogData[]) => ({
    type: t.SET_CATALOGS_DATA,
    payload: data
});

export const verifyUserTokenAsync = (token: string) => {
    return (dispatch: any, getState: any) => {
        DataSvc.utilsSvc.verifyUserByRecaptchaToken({ recaptchaToken: token }).then(
            (result: any) => {
                if (result !== Res.success) {
                    dispatch(setIsUserReal(false));
                }
            },
            (error) => { console.error(error); }
        );
    }
}

export const getCatalogsPricesDataAsync = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setPricingLoading(true));
        DataSvc.utilsSvc.getPriceCatalogs().then(
            (result: any) => {
                dispatch(setCatalogsData(result));
                dispatch(setPricingLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}