import { CSVMappedField } from './csv-mapping-model';

let newMappedObj: any; // otherwise it gives an error

export const csvDataToJson = (data: any[], mapping: CSVMappedField[]) => {
    let mappedData = [];
    for (let i = 0; i < data.length; i++) {
        const patientInfo = data[i];
        newMappedObj = {};
        for (let j = 0; j < mapping.length; j++) {
            const mapItem = mapping[j];
            newMappedObj[mapItem.objectProperty] = patientInfo[mapItem.csvColumnName];
        }
        mappedData.push(newMappedObj);
    }

    return mappedData;
};
