import { blankResponse, State } from "./models";
import * as t from './actionTypes';
import { ContentResponse } from "../../../common/models/input-models";

const initialState: State = {
    isContentLoading: false,
    contentResponse: blankResponse
}

export default (state = initialState, action: any): State => {
    switch (action.type) {
        case t.SET_CONTENT_LOADING:
            return setContentLoading(state, action.payload);
        case t.SET_CONTENT_RESPONSE:
            return setContentResponse(state, action.payload);
        default:
            return state;
    }
}

const setContentLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isContentLoading: val
    } as State;

    return newState;
}

const setContentResponse = (state: State, val: ContentResponse): State => {
    let newState = {
        ...state,
        contentResponse: { ...val }
    } as State;

    return newState;
}