import { ProjectStatus } from "../../../common/helpers";
import { EmailTemplateData, LookUpData, PatientSearchData, ProjectPatientData, ProjectStatusData, RequestData } from "../../../common/models/input-models";
import { Patient, Project } from "../../../common/models/main-models";
import DataSvc from "../../../common/services/data-svc";
import * as t from './actionTypes';
import { defaultEmailTemplate } from "./model";

export const setLoading = (isLoading: boolean) => ({
    type: t.SET_LOADING,
    payload: isLoading
});

export const setLookUpData = (data: LookUpData[]) => ({
    type: t.SET_LOOKUP_DATA,
    payload: data
});

export const setProjects = (projects: Project[]) => ({
    type: t.SET_PROJECTS,
    payload: [...projects]
});

export const setIsProjectsLoading = (isProjectsLoading: boolean) => ({
    type: t.SET_PROJECTS_LOADING,
    payload: isProjectsLoading
});

export const setProjectsFilter = (filter: number) => ({
    type: t.SET_PROJECTS_FILTER,
    payload: filter
});

export const setMatchedPatients = (matchedPatients: Patient[]) => ({
    type: t.SET_MATCHED_PATIENTS,
    payload: [...matchedPatients]
});

export const setMatchedPatientsLoading = (isMatchedPatientsLoading: boolean) => ({
    type: t.SET_MATCHED_PATIENTS_LOADING,
    payload: isMatchedPatientsLoading
});

export const setProjectData = (project: Project) => ({
    type: t.SET_PROJECT_DATA,
    payload: project
});

export const setProjectLoading = (isProjectsLoading: boolean) => ({
    type: t.SET_PROJECT_LOADING,
    payload: isProjectsLoading
});

export const setEmailTemplates = (emailTemplates: EmailTemplateData[]) => ({
    type: t.SET_EMAIL_TEMPLATES,
    payload: [...emailTemplates]
});

export const setSingleEmailTemplate = (singleEmailTemplate: EmailTemplateData) => ({
    type: t.SET_SINGLE_EMAIL_TEMPLATE,
    payload: { ...singleEmailTemplate }
});

export const setEmailTemplateLoading = (emailTemplateLoading: boolean) => ({
    type: t.SET_TEMPLATES_LOADING,
    payload: emailTemplateLoading
});

export const getLookUpDataAsync = () => {
    return (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        DataSvc.utilsSvc.getLookUpData().then(
            (result: any) => {
                dispatch(setLookUpData(result));
                dispatch(setLoading(false));
            },
            (error) => { console.error(error); }
        );
    };
};

export const getAllFilteredProjectsAsync = (condition: number) => {
    return (dispatch: any, getState: any) => {
        dispatch(setIsProjectsLoading(true));
        DataSvc.projectsSvc.getAllProjects().then(
            (result: any) => {
                var projects = [];
                if (condition > 0) {
                    projects = [...result.filter((x: any) => {
                        return x.projectStatusId == condition;
                    })];
                } else {
                    projects = result;
                }
                dispatch(setProjects(projects));
                dispatch(setIsProjectsLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}

export const updateSearchPatients = (searchData?: PatientSearchData, projectName?: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setMatchedPatientsLoading(true));
        DataSvc.patientSvc.searchPatients(searchData).then(
            (result: any) => {
                if (typeof result !== 'undefined' && typeof projectName !== 'undefined') {
                    dispatch(setMatchedPatients([...result.filter((pt: Patient) =>
                        !pt.matchedProjects.some((x: any) => x.name.toLowerCase() === projectName.toLowerCase()) &&
                        !pt.confirmedProjects.some((x: any) => x.name.toLowerCase() === projectName.toLowerCase()))])
                    );
                }
                dispatch(setMatchedPatientsLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}

export const getProjectDataByIdAsync = (projectId: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setProjectLoading(true));
        DataSvc.projectsSvc.getProjectById(projectId).then(
            (result: any) => {
                if (typeof result !== 'undefined') { dispatch(setProjectData(result)); }
                dispatch(setProjectLoading(false));
            },
            (error) => { console.error(error); }
        )
    }
}

export const removePatientFromProjectAsync = (inputModel: ProjectPatientData) => {
    return (dispatch: any, getState: any) => {
        DataSvc.projectsSvc.removePatientFromProject(inputModel).then(
            () => {
                dispatch(getProjectDataByIdAsync(inputModel.projectId));
            },
            (error) => { console.error(error); }
        )
    }
}

export const addPatientToProjectAsync = (inputModel: ProjectPatientData, searchData?: PatientSearchData, projectName?: string) => {
    return (dispatch: any, getState: any) => {
        DataSvc.projectsSvc.assignPatientToProject(inputModel).then(
            () => {
                dispatch(updateSearchPatients(searchData, projectName));
            },
            (error) => { console.error(error); }
        )
    }
}

export const changePatientStatusToConfirmAsync = (inputModel: ProjectPatientData) => {
    return (dispatch: any, getState: any) => {
        DataSvc.projectsSvc.changePatientStatusToConfirm(inputModel).then(
            () => {
                dispatch(getProjectDataByIdAsync(inputModel.projectId));
            },
            (error) => { console.error(error); }
        )
    }
}

export const changeProjectStatusAsync = (id: string, filter: number) => {
    return (dispatch: any, getState: any) => {
        DataSvc.projectsSvc.getProjectById(id).then(
            (result: any) => {
                const inputModel = {
                    projectId: result.id,
                    statusId: filter //=== ProjectStatus.archivedStatusID ? ProjectStatus.inProgressStatusID.toString() : ProjectStatus.archivedStatusID
                } as ProjectStatusData;
                DataSvc.projectsSvc.changeProjectStatus(inputModel).then(
                    () => {
                        dispatch(getAllFilteredProjectsAsync(result.projectStatusId));
                    },
                    (error) => { console.error(error); }
                )
            },
            (error) => { console.error(error); }
        );
    }
}

export const submitEmailDataAsync = (data: RequestData) => {
    return (dispatch: any, getState: any) => {
        DataSvc.utilsSvc.sendEmail(data).catch(error => console.error(error));
    }
}

export const getAllEmailTemplatesAsync = () => {
    return (dispatch: any, getState: any) => {
        DataSvc.utilsSvc.getAllEmailTemplates().then(
            (result: any) => {
                dispatch(setEmailTemplates(result));
            },
            (error) => { console.error(error); }
        )
    }
}

export const getEmailTemplateByNameAsync = (name: string) => {
    return (dispatch: any, getState: any) => {
        dispatch(setEmailTemplateLoading(true));
        switch (name) {
            case 'empty':
                dispatch(setSingleEmailTemplate(defaultEmailTemplate));
                dispatch(setEmailTemplateLoading(false));
                break;
            default:
                DataSvc.utilsSvc.getEmailTemplateByName(name).then(
                    (result: any) => {
                        dispatch(setSingleEmailTemplate(result));
                        dispatch(setEmailTemplateLoading(false));
                    },
                    (error) => { console.error(error); }
                );
        }
    }
}