import { State } from './model';
import * as t from './actionType'
import { CountriesWithCodeModel, LookUpData, PaymentIntentResponseModel } from '../../common/models/input-models';

const initialState: State = {
    isPaymentLoading: false,
    isLookUpDataLoading: false,
    isCountriesLoading: false,
    lookUpData: [],
    countries: [],
    paymentResponse: {
        clientSecret: ''
    }
};

export default (state = initialState, action: any): State => {
    switch (action.type) {
        case t.SET_PAYMENT_LOADING:
            return setPaymentLoading(state, action.payload);
        case t.SET_LOOKUP_DATA_LOADING:
            return setLookUpDataLoading(state, action.payload);
        case t.SET_LOOKUP_DATA:
            return setLookUpData(state, action.payload);
        case t.SET_PAYMENT_RESPONSE:
            return setPaymentResponse(state, action.payload);
        case t.SET_ALL_COUNTRIES_DATA:
            return setAllCountriesData(state, action.payload);
        case t.SET_ALL_COUNTRIES_LOADING:
            return setAllCountriesLoading(state, action.payload);
        default:
            return state;
    }
};

const setPaymentLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isPaymentLoading: val
    } as State;

    return newState;
}

const setLookUpDataLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isLookUpDataLoading: val
    } as State;

    return newState;
}

const setLookUpData = (state: State, val: LookUpData[]): State => {
    let newState = {
        ...state,
        lookUpData: [...val]
    } as State;

    return newState;
}

const setPaymentResponse = (state: State, val: PaymentIntentResponseModel): State => {
    let newState = {
        ...state,
        paymentResponse: { ...val }
    } as State;

    return newState;
}

const setAllCountriesData = (state: State, val: CountriesWithCodeModel[]): State => {
    let newState = {
        ...state,
        countries: [...val]
    } as State;

    return newState;
}

const setAllCountriesLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isCountriesLoading: val
    } as State;

    return newState;
}