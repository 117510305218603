import * as React from 'react';
import { Form } from 'react-bootstrap';
import { CatalogData } from '../../../../common/models/input-models';
import EupatiLoader from '../../../../common/static/loading-animation/loader';
import EupatiPricesGrid from '../../../../common/static/prices-grid';
import { State } from '../model';
import '../style.scss';

type OwnProps = {
    isCatalogsLoading: boolean;
    catalogsData: CatalogData[]
};

export type DispatchProps = {
    setCatalogsPrices: () => void;
    updateCatalogsPrices: (data: CatalogData[]) => void;
};

type Props = State & DispatchProps & OwnProps;

type OwnState = {};

export default class ComponentRoot extends React.Component<Props, OwnState> {

    componentDidMount() {
        this.props.setCatalogsPrices();
    }

    render() {

        let mainComp: any;

        let loadComp = (
            <div className='loader-wrapper'>
                <EupatiLoader />
            </div>
        )

        if (this.props.isCatalogsLoading) {
            mainComp = loadComp;
        }
        else {
            mainComp = (
                <Form.Group id='price-catalog-tbl' className='price-catalog-wrapper mb-5'>
                    <EupatiPricesGrid
                        {...{
                            catalogsData: this.props.catalogsData,
                            setCatalogsPrices: this.props.setCatalogsPrices,
                            updateCatalogsPrices: this.props.updateCatalogsPrices
                        }}
                    />
                </Form.Group>
            )
        }

        return (
            <>
                {mainComp}
            </>
        )
    }
}