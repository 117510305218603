import { NAME } from "./constants";

export const SET_LOOKUP_DATA = `${NAME}/SET_LOOKUP_DATA`;

export const SET_LOADING = `${NAME}/SET_LOADING`;

export const SET_PATIENTS = `${NAME}/SET_PATIENTS`;

export const SET_PATIENTS_LOADING = `${NAME}/SET_PATIENTS_LOADING`;

export const REMOVE_PATIENT = `${NAME}/REMOVE_PATIENT`;

export const SET_PATIENT_INFO = `allPatient/SET_PATIENT_INFO`;

export const SET_PATIENT_INFO_LOADING = `allPatient/SET_PATIENT_INFO_LOADING`;