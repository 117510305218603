import { State } from './model';
import * as t from './actionTypes';
import { LookUpData } from '../../../common/models/input-models';
import { Patient } from '../../../common/models/main-models';
import { defaultPatientInfo } from '../../patient-profile/model';

const initialState: State = {
    patients: [],
    lookUpData: [],
    isLoading: false,
    isPatientsLoading: false,
    isPatientInfoLoading: false,
    patientInfo: defaultPatientInfo
};

export default (state = initialState, action: any): State => {
    switch (action.type) {
        case t.SET_LOADING:
            return setLoading(state, action.payload);
        case t.SET_LOOKUP_DATA:
            return setLookUpData(state, action.payload);
        case t.SET_PATIENTS_LOADING:
            return setPatientsLoading(state, action.payload);
        case t.SET_PATIENTS:
            return setPatients(state, action.payload);
        case t.SET_PATIENT_INFO_LOADING:
            return setPatientInfoLoading(state, action.payload);
        case t.SET_PATIENT_INFO:
            return setPatientInfo(state, action.payload);
        default:
            return state;
    }
}

const setLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isLoading: val
    } as State;

    return newState;
}

const setLookUpData = (state: State, val: LookUpData[]): State => {
    let newState = {
        ...state,
        lookUpData: [...val]
    } as State;

    return newState;
}

const setPatientsLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isPatientsLoading: val
    } as State;

    return newState;
}

const setPatients = (state: State, val: Patient[]): State => {
    let newState = {
        ...state,
        patients: [...val]
    } as State;

    return newState;
}

const setPatientInfoLoading = (state: State, val: boolean): State => {
    let newState = {
        ...state,
        isPatientInfoLoading: val
    } as State;

    return newState;
}

const setPatientInfo = (state: State, val: Patient): State => {
    let newState = {
        ...state,
        patientInfo: { ...val }
    } as State;

    return newState;
}
