import React from "react";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { Rules } from "../../../../common/helpers";
import EupatiTextbox from "../../../../common/static/textbox";
import EupatiDropdown from "../../../../common/static/dropdown";
import EupatiToggleBtn from "../../../../common/static/toggle-btn";
import { useHistory } from "react-router";
import { Messages } from "../../../../common/helpers";
import { Project } from "../../../../common/models/main-models";
import { LookUpOptions } from "../../../../common/models/input-models";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/fontawesome-free-solid';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ReactTooltip from 'react-tooltip';

type OwnProps = {
    project: Project,
    isUserReal: boolean
};

type DispatchProps = {
    nextPage: () => void;
    hideCompanyInfoSection: () => void;
    setProject: (project: Project) => void;
    returnProperDefaultValue: (val: any) => any;
    findLookUpData: (lookUpDataType?: string) => LookUpOptions[] | null;
};

type Props = OwnProps & DispatchProps;

type FieldProps = {
    pocOrganisation: string,
    pocJobTitle: string,
    pocCountryId: number,
    pocOrganisationTypeId: number,
    pocOrganisationTypeDescription: string
};

export default function CompanyInfo(props: Props) {

    const history = useHistory();

    const { handleSubmit, control, watch, reset, formState: { errors } } = useForm();

    const fieldsData = {
        pocOrganisation: watch("pocOrganisation") as string,
        pocJobTitle: watch("pocJobTitle") as string,
        pocCountryId: watch("pocCountryId") as number,
        pocOrganisationTypeId: watch("pocOrganisationTypeId") as number,
        pocOrganisationTypeDescription: watch("pocOrganisationTypeDescription") as string
    } as FieldProps;

    const onSubmit = (data: any) => {
        if (!props.isUserReal) {
            toast.error(Messages.FAIL_AUTH_MSG);
            return history.push('/');
        }

        const updatedProject = Object.assign(props.project, data);
        props.setProject(updatedProject);
        props.nextPage();
    }

    const onBackHandler = () => {
        if (!props.isUserReal) {
            toast.error(Messages.FAIL_AUTH_MSG);
            return history.push('/');
        }

        if (adjustFieldsValues() === 0) {
            const updatedProject = Object.assign(props.project, fieldsData);
            props.setProject(updatedProject);
        }
        props.hideCompanyInfoSection();
    }

    const returnProperIconType = (icon: any) => {
        return icon as IconProp;
    }

    const toggleField = () => {
        let orgTypeId = 0;
        if (fieldsData.pocOrganisationTypeId !== undefined && fieldsData.pocOrganisationTypeId !== null) {
            orgTypeId = fieldsData.pocOrganisationTypeId;
        }
        else if (props.project.pocOrganisationTypeId !== undefined && props.project.pocOrganisationTypeId !== null) {
            orgTypeId = props.project.pocOrganisationTypeId;
        }

        const organisationTypes = props.findLookUpData('organisationType');
        return organisationTypes?.find(x => x.id.toString() === orgTypeId.toString())?.name.toLowerCase().includes('other');
    }

    const adjustFieldsValues = () => {
        if (fieldsData.pocOrganisation === undefined &&
            fieldsData.pocJobTitle === undefined &&
            fieldsData.pocCountryId === undefined &&
            fieldsData.pocOrganisationTypeId === undefined &&
            fieldsData.pocOrganisationTypeDescription === undefined) {
            return 1;
        }

        if (fieldsData.pocOrganisation === undefined &&
            props.project.pocOrganisation !== undefined) {
            fieldsData.pocOrganisation = props.project.pocOrganisation;
        }

        if (fieldsData.pocJobTitle === undefined &&
            props.project.pocJobTitle !== undefined) {
            fieldsData.pocJobTitle = props.project.pocJobTitle;
        }

        if (fieldsData.pocCountryId === undefined &&
            props.project.pocCountryId !== undefined) {
            fieldsData.pocCountryId = props.project.pocCountryId;
        }

        if (fieldsData.pocOrganisationTypeId === undefined &&
            props.project.pocOrganisationTypeId !== undefined) {
            fieldsData.pocOrganisationTypeId = props.project.pocOrganisationTypeId;
        }

        if (fieldsData.pocOrganisationTypeDescription === undefined &&
            props.project.pocOrganisationTypeDescription !== undefined) {
            fieldsData.pocOrganisationTypeDescription = props.project.pocOrganisationTypeDescription;
        }

        return 0;
    }

    return (
        <Form className='researcher' onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='researcher-info'>
                <Form.Group className='rcolumn'>

                    <Form.Group id='r-org' className='researcher-info-field mb-3'>
                        <Form.Label>Company</Form.Label>
                        <Controller
                            name='pocOrganisation'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.pocOrganisation)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.pocOrganisation?.message,
                                        placeholder: 'Please type company name'
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='r-jobTitle' className='researcher-info-field mb-3'>
                        <Form.Label>Job title</Form.Label>
                        <Controller
                            name='pocJobTitle'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.pocJobTitle)}
                            rules={Rules.requiredMessage}
                            render={({ field }) =>
                                <EupatiTextbox
                                    {...{
                                        ...field,
                                        errorMsg: errors?.pocJobTitle?.message,
                                        placeholder: 'Please type job title'
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='r-country' className='researcher-info-field'>
                        <Form.Label>Country <FontAwesomeIcon data-tip data-for='ctryInfo' icon={returnProperIconType(faInfoCircle)} /> <ReactTooltip id='ctryInfo'><span>Country, where your organisation is based.</span></ReactTooltip> </Form.Label>
                        <Controller
                            name='pocCountryId'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.pocCountryId)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiDropdown
                                    {...{
                                        ...field,
                                        errorMsg: errors?.pocCountryId?.message,
                                        fieldName: 'pocCountryId',
                                        values: props.findLookUpData('country'),
                                        defaultOptionText: 'Select country...'
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    <Form.Group id='r-orgType' className='researcher-info-field'>
                        <Form.Label>Organization type</Form.Label>
                        <Controller
                            name='pocOrganisationTypeId'
                            control={control}
                            defaultValue={props.returnProperDefaultValue(props.project.pocOrganisationTypeId)}
                            rules={Rules.selectOptionMessage}
                            render={({ field }) =>
                                <EupatiToggleBtn
                                    {...{
                                        ...field,
                                        fieldName: 'pocOrganisationTypeId',
                                        values: props.findLookUpData('organisationType'),
                                        errorMsg: errors?.pocOrganisationTypeId?.message
                                    }}
                                />
                            }
                        />
                    </Form.Group>

                    {toggleField() &&
                        <Form.Group id='r-orgTypeDescr' className='researcher-info-field'>
                            <Form.Label>Organization type description</Form.Label>
                            <Controller
                                name='pocOrganisationTypeDescription'
                                control={control}
                                defaultValue={props.returnProperDefaultValue(props.project.pocOrganisationTypeDescription)}
                                rules={Rules.notRequired}
                                render={({ field }) =>
                                    <EupatiTextbox
                                        {...{
                                            ...field,
                                            errorMsg: errors?.pocOrganisationTypeDescription?.message,
                                            placeholder: 'Please type organization type'
                                        }}
                                    />
                                }
                            />
                        </Form.Group>
                    }

                    <Form.Group id='r-btns' className='researcher-info-field'>
                        <Button className='bck' variant='secondary' onClick={onBackHandler}>Back</Button>
                        <Button className='nxt' variant='primary' type='submit'>Next</Button>
                    </Form.Group>
                </Form.Group>
            </Form.Group>
        </Form>
    )
}