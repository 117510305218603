import React from "react"
import { useHistory } from "react-router";

type OwnProps = {
    message: string;
    isFail: boolean;
    price?: number;
    plan?: string;
    package?: string;
    paymentMethod?: string;
    clientSecret?: string;
}

type DispatchProps = {
    swapComponents: (shouldSwap: boolean) => void;
    startLoading: () => void;
}

type Props = OwnProps & DispatchProps;

export default function CheckoutResult(props: Props) {
    const history = useHistory();

    function redirectTo(pathname: string) {
        history.push({
            pathname: pathname,
            state: {
                price: props.price === undefined ? -1 : props.price,
                plan: props.plan === undefined ? '' : props.plan,
                package: props.package === undefined ? '' : props.package,
                paymentMethod: props.paymentMethod === undefined ? '' : props.paymentMethod,
                clientSecret: props.clientSecret
            }
        });
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {props.isFail ? (
                <div>purchased failed - <span style={{ color: '#bb2124' }} >{props.message}</span>
                    <button onClick={() => {
                        props.startLoading();
                        setTimeout(() => {
                            props.swapComponents(false)
                        }, 200);
                    }}>
                        Try again
                    </button>
                </div>
            ) : (
                <div>thanks for the purchase - <span style={{ color: '#22bb33' }} >{props.message}</span>
                    <button onClick={() => redirectTo('/new-project')}>Proceed</button>
                </div>
            )}
        </div>
    )
}