import * as React from 'react';
import { Form } from 'react-bootstrap';

type OwnProps = {
    value: number;
    errorMsg: string;
    fieldType?: string;
    placeholder?: string;
};

export type DispatchProps = {
    onChange: (e: any) => void;
};

type OwnState = {};

type Props = DispatchProps & OwnProps;

export default class EupatiNumberbox extends React.Component<Props, OwnState> {

    render() {
        return (
            <>
                <Form.Group>
                    <Form.Control
                        type={typeof this.props.fieldType === 'undefined' ? 'number' : this.props.fieldType}
                        value={this.props.value}
                        className="eupati-numberbox"
                        placeholder={this.props.placeholder}
                        onChange={(e) => this.props.onChange(e)}
                    />
                    {!!this.props.errorMsg &&
                        <span className='text-danger'>{this.props.errorMsg}</span>
                    }
                </Form.Group>
            </>
        );
    }
}
