import * as React from 'react';
import { Form } from 'react-bootstrap';
import { Patient } from '../../../common/models/main-models';
import EupatiLoader from '../../../common/static/loading-animation/loader';
import { State } from '../model';
import PatientGeneralInfo from './personal-info';
import PatientExpcInfo from './experience-info';
import RequirementsInfo from './preference-info';
import EupatiConfirmPage from '../../../common/static/confirm-page';
import { RouteComponentProps } from '@reach/router';
import '../style.scss';
import { toast } from 'react-toastify';

type OwnProps = {};

export type DispatchProps = {
    setPatientInfo: (patient: Patient) => void;
    setLookUpData: () => void;
    savePatient: (patient: Patient) => void;
    setPatientData: (id?: string) => void;
    setUserPatientData: (userId?: string) => void;
    submitNewPatient: (patient: Patient) => void;
};

type Props = State & DispatchProps & OwnProps & RouteComponentProps<{ location: { state: { userId: string, patientId: string, isNewPatient: boolean, isUserPatient: boolean } } }>;

type OwnState = {
    showComponentByIndex: number;
    showSecondSubComponent: boolean;
};

class ComponentRoot extends React.Component<Props, OwnState> {

    constructor(props: any) {
        super(props);

        this.state = {
            showComponentByIndex: 0,
            showSecondSubComponent: true    // when this is "true" on render it will show the first subcomponent (PatientGeneralInfo --> PatientGenericInfo).
        }

        this.nextPageHandler = this.nextPageHandler.bind(this);
        this.previousPageHandler = this.previousPageHandler.bind(this);
        this.returnProperDefaultValue = this.returnProperDefaultValue.bind(this);
        this.findLookUpData = this.findLookUpData.bind(this);
        this.showLoadingComponent = this.showLoadingComponent.bind(this);
    }

    componentDidMount() {
        this.props.setLookUpData();
        const locationState = this.props.location?.state;
        if (locationState === undefined || locationState === null) {
            return toast.error("Something went wrong! Try again later.");   //  unauthorized access
        }
        else if (locationState.isNewPatient === false) {
            this.props.setPatientData(locationState.patientId);             //  admin edit patient
        }
        else if (locationState.isNewPatient === true) {
            this.props.setPatientData();                                    //  admin add patient
        }
        else if (locationState.isUserPatient === true) {
            this.props.setUserPatientData(locationState.userId);            //  user access patient profile
        }
    }

    nextPageHandler() {
        if (this.state.showComponentByIndex !== 3) {
            this.setState(prevState => ({
                showComponentByIndex: prevState.showComponentByIndex + 1,
                showSecondSubComponent: true
            }));
        }
        //window.scrollTo(0, 0);
    }

    previousPageHandler() {
        if (this.state.showComponentByIndex !== 0) {
            this.setState(prevState => ({
                showComponentByIndex: prevState.showComponentByIndex - 1,
                showSecondSubComponent: false
            }));
        }
        //window.scrollTo(0, 0);
    }

    findLookUpData(lookUpDataType?: string) {
        if (this.props.lookUpData &&
            this.props.lookUpData.length > 0) {
            return this.props.lookUpData?.filter((x: any) => x.name == lookUpDataType)[0]?.data;
        }

        return [];
    }

    returnProperDefaultValue = (val: any) => {
        if (typeof val === 'undefined' || val === null) {
            return '';
        } else if (typeof val === 'boolean') {
            return val.toString(); // it doesn't set default val when 'false'
        }

        return val;
    }

    showLoadingComponent = (isNewPatient?: boolean) => {
        if (isNewPatient && !this.props.isLoading) {
            return false;
        }
        else if (this.props.isLoading || this.props.isPatientDataLoaded) {
            return true;
        }
    }

    render() {

        let mainComponent: any;

        let loadingComponent = (
            <div className='loader-wrapper'>
                <EupatiLoader />
            </div>
        );

        const props = {
            patient: this.props.patient,
            lookUpData: this.props.lookUpData,
            isNewPatient: typeof this.props.location?.state?.isNewPatient === 'undefined' ? false : this.props.location?.state?.isNewPatient,
            showSecondSubComponent: this.state.showSecondSubComponent,
            setPatientInfo: this.props.setPatientInfo,
            nextPage: this.nextPageHandler,
            prevPage: this.previousPageHandler,
            returnProperDefaultValue: this.returnProperDefaultValue,
            findLookUpData: this.findLookUpData,
            savePatient: this.props.savePatient,
            submitNewPatient: this.props.submitNewPatient
        };

        const spanStyles = {
            fontWeight: 'bolder',
            //textDecoration: 'underline',
            backgroundColor: '#00749F',
            borderRadius: '30px',
            color: '#fff'
        } as any;

        if (this.showLoadingComponent(this.props.location?.state?.isNewPatient)) {
            mainComponent = loadingComponent;
        }
        else {
            mainComponent = (
                <>
                    <h2 className='heading' style={this.state.showComponentByIndex === 3 ? { display: 'none' } : {}}>Patient Profile</h2>
                    {this.state.showComponentByIndex !== 3 &&
                        <Form.Group className='titles-wrapper'>
                            <div className='titles'>
                                <div className='titles-title' style={this.state.showComponentByIndex === 0 ? { ...spanStyles } : {}}>
                                    <span>1. General</span>
                                </div>
                                <div className='titles-title' style={this.state.showComponentByIndex === 1 ? { ...spanStyles } : {}}>
                                    <span>2. Experience</span>
                                </div>
                                <div className='titles-title' style={this.state.showComponentByIndex === 2 ? { ...spanStyles } : {}}>
                                    <span>3. Preferences</span>
                                </div>
                            </div>
                        </Form.Group>
                    }
                    {this.state.showComponentByIndex === 0 && <PatientGeneralInfo {...props} />}
                    {this.state.showComponentByIndex === 1 && <PatientExpcInfo {...props} />}
                    {this.state.showComponentByIndex === 2 && <RequirementsInfo {...props} />}
                    {this.state.showComponentByIndex === 3 && <EupatiConfirmPage />}
                </>
            )
        }

        return (
            <>
                {mainComponent}
            </>
        );
    }
}

export default ComponentRoot;