import { EmailAutomationTriggersMenu, EmailTemplateData, LookUpOptions, ModelPropsNames } from "../../../common/models/input-models";

export type State = {
    isEmailTemplatesLoading: boolean;
    isSingleEmailTemplateLoading: boolean;
    emailTemplatesData: EmailTemplateData[];
    singleEmailTemplateData: EmailTemplateData;
    projectPropsNames: ModelPropsNames[];
    patientPropsNames: ModelPropsNames[];
    triggersMenuOptions: EmailAutomationTriggersMenu[];
    isTriggersMenuOptionsLoading: boolean;
    receiversMenuOptions: LookUpOptions[];
    choosenReceivers: number[];
};

export const emptyTemplate = {
    id: '',
    name: '',
    htmlContent: ''
} as EmailTemplateData;