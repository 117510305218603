import * as React from 'react';
import { Form } from 'react-bootstrap';
import EupatiLoader from '../../../../common/static/loading-animation/loader';
import EupatiAllPatients from '../../../../common/static/patients-grid';
import { State } from '../model';
import '../style.scss';

type OwnProps = {};

export type DispatchProps = {
    setLookUpData: () => void;
    setPatients: () => void;
    removePatientById: (patientId: string) => void;
    setPatientInfo: (patientId: string) => void;
};

type Props = State & DispatchProps & OwnProps;

type OwnState = {};

export default class ComponentRoot extends React.Component<Props, OwnState> {

    componentDidMount() {
        this.props.setPatients();
        this.props.setLookUpData();
    }

    render() {

        let mainComponent: any;

        let loadingComponent = (
            <div className='loader-wrapper'>
                <EupatiLoader />
            </div>
        )

        if (this.props.isLoading ||
            this.props.isPatientsLoading) {
            mainComponent = loadingComponent;
        }
        else {
            mainComponent = (
                <Form.Group id='all-patients-tbl' className='all-patients-wrapper mb-5'>
                    <EupatiAllPatients
                        {...{
                            lookUpData: this.props.lookUpData,
                            patients: this.props.patients,
                            removePatientById: this.props.removePatientById,
                            showPatientDetails: this.props.setPatientInfo,
                            patientDetails: this.props.patientInfo
                        }}
                    />
                </Form.Group>
            )
        }

        return (
            <>
                {mainComponent}
            </>
        );
    }
}